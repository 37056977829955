import React from "react"
import styled from "styled-components/macro"
import { useQuery } from "@apollo/client"
import { useNavigate } from "react-router-dom"

import ALL_STORE_GROUPS from "graphql/queries/store/AllStoreGroups"
import ALL_INVENTORIES from "graphql/queries/inventory/AllInventories"
import SEARCH_DISCOUNT_CODE_RULES from "graphql/queries/discount/SearchDiscountCodeRules"
import SEARCH_DISCOUNT_RULES from "graphql/queries/discount/SearchDiscountRules"
import SEARCH_TAX_GROUPS from "graphql/queries/tax/SearchTaxGroups"

import { DailyOrders } from "components/Dashboard/DailyOrders"
import { CountCard } from "components/Dashboard/CountCard"
import PageHeader from "components/Ui/Page/PageHeader"
import LastFiveDays from "components/Dashboard/LastFiveDays"
import Providers from "components/Dashboard/Providers"
import CartRules from "components/Dashboard/CartRules"
import DiscountCodes from "components/Dashboard/DiscountCodes"

import { ReactComponent as StoreIcon } from "images/icons/store.svg"
import { ReactComponent as InventoryIcon } from "images/icons/warehouse.svg"
import { ReactComponent as TaxIcon } from "images/icons/badge-percent.svg"
import underline from "images/underline.svg"
import { useSelector } from "react-redux"
import { selectUserAttributes, selectUserGroups } from "lib/store/services/auth/selectors"
import { UserError } from "./UserError"

const Flex = styled.div`
  display: flex;
`

const Grid = styled.div`
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  gap: 1.5rem;
  margin-bottom: 1.5rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    margin-bottom: 3rem;
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_X_LARGE} {
    grid-template-columns: repeat(6, 1fr);
  }
`

const WelcomeGreeting = styled.h2`
  margin-bottom: 4rem;
  font-size: 2.4rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    margin-bottom: 6rem;
    font-size: 3.6rem;
  }

  span {
    padding-bottom: 0.3rem;
    background-image: url(${underline});
    background-repeat: no-repeat;
    background-position: center bottom;
  }
`

export function Home() {
  const { data: storeGroupsData } = useQuery(ALL_STORE_GROUPS, {
    fetchPolicy: "cache-first"
  })
  const { data: inventoriesData } = useQuery(ALL_INVENTORIES, {
    fetchPolicy: "cache-first"
  })
  const { data: discountCodeRuleData } = useQuery(SEARCH_DISCOUNT_CODE_RULES, {
    variables: { from: 0, size: 1000 },
    fetchPolicy: "cache-first"
  })

  const { data: discountRulesData } = useQuery(SEARCH_DISCOUNT_RULES, {
    variables: { from: 0, size: 1000 },
    fetchPolicy: "cache-first"
  })

  const { data: taxGroupData } = useQuery(SEARCH_TAX_GROUPS, {
    variables: { from: 0, size: 1000 },
    fetchPolicy: "cache-first"
  })

  const navigate = useNavigate()
  const userAttributes = useSelector(selectUserAttributes)
  const userGroups = useSelector(selectUserGroups)

    if (!userGroups)
      return <UserError />


  return (
    <>
      <PageHeader title="Dashboard" />
      <WelcomeGreeting>
        Welcome back
        {userAttributes?.given_name && (
          <>
            , <span>{userAttributes.given_name}</span>
          </>
        )}
      </WelcomeGreeting>
      <Grid>
        <DailyOrders />
        <CountCard
          icon={StoreIcon}
          count={storeGroupsData?.getStoreGroups?.length}
          text="Store groups"
          onClick={() => navigate("/stores/store-groups")}
        />
        <CountCard
          icon={TaxIcon}
          count={taxGroupData?.searchTaxGroups?.total}
          text="Tax groups"
          onClick={() => navigate("/stores/taxes")}
        />
        <CountCard
          icon={InventoryIcon}
          count={inventoriesData?.getInventories?.length}
          text="Inventories"
          onClick={() => navigate("/stores/inventories")}
        />
        <DiscountCodes discountCodeRules={discountCodeRuleData?.searchDiscountCodeRules} />
        <CartRules cartRules={discountRulesData?.searchDiscountRules} />
      </Grid>
      <Flex>
        <LastFiveDays />
      </Flex>
      <Providers />
    </>
  )
}
