import React, { useState } from "react"
import Box from "../../../components/Ui/Box"
import { TabText } from "./ManageOrder.styled"
import { useQuery } from "@apollo/client"
import GET_ORDER from "../../../graphql/queries/order/GetOrder"
import { useParams } from "react-router-dom"

import {
  OrderCancellation,
  OrderCompensation,
  OrderDelivery,
  OrderRefund,
  OrderRelease
} from "lib/types/generated/graphql-types"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import PageHeader from "../../../components/Ui/Page/PageHeader"
import PrimaryButton from "../../../components/Ui/Buttons/PrimaryButton"
import CancelOrder from "../../../components/Order/ManageOrder/CancelOrder/CancelOrder"
import FailedCancellation from "../../../components/Order/ManageOrder/CancelOrder/FailedCancellation"
import RefundOrder from "../../../components/Order/ManageOrder/RefundOrder/RefundOrder"
import DeliveryOrder from "../../../components/Order/ManageOrder/DeliverOrder/DeliveryOrder"
import CompensationOrder from "../../../components/Order/ManageOrder/CompensationOrder/CompensationOrder"
import { NoticeBanner } from "../../../components/Ui/Messages/NoticeBanner"
import ReleaseOrder from "../../../components/Order/ManageOrder/ReleaseOrder/ReleaseOrder"
import PendingCancellation from "../../../components/Order/ManageOrder/CancelOrder/PendingCancellation"

const ManageOrder = () => {
  const { id } = useParams()
  const { data, refetch, loading } = useQuery(GET_ORDER, { variables: { id } })
  const [showCancelPopup, setShowCancelPopup] = useState(false)

  const order = data?.Order?.details
  const orderStatus = data?.Order?.status
  const failedDeliveries = order?.Deliveries?.filter((delivery: OrderDelivery) => !!delivery.failed)
  const failedReleases = order?.Releases?.filter((release: OrderRelease) => !!release.failed)
  const failedCompensations = order?.Compensations?.filter(
    (compensation: OrderCompensation) => !!compensation.failed
  )
  const failedRefunds = order?.Refunds?.filter((refund: OrderRefund) => !!refund.failed)
  const failedCancellation = !!order?.Cancellations[0]?.failed
  const completedCompensationsExists =
    order?.Compensations.filter((compensation: OrderCompensation) => compensation.completed)
      .length > 0
  const completedRefundsExists =
    order?.Refunds.filter((refund: OrderRefund) => refund.completed).length > 0
  const completedDeliveriesExists =
    order?.Deliveries.filter((delivery: OrderDelivery) => delivery.completed).length > 0
  const completedReleasesExists =
    order?.Releases.filter((release: OrderRelease) => release.completed).length > 0

  const pendingCancellations = order?.Cancellations?.filter(
    (cancellation: OrderCancellation) =>
      !cancellation.completed && cancellation.started && !cancellation.failed
  )

  const showDelivery = () => !completedReleasesExists && pendingCancellations && pendingCancellations?.length === 0
  const showRelease = () =>
    !completedReleasesExists &&
    !orderStatus?.states?.includes("DELIVERED") &&
    completedDeliveriesExists
  const showCompensation = () => !completedRefundsExists && completedDeliveriesExists
  const showRefund = () => !completedCompensationsExists && completedDeliveriesExists

  return (
    <Box padding="0" margin="0">
      <PageHeader
        title={"Manage order #" + (order?.reference ?? "")}
        description={<>Manage order</>}
        goBackLinkUrl={`/orders/${id}`}
        goBackLinkText="Back to order"
        hasTabs
      >
        {((order?.Cancellations.length === 0 && order.Deliveries.length === 0) ||
          (order?.Cancellations.length > 0 && !order?.Cancellations[0].completed)) && (
          <PrimaryButton mode="delete" handleClick={() => setShowCancelPopup(true)}>
            Cancel order
          </PrimaryButton>
        )}
      </PageHeader>
      <CancelOrder
        setShowCancelPopup={setShowCancelPopup}
        showCancelPopup={showCancelPopup}
        order={order}
        refetch={refetch}
      />
      <Tabs>
        <TabList>
          {showDelivery() && <Tab>Deliveries</Tab>}
          {completedDeliveriesExists && (
            <>
              {showRelease() && <Tab>Releases</Tab>}
              {!completedCompensationsExists && <Tab>Refunds</Tab>}
              {showCompensation() && <Tab>Compensations</Tab>}
            </>
          )}
          {failedCancellation ||
            (pendingCancellations?.length > 0 && (
              <Tab>
                <TabText>Cancellations</TabText>
              </Tab>
            ))}
        </TabList>
        {showDelivery() && (
          <TabPanel>
            <DeliveryOrder
              order={order}
              refetch={refetch}
              loading={loading}
              failedDeliveries={failedDeliveries}
              orderStatus={orderStatus}
            />
          </TabPanel>
        )}
        {showRelease() && (
          <TabPanel>
            <ReleaseOrder
              order={order}
              refetch={refetch}
              loading={loading}
              failedReleases={failedReleases}
              orderStatus={orderStatus}
            />
          </TabPanel>
        )}
        {showRefund() && (
          <TabPanel>
            {!completedRefundsExists && !completedCompensationsExists && (
              <NoticeBanner type="reminder">{`You cannot issue both a refund and compensation for the same order. Once a refund is initiated, compensation cannot be applied to the order.`}</NoticeBanner>
            )}
            <RefundOrder
              order={order}
              refetch={refetch}
              failedRefunds={failedRefunds}
              loading={loading}
              orderStatus={orderStatus}
            />
          </TabPanel>
        )}
        {showCompensation() && (
          <TabPanel>
            {!completedRefundsExists && !completedCompensationsExists && (
              <NoticeBanner type="reminder">{`You cannot issue both a compensation and refund for the same order. Once a compensation is initiated, refund cannot be applied to the order.`}</NoticeBanner>
            )}
            <CompensationOrder
              order={order}
              refetch={refetch}
              failedCompensations={failedCompensations}
              loading={loading}
              orderStatus={orderStatus}
            />
          </TabPanel>
        )}
        {pendingCancellations?.length > 0 && (
          <TabPanel>
            <PendingCancellation pendingCancellation={pendingCancellations[0]} orderRefetch={refetch}/>
          </TabPanel>
        )}
        {failedCancellation && (
          <TabPanel>
            <FailedCancellation
              failedCancellation={order?.Cancellations[0]}
              orderId={order.id}
              refetch={refetch}
              orderStatus={orderStatus.history}
            />
          </TabPanel>
        )}
      </Tabs>
    </Box>
  )
}

export default ManageOrder
