import styled from "styled-components/macro"

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  border-radius: 0.5rem;
  padding: 1.7rem 2rem;
  bottom: 3rem;
  right: 3rem;
  font-weight: 700;
  letter-spacing: 0.05rem;
  background: ${(p) => p.theme.colors.error.background};
  border: 0.1rem solid ${(p) => p.theme.colors.borderLight};

  p {
    margin: 0;
    color: ${(p) => p.theme.colors.error.text};
  }

  p::first-letter {
    text-transform: capitalize;
  }
`
export const Title = styled.div`
  display: flex;
  align-items: center;

    span::first-letter {
      text-transform: capitalize;
      margin-left: 0.5rem;
    }


  svg {
    height: 2.2rem;
    margin-right: 1rem;
  }
`
