import { Order, OrderRefund, OrderStatus } from "@lib/types/generated/graphql-types"
import FailedRefund from "./FailedRefund"
import { Container } from "../../../../views/Orders/ManageOrder/ManageOrder.styled"
import { OrderSummary } from "./OrderSummary/OrderSummary"
import { Cart } from "./Cart/Cart"
import React from "react"

type Props = {
  order: Order
  refetch: () => void
  loading: boolean
  failedRefunds: OrderRefund[]
  orderStatus: OrderStatus
}

const RefundOrder = ({ order, refetch, loading, failedRefunds, orderStatus }: Props) => {
  return (
    <>
      {failedRefunds?.length > 0 ? (
        failedRefunds.map((refund: OrderRefund) => (
          <FailedRefund
            key={refund.id}
            failedRefund={refund}
            currencyCode={order.currencyCode}
            orderId={order.id ?? ""}
            orderStatus={orderStatus.history}
          />
        ))
      ) : (
        <Container>
          <div>
            <OrderSummary
              order={order}
              refetch={refetch}
              orderLoading={loading}
            />
          </div>
          <div>
            <Cart order={order} refetch={refetch} />
          </div>
        </Container>
      )}
    </>
  )
}

export default RefundOrder
