import React from "react"
import { Container, Divider, Provider, Row } from "./Totals.styled"

import DineroFactory from "dinero.js"

import { Maybe, OrderDeliveryTotals, OrderRefundTotals, RefundFee } from "@lib/types/generated/graphql-types"
import Money from "../../Money"
import { Loader } from "../../Ui/Loader"

type Props = {
  totals: OrderRefundTotals | OrderDeliveryTotals
  currencyCode: string
  label: string
  refundFee?: Maybe<RefundFee> | undefined
}

const Totals = ({ totals, currencyCode, label, refundFee }: Props) => (
  <Container>
    {totals ? (
      <>
        <Row>
          <h4>Subtotal</h4> <Money amount={totals.subTotal} currencyUnit={currencyCode as DineroFactory.Currency}/>
        </Row>
        <Row>
          <h4>Shipping total</h4> <Money amount={totals.shippingTotal} currencyUnit={currencyCode as DineroFactory.Currency}/>
        </Row>
        {refundFee && <Row>
          <Provider>Refund fee</Provider> - <Money amount={refundFee.amount} currencyUnit={currencyCode as DineroFactory.Currency}/>
        </Row>}
        {totals.bonusTotal > 0 && <Row>
          <Provider>Bonus total</Provider> - <Money amount={totals.bonusTotal} currencyUnit={currencyCode as DineroFactory.Currency}/>
        </Row>}
        {totals.giftCardTotal > 0 && <Row>
          <Provider>Gift card total</Provider> - <Money amount={totals.giftCardTotal} currencyUnit={currencyCode as DineroFactory.Currency}/>
        </Row>}
        <Divider />
        <Row>
          <h3>{label}</h3> <h3><Money amount={totals.grandTotal} currencyUnit={currencyCode as DineroFactory.Currency}/></h3>
        </Row>
      </>
    ) : (
      <Loader />
    )}
  </Container>
)

export default Totals
