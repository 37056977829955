import React, { Dispatch, SetStateAction } from "react"
import { Order } from "lib/types/generated/graphql-types"

import { ButtonWrapper, CartContainer, CartItems, EmptyContainer, Label } from "./Cart.styled"

import CartItem from "./CartItem"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import SecondaryButton from "../../../../Ui/Buttons/SecondaryButton"
import { useMutation } from "@apollo/client"
import ShippingFees from "./ShippingFees"
import DELETE_COMPENSATION from "../../../../../graphql/mutations/order/compensation/DeleteCompensation"
import Totals from "./Totals/Totals"
import Reason from "./Reason"
import { handleOrderManagementError } from "../../Helpers/errors"

type Props = {
  order: Order
  refetch: () => void
  setShowConfirmation: Dispatch<SetStateAction<boolean>>
  summaryMode?: boolean
}

export const Cart = ({ order, refetch, setShowConfirmation, summaryMode = false }: Props) => {
  const filteredCompensations = order?.Compensations?.filter(
    (compensation) => !compensation.completed && !compensation.started
  )

  const [deleteCompensation, { loading: deleteLoading }] = useMutation(DELETE_COMPENSATION, {
    onCompleted: () => refetch(),
    onError: (error) => {
      handleOrderManagementError(error)
    }
  })

  if (!order) return null
  return (
    <>
      {filteredCompensations?.length > 0 ? (
        <>
          <h2>Compensation draft</h2>
          {filteredCompensations?.map((compensation) => (
            <CartContainer key={compensation.id}>
              <CartItems>
                {compensation?.orderLines?.map((compensationOrderLine) => (
                  <CartItem
                    currencyCode={order.currencyCode}
                    key={compensationOrderLine.orderLineId}
                    item={compensationOrderLine}
                    orderLine={order?.orderLines?.find(
                      (ol) => ol.id === compensationOrderLine.orderLineId
                    )}
                    compensation={compensation}
                    refetch={refetch}
                  />
                ))}
                <ShippingFees
                  compensation={compensation}
                  refetch={refetch}
                  currencyCode={order.currencyCode}
                  order={order}
                />
                <Reason refetch={refetch} compensation={compensation} />
                <Label>To be compensated: </Label>
                <br />
                <Totals compensation={compensation} order={order} refetch={refetch} />
              </CartItems>
              <ButtonWrapper>
                {!summaryMode && (
                  <SecondaryButton
                    loading={deleteLoading}
                    handleClick={() =>
                      deleteCompensation({ variables: { compensationId: compensation.id } })
                    }
                  >
                    Cancel compensation draft
                  </SecondaryButton>
                )}
                {!summaryMode && (
                  <PrimaryButton handleClick={() => setShowConfirmation(true)}>Next</PrimaryButton>
                )}
              </ButtonWrapper>
            </CartContainer>
          ))}
        </>
      ) : (
        <>
          <h2>Compensation draft</h2>
          <EmptyContainer>
            <h2>Create compensation to finalize.</h2>
          </EmptyContainer>
        </>
      )}
    </>
  )
}
