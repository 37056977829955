import React, { useState } from "react"
import dayjs from "dayjs"
import { OrderDelivery, OrderRefund, OrderStatusLogEntry } from "@lib/types/generated/graphql-types"
import {
  Entry,
  EntryDate,
  EntryEvent,
  Icon,
  Info,
  Message,
  Timeline,
  Url
} from "./OrderHistoryEntry.styled"

import { ReactComponent as CheckIcon } from "images/icons/check-thick.svg"
import { ReactComponent as FailedIcon } from "images/icons/xmark-thick.svg"
import { ReactComponent as ChevronDown } from "images/icons/chevron-down.svg"
import { ReactComponent as ChevronUp } from "images/icons/chevron-up.svg"

import CopyButton from "../Ui/CopyButton"
import Money from "../Money"
import DineroFactory from "dinero.js"

type Props = {
  entry: OrderStatusLogEntry
  currencyCode: string
  deliveries: OrderDelivery[]
  refunds: OrderRefund[]
}

const OrderHistoryEntry = ({ entry, currencyCode, deliveries, refunds }: Props) => {
  const [expanded, setExpanded] = useState(false)
  const getIcon = (status: string) => {
    if (status.endsWith("_SUCCESS") || status === "SKIPPED" || status === "MANUAL") {
      return (
        <Icon $type="success">
          <CheckIcon />
        </Icon>
      )
    }

    if (status.endsWith("_FAILURE")) {
      return (
        <Icon $type="failed">
          <FailedIcon />
        </Icon>
      )
    }

    return <Icon $type="default" />
  }

  const getInfo = (entry: OrderStatusLogEntry) => {
    if (entry.status === "MANUAL") {
      switch (entry.type) {
        case "CAPTURE_PAYMENT": {
          const delivery = deliveries.find((d) => d.id === entry.operationId)
          return (
            <div>
              <div>
                <b>Reference:</b> {delivery?.capturedPayment?.reference}
              </div>
              <div>
                <b>Total captured:</b>{" "}
                <Money
                  amount={delivery?.totals.grandTotal ?? 0}
                  currencyUnit={currencyCode as DineroFactory.Currency}
                />
              </div>
            </div>
          )
        }
        case "REFUND_PAYMENT": {
          const refund = refunds.find((refund) => refund.id === entry.operationId)
          return (
            <div>
              <div>
                <b>Reference:</b> {refund?.refundedPayment?.reference}
              </div>
              <div>
                <b>Total refunded:</b>{" "}
                <Money
                  amount={refund?.totals.grandTotal ?? 0}
                  currencyUnit={currencyCode as DineroFactory.Currency}
                />
              </div>
            </div>
          )
        }
        case "TRACK_SHIPMENT": {
          const delivery = deliveries.find((d) => d.id === entry.operationId)
          return (
            <div>
              <div>
                <b>Reference:</b> {delivery?.tracking?.reference}
              </div>
              <Url>
                <b>Url:</b>
                <a href={delivery?.tracking?.url} target="_blank" rel="noreferrer">
                  Tracking url
                </a>
                <CopyButton string={delivery?.tracking?.url} />
              </Url>
              <div>
                <b>Company: </b>
                {delivery?.tracking?.shippingCompany || "-"}
              </div>
              <div>
                <b>Method: </b>
                {delivery?.tracking?.shippingMethod}
              </div>
            </div>
          )
        }
        default:
          return <></>
      }
    }

    switch (entry.status) {
      case "CAPTURE_PAYMENT_SUCCESS": {
        const delivery = deliveries.find((d) => d.id === entry.operationId)
        return (
          <div>
            <b>Totals:</b>
            <div>
              <b>Total captured:</b>{" "}
              <Money
                amount={delivery?.totals.grandTotal ?? 0}
                currencyUnit={currencyCode as DineroFactory.Currency}
              />
            </div>
          </div>
        )
      }
      case "CAPTURE_REFUND_SUCCESS": {
        const refund = refunds.find((refund) => refund.id === entry.operationId)
        return (
          <div>
            <b>Totals:</b>
            <div>
              <b>Total refunded:</b>{" "}
              <Money
                amount={refund?.totals.grandTotal ?? 0}
                currencyUnit={currencyCode as DineroFactory.Currency}
              />
            </div>
          </div>
        )
      }
      case "TRACK_SHIPMENT_SUCCESS": {
        const delivery = deliveries.find((d) => d.id === entry.operationId)
        return (
          <div>
            <div>
              <b>Reference:</b> {delivery?.tracking?.reference}
            </div>
            <Url>
              <b>Url:</b>
              <a href={delivery?.tracking?.url} target="_blank" rel="noreferrer">
                Tracking url
              </a>
              <CopyButton string={delivery?.tracking?.url} />
            </Url>
            <div>
              <b>Company: </b>
              {delivery?.tracking?.shippingCompany || "-"}
            </div>
            <div>
              <b>Method: </b>
              {delivery?.tracking?.shippingMethod}
            </div>
          </div>
        )
      }
      default:
        return <></>
    }
  }

  const getEntry = (entry: OrderStatusLogEntry) => {
    switch (entry.status) {
      case "CAPTURE_PAYMENT_SUCCESS":
      case "TRACK_SHIPMENT_SUCCESS":
      case "MANUAL":
        return (
          <Entry $expanded={expanded}>
            <Timeline>{getIcon(entry.status)}</Timeline>
            <EntryEvent>
              <div>
                <Message $expanded={expanded}>
                  {entry?.message ?? entry.status}
                  {expanded ? (
                    <ChevronUp onClick={() => setExpanded(false)} />
                  ) : (
                    <ChevronDown onClick={() => setExpanded(true)} />
                  )}
                </Message>
                {expanded && <Info>{getInfo(entry)}</Info>}
              </div>
              <EntryDate>{dayjs(entry.timestamp).format("YYYY-MM-DD: HH:mm")}</EntryDate>
            </EntryEvent>
          </Entry>
        )
      default:
        return (
          <Entry>
            <Timeline>{getIcon(entry.status)}</Timeline>
            <EntryEvent>
              <div>
                {entry.errorMessage ? (
                  <Message $expanded={expanded}>
                    {entry?.message ?? entry.status}
                    {expanded ? (
                      <ChevronUp onClick={() => setExpanded(false)} />
                    ) : (
                      <ChevronDown onClick={() => setExpanded(true)} />
                    )}
                  </Message>
                ) : (
                  <Message $expanded={expanded}>
                    {entry?.message ?? entry.status}
                  </Message>
                )}
                {expanded && <Info><b>Error message: </b>{entry.errorMessage}</Info>}
              </div>
              <EntryDate>{dayjs(entry.timestamp).format("YYYY-MM-DD: HH:mm")}</EntryDate>
            </EntryEvent>
          </Entry>
        )
    }
  }
  return getEntry(entry)
}

export default OrderHistoryEntry
