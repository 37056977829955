import {
  InputActionBonus,
  InputActionGiftCard, InputActionPaymentDelivery,
  InputActionShipping,
  InputActionType,
  Order,
  OrderDelivery, OrderStatus
} from "lib/types/generated/graphql-types"
import FailedDelivery from "./FailedDelivery"
import { Container } from "../../../../views/Orders/ManageOrder/ManageOrder.styled"
import { OrderSummary } from "./OrderSummary/OrderSummary"
import { Cart } from "./Cart/Cart"
import React, { useState } from "react"
import DeliveryAction from "./Cart/DeliveryAction"
import StatusPopup from "./Cart/StatusPopup"
import PendingDelivery from "./PendingDelivery"

type Props = {
  order: Order
  refetch: () => void
  loading: boolean
  failedDeliveries: OrderDelivery[]
  orderStatus: OrderStatus
}

const DeliveryOrder = ({ order, refetch, loading, failedDeliveries, orderStatus }: Props) => {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showStatusPopup, setShowStatusPopup] = useState(false)
  const [startedDelivery, setStartedDelivery] = useState("")
  const filteredDeliveries = order?.Deliveries?.filter(
    (delivery) => !delivery.completed && !delivery.started
  )
  const pendingDeliveries = order?.Deliveries?.filter(
    (delivery) => !delivery.completed && delivery.started && !delivery.failed
  )

  const [giftCardAction, setGiftCardAction] = useState<InputActionGiftCard>({
    actionType: InputActionType.Auto
  })
  const [bonusAction, setBonusAction] = useState<InputActionBonus>({
    actionType: InputActionType.Auto
  })
  const [paymentAction, setPaymentAction] = useState<InputActionPaymentDelivery>({
    actionType: InputActionType.Auto
  })
  const [shippingAction, setShippingAction] = useState<InputActionShipping>({
    actionType: InputActionType.Auto
  })

  if (pendingDeliveries?.length > 0 && !showStatusPopup)
    return (
      <PendingDelivery pendingDelivery={pendingDeliveries[0]} orderRefetch={refetch} orderLoading={loading}/>
    )
  return (
    <>
      {showStatusPopup && startedDelivery && (
        <StatusPopup
          setShowStatusPopup={setShowStatusPopup}
          deliveryId={startedDelivery}
          refetchOrder={refetch}
        />
      )}
      {failedDeliveries?.length > 0 ? (
        failedDeliveries.map((delivery: OrderDelivery) => (
          <FailedDelivery
            key={delivery.id}
            failedDelivery={delivery}
            currencyCode={order.currencyCode}
            setShowStatusPopup={setShowStatusPopup}
            setStartedDelivery={setStartedDelivery}
            orderStatus={orderStatus.history}
            refetch={refetch}
          />
        ))
      ) : !showConfirmation ? (
        <Container>
          <div>
            <OrderSummary order={order} refetch={refetch} orderLoading={loading} />
          </div>
          <div>
            <Cart order={order} refetch={refetch} setShowConfirmation={setShowConfirmation} />
          </div>
        </Container>
      ) : (
        <Container>
          <div>
            <Cart order={order} refetch={refetch} setShowConfirmation={setShowConfirmation} />
          </div>
          <div>
            {filteredDeliveries?.map((delivery) => (
              <DeliveryAction
                key={delivery.id}
                delivery={delivery}
                actionState={{
                  giftCardAction,
                  setGiftCardAction,
                  bonusAction,
                  setBonusAction,
                  paymentAction,
                  setPaymentAction,
                  shippingAction,
                  setShippingAction
                }}
                orderId={order.id}
                setShowStatusPopup={setShowStatusPopup}
                setStartedDelivery={setStartedDelivery}
                setShowConfirmation={setShowConfirmation}
              />
            ))}
          </div>
        </Container>
      )}
    </>
  )
}

export default DeliveryOrder
