import React from "react"
import dayjs from "dayjs"
import { OrderStatusLogEntry } from "@lib/types/generated/graphql-types"
import { ReactComponent as FailedIcon } from "images/icons/xmark-thick.svg"

import { Entry, EntryDate, EntryEvent, Icon, IconWrapper, Info, Message } from "./ErrorMessageEntry.styled"


type Props = {
  entry: OrderStatusLogEntry
}

const ErrorMessageEntry = ({ entry }: Props) => {

        return (
          <Entry>
            <IconWrapper>
              <Icon >
                <FailedIcon />
              </Icon>
            </IconWrapper>
            <EntryEvent>
              <div>
                {entry.errorMessage ? (
                  <Message >
                    {entry?.message ?? entry.status}
                  </Message>
                ) : (
                  <Message>{entry?.message ?? entry.status}</Message>
                )}
                  <Info>
                    <b>Error message: </b>
                    {entry.errorMessage}
                  </Info>
              </div>
              <EntryDate>{dayjs(entry.timestamp).format("YYYY-MM-DD: HH:mm")}</EntryDate>
            </EntryEvent>
          </Entry>
        )
}

export default ErrorMessageEntry
