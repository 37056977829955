import React, { useState } from "react"
import {
  InputActionBonus,
  InputActionGiftCard,
  InputActionPaymentCancellation,
  InputActionType,
  OrderCancellation,
  OrderStatusLogEntry
} from "lib/types/generated/graphql-types"
import { Container, Provider, ProviderWrapper, Title } from "./FailedCancellation.styled"
import { useMutation, useQuery } from "@apollo/client"
import SecondaryButton from "../../../Ui/Buttons/SecondaryButton"
import CopyButton from "../../../Ui/CopyButton"
import Popup from "../../../Ui/Popup"
import { ActionDropdownButton } from "../../../Ui/Buttons/ActionDropdownButton"
import Input from "../../../Ui/Form/Input"
import { ReactComponent as GiftCardIcon } from "../../../../images/icons/gift.svg"
import { ReactComponent as BonusIcon } from "../../../../images/icons/hand-holding-dollar.svg"
import { ReactComponent as PaymentIcon } from "../../../../images/icons/credit-card.svg"
import RESTART_CANCELLATION from "../../../../graphql/mutations/order/cancellation/RestartCancellation"
import GET_CANCELLATION from "../../../../graphql/queries/order/GetCancellation"
import { ActionWrapper, GiftCardInputWrapper, InfoWrapper, LabelWrapper, StyledActionDropdownButton } from "./CancellationAction.styled"
import StatusPopup from "./StatusPopup"
import ErrorMessages from "../ErrorMessages"
import { handleOrderManagementError } from "../Helpers/errors"

type Providers = {
  bonus: boolean
  giftCard: boolean
  payment: boolean
}

type Props = {
  failedCancellation: OrderCancellation
  orderId: string
  refetch: () => void
  orderStatus: OrderStatusLogEntry[]
}

enum FAILURE  {
  BONUS = "CANCEL_BONUS_FAILURE",
  GIFT_CARD = "CANCEL_GIFT_CARD_FAILURE",
  PAYMENT = "CANCEL_PAYMENT_FAILURE"
}

const FailedCancellation = ({ failedCancellation, refetch, orderStatus }: Props) => {
  const [failedProviders, setFailedProviders] = useState<Providers | undefined>()
  const [showPopup, setShowPopup] = useState(false)
  const [showStatusPopup, setShowStatusPopup] = useState(false)
  const [giftCardAction, setGiftCardAction] = useState<InputActionGiftCard>({actionType: InputActionType.Auto})
  const [bonusAction, setBonusAction] = useState<InputActionBonus>({actionType: InputActionType.Auto})
  const [paymentAction, setPaymentAction] = useState<InputActionPaymentCancellation>({actionType: InputActionType.Auto})

  const [restartCancellation, {loading}] = useMutation(RESTART_CANCELLATION, {
    onCompleted: () => {
      setShowPopup(false)
      setShowStatusPopup(true)
    },
    onError: (error) => {
      handleOrderManagementError(error)
    }
  })

  const restartOrderCancellation = () => {
    restartCancellation({
      variables: {
        cancellationId: failedCancellation.id,
        input: {
          ...(failedProviders?.bonus && { bonus: bonusAction }),
          ...(failedProviders?.giftCard && { giftCard: giftCardAction }),
          ...(failedProviders?.payment && {payment: paymentAction})
        }
      }
    })
  }

  const updateGiftCardActionCards = (id: string, value: string) => {
    const idExists = giftCardAction?.giftCards?.find((giftCard) => giftCard.giftCardId === id)
    if (idExists) {
      return setGiftCardAction({
        ...giftCardAction,
        giftCards: giftCardAction?.giftCards?.map((giftCard) =>
          giftCard.giftCardId === id
            ? {
              giftCardId: id,
              transactionId: value
            }
            : giftCard
        )
      })
    }
    return setGiftCardAction({
      ...giftCardAction,
      giftCards: [...(giftCardAction.giftCards ?? []), { giftCardId: id, transactionId: value }]
    })
  }

  const updatePaymentCaptureReference = (reference: string) => {
    setPaymentAction({ ...paymentAction, reference: reference })
  }

  useQuery(GET_CANCELLATION, {
    variables: { cancellationId: failedCancellation.id },
    onCompleted: (data) => {
      const cancellationDetails = data?.Order?.cancellation
      setFailedProviders({
        bonus: cancellationDetails?.bonusProvider?.status?.current === FAILURE.BONUS,
        giftCard: cancellationDetails?.giftCardProvider?.status?.current === FAILURE.GIFT_CARD,
        payment: cancellationDetails?.paymentProvider?.status?.current === FAILURE.PAYMENT
      })
    }
  })
  const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1)
  const getLabel = (value: string) => capitalize(value.toLowerCase())

if(showStatusPopup) {
  return(<StatusPopup setShowStatusPopup={setShowStatusPopup} cancellationId={failedCancellation.id} refetchOrder={refetch}/>)
}

  return (
    <>
      {showPopup && <Popup
        title={"How do you want to perform the action"}
        handleCloseClick={() => setShowPopup(false)}
        buttonText={"Restart cancellation"}
        handleOkClick={() => restartOrderCancellation()}
        loading={loading}
      >
        <InfoWrapper>
          <b>Auto</b> <p>Brink commerce will perform the necessary requests to fulfill the action.</p>
          <b>Manual</b>{" "}
          <p>
            The action has been performed in an external system and will be stored as manually
            completed.
          </p>
          <b>Skip</b> <p>The action will be skipped entirely.</p>
        </InfoWrapper>
        <div>
          {failedProviders?.giftCard && (
            <div>
              <ActionWrapper>
                <LabelWrapper>
                  <GiftCardIcon />
                  <b>GiftCard:</b>
                </LabelWrapper>
                <ActionDropdownButton title={getLabel(giftCardAction.actionType)}>
                  <li onClick={() => setGiftCardAction({ actionType: InputActionType.Auto })}>
                    Auto
                  </li>
                  <li onClick={() => setGiftCardAction({ actionType: InputActionType.Manual })}>
                    Manual
                  </li>
                  <li onClick={() => setGiftCardAction({ actionType: InputActionType.Skip })}>
                    Skip
                  </li>
                </ActionDropdownButton>
              </ActionWrapper>
              {giftCardAction.actionType === InputActionType.Manual && (
                <>
                  {failedCancellation.giftCards.map((giftCard) => {
                    return (
                      <GiftCardInputWrapper key={giftCard.giftCardId}>
                        <p>{giftCard.giftCardId}</p>
                        <Input
                          removePaddingBottom={true}
                          name="transactionId"
                          placeholder="Transaction id"
                          onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            updateGiftCardActionCards(giftCard.giftCardId, e.currentTarget.value)
                          }
                        />
                      </GiftCardInputWrapper>
                    )
                  })}
                </>
              )}
            </div>
          )}
          {failedProviders?.bonus && (
            <div>
              <ActionWrapper>
                <LabelWrapper>
                  <BonusIcon />
                  <b>Bonus:</b>{" "}
                </LabelWrapper>
                <ActionDropdownButton title={getLabel(bonusAction.actionType)}>
                  <li onClick={() => setBonusAction({ actionType: InputActionType.Auto })}>Auto</li>
                  <li onClick={() => setBonusAction({ actionType: InputActionType.Manual })}>
                    Manual
                  </li>
                  <li onClick={() => setBonusAction({ actionType: InputActionType.Skip })}>Skip</li>
                </ActionDropdownButton>
              </ActionWrapper>
            </div>
          )}
          {failedProviders?.payment && <div>
          <ActionWrapper>
            <LabelWrapper>
              <PaymentIcon />
              <b>Payment:</b>
            </LabelWrapper>
            <StyledActionDropdownButton title={getLabel(paymentAction.actionType)}>
              <li onClick={() => setPaymentAction({ actionType: InputActionType.Auto })}>Auto</li>
              <li onClick={() => setPaymentAction({ actionType: InputActionType.Manual })}>Manual</li>
              <li onClick={() => setPaymentAction({ actionType: InputActionType.Skip })}>Skip</li>
            </StyledActionDropdownButton>
          </ActionWrapper>
          {paymentAction.actionType === InputActionType.Manual && (
            <Input
              name="captureReference"
              label="Capture reference"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updatePaymentCaptureReference(e.currentTarget.value)
              }
            />
          )}
          </div>}
        </div>
      </Popup>}
      <Container>
        <Title>
          <div><h2>Failed Cancellation</h2></div>
          <SecondaryButton handleClick={() => setShowPopup(true)}>Restart cancellation</SecondaryButton>
        </Title>
        id: {failedCancellation.id} <CopyButton string={failedCancellation.id}/>
        <ProviderWrapper>
        <h4>Failed providers:</h4>
        {failedProviders?.bonus && <Provider>Bonus</Provider>}
        {failedProviders?.giftCard && <Provider>Gift card</Provider>}
        {failedProviders?.payment && <Provider>Payment</Provider>}
        </ProviderWrapper>
        <ErrorMessages orderStatus={orderStatus} operationId={failedCancellation.id} operation={"CANCELLATION"}/>
      </Container>
    </>
  )
}

export default FailedCancellation
