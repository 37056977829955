import React, { useState } from "react"
import {
  InputActionBonus,
  InputActionGiftCard,
  InputActionPaymentRefund,
  InputActionType,
  Order
} from "lib/types/generated/graphql-types"

import { ButtonWrapper, CartContainer, CartItems, EmptyContainer, Error, Label } from "./Cart.styled"

import CartItem from "./CartItem"
import Totals from "./Totals"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import SecondaryButton from "../../../../Ui/Buttons/SecondaryButton"
import { useMutation } from "@apollo/client"
import DELETE_REFUND from "../../../../../graphql/mutations/order/refunds/DeleteRefund"
import GiftItem from "./GiftItem"
import Reason from "./Reason"
import ShippingFees from "./ShippingFees"
import RefundAction from "./RefundAction"
import StatusPopup from "./StatusPopup"
import RefundFee from "./RefundFee"
import { handleOrderManagementError } from "../../Helpers/errors"

type Props = {
  order: Order
  refetch: () => void
}

export const Cart = ({ order, refetch }: Props) => {
  const filteredRefunds = order?.Refunds?.filter((refund) => !refund.completed && !refund.started)
  const [giftCardAction, setGiftCardAction] = useState<InputActionGiftCard>({
    actionType: InputActionType.Auto
  })
  const [bonusAction, setBonusAction] = useState<InputActionBonus>({
    actionType: InputActionType.Auto
  })
  const [paymentAction, setPaymentAction] = useState<InputActionPaymentRefund>({
    actionType: InputActionType.Auto
  })
  const [showPopup, setShowPopup] = useState(false)
  const [showStatusPopup, setShowStatusPopup] = useState(false)
  const [startedRefund, setStartedRefund] = useState("")

  const [deleteRefund, { loading: deleteLoading }] = useMutation(DELETE_REFUND, {
    onCompleted: () => refetch(), onError: (error) => handleOrderManagementError(error)
  })

  const multipleKlarnaCheckoutDeliveries =
    order.Deliveries.filter(
      (delivery) => delivery.paymentProvider.providerName === "KlarnaCheckout"
    ).filter((delivery) => delivery.completed).length > 1

  if (!order) return null
  return (
    <>
      {showStatusPopup && startedRefund && (
        <StatusPopup
          setShowStatusPopup={setShowStatusPopup}
          refundId={startedRefund}
          refetchOrder={refetch}
        />
      )}
      {filteredRefunds?.length > 0 ? (
        <>
          <h2>Updated order draft</h2>
          {filteredRefunds?.map((refund) => (
            <CartContainer key={refund.id}>
              {showPopup && (
                <RefundAction
                  refund={refund}
                  actionState={{
                    giftCardAction,
                    setGiftCardAction,
                    bonusAction,
                    setBonusAction,
                    paymentAction,
                    setPaymentAction
                  }}
                  orderId={order.id}
                  setShowPopup={setShowPopup}
                  setShowStatusPopup={setShowStatusPopup}
                  setStartedRefund={setStartedRefund}
                />
              )}
              <CartItems>
                {refund?.orderLines?.map((refundOrderLine) => (
                  <CartItem
                    currencyCode={order.currencyCode}
                    key={refundOrderLine.orderLineId}
                    item={refundOrderLine}
                    orderLine={order?.orderLines?.find(
                      (ol) => ol.id === refundOrderLine.orderLineId
                    )}
                    refund={refund}
                    refetch={refetch}
                  />
                ))}
                {refund?.gifts?.map((refundGift) => (
                  <GiftItem
                    currencyCode={order?.currencyCode}
                    key={refundGift.giftId}
                    item={refundGift}
                    orderGift={order?.gifts?.find((gift) => gift.id === refundGift.giftId)}
                    refund={refund}
                    refetch={refetch}
                  />
                ))}
                <ShippingFees
                  refund={refund}
                  refetch={refetch}
                  currencyCode={order.currencyCode}
                  order={order}
                />
                <Reason refund={refund} refetch={refetch} />
                {multipleKlarnaCheckoutDeliveries ? (
                  <Error>
                    {
                      "- Refund fee not possible, KlarnaCheckout cannot use refund fees when there's multiple partial captures"
                    }
                  </Error>
                ) : (
                  <RefundFee refund={refund} refetch={refetch} currencyCode={order?.currencyCode} />
                )}
                <Label>To be refunded: </Label>
                <Totals
                  totals={refund.totals}
                  currencyCode={order?.currencyCode}
                  refundFee={refund?.fee}
                />
              </CartItems>
              <ButtonWrapper>
                <SecondaryButton
                  loading={deleteLoading}
                  handleClick={() => deleteRefund({ variables: { refundId: refund.id } })}
                >
                  Cancel order draft
                </SecondaryButton>
                <PrimaryButton
                  disabled={
                    refund?.orderLines.length < 1 &&
                    refund?.gifts.length < 1 &&
                    refund?.shippingFees.length < 1
                  }
                  handleClick={() => setShowPopup(true)}
                >
                  Next
                </PrimaryButton>
              </ButtonWrapper>
            </CartContainer>
          ))}
        </>
      ) : (
        <>
          <h2>New order draft</h2>
          <EmptyContainer>
            <h2>Create refunds to finalize order.</h2>
          </EmptyContainer>
        </>
      )}
    </>
  )
}
