import styled from "styled-components/macro"

type StyledProps = {
  $excluded: boolean
}

export const Container = styled.div`
  margin-bottom: 2rem;
`

export const AddedBundleContainer = styled.div`
  background: ${(p) => p.theme.colors.white};
  padding: 2rem;
  border: 0.1rem solid ${(p) => p.theme.colors.borderLight};
  border-radius: 0.6rem;
  margin-top: 1rem;
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    cursor: pointer;
  }


`
export const BundleLabel = styled.h3`
display: flex;
  align-items: center;
  margin-bottom: 1rem;
  svg {
    height: 2rem;
    margin-right: 1rem;
  }
`

export const ExpandButton = styled.div`
  svg {
    height: 2rem;
    margin-right: 1rem;
  }
`
export const ItemRulesContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    margin: 2rem 0;

    ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
        grid-template-columns: 1fr 1fr;
    }

    ${(p) => p.theme.mQ.MEDIA_MIN_X_LARGE} {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
`

export const ItemRuleWrapper = styled.div`
  gap: 1rem;
  padding: 2rem;

  border: 0.1rem solid ${(p) => p.theme.colors.borderLight};
  border-radius: 0.6rem;
`

export const ExcludedContainer = styled.div<StyledProps>`
  display: flex;
  align-items: center;

  svg {
    fill: ${(p) =>
      p.$excluded ? p.theme.colors.success.background : p.theme.colors.error.background};
    margin-left: 0.5rem;
    height: 1.4rem;
  }
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const CurrencyWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 1rem;
`

export const Currency = styled.span`
    padding: 0 1rem;
    width: auto;
    border: 1px solid ${(p) => p.theme.colors.borderLight};

    border-radius: 0.3rem;
    font-size: 1.2rem;
    margin-right: 0.5rem;
`
