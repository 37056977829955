import React from "react"
import DineroFactory from "dinero.js"
import { Order } from "@lib/types/generated/graphql-types"
import { v4 as uuidv4 } from "uuid"
import { useNavigate } from "react-router-dom"

import {
  Columns,
  DiscountTotal,
  DiscountTotalLabel,
  DiscountTotalValue,
  Name,
  RuleLabel,
  RuleValue,
  StyledTableHeader,
  Total
} from "./OrderDiscount.styled"

import Money from "components/Money"
import OrderBlock from "components/Order/OrderBlock"
import { Item, Label, List, Value } from "components/Ui/List/List"

import { ReactComponent as RuleIcon } from "images/icons/cart-shopping.svg"
import { ReactComponent as CodeIcon } from "images/icons/badge-percent.svg"
import { ReactComponent as ExternalRuleIcon } from "images/icons/gear.svg"
import { ReactComponent as ArrowRight } from "images/icons/arrow-right.svg"

type Props = {
  data: Order
}

const OrderDiscount = ({ data }: Props) => {
  const navigate = useNavigate()

  const {
    totals,
    currencyCode,
    discountCodeRules,
    discountRules,
    orderLines,
    discountExternalRules
  } = data
  const productDiscountAmount =
    orderLines?.reduce((acc, a) => acc + a.discountAmount * a.quantity, 0) ?? 0
  const discountCartRuleAmount =
    discountRules?.reduce((acc, { discountAmount }) => acc + (discountAmount ?? 0), 0) ?? 0
  const discountCodeAmount =
    discountCodeRules?.reduce((acc, { discountAmount }) => acc + (discountAmount ?? 0), 0) ?? 0
  const discountExternalRuleAmount =
    discountExternalRules?.reduce((acc, { discountAmount }) => acc + (discountAmount ?? 0), 0) ?? 0

  return (
    <>
      <Columns>
        <OrderBlock>
          <h2>
            <CodeIcon /> Discount codes used
          </h2>
          <List>
            {discountCodeRules && discountCodeRules.length > 0 ? (
              <>
                <StyledTableHeader>
                  <div>Code</div>
                  <div>Rule</div>
                  <div>Discount value</div>
                </StyledTableHeader>
                {discountCodeRules.map((discountCodeRule) => (
                  <Item key={uuidv4()}>
                    <RuleLabel>{discountCodeRule.code}</RuleLabel>
                    <RuleValue>
                      <>
                        <Name
                          onClick={() => {
                            navigate(`/discounts/discount-code-rules/${discountCodeRule.id}`)
                          }}
                        >
                          {discountCodeRule.name}
                          <ArrowRight />
                        </Name>
                      </>
                    </RuleValue>
                    <Value>
                      <>
                        {(discountCodeRule?.discountAmount ?? 0) > 0 && (
                          <Money
                            amount={parseInt(`${discountCodeRule.discountAmount}`)}
                            currencyUnit={currencyCode as DineroFactory.Currency}
                          />
                        )}
                        {" "}{discountCodeRule.freeShipping && " (Free shipping)"}
                      </>
                    </Value>
                  </Item>
                ))}
              </>
            ) : (
              <p>No discount codes used for this order.</p>
            )}
          </List>
        </OrderBlock>
        <OrderBlock>
          <h2>
            <RuleIcon /> Cart rules applied
          </h2>
          <List>
            {discountRules && discountRules.length > 0 ? (
              <>
                <StyledTableHeader>
                  <div>Cart rule</div>
                  <div style={{ textAlign: "right" }}>Discount value</div>
                </StyledTableHeader>
                {discountRules.map((discountRule) => (
                  <Item key={uuidv4()}>
                    <RuleLabel
                      handleOnClick={() => {
                        navigate(`/discounts/cart-rules/${discountRule.id}`)
                      }}
                    >
                      <>
                        {discountRule.name} <ArrowRight />
                      </>
                    </RuleLabel>
                    <Value>
                      <>
                        {(discountRule?.discountAmount ?? 0) > 0 && (
                          <Money
                            amount={parseInt(`${discountRule.discountAmount}`)}
                            currencyUnit={currencyCode as DineroFactory.Currency}
                          />
                        )}
                        {" "}{discountRule.freeShipping && " (Free shipping)"}
                      </>
                    </Value>
                  </Item>
                ))}
              </>
            ) : (
              <p>No cart rules applied for this order.</p>
            )}
          </List>
        </OrderBlock>
        <OrderBlock>
          <h2>
            <ExternalRuleIcon /> External rules applied
          </h2>
          <List>
            {discountExternalRules && discountExternalRules.length > 0 ? (
              <>
                <StyledTableHeader>
                  <div>External rule</div>
                  <div style={{ textAlign: "right" }}>Discount value</div>
                </StyledTableHeader>
                {discountExternalRules.map((discountRule) => (
                  <Item key={uuidv4()}>
                    <RuleLabel
                      handleOnClick={() => {
                        navigate(`/discounts/external-rules/${discountRule.id}`)
                      }}
                    >
                      <>
                        {discountRule.name} <ArrowRight />
                      </>
                    </RuleLabel>
                    <Value>
                      <>
                        {(discountRule?.discountAmount ?? 0) > 0 && (
                          <Money
                            amount={parseInt(`${discountRule.discountAmount}`)}
                            currencyUnit={currencyCode as DineroFactory.Currency}
                          />
                        )}
                        {" "}{discountRule.freeShipping && " (Free shipping)"}
                      </>
                    </Value>
                  </Item>
                ))}
              </>
            ) : (
              <p>No external rules applied for this order.</p>
            )}
          </List>
        </OrderBlock>
      </Columns>
      <DiscountTotal>
        <List>
          {discountCodeAmount > 0 ? (
            <Total>
              <Label>Total discount from codes:</Label>
              <Value alignRight>
                <Money
                  amount={parseInt(`${discountCodeAmount}`)}
                  currencyUnit={currencyCode as DineroFactory.Currency}
                />
              </Value>
            </Total>
          ) : (
            <></>
          )}
          {discountCartRuleAmount > 0 ? (
            <Total>
              <Label>Total discount from cart rules:</Label>
              <Value alignRight>
                <Money
                  amount={parseInt(`${discountCartRuleAmount}`)}
                  currencyUnit={currencyCode as DineroFactory.Currency}
                />
              </Value>
            </Total>
          ) : (
            <></>
          )}
          {discountExternalRuleAmount > 0 ? (
            <Total>
              <Label>Total discount from external rules:</Label>
              <Value alignRight>
                <Money
                  amount={parseInt(`${discountExternalRuleAmount}`)}
                  currencyUnit={currencyCode as DineroFactory.Currency}
                />
              </Value>
            </Total>
          ) : (
            <></>
          )}
          {productDiscountAmount > 0 ? (
            <Total>
              <Label>Total discount from products:</Label>
              <Value alignRight>
                <Money
                  amount={parseInt(`${productDiscountAmount}`)}
                  currencyUnit={currencyCode as DineroFactory.Currency}
                />
              </Value>
            </Total>
          ) : (
            <></>
          )}
          {productDiscountAmount > 0 &&
          discountCartRuleAmount === 0 &&
          discountCodeAmount === 0 &&
          productDiscountAmount !== totals?.discountTotal ? (
            <Total>
              <Label>Total discount from codes/rules:</Label>
              <Value alignRight>
                <Money
                  amount={parseInt(`${totals?.discountTotal - productDiscountAmount}`)}
                  currencyUnit={currencyCode as DineroFactory.Currency}
                />
              </Value>
            </Total>
          ) : (
            <></>
          )}
          <Total>
            <DiscountTotalLabel>Total discount amount </DiscountTotalLabel>
            <DiscountTotalValue alignRight>
              <Money
                amount={parseInt(`${totals?.discountTotal}`)}
                currencyUnit={currencyCode as DineroFactory.Currency}
              />
            </DiscountTotalValue>
          </Total>
        </List>
      </DiscountTotal>
    </>
  )
}

export default OrderDiscount
