import {
  CompensationOrderLine,
  CompensationShippingFee,
  DeliveryOrderLine,
  OrderCompensation,
  OrderDelivery
} from "@lib/types/generated/graphql-types"

export const compensationOrderLineOutputToInput = (orderLine: CompensationOrderLine) => ({
  orderLineId: orderLine.orderLineId,
  quantity: orderLine.quantity,
  taxPercentage: orderLine.taxPercentage,
  taxPercentageDecimals: orderLine.taxPercentageDecimals,
  totalAmount: orderLine.totalAmount,
  totalDiscountAmount: orderLine.totalDiscountAmount,
  totalTaxAmount: orderLine.totalTaxAmount
})

export const compensationShippingFeeOutputToInput = (fee: CompensationShippingFee) => ({
  ShippingFeeId: fee.shippingFeeId,
  TaxPercentage: fee.taxPercentage,
  TaxPercentageDecimals: fee.taxPercentageDecimals,
  TotalAmount: fee.totalAmount,
  TotalDiscountAmount: fee.totalDiscountAmount,
  TotalTaxAmount: fee.totalTaxAmount
})

export const compensationOrderLinesOutputToInput = (
  compensationOrderLines: CompensationOrderLine[]
) => compensationOrderLines?.map((ol) => compensationOrderLineOutputToInput(ol))

export const compensationShippingFeesOutputToInput = (shippingFees: CompensationShippingFee[]) =>
  shippingFees?.map((fee) => compensationShippingFeeOutputToInput(fee))

export const deliveryOrderLineOutputToCompensationInput = (
  compensationOrderLines: DeliveryOrderLine[]
) => {
  return compensationOrderLines?.map((ol) => ({
    orderLineId: ol.orderLineId,
    quantity: ol.quantity,
    taxPercentage: ol.taxPercentage,
    taxPercentageDecimals: ol.taxPercentageDecimals,
    totalAmount: ol.totalAmount,
    totalDiscountAmount: ol.totalDiscountAmount,
    totalTaxAmount: ol.totalTaxAmount
  }))
}

export const getDeliveryPaymentTotals = (deliveries: OrderDelivery[]) =>
  deliveries.map((delivery) => delivery.totals.grandTotal).reduce((a, b) => a + b, 0)

export const getDeliveryGiftCardTotals = (deliveries: OrderDelivery[]) =>
  deliveries.map((delivery) => delivery.totals.giftCardTotal).reduce((a, b) => a + b, 0)

export const getDeliveryBonusTotals = (deliveries: OrderDelivery[]) =>
  deliveries.map((delivery) => delivery.totals.bonusTotal).reduce((a, b) => a + b, 0)

export const getCompensationPaymentTotals = (compensations: OrderCompensation[]) =>
  compensations.map((compensation) => compensation.totals.grandTotal).reduce((a, b) => a + b, 0)

export const getCompensationGiftCardTotals = (compensations: OrderCompensation[]) =>
  compensations.map((compensation) => compensation.totals.giftCardTotal).reduce((a, b) => a + b, 0)

export const getCompensationBonusTotals = (compensations: OrderCompensation[]) =>
  compensations.map((compensation) => compensation.totals.bonusTotal).reduce((a, b) => a + b, 0)

export const getCompensationInput = (compensation: OrderCompensation | undefined) => ({
  ...(compensation?.payment && { payment: { amount: compensation.payment.amount } }),
  ...(compensation?.bonus && { bonus: { amount: compensation.bonus.amount } }),
  ...(compensation?.giftCards &&
    compensation?.giftCards?.length > 0 && { giftCards: compensation.giftCards }),
  ...(compensation?.orderLines && {
    orderLines: compensationOrderLinesOutputToInput(compensation?.orderLines)
  }),
  ...(compensation?.reason && { reason: compensation.reason }),
  ...(compensation?.shippingFees && {
    shippingFees: compensationShippingFeesOutputToInput(compensation.shippingFees)
  })
})
