import React from "react"
import { getCountryName } from "helpers/countries"
import dayjs from "dayjs"
import DineroFactory from "dinero.js"
import { Order } from "@lib/types/generated/graphql-types"
import { useQuery } from "@apollo/client"

import GET_STORE_GROUP from "graphql/queries/store/GetStoreGroup"

import { Header } from "../Shared.styled"
import { Summary } from "./OrderSummary.styled"

import Money from "components/Money"
import { Item, Label, Value } from "components/Ui/List/List"
import FlagIcon from "components/Ui/FlagIcon"
import DotLoader from "components/Ui/DotLoader"
import CopyButton from "components/Ui/CopyButton"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import { VariantRow } from "../VariantRow"

import { ReactComponent as ArrowRight } from "images/icons/arrow-right.svg"

type Props = {
  order: Order
  createOrderDraft: () => void
  startSessionLoading: boolean
  isCartInitiated: boolean
}

export const OrderSummary = ({
  order,
  createOrderDraft,
  isCartInitiated,
  startSessionLoading
}: Props) => {
  const { data, loading } = useQuery(GET_STORE_GROUP, {
    fetchPolicy: "cache-first",
    variables: { id: order?.storeGroupId }
  })

  if (!data) return null

  return (
    <>
      <Header>
        <h2>Order: #{order.reference}</h2>
        {!isCartInitiated && (
          <PrimaryButton handleClick={() => createOrderDraft()} loading={startSessionLoading}>
            Import order <ArrowRight />
          </PrimaryButton>
        )}
      </Header>
      <Summary>
        <Item flexibleHeight>
          <Label>E-mail:</Label>
          <Value>
            <>
              {order.billingAddress?.email}
              <CopyButton string={order.billingAddress?.email} />
            </>
          </Value>
        </Item>
        <Item>
          <Label>Store group:</Label>
          <Value>{loading ? <DotLoader /> : data?.getStoreGroup?.name}</Value>
        </Item>
        <Item>
          <Label>Store market:</Label>
          <Value flex>
            <>
              <FlagIcon countryCode={order.countryCode} />
              {getCountryName(order.countryCode)}
            </>
          </Value>
        </Item>
        <Item>
          <Label>Order date:</Label>
          <Value>{dayjs(order.date).format("YYYY-MM-DD")}</Value>
        </Item>
        <Item>
          <Label>Total order value:</Label>
          <Value>
            <Money
              amount={parseInt(`${order.totals?.grandTotal}`)}
              currencyUnit={order.currencyCode as DineroFactory.Currency}
            />
          </Value>
        </Item>
      </Summary>

      <h2>Order lines ({order.orderLines?.length ?? 0})</h2>
      {order.orderLines?.map((productVariant) => (
        <VariantRow key={productVariant.id} productVariant={productVariant} />
      ))}
    </>
  )
}
