import React from "react"

import {
  Container,
  Blur,
  Item,
  StyledErrorMessage,
  ButtonWrapper,
  CancelButton
} from "./Popup.styled"

import PrimaryButton from "./Buttons/PrimaryButton"

import { ReactComponent as ArrowRight } from "images/icons/arrow-right.svg"

type Props = {
  children?: React.ReactNode
  type?: "delete" | "default"
  title: string | React.ReactNode
  subtitle?: string
  item?: {
    itemId: string | undefined
    itemName: string
  }
  buttonText?: string
  cancelButtonText?: string
  loading?: boolean
  handleOkClick?: React.MouseEventHandler
  handleCloseClick: React.MouseEventHandler
  blurBackgroundColor?: boolean
  errorMessage?: string
  hideOkButton?: boolean
  disableOkButton?: boolean
  overrideOkButton?: boolean
}

const Popup = ({
  children,
  type = "default",
  title,
  subtitle,
  item,
  loading,
  blurBackgroundColor = true,
  buttonText,
  cancelButtonText,
  handleOkClick,
  handleCloseClick,
  errorMessage,
  hideOkButton,
  disableOkButton,
  overrideOkButton
}: Props) => (
  <>
    <Blur onClick={handleCloseClick} blurBackgroundColor={blurBackgroundColor} />
    <Container>
      <h2>{title}</h2>
      {subtitle && <p>{subtitle}</p>}
      {item && (
        <Item>
          <li>
            <h4>
              <ArrowRight />
              {item.itemName}
            </h4>
            {item?.itemId && <span>{item.itemId}</span>}
          </li>
        </Item>
      )}
      {errorMessage && errorMessage !== "" && <StyledErrorMessage message={errorMessage} />}
      {children}
      {
        <>
          <ButtonWrapper>
            <CancelButton overrideDisabled={overrideOkButton} handleClick={handleCloseClick}>
              {cancelButtonText ?? "Cancel"}
            </CancelButton>
            {!hideOkButton && (
              <PrimaryButton
                loading={loading}
                handleClick={handleOkClick}
                mode={type}
                disabled={disableOkButton}
                overrideDisabled={overrideOkButton}
              >
                {buttonText ?? "Yes, delete"}
              </PrimaryButton>
            )}
          </ButtonWrapper>
        </>
      }
    </Container>
  </>
)

export default Popup
