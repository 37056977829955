import React from "react"
import {
  Order,
  OrderGift,
  OrderGiftCardProduct,
  OrderGiftCardProductRetain24,
  OrderLine,
  OrderStatus
} from "@lib/types/generated/graphql-types"
import { v4 as uuid } from "uuid"

import { Title } from "./OrderLines.styled"
import { Orderline } from "./OrderLine"

import { ReactComponent as OrderLineIcon } from "images/icons/cart-shopping.svg"
import { GiftLine } from "./GiftLine"
import { ReactComponent as GiftIcon } from "images/icons/gift.svg"
import { ReactComponent as CardIcon } from "images/icons/gift-card.svg"
import { GiftCardProductLine } from "./GiftCardProductLine"

type Props = {
  order: Order
  orderStatus: OrderStatus
}

export const Orderlines = ({ order, orderStatus }: Props) => {
  const { orderLines, gifts, GiftCardProduct } = order

  function isOrderGiftCardProductRetain24(
    object: OrderGiftCardProduct
  ): object is OrderGiftCardProductRetain24 {
    return object.providerName === "Retain24"
  }

  return (
    <>
      {orderLines && orderLines?.length > 0 && (
        <>
          <Title>
            <OrderLineIcon />
            Products
          </Title>

          {orderLines.map((orderLine: OrderLine) => (
            <Orderline key={uuid()} orderLine={orderLine} orderStatus={orderStatus} />
          ))}
        </>
      )}
      {gifts && gifts?.length > 0 && (
        <>
          <Title>
            <GiftIcon />
            Gifts
          </Title>

          {gifts &&
            gifts.map((gift: OrderGift) => (
              <GiftLine key={uuid()} gift={gift} orderStatus={orderStatus} />
            ))}
        </>
      )}
      {isOrderGiftCardProductRetain24(GiftCardProduct) && (
        <>
          <Title>
            <CardIcon />
            Gift cards
          </Title>
          {GiftCardProduct?.giftCardProducts?.map((product) => (
            <GiftCardProductLine key={product.id} giftCardProductLine={product} order={order} orderStatus={orderStatus}/>
          ))}
        </>
      )}
    </>
  )
}
