import React from "react"

import { Container } from "./OrderHistory.styled"

import {
  Order,
  OrderStatus,
  OrderStatusLogEntry
} from "@lib/types/generated/graphql-types"
import OrderHistoryEntry from "./OrderHistoryEntry"

type Props = {
  orderStatus: OrderStatus
  currencyCode: string
  order: Order
}

const OrderHistory = ({ orderStatus, currencyCode,order}: Props) => {
  const entryKey = (e: OrderStatusLogEntry) => `${e.operationId}-${e.timestamp}`
  return (
    <Container>
      {[...orderStatus.history].reverse().map((entry) => (
        <OrderHistoryEntry
          entry={entry}
          key={entryKey(entry)}
          currencyCode={currencyCode}
          deliveries={order.Deliveries}
          refunds={order.Refunds}
        />
      ))}
    </Container>
  )
}

export default OrderHistory
