import React, { useEffect, useState } from "react"
import esb, { RequestBodySearch } from "elastic-builder"
import { CampaignFilter, Option } from "lib/types/common"
import SingleSelectSimple from "../Ui/Form/SingleSelectSimple"
import {
  ActiveFilters,
  ActiveListList,
  ActiveListListItem,
  Arrow,
  Children,
  ClearButton,
  Flex,
  FromIcon,
  Label,
  LabelWrapper,
  RemoveButton,
  SearchWrapper,
  SelectWrapper,
  StyledSearch,
  ToIcon,
  Wrapper
} from "../Ui/Table/Filter/Filter.styled"
import { ReactComponent as StatusIcon } from "images/icons/gauge.svg"
import { ReactComponent as EarthIcon } from "images/icons/earth.svg"
import dayjs from "dayjs"
import { ReactComponent as FilterIcon } from "images/icons/sliders.svg"
import FilterDate from "../Ui/Table/Filter/FilterDate"
import { ReactComponent as CalendarIcon } from "images/icons/calendar-days.svg"
import {
  BoldText,
  CampaignWrapper,
  Container,
  FilterContainer,
  Icon
} from "./CampaignFilters.styled"
import SingleSelect from "../Ui/Form/SingleSelect"

type Props = {
  setSearchQuery: (input: string) => void
  loading: boolean
  searchQuery: string
  setEsbQuery: (query: RequestBodySearch) => void
  campaignFilter: CampaignFilter
  setCampaignFilter: (filter: CampaignFilter) => void
  defaultCampaignFilter: CampaignFilter
}

const ProductFilters = ({
  setSearchQuery,
  loading,
  searchQuery,
  setEsbQuery,
  campaignFilter,
  setCampaignFilter,
  defaultCampaignFilter
}: Props) => {
  const [expanded, setExpanded] = useState(false)
  const [timeZone, setTimeZone] = useState<Option>({
    value: Intl.DateTimeFormat().resolvedOptions().timeZone,
    label: Intl.DateTimeFormat().resolvedOptions().timeZone
  })
  const setStatus = (status: string) => {
    setCampaignFilter({ ...campaignFilter, ...{ status: status } })
  }
  const setCreatedFromDate = (value: string) =>
    setCampaignFilter({ ...campaignFilter, ...{ createdFromDate: value } })
  const setCreatedToDate = (value: string) =>
    setCampaignFilter({ ...campaignFilter, ...{ createdToDate: value } })

  const fromDateCount = campaignFilter?.createdFromDate === "" ? 0 : 1
  const toDateCount = campaignFilter?.createdToDate === "" ? 0 : 1
  const statusCount = campaignFilter?.status === "" ? 0 : 1

  const totalFilters = campaignFilter && statusCount + fromDateCount + toDateCount
  const timeZones = Intl.supportedValuesOf("timeZone")

  useEffect(() => {
    const boolQuery = esb.boolQuery()

    if (campaignFilter.status === "COMPLETED") {
      boolQuery.must(
        esb
          .rangeQuery("endDate")
          .gt(dayjs().subtract(10, "year").format("YYYY-MM-DD-HH:mm"))
          .lt(dayjs().format("YYYY-MM-DD-HH:mm"))
          .format("yyyy-MM-dd-HH:mm")
          .timeZone(timeZone.value)
      )
    }

    if (campaignFilter.status === "UPCOMING") {
      boolQuery.must(
        esb
          .rangeQuery("startDate")
          .gt(dayjs().format("YYYY-MM-DD-HH:mm"))
          .lt(dayjs().add(10, "year").format("YYYY-MM-DD-HH:mm"))
          .format("yyyy-MM-dd-HH:mm")
          .timeZone(timeZone.value)
      )
    }

    if (campaignFilter.status === "RUNNING") {
      boolQuery.must(
        esb
          .rangeQuery("startDate")
          .gt(dayjs().subtract(10, "year").format("YYYY-MM-DD-HH:mm"))
          .lt(dayjs().format("YYYY-MM-DD-HH:mm"))
          .format("yyyy-MM-dd-HH:mm")
          .timeZone(timeZone.value)
      )
      boolQuery.must(
        esb
          .rangeQuery("endDate")
          .gt(dayjs().format("YYYY-MM-DD-HH:mm"))
          .lt(dayjs().add(10, "year").format("YYYY-MM-DD-HH:mm"))
          .format("yyyy-MM-dd-HH:mm")
          .timeZone(timeZone.value)
      )
    }

    if (campaignFilter.createdFromDate && !campaignFilter.createdToDate) {
      boolQuery.must(
        esb
          .rangeQuery("created")
          .gt(campaignFilter.createdFromDate.replace("T", "-"))
          .lt(dayjs().format("YYYY-MM-DD-HH:mm"))
          .format("yyyy-MM-dd-HH:mm")
          .timeZone(timeZone.value)
      )
    }

    if (!campaignFilter.createdFromDate && campaignFilter.createdToDate) {
      boolQuery.must(
        esb
          .rangeQuery("created")
          .gt(dayjs().subtract(6, "month").format("YYYY-MM-DD-HH:mm"))
          .lt(campaignFilter.createdToDate.replace("T", "-"))
          .format("yyyy-MM-dd-HH:mm")
          .timeZone(timeZone.value)
      )
    }

    if (campaignFilter.createdFromDate && campaignFilter.createdToDate) {
      boolQuery.must(
        esb
          .rangeQuery("created")
          .gt(campaignFilter.createdFromDate.replace("T", "-"))
          .lt(campaignFilter.createdToDate.replace("T", "-"))
          .format("yyyy-MM-dd-HH:mm")
          .timeZone(timeZone.value)
      )
    }

    boolQuery.must([esb.queryStringQuery(searchQuery.trim() + "*").analyzeWildcard(true)])
    setEsbQuery(esb.requestBodySearch().query(boolQuery))
  }, [campaignFilter, searchQuery, timeZone])

  const resetFilter = () => {
    setCampaignFilter(defaultCampaignFilter)
  }

  const options = [
    {
      label: "Upcoming",
      value: "UPCOMING"
    },
    {
      label: "Running",
      value: "RUNNING"
    },
    {
      label: "Completed",
      value: "COMPLETED"
    }
  ]

  const isFilterActive = () => {
    return !!(
      campaignFilter.status ||
      campaignFilter.createdFromDate ||
      campaignFilter.createdToDate
    )
  }

  return (
    <>
      <Container>
        <Flex>
          <SearchWrapper>
            <StyledSearch
              handleOnChange={(input: string) => {
                setSearchQuery(input)
              }}
              loading={loading}
              defaultValue={searchQuery}
              padding="1rem 2rem 2rem 2rem"
              placeholder="Type to search for campaign groups and campaigns..."
            />
            <LabelWrapper $isFilterActive={isFilterActive()} onClick={() => setExpanded(!expanded)}>
              <Label $isFilterActive={isFilterActive()}>
                <FilterIcon />
                Filter {isFilterActive() && `(${totalFilters})`}
                <Arrow $expanded={expanded} />
              </Label>
            </LabelWrapper>
          </SearchWrapper>

          <SelectWrapper></SelectWrapper>
        </Flex>
        <Wrapper $isFilterActive={isFilterActive && isFilterActive()}>
          {expanded && (
            <Children>
              {" "}
              <FilterContainer>
                <BoldText>Campaign status</BoldText>
                <CampaignWrapper>
                  <Icon>
                    <StatusIcon />
                  </Icon>
                  <SingleSelectSimple
                    setValue={setStatus}
                    options={options}
                    placeholder={"Select status"}
                    value={
                      campaignFilter?.status
                        ? options.find((option) => option.value === campaignFilter?.status) ?? null
                        : null
                    }
                  />
                </CampaignWrapper>
                <BoldText>Campaign created date range</BoldText>
                <FilterDate
                  defaultFromDate={campaignFilter?.createdFromDate}
                  defaultToDate={campaignFilter?.createdToDate}
                  setFromDate={setCreatedFromDate}
                  setToDate={setCreatedToDate}
                />
                <BoldText>Time zone</BoldText>
                <CampaignWrapper>
                  <Icon>
                    <EarthIcon />
                  </Icon>
                  <SingleSelect
                    options={timeZones.map((timeZone) => ({ value: timeZone, label: timeZone }))}
                    setValue={setTimeZone}
                    defaultValue={{
                      value: Intl.DateTimeFormat().resolvedOptions().timeZone,
                      label: Intl.DateTimeFormat().resolvedOptions().timeZone
                    }}
                  />
                </CampaignWrapper>
              </FilterContainer>
            </Children>
          )}
        </Wrapper>
      </Container>
      {isFilterActive() && (
        <ActiveFilters>
          <div>
            <b>Active filters:</b>
            <ActiveListList>
              {campaignFilter?.status && (
                <ActiveListListItem>
                  <StatusIcon /> {campaignFilter.status}
                  <RemoveButton onClick={() => setStatus("")} />
                </ActiveListListItem>
              )}
              {campaignFilter.createdFromDate && (
                <ActiveListListItem>
                  <CalendarIcon />
                  <FromIcon /> {dayjs(campaignFilter.createdFromDate).format(
                    "YYYY-MM-DD HH:mm"
                  )}{" "}
                  <RemoveButton onClick={() => setCreatedFromDate("")} />
                </ActiveListListItem>
              )}
              {campaignFilter.createdToDate && (
                <ActiveListListItem>
                  <ToIcon />
                  <CalendarIcon /> {dayjs(campaignFilter.createdToDate).format(
                    "YYYY-MM-DD HH:mm"
                  )}{" "}
                  <RemoveButton onClick={() => setCreatedToDate("")} />
                </ActiveListListItem>
              )}
            </ActiveListList>
          </div>
          {isFilterActive() && (
            <ClearButton
              handleClick={(e) => {
                e.stopPropagation()
                resetFilter()
              }}
            >
              Reset
            </ClearButton>
          )}
        </ActiveFilters>
      )}
    </>
  )
}
export default ProductFilters
