import React, { useState } from "react"
import { InputBundleWithItemRuleId } from "lib/types/discount"
import {
  AddedBundleContainer,
  BundleLabel,
  Currency,
  CurrencyWrapper,
  ExcludedContainer,
  ExpandButton,
  Header,
  ItemRulesContainer,
  ItemRuleWrapper
} from "./AddedBundles.styled"
import ProductParent from "./ProductParent"
import { ReactComponent as CheckIcon } from "images/icons/circle-check.svg"
import { ReactComponent as XmarkIcon } from "images/icons/circle-xmark.svg"
import { ReactComponent as ChevronDown } from "images/icons/chevron-down.svg"
import { ReactComponent as ChevronUp } from "images/icons/chevron-up.svg"
import { InputDiscountValue, InputDiscountValueType } from "lib/types/generated/graphql-types"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import { ButtonWrapper } from "../AddBundles.styled"
import TagConditions from "./TagConditions"
import Money from "../../../../Money"
import DineroFactory from "dinero.js"
import { ReactComponent as BundleIcon } from "images/icons/box-open-full.svg"
import SecondaryButton from "../../../../Ui/Buttons/SecondaryButton"

type Props = {
  bundle: InputBundleWithItemRuleId
  index: number
  removeBundle?: (bundleId: string) => void
  editBundle?: (bundle: InputBundleWithItemRuleId) => void
}

const AddedBundle = ({ bundle, index, removeBundle, editBundle }: Props) => {
  const [expanded, setExpanded] = useState(false)

  const getDiscountText = (discountValue: InputDiscountValue) => {
    if (discountValue.type === InputDiscountValueType.Relative) {
      return `${discountValue.percentage} %`
    }
    return `Fixed discount`
  }

  return (
    <AddedBundleContainer key={bundle.id}>
      <Header onClick={() => setExpanded(!expanded)}>
        <div>
          <BundleLabel>
            <BundleIcon /> Bundle {index + 1}
          </BundleLabel>
          <div>
            <b>Item rules:</b> {bundle.itemRules.length}
          </div>
          <div>
            <b>Discount:</b> {getDiscountText(bundle.discountValue)}
          </div>
        </div>
        <ExpandButton>{expanded ? <ChevronUp /> : <ChevronDown />}</ExpandButton>
      </Header>
      {expanded && (
        <>
          {bundle.discountValue.fixedDiscounts && (
            <CurrencyWrapper>
              {bundle.discountValue.fixedDiscounts.map((fixedDiscount) => (
                <Currency key={fixedDiscount.currencyCode}>
                  <Money
                    currencyUnit={fixedDiscount.currencyCode as DineroFactory.Currency}
                    amount={fixedDiscount.units}
                  />
                </Currency>
              ))}
            </CurrencyWrapper>
          )}
          <ItemRulesContainer>
            {bundle.itemRules.map((itemRule, index) => (
              <ItemRuleWrapper key={itemRule.id}>
                <b>Item rule {index + 1}</b>
                <div>Quantity : {itemRule.quantity}</div>
                <ExcludedContainer $excluded={itemRule.excludeDiscountedPrices}>
                  Exclude discounted prices:{" "}
                  {itemRule.excludeDiscountedPrices ? <CheckIcon /> : <XmarkIcon />}
                </ExcludedContainer>
                <ExcludedContainer $excluded={itemRule.excludeFullPrices}>
                  Exclude full prices: {itemRule.excludeFullPrices ? <CheckIcon /> : <XmarkIcon />}
                </ExcludedContainer>
                {itemRule.productParents.map((productParent) => (
                  <ProductParent key={productParent} productParentId={productParent} />
                ))}
                <div>
                  <TagConditions tagConditions={itemRule.tagConditions} />
                </div>
              </ItemRuleWrapper>
            ))}
          </ItemRulesContainer>
          {removeBundle && editBundle && (
            <ButtonWrapper>
              <SecondaryButton
                type={"button"}
                handleClick={() => {
                  editBundle(bundle)
                  setExpanded(false)
                }}
              >
                Edit bundle
              </SecondaryButton>
              <PrimaryButton
                mode={"delete"}
                type={"button"}
                handleClick={() => removeBundle(bundle.id)}
              >
                Delete bundle
              </PrimaryButton>
            </ButtonWrapper>
          )}
        </>
      )}
    </AddedBundleContainer>
  )
}

export default AddedBundle
