import React, { Dispatch, SetStateAction, useState } from "react"
import {
  AddProductsButton,
  BundleContainer,
  ButtonWrapper,
  ItemRuleContainer,
  ItemRuleWrapper,
  TypeSelector,
  TypeSelectorContainer
} from "./AddBundles.styled"
import { ReactComponent as FixedPriceIcon } from "images/icons/badge-dollar.svg"
import { ReactComponent as PercentageIcon } from "images/icons/badge-percent.svg"
import { ReactComponent as PlusIcon } from "images/icons/circle-plus.svg"
import {
  InputDiscountValue,
  InputDiscountValueType,
  InputMoney,
  InputStoreMarket,
  StoreGroup
} from "lib/types/generated/graphql-types"
import Input from "../../../Ui/Form/Input"
import BundleFixedAmount from "./BundleItem/BundleFixedAmount"
import PrimaryButton from "../../../Ui/Buttons/PrimaryButton"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"
import BundleItemRule from "./BundleItem/BundleItemRule"
import { useAppDispatch } from "lib/store"
import { v4 as uuidv4 } from "uuid"
import { InputBundleItemRuleWithId, InputBundleWithItemRuleId } from "@lib/types/discount"
import TagConditions from "./BundleItem/TagConditions"
import ProductParent from "./BundleItem/ProductParent"

type Props = {
  addedStoreMarkets: InputStoreMarket[]
  storeGroups: StoreGroup[] | undefined
  setBundles: Dispatch<SetStateAction<InputBundleWithItemRuleId[]>>
  discountValue: InputDiscountValue | undefined
  setDiscountValue: Dispatch<SetStateAction<InputDiscountValue | undefined>>
  itemRules: InputBundleItemRuleWithId[]
  setItemRules: Dispatch<SetStateAction<InputBundleItemRuleWithId[]>>
  editBundleId: string
  setEditBundleId: Dispatch<SetStateAction<string>>
}

const AddBundles = ({
  addedStoreMarkets,
  storeGroups,
  setBundles,
  discountValue,
  setDiscountValue,
  itemRules,
  setItemRules,
  editBundleId,
  setEditBundleId
}: Props) => {
  const [editBundleItemRule, setEditBundleItemRule] = useState<
    InputBundleItemRuleWithId | undefined
  >()
  const dispatch = useAppDispatch()

  const updateFixedDiscounts = (fixedDiscounts: InputMoney[]) => {
    setDiscountValue({ ...discountValue, fixedDiscounts } as InputDiscountValue)
  }

  const addBundle = () => {
    if (discountValue) {
      if (editBundleId) {
        setBundles((prev) =>
          prev.map((bundle) =>
            bundle.id === editBundleId ? { ...bundle, itemRules, discountValue } : bundle
          )
        )
      } else {
        setBundles((prev) => {
          const copy = [...prev]
          copy.push({
            id: uuidv4(),
            itemRules: itemRules,
            discountValue: discountValue
          })
          return copy
        })
      }
      setItemRules([])
      setDiscountValue(undefined)
      setEditBundleId("")
    }
  }

  return (
    <div>
      <h2>Add bundles</h2>
      <BundleContainer>
        <BundleItemRule
          setItemRules={setItemRules}
          editBundleItemRule={editBundleItemRule}
          setEditBundleItemRule={setEditBundleItemRule}
        />
        <h3>Bundle item rules</h3>
        <p>
          Build item rules containing product selection from product parent/product variant tags.
          Specified quantity of product variants from each item rule in the cart will trigger the
          bundle discount. (Minimum item rules: 2)
        </p>
        <ItemRuleContainer $listLength={itemRules.length}>
          {itemRules.map((itemRule, index) => (
            <ItemRuleWrapper
              key={uuidv4()}
              onClick={() => {
                setEditBundleItemRule(itemRule)
                dispatch(showEditSidebar())
              }}
            >
              <h3>Item rule {index + 1}</h3>
              <b>Quantity: </b>
              {itemRule.quantity}
              {itemRule.productParents.map((productParent) => (
                <ProductParent
                  key={productParent}
                  productParentId={productParent}
                  padding={"0.5rem 0"}
                />
              ))}
              <div>
                <TagConditions tagConditions={itemRule.tagConditions} />
              </div>
            </ItemRuleWrapper>
          ))}
          <AddProductsButton onClick={() => dispatch(showEditSidebar())}>
            <PlusIcon /> Add products to new item rule
          </AddProductsButton>
        </ItemRuleContainer>
        {itemRules.length > 1 && (
          <>
            <h3>Select discount</h3>
            <TypeSelectorContainer>
              <TypeSelector
                $active={discountValue?.type === InputDiscountValueType.Relative}
                onClick={() =>
                  setDiscountValue({ ...discountValue, type: InputDiscountValueType.Relative })
                }
              >
                <span>
                  <PercentageIcon />
                  <b>Percentage</b>
                </span>
                Discount in percentage
              </TypeSelector>
              <TypeSelector
                $active={discountValue?.type === InputDiscountValueType.Fixed}
                onClick={() =>
                  setDiscountValue({ ...discountValue, type: InputDiscountValueType.Fixed })
                }
              >
                <span>
                  <FixedPriceIcon />
                  <b>Fixed amount</b>
                </span>
                Define a fixed amount of discount to be subtracted from base price
              </TypeSelector>
            </TypeSelectorContainer>
            {discountValue?.type === InputDiscountValueType.Relative && (
              <Input
                name="percentage"
                type="number"
                placeholder="Percentage, 1 - 100"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setDiscountValue({ ...discountValue, percentage: parseInt(event.target.value) })
                }
                label="Percentage % *"
              />
            )}
            {discountValue?.type === InputDiscountValueType.Fixed && (
              <BundleFixedAmount
                storeGroups={storeGroups}
                addedStoreMarkets={addedStoreMarkets}
                fixedDiscounts={discountValue?.fixedDiscounts}
                updateFixedDiscounts={updateFixedDiscounts}
              />
            )}
            <ButtonWrapper>
              <PrimaryButton handleClick={addBundle} type={"button"}>
                {editBundleId ? "Update bundle" : "Add bundle"}
              </PrimaryButton>
            </ButtonWrapper>
          </>
        )}
      </BundleContainer>
    </div>
  )
}

export default AddBundles
