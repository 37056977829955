import React from "react"
import { OrderStatusLogEntry } from "@lib/types/generated/graphql-types"
import { Container } from "./ErrorMessages.styled"
import ErrorMessageEntry from "./ErrorMessageEntry"

type Props = {
  orderStatus: OrderStatusLogEntry[]
  operationId: string
  operation: string
}

const ErrorMessages = ({ orderStatus, operationId, operation }: Props) => {
  const errorMessages = orderStatus
    ?.filter((entry) => entry.operationId === operationId)
    ?.filter((entry) => entry.operation === operation)
    ?.filter((entry) => entry.status.endsWith("_FAILURE"))

  if (errorMessages?.length === 0) return null
  const entryKey = (e: OrderStatusLogEntry) => `${e.operationId}-${e.timestamp}`
  return (
    <Container>
      {[...errorMessages].reverse().map((entry) => (
        <ErrorMessageEntry
          entry={entry}
          key={entryKey(entry)}
        />
      ))}
    </Container>
  )
}

export default ErrorMessages
