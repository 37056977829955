import styled from "styled-components/macro"
import TableHeader from "components/Ui/Table/TableHeader"
import { Item, Label, Value } from "components/Ui/List/List"

export const Columns = styled.div`
  h2 {
    margin-top: 3rem;
  }
`

export const StyledTableHeader = styled(TableHeader)`
  padding: 0;
  border: none;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLighter};
  width: 100%;
  div {
    border: none;
    padding: 0;
  }
`

export const Name = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    transition: all 0.3s;
    height: 1.5rem;
    margin-left: 0.5rem;
    transform: rotate(0deg);
  }

  &:hover {
    svg {
      transform: rotate(-45deg);
    }
  }
`

export const RuleLabel = styled(Label)`
  cursor: pointer;
  width: auto;
  flex: 1;

  svg {
    transition: all 0.3s;
    height: 1.5rem;
    margin-left: 0.5rem;
    transform: rotate(0deg);
  }

  &:hover {
    svg {
      transform: rotate(-45deg);
    }
  }
`

export const RuleValue = styled(Value)`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const Total = styled(Item)`
  margin-top: 0;

  > div {
    &:first-child {
      width: 60%;
    }
  }
`

export const DiscountTotal = styled.div`
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  padding: 2rem;
  position: relative;
  margin-top: 1rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    padding: 2rem 4rem;
  }
`

export const DiscountTotalLabel = styled(Label)`
  width: 100%;
  font-size: 1.6rem;
`

export const DiscountTotalValue = styled(Value)`
  width: 100%;
  font-size: 1.6rem;
`
