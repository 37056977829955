import React from "react"
import { useQuery } from "@apollo/client"
import esb from "elastic-builder"
import dayjs from "dayjs"
import { Query } from "@lib/types/common"
import { useNavigate } from "react-router-dom"

import SEARCH_ORDERS_TOTAL_HITS from "graphql/queries/order/SearchOrdersTotalHits"

import { CountCard } from "./CountCard"

import { ReactComponent as OrderIcon } from "images/icons/cart-shopping.svg"

export const DailyOrders = () => {
  const yesterday = dayjs().subtract(1, "days").startOf("day")
  const navigate = useNavigate()

  const dailyRevenueQuery = esb
    .requestBodySearch()
    .query(
      esb
        .boolQuery()
        .must([esb.rangeQuery("date").gt(yesterday.format("YYYY-MM-DD")).format("yyyy-MM-dd")])
    )

  const query = dailyRevenueQuery.toJSON() as Query
  const { data } = useQuery(SEARCH_ORDERS_TOTAL_HITS, {
    variables: { from: 0, size: 1000, query: JSON.stringify(query.query) }
  })

  return (
    <CountCard
      onClick={() => navigate("/orders")}
      icon={OrderIcon}
      count={data?.searchOrders?.total}
      text="Orders today"
    />
  )
}
