import styled from "styled-components/macro"
import TableHeader from "components/Ui/Table/TableHeader"

export const Container = styled.div`
  width: 100%;
  min-height: 20rem;
`

export const FlexColumn = styled.div`
  display: flex;
  align-items: center;
`

export const Customer = styled(FlexColumn)`
  min-width: 30rem;
`

export const StyledTableHeader = styled(TableHeader)`
  padding: 0 2rem 0 6.4rem;
`

export const SmallInfo = styled.div`
  font-size: 1.1rem;
  margin: 0;
  line-height: 1.6rem;
display: flex;
  align-items: center;
  svg {
    height: 1rem;
    margin-left: 0.3rem;
  }
`

export const PaymentContainer = styled(FlexColumn)`
  display: flex;
  align-items: center;
  flex-direction: row;

  > span {
    display: flex;
    flex-direction: column;
  }

  svg {
    width: 2.4rem;
    display: block;
  }
`

export const IconContainer = styled.div`
  border: 0.1rem solid rgb(223, 227, 232);
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin-right: 1.5rem;

  svg {
    margin-right: 0;
  }
`

export const PaymentMethod = styled(SmallInfo)`
  &:first-letter {
    text-transform: uppercase;
  }
`

export const EmptyList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 3rem 0;

  svg {
    height: 2rem;
    margin-right: 2rem;
  }
`
