import React from "react"
import styled from "styled-components/macro"
import DineroFactory from "dinero.js"
import { OrderAddress, OrderShippingIngrid, ShippingFee } from "@lib/types/generated/graphql-types"
import { getCountryName } from "helpers/countries"

import Money from "components/Money"
import OrderBlock from "components/Order/OrderBlock"
import { Item, Label, List, Value } from "components/Ui/List/List"
import FlagIcon from "components/Ui/FlagIcon"
import IngridDetails from "./IngridDetails"

import { ReactComponent as Icon } from "images/icons/cart-flatbed-boxes.svg"
import { ReactComponent as LocationIcon } from "images/icons/location-dot.svg"
import { ReactComponent as IngridLogo } from "images/providers/ingrid-logo.svg"

const StyledIngridLogo = styled(IngridLogo)`
  position: absolute;
  height: 2rem;
  right: 3rem;
  top: 2rem;
`

const Columns = styled.div`
  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    display: flex;
    gap: 6rem;
  }
`

type Props = {
  orderShipping: OrderShippingIngrid
  shippingAddress: OrderAddress
  shippingFees: ShippingFee[]
}

const Shipping = ({ orderShipping, shippingAddress, shippingFees }: Props) => (
  <>
    <Columns>
      <OrderBlock>
        <h2>
          <Icon />
          Shipping details
        </h2>
        <List>
          <>{orderShipping.providerName === "Ingrid" && <StyledIngridLogo />}</>
          <Item>
            <Label>Shipping provider:</Label>
            <Value>
              {orderShipping.providerName ? <span>{orderShipping.providerName}</span> : "-"}
            </Value>
          </Item>

          {shippingFees && shippingFees.length > 0 ? (
            <>
              <Item>
                <Label>Shipping method:</Label>
                <Value>{shippingFees[0]?.displayName}</Value>
              </Item>
              <Item>
                <Label>Shipping cost:</Label>
                <Value>
                  <Money
                    amount={parseInt(`${shippingFees[0]?.basePriceAmount}`)}
                    currencyUnit={shippingFees[0]?.currencyCode as DineroFactory.Currency}
                  />
                </Value>
              </Item>
              <Item>
                <Label>Shipping tax:</Label>
                <Value>
                  <Money
                    amount={parseInt(`${shippingFees[0]?.taxAmount}`)}
                    currencyUnit={shippingFees[0]?.currencyCode as DineroFactory.Currency}
                  />
                </Value>
              </Item>
              <Item>
                <Label>Shipping tax rate:</Label>
                <Value>
                  <>
                    {(shippingFees[0]?.taxPercentage || 0) /
                      Math.pow(10, shippingFees[0]?.taxPercentageDecimals || 0)}
                    %
                  </>
                </Value>
              </Item>
              {shippingAddress?.pcc && (
                <Item>
                  <Label>Personal customs code:</Label>
                  <Value>{shippingAddress?.pcc}</Value>
                </Item>
              )}
            </>
          ) : (
            <></>
          )}
        </List>
      </OrderBlock>
      <OrderBlock>
        <h2>
          <LocationIcon />
          Shipping address
        </h2>
        <List>
          <Item>
            <Label>Name:</Label>
            <Value>
              <>
                <span>
                  {shippingAddress?.givenName} {shippingAddress?.familyName}
                </span>
              </>
            </Value>
          </Item>
          <Item>
            <Label>Street Address:</Label>
            <Value>{shippingAddress.streetAddress}</Value>
          </Item>
          {shippingAddress?.streetAddress2 ? (
            <Item>
              <Label>Street Address 2:</Label>
              <Value>{shippingAddress?.streetAddress2}</Value>
            </Item>
          ) : (
            <></>
          )}
          <Item>
            <Label>Postal code:</Label>
            <Value>{shippingAddress?.postalCode}</Value>
          </Item>
          <Item>
            <Label>City:</Label>
            <Value>{shippingAddress?.city}</Value>
          </Item>
          <Item>
            <Label>Country:</Label>
            <Value flex>
              <>
                <FlagIcon countryCode={shippingAddress?.country ?? ""} />
                {getCountryName(shippingAddress?.country ?? "")}
              </>
            </Value>
          </Item>
          <Item>
            <Label>Telephone number:</Label>
            <Value>{shippingAddress?.telephoneNumber}</Value>
          </Item>
        </List>
      </OrderBlock>
    </Columns>
    {orderShipping.providerName === "Ingrid" && <IngridDetails shippingIngrid={orderShipping} />}
  </>
)

export default Shipping
