import React, { useState } from "react"
import DineroFactory from "dinero.js"
import { Link } from "react-router-dom"
import { OrderGift, OrderStatus } from "@lib/types/generated/graphql-types"

import {
  BasePrice,
  Container,
  Content, Delivered,
  ExpandIcon,
  FieldTitle,
  Footer,
  Id,
  ImageAndDetails,
  ImageContainer,
  Name,
  ParentId,
  Price,
  PriceInfo,
  ProductImage,
  Qty, Refunded,
  Row,
  TotalTitle
} from "./OrderLine.styled"
import Money from "components/Money"
import placeholder from "images/placeholder.svg"

import { ReactComponent as DownIcon } from "images/icons/chevron-down.svg"

type Props = {
  gift: OrderGift
  orderStatus: OrderStatus
}

export const GiftLine = ({ gift, orderStatus }: Props) => {
  const [showFullSummary, setShowFullSummary] = useState<boolean>(false)

  if (!gift) return null

  const getGiftRefund = () => orderStatus.operations?.refunded.gifts.find(
    (gl) => gl?.giftId === gift.id
  )

  const getGiftDelivery = () => orderStatus.operations?.delivered.gifts.find(
    (gl) => gl?.giftId === gift.id
  )
  const hasDiscountPrice = gift.salePriceAmount < gift.basePriceAmount

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = placeholder
  }

  return (
    <Container onClick={() => setShowFullSummary(!showFullSummary)}>
      <Content>
        <div>
          <ImageAndDetails>
            <ImageContainer $showFullSummary={showFullSummary}>
              <ProductImage
                $showFullSummary={showFullSummary}
                src={gift.imageUrl as string}
                onError={handleImageError}
              />
            </ImageContainer>
            <div>
              <Name>{gift.displayName}</Name>
              <Id>
                <b>Id: </b>
                {gift.productVariantId} |{" "}
                <ParentId>
                  <b>Parent Id:</b>{" "}
                  <Link to={`/products/${gift.productParentId}`}>{gift.productParentId}</Link>
                </ParentId>
              </Id>
              <Price>
                <BasePrice $hasDiscountPrice={true}>
                  <Money
                    amount={parseInt(`${gift.salePriceAmount}`)}
                    currencyUnit={gift.currencyCode as DineroFactory.Currency}
                  />
                </BasePrice>
              </Price>
            </div>
          </ImageAndDetails>
        </div>
        <Qty>
          <div>
            <b>Quantity:</b> {gift.quantity}
          </div>
        </Qty>
        <div>
          <Row>
            <FieldTitle>Tax rate:</FieldTitle>
            <span>
              {(gift.taxPercentage || 0) / Math.pow(10, gift.taxPercentageDecimals || 0)}%
            </span>
          </Row>
          <Row>
            <FieldTitle>Total tax:</FieldTitle>
            <span>
              <Money
                amount={parseInt(`${gift.totalTaxAmount}`)}
                currencyUnit={gift.currencyCode as DineroFactory.Currency}
              />
            </span>
          </Row>
          <PriceInfo $showFullSummary={showFullSummary}>
            <Row>
              <FieldTitle>Base price:</FieldTitle>
              <span>
                <Money
                  amount={parseInt(`${gift.basePriceAmount}`)}
                  currencyUnit={gift.currencyCode as DineroFactory.Currency}
                />
              </span>
            </Row>
            {hasDiscountPrice && (
              <Row>
                <FieldTitle>Sale price:</FieldTitle>
                <span>
                  <Money
                    amount={parseInt(`${gift.salePriceAmount}`)}
                    currencyUnit={gift.currencyCode as DineroFactory.Currency}
                  />
                </span>
              </Row>
            )}
            {gift.discountAmount > 0 && (
              <Row>
                <FieldTitle>Product discount:</FieldTitle>
                <span>
                  -
                  <Money
                    amount={parseInt(`${gift.discountAmount}`)}
                    currencyUnit={gift.currencyCode as DineroFactory.Currency}
                  />
                </span>
              </Row>
            )}
          </PriceInfo>
        </div>
      </Content>
      <Footer>
        <div>
          {getGiftDelivery() && (
            <Delivered>
              <b>Delivered quantity: {getGiftDelivery()?.quantity}</b>
            </Delivered>
          )}
          {getGiftRefund() && (
            <Refunded>
              <b>Refunded quantity: {getGiftRefund()?.quantity}</b>
            </Refunded>
          )}
        </div>
        <div>
        <TotalTitle>Total:</TotalTitle>
        <TotalTitle>
          <Money
            amount={parseInt(`${gift.totalPriceAmount}`)}
            currencyUnit={gift.currencyCode as DineroFactory.Currency}
          />
        </TotalTitle>
        </div>
      </Footer>
      <ExpandIcon $showFullSummary={showFullSummary}>
        <DownIcon />
      </ExpandIcon>
    </Container>
  )
}
