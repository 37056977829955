import styled from "styled-components/macro"
import QuickAdd from "../../../Ui/Table/QuickAdd"
import PrimaryButton from "../../../Ui/Buttons/PrimaryButton"
import TableHeader from "../../../Ui/Table/TableHeader"
import TableRowSimple from "../../../Ui/Table/TableRowSimple"
import SingleSelect from "../../../Ui/Form/SingleSelect"

type StyledProps = {
  $active?: boolean
  $listLength?: number
}

export const BundleContainer = styled.div`
  padding: 2rem 2rem 1rem;
  border: 0.1rem solid ${(props) => props.theme.colors.borderLight};
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
`
export const TypeSelector = styled.div<StyledProps>`
  background: ${(p) => (p.$active ? p.theme.colors.turquoiseDark : p.theme.colors.white)};
  color: ${(p) => (p.$active ? p.theme.colors.white : p.theme.colors.black)};
  width: 100%;
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  padding: 1.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span {
    display: flex;
    align-items: center;
  }

  svg {
    margin-right: 1rem;
    width: 1.6rem;
    height: 1.6rem;
    fill: ${(p) => (p.$active ? p.theme.colors.white : p.theme.colors.black)};
  }
`

export const TypeSelectorContainer = styled.div`
  width: 100%;
  display: grid;
  gap: 1rem;
  margin-bottom: 2rem;
  grid-template-columns: repeat(1, 1fr);

  ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    flex-direction: row;
    justify-content: flex-start;
  }
`

export const Container = styled.div`
  margin-right: 0;
  padding-bottom: 1rem;

  > div {
    display: block;
  }
`

export const ItemRuleWrapper = styled.div`
  gap: 1rem;
  padding: 2rem;

  border: 0.1rem solid ${(p) => p.theme.colors.borderLight};
  border-radius: 0.6rem;

  &:hover {
    cursor: pointer;
    background: ${(p) => p.theme.colors.background};
    div {
      background: ${(p) => p.theme.colors.background};
    }
  }
`

export const ItemRuleContainer = styled.div<StyledProps>`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin-bottom: 2rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    grid-template-columns: ${p => p.$listLength && (p.$listLength > 0) ? "1fr 1fr": "1fr"};
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_X_LARGE} {
    grid-template-columns: ${p => p.$listLength && (p.$listLength > 0) ? "1fr 1fr 1fr": "1fr"};
  }
}

`

export const AddProductsButton = styled.div`
  border: 0.1rem solid ${(props) => props.theme.colors.borderLight};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 10rem;
  border-radius: 0.6rem;
  color: ${(props) => props.theme.colors.greyDarker};

  svg {
    height: 3rem;
    fill: ${(props) => props.theme.colors.greyDarker};
    margin-bottom: 0.5rem;
  }
  &:hover {
  background: ${(props) => props.theme.colors.background};
    cursor: pointer;
  }
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 1rem;
  }
`

export const Add = styled(QuickAdd)`
  background: transparent;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  padding: 2rem 0;
`

export const AddButton = styled(PrimaryButton)`
  margin: -3rem 0 0 auto;
`

export const FixedPrice = styled.div`
  width: 100%;

  p {
    text-align: center;
  }
`

export const FixedPriceHeader = styled(TableHeader)`
  padding: 0 6rem 0 2rem;
`

export const FixedPriceRow = styled(TableRowSimple)`
  background: rgba(255, 255, 255, 0.5);
  padding: 1.2rem 2rem 1rem;
  margin-bottom: 0;
`

export const FixedPriceCell = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  font-size: 1.1rem;
  line-height: 1.7rem;
  padding-right: 1rem;

  span {
    line-height: 1.8rem;
    font-size: 1.4rem;
    ${(p) => p.theme.bold}
  }
`

export const Currency = styled(FixedPriceCell)`
  width: 50%;
  flex: auto;
`

export const Amount = styled(FixedPriceCell)`
  flex: auto;
  width: calc(50% - 8rem);
`

export const InputWrapper = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: 3fr 3fr 1fr;

  button {
    margin: 0;
    position: relative;
    top: 2.8rem;
    height: 4.5rem;
  }
`

export const CurrencySelect = styled(SingleSelect)`
  margin-bottom: 1rem;
`

export const Formatted = styled.div`
  margin: 1rem 0;
`
export const Description = styled.span`
  font-size: 1.4rem;
  color: ${(p) => p.theme.colors.greyDarker};
  margin-bottom: 1.5rem;
`
