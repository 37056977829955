import React, { useState } from "react"
import {
  InputActionBonus,
  InputActionGiftCard,
  InputActionPaymentRefund,
  InputActionType,
  OrderRefund,
  OrderStatusLogEntry
} from "lib/types/generated/graphql-types"
import { Container, Provider, ProviderWrapper, Title } from "./FailedRefund.styled"
import Totals from "./Cart/Totals"
import { useMutation, useQuery } from "@apollo/client"
import GET_REFUND from "../../../../graphql/queries/order/GetRefund"
import SecondaryButton from "../../../Ui/Buttons/SecondaryButton"
import CopyButton from "../../../Ui/CopyButton"
import Popup from "../../../Ui/Popup"
import {
  ActionWrapper,
  GiftCardInputWrapper,
  InfoWrapper,
  LabelWrapper,
  StyledActionDropdownButton
} from "./Cart/RefundAction.styled"
import { ActionDropdownButton } from "../../../Ui/Buttons/ActionDropdownButton"
import Input from "../../../Ui/Form/Input"
import alertActions from "lib/store/services/Alert/AlertSlice"
import RESTART_REFUND from "../../../../graphql/mutations/order/refunds/RestartRefund"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { ReactComponent as GiftCardIcon } from "../../../../images/icons/gift.svg"
import { ReactComponent as BonusIcon } from "../../../../images/icons/hand-holding-dollar.svg"
import { ReactComponent as PaymentIcon } from "../../../../images/icons/credit-card.svg"
import ErrorMessages from "../ErrorMessages"

type Providers = {
  bonus: boolean
  giftCard: boolean
  payment: boolean
}

type Props = {
  failedRefund: OrderRefund
  currencyCode: string
  orderId: string
  orderStatus: OrderStatusLogEntry[]
}

enum FAILURE {
  BONUS = "REFUND_BONUS_FAILURE",
  GIFT_CARD = "REFUND_GIFT_CARD_FAILURE",
  PAYMENT = "REFUND_PAYMENT_FAILURE"
}

const FailedRefund = ({ failedRefund, currencyCode, orderId, orderStatus }: Props) => {
  const [failedProviders, setFailedProviders] = useState<Providers | undefined>()
  const [showPopup, setShowPopup] = useState(false)
  const [giftCardAction, setGiftCardAction] = useState<InputActionGiftCard>({
    actionType: InputActionType.Auto
  })
  const [bonusAction, setBonusAction] = useState<InputActionBonus>({
    actionType: InputActionType.Auto
  })
  const [paymentAction, setPaymentAction] = useState<InputActionPaymentRefund>({
    actionType: InputActionType.Auto
  })
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [restartRefund, { loading }] = useMutation(RESTART_REFUND, {
    onCompleted: () => {
      setShowPopup(false)
      navigate(`/orders/${orderId}`)
    },
    onError: (error) => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error",
          message: error.message
        })
      )
    }
  })

  const restartOrderRefund = () => {
    restartRefund({
      variables: {
        refundId: failedRefund.id,
        input: {
          ...(failedProviders?.bonus && { bonus: bonusAction }),
          ...(failedProviders?.giftCard && { giftCard: giftCardAction }),
          ...(failedProviders?.payment && { payment: paymentAction })
        }
      }
    })
  }

  const updateGiftCardActionCards = (id: string, value: string) => {
    const idExists = giftCardAction?.giftCards?.find((giftCard) => giftCard.giftCardId === id)
    if (idExists) {
      return setGiftCardAction({
        ...giftCardAction,
        giftCards: giftCardAction?.giftCards?.map((giftCard) =>
          giftCard.giftCardId === id
            ? {
                giftCardId: id,
                transactionId: value
              }
            : giftCard
        )
      })
    }
    return setGiftCardAction({
      ...giftCardAction,
      giftCards: [...(giftCardAction.giftCards ?? []), { giftCardId: id, transactionId: value }]
    })
  }

  const updatePaymentCaptureReference = (reference: string) => {
    setPaymentAction({ ...paymentAction, refundReference: reference })
  }

  useQuery(GET_REFUND, {
    variables: { refundId: failedRefund.id },
    onCompleted: (data) => {
      const refundDetails = data?.Order?.refund
      setFailedProviders({
        bonus: refundDetails?.bonusProvider?.status?.current === FAILURE.BONUS,
        giftCard: refundDetails?.giftCardProvider?.status?.current === FAILURE.GIFT_CARD,
        payment: refundDetails?.paymentProvider?.status?.current === FAILURE.PAYMENT
      })
    }
  })
  const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1)
  const getLabel = (value: string) => capitalize(value.toLowerCase())

  return (
    <>
      {showPopup && (
        <Popup
          title={"How do you want to perform the action"}
          handleCloseClick={() => setShowPopup(false)}
          buttonText={"Restart refund"}
          handleOkClick={() => restartOrderRefund()}
          loading={loading}
        >
          <InfoWrapper>
            <b>Auto</b>{" "}
            <p>Brink commerce will perform the necessary requests to fulfill the action.</p>
            <b>Manual</b>{" "}
            <p>
              The action has been performed in an external system and will be stored as manually
              completed.
            </p>
            <b>Skip</b> <p>The action will be skipped entirely.</p>
          </InfoWrapper>
          <div>
            {failedProviders?.giftCard && (
              <div>
                <ActionWrapper>
                  <LabelWrapper>
                    <GiftCardIcon />
                    <b>GiftCard:</b>
                  </LabelWrapper>
                  <ActionDropdownButton title={getLabel(giftCardAction.actionType)}>
                    <li onClick={() => setGiftCardAction({ actionType: InputActionType.Auto })}>
                      Auto
                    </li>
                    <li onClick={() => setGiftCardAction({ actionType: InputActionType.Manual })}>
                      Manual
                    </li>
                    <li onClick={() => setGiftCardAction({ actionType: InputActionType.Skip })}>
                      Skip
                    </li>
                  </ActionDropdownButton>
                </ActionWrapper>
                {giftCardAction.actionType === InputActionType.Manual && (
                  <>
                    {failedRefund.giftCards.map((giftCard) => {
                      return (
                        <GiftCardInputWrapper key={giftCard.giftCardId}>
                          <p>{giftCard.giftCardId}</p>
                          <Input
                            removePaddingBottom={true}
                            name="transactionId"
                            placeholder="Transaction id"
                            onChange={(e: React.FormEvent<HTMLInputElement>) =>
                              updateGiftCardActionCards(giftCard.giftCardId, e.currentTarget.value)
                            }
                          />
                        </GiftCardInputWrapper>
                      )
                    })}
                  </>
                )}
              </div>
            )}
            {failedProviders?.bonus && (
              <div>
                <ActionWrapper>
                  <LabelWrapper>
                    <BonusIcon />
                    <b>Bonus:</b>{" "}
                  </LabelWrapper>
                  <ActionDropdownButton title={getLabel(bonusAction.actionType)}>
                    <li onClick={() => setBonusAction({ actionType: InputActionType.Auto })}>
                      Auto
                    </li>
                    <li onClick={() => setBonusAction({ actionType: InputActionType.Manual })}>
                      Manual
                    </li>
                    <li onClick={() => setBonusAction({ actionType: InputActionType.Skip })}>
                      Skip
                    </li>
                  </ActionDropdownButton>
                </ActionWrapper>
              </div>
            )}
            {failedProviders?.payment && (
              <div>
                <ActionWrapper>
                  <LabelWrapper>
                    <PaymentIcon />
                    <b>Payment:</b>
                  </LabelWrapper>
                  <StyledActionDropdownButton title={getLabel(paymentAction.actionType)}>
                    <li onClick={() => setPaymentAction({ actionType: InputActionType.Auto })}>
                      Auto
                    </li>
                    <li onClick={() => setPaymentAction({ actionType: InputActionType.Manual })}>
                      Manual
                    </li>
                    <li onClick={() => setPaymentAction({ actionType: InputActionType.Skip })}>
                      Skip
                    </li>
                  </StyledActionDropdownButton>
                </ActionWrapper>
                {paymentAction.actionType === InputActionType.Manual && (
                  <Input
                    name="captureReference"
                    label="Capture reference"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      updatePaymentCaptureReference(e.currentTarget.value)
                    }
                  />
                )}
              </div>
            )}
          </div>
        </Popup>
      )}
      <Container>
        <Title>
          <div>
            <h2>Failed Refund</h2>
          </div>
          <SecondaryButton handleClick={() => setShowPopup(true)}>Restart refund</SecondaryButton>
        </Title>
        id: {failedRefund.id} <CopyButton string={failedRefund.id} />
        <ProviderWrapper>
          <h4>Failed providers:</h4>
          {failedProviders?.bonus && <Provider>Bonus</Provider>}
          {failedProviders?.giftCard && <Provider>Gift card</Provider>}
          {failedProviders?.payment && <Provider>Payment</Provider>}
        </ProviderWrapper>
        <Totals
          totals={failedRefund.totals}
          currencyCode={currencyCode}
          refundFee={failedRefund?.fee}
        />
        <ErrorMessages orderStatus={orderStatus} operationId={failedRefund.id} operation={"REFUND"}/>
      </Container>
    </>
  )
}

export default FailedRefund
