import React, { Dispatch, SetStateAction, useState } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { ApiClientDetails, ApiScope, InputCreateApiClient } from "lib/types/generated/graphql-types"
import { useMutation } from "@apollo/client"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useDispatch } from "react-redux"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import CREATE_API_CLIENT from "graphql/mutations/apiConfig/createApiClient"

import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import EditSidebarSection from "components/Ui/EditSidebar/EditSidebarSection"
import Input from "components/Ui/Form/Input"
import Popup from "components/Ui/Popup"
import CopyButton from "components/Ui/CopyButton"
import { ReactComponent as ExclamationIcon } from "images/icons/circle-exclamation.svg"
import { PopupHint, PopupInfo, PopupLabel, PopupWrapper } from "./ApiClients.styled"
import ScopeSelect from "../../../components/Settings/ApiClients/ScopeSelect"
import { Scopes } from "lib/types/common"

type Props = {
  setEditApiClient: Dispatch<SetStateAction<string>>
  availableScopes: ApiScope[]
  refetch: () => void
}

export const CreateApiClient = ({ refetch, availableScopes }: Props) => {
  const dispatch = useDispatch()
  const [selectedScopes, setSelectedScopes] = useState<Scopes>({ read: [], write: [] })
  const [createClientResponse, setCreateClientResponse] = useState<ApiClientDetails>()
  const [scopeError, setScopeError] = useState(false)

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<InputCreateApiClient>()

  const [createApiClient, { loading: createLoading }] = useMutation(CREATE_API_CLIENT, {
    onCompleted: (data) => {
      dispatch(
        alertActions.actions.createAlert({
          message: `Client ${data.createApiClient.name} successfully created`,
          type: "success"
        })
      )
      setCreateClientResponse(data.createApiClient)
      reset({
        name: ""
      })
      setSelectedScopes({ read: [], write: [] })
      dispatch(hideEditSidebar())
      refetch()
    },
    onError: (error) => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error",
          message: error.message
        })
      )
    }
  })

  const onSubmit: SubmitHandler<InputCreateApiClient> = (data) => {
    const scopes = [
      ...selectedScopes.read.map((scope) => scope.name),
      ...selectedScopes.write.map((scope) => scope.name)
    ]
    if (scopes.length > 0) {
      createApiClient({
        variables: {
          ...data,
          scopes
        }
      })
    } else {
      setScopeError(true)
    }
  }

  return (
    <>
      {createClientResponse && (
        <Popup
          title={"API client credentials"}
          subtitle={`Credentials for ${createClientResponse?.name}:`}
          buttonText="Ok"
          handleOkClick={() => {
            setCreateClientResponse(undefined)
          }}
          handleCloseClick={() => {
            setCreateClientResponse(undefined)
          }}
        >
          <PopupWrapper>
            <PopupLabel>Client ID:</PopupLabel>
            <PopupInfo>
              {createClientResponse?.clientId}{" "}
              <CopyButton string={createClientResponse?.clientId} />
            </PopupInfo>
            <PopupLabel>Client secret:</PopupLabel>
            <PopupInfo>
              {createClientResponse?.clientSecret}{" "}
              <CopyButton string={createClientResponse?.clientSecret} />
            </PopupInfo>
            <PopupHint>
              <ExclamationIcon />
              <div>Save client secret before closing this window</div>
            </PopupHint>
          </PopupWrapper>
        </Popup>
      )}
      <EditSidebar>
        <form onSubmit={handleSubmit(onSubmit)}>
          <EditSidebarHeader title="Create new API client">
            <PrimaryButton loading={createLoading} type="submit">
              Save
            </PrimaryButton>
          </EditSidebarHeader>
          <EditSidebarSection>
            <Controller
              name="name"
              render={({ field }) => (
                <Input {...field} label="Name *" placeholder="Name" errors={errors} />
              )}
              defaultValue=""
              control={control}
              rules={{
                required: "This is a required field"
              }}
            />
          </EditSidebarSection>
        </form>
        <ScopeSelect
          error={scopeError}
          selectedScopes={selectedScopes}
          setSelectedScopes={setSelectedScopes}
          availableScopes={availableScopes}
        />
      </EditSidebar>
    </>
  )
}
