import {
  InputActionBonus,
  InputActionGiftCard,
  InputActionPaymentRelease,
  InputActionType,
  Order,
  OrderRelease,
  OrderStatus
} from "lib/types/generated/graphql-types"
import FailedRelease from "./FailedRelease"
import { Container } from "../../../../views/Orders/ManageOrder/ManageOrder.styled"
import { OrderSummary } from "./OrderSummary/OrderSummary"
import { Cart } from "./Cart/Cart"
import React, { useState } from "react"
import ReleaseAction from "./Cart/ReleaseAction"
import StatusPopup from "./Cart/StatusPopup"

type Props = {
  order: Order
  refetch: () => void
  loading: boolean
  failedReleases: OrderRelease[]
  orderStatus: OrderStatus
}

const ReleaseOrder = ({ order, refetch, loading, failedReleases, orderStatus }: Props) => {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showStatusPopup, setShowStatusPopup] = useState(false)
  const [startedRelease, setStartedRelease] = useState("")

  const [giftCardAction, setGiftCardAction] = useState<InputActionGiftCard>({
    actionType: InputActionType.Auto
  })
  const [bonusAction, setBonusAction] = useState<InputActionBonus>({
    actionType: InputActionType.Auto
  })
  const [paymentAction, setPaymentAction] = useState<InputActionPaymentRelease>({
    actionType: InputActionType.Auto
  })

  const filteredReleases = order?.Releases?.filter(
    (release) => !release.completed && !release.started
  )

  return (
    <>
      {showStatusPopup && startedRelease && (
        <StatusPopup
          setShowStatusPopup={setShowStatusPopup}
          releaseId={startedRelease}
          refetchOrder={refetch}
        />
      )}
      {failedReleases?.length > 0 ? (
        failedReleases.map((release: OrderRelease) => (
          <FailedRelease
            key={release.id}
            refetch={refetch}
            failedRelease={release}
            orderStatus={orderStatus.history}
            setStartedRelease={setStartedRelease}
            setShowStatusPopup={setShowStatusPopup}
          />
        ))
      ) : !showConfirmation ? (
        <Container>
          <div>
            <OrderSummary order={order} refetch={refetch} orderLoading={loading} />
          </div>
          <div>
            <Cart order={order} refetch={refetch} setShowConfirmation={setShowConfirmation}/>
          </div>
        </Container>
      ) : (
        <Container>
          <div>
            <Cart
              order={order}
              refetch={refetch}
              setShowConfirmation={setShowConfirmation}
              summaryMode={true}
            />
          </div>
          <div>
            {filteredReleases?.map((release) => (
              <ReleaseAction
                key={release.id}
                release={release}
                actionState={{
                  giftCardAction,
                  setGiftCardAction,
                  bonusAction,
                  setBonusAction,
                  paymentAction,
                  setPaymentAction,
                }}
                orderId={order.id}
                setShowStatusPopup={setShowStatusPopup}
                setStartedRelease={setStartedRelease}
                setShowConfirmation={setShowConfirmation}
              />
            ))}
          </div>
        </Container>
      )}
    </>
  )
}

export default ReleaseOrder
