import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `AWSDateTime` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 DateTime](https://en.wikipedia.org/wiki/ISO_8601#Combined_date_and_time_representations) string. In other words, this scalar type accepts datetime strings of the form `YYYY-MM-DDThh:mm:ss.SSSZ`.  The scalar can also accept "negative years" of the form `-YYYY` which correspond to years before `0000`. For example, "**-2017-01-01T00:00Z**" and "**-9999-01-01T00:00Z**" are both valid datetime strings.  The field after the two digit seconds field is a nanoseconds field. It can accept between 1 and 9 digits. So, for example, "**1970-01-01T12:00:00.2Z**", "**1970-01-01T12:00:00.277Z**" and "**1970-01-01T12:00:00.123456789Z**" are all valid datetime strings.  The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used).  The [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators) is compulsory for this scalar. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDateTime: any;
  /** The `AWSEmail` scalar type provided by AWS AppSync, represents an Email address string that complies with [RFC 822](https://www.ietf.org/rfc/rfc822.txt). For example, "**username@example.com**" is a valid Email address. */
  AWSEmail: any;
  /** The `AWSJSON` scalar type provided by AWS AppSync, represents a JSON string that complies with [RFC 8259](https://tools.ietf.org/html/rfc8259).  Maps like "**{\\"upvotes\\": 10}**", lists like "**[1,2,3]**", and scalar values like "**\\"AWSJSON example string\\"**", "**1**", and "**true**" are accepted as valid JSON and will automatically be parsed and loaded in the resolver mapping templates as Maps, Lists, or Scalar values rather than as the literal input strings.  Invalid JSON strings like "**{a: 1}**", "**{'a': 1}**" and "**Unquoted string**" will throw GraphQL validation errors. */
  AWSJSON: any;
};

export type AddExternalEventsDestinationApiResult = {
  __typename?: 'AddExternalEventsDestinationAPIResult';
  apiDestinationName: Scalars['String'];
};

export type AddExternalEventsDestinationEventBusResult = {
  __typename?: 'AddExternalEventsDestinationEventBusResult';
  eventBusArn: Scalars['String'];
};

export type AddExternalEventsRuleResult = {
  __typename?: 'AddExternalEventsRuleResult';
  ruleName: Scalars['String'];
};

export type AdyenConfig = {
  __typename?: 'AdyenConfig';
  clientKey: Scalars['String'];
  created: Scalars['AWSDateTime'];
  credentials: AdyenCredentials;
  environment: AdyenEnvironment;
  id: Scalars['ID'];
  liveEndpointUrlPrefix?: Maybe<Scalars['String']>;
  merchantAccount: Scalars['String'];
  updated: Scalars['AWSDateTime'];
  webhooks?: Maybe<Array<AdyenWebhook>>;
};

export type AdyenCredentials = {
  __typename?: 'AdyenCredentials';
  apiKey: Scalars['String'];
  /** @deprecated will be moved to AdyenWebhookCredentials.hmac instead */
  hmac?: Maybe<Scalars['String']>;
};

export enum AdyenEnvironment {
  Live = 'LIVE',
  Test = 'TEST'
}

export type AdyenWebhook = {
  __typename?: 'AdyenWebhook';
  configId?: Maybe<Scalars['String']>;
  created: Scalars['AWSDateTime'];
  description?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  sequenceNumber: Scalars['Int'];
  status?: Maybe<AdyenWebhookStatus>;
  target?: Maybe<AdyenWebhookTarget>;
  updated: Scalars['AWSDateTime'];
  username: Scalars['String'];
};

export type AdyenWebhookCredentials = {
  __typename?: 'AdyenWebhookCredentials';
  hmac?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type AdyenWebhookGenerationResult = {
  __typename?: 'AdyenWebhookGenerationResult';
  started: Scalars['Boolean'];
};

export type AdyenWebhookLegacy = {
  __typename?: 'AdyenWebhookLegacy';
  created: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  updated: Scalars['AWSDateTime'];
  username: Scalars['String'];
};

export enum AdyenWebhookStatus {
  Failed = 'FAILED',
  Success = 'SUCCESS'
}

export enum AdyenWebhookTarget {
  OrderManagement = 'OrderManagement',
  Shopper = 'Shopper'
}

export type ApiClient = {
  __typename?: 'ApiClient';
  clientId: Scalars['String'];
  name: Scalars['String'];
};

export type ApiClientDetails = {
  __typename?: 'ApiClientDetails';
  clientId: Scalars['ID'];
  clientSecret?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['AWSDateTime']>;
  name: Scalars['String'];
  scopes?: Maybe<Array<Scalars['String']>>;
  updated?: Maybe<Scalars['AWSDateTime']>;
};

export type ApiClientsList = {
  __typename?: 'ApiClientsList';
  apiClients: Array<ApiClient>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ApiResource = {
  __typename?: 'ApiResource';
  identifier: Scalars['String'];
  scopes?: Maybe<Array<ApiScope>>;
};

export type ApiScope = {
  __typename?: 'ApiScope';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type BsgGiftCardConfig = {
  __typename?: 'BSGGiftCardConfig';
  baseUrl: Scalars['String'];
  created: Scalars['AWSDateTime'];
  credentials: BsgGiftCardCredentials;
  id: Scalars['ID'];
  scopes: Array<Scalars['String']>;
  tokenUrl: Scalars['String'];
  updated: Scalars['AWSDateTime'];
};

export type BsgGiftCardCredentials = {
  __typename?: 'BSGGiftCardCredentials';
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
};

export type BonusConfig = {
  __typename?: 'BonusConfig';
  authUrl: Scalars['String'];
  baseUrl: Scalars['String'];
  created: Scalars['AWSDateTime'];
  credentials: BonusCredentials;
  id: Scalars['ID'];
  updated: Scalars['AWSDateTime'];
};

export type BonusCredentials = {
  __typename?: 'BonusCredentials';
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
};

export type BonusProvider = {
  __typename?: 'BonusProvider';
  providerId: Scalars['String'];
  providerName: BonusProviderName;
  status?: Maybe<ProviderStatusLog>;
};

export enum BonusProviderName {
  Bonus = 'Bonus'
}

export type Bundle = {
  __typename?: 'Bundle';
  discountValue: DiscountValue;
  id: Scalars['String'];
  itemRules: Array<BundleItemRule>;
};

export type BundleGroup = {
  __typename?: 'BundleGroup';
  bundles: Array<Bundle>;
  created: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  storeMarkets: Array<Maybe<BundleStoreMarket>>;
  updated: Scalars['AWSDateTime'];
  validDateRange?: Maybe<ValidDateRange>;
};

export type BundleGroupSearchHit = {
  __typename?: 'BundleGroupSearchHit';
  bundles: Array<Scalars['String']>;
  created: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  updated: Scalars['AWSDateTime'];
  validFrom?: Maybe<Scalars['AWSDateTime']>;
  validTo?: Maybe<Scalars['AWSDateTime']>;
};

export type BundleGroupSearchHits = {
  __typename?: 'BundleGroupSearchHits';
  hits: Array<BundleGroupSearchHit>;
  total: Scalars['Int'];
};

export type BundleItemRule = {
  __typename?: 'BundleItemRule';
  excludeDiscountedPrices: Scalars['Boolean'];
  excludeFullPrices: Scalars['Boolean'];
  productParents: Array<Scalars['String']>;
  quantity: Scalars['Int'];
  tagConditions: Array<TagCondition>;
};

export type BundleOutcome = {
  __typename?: 'BundleOutcome';
  bundleGroupId?: Maybe<Scalars['String']>;
  bundleId?: Maybe<Scalars['String']>;
  discountAmount?: Maybe<Scalars['Int']>;
};

export type BundleStoreMarket = {
  __typename?: 'BundleStoreMarket';
  key: Scalars['String'];
  value: Array<Scalars['String']>;
};

export type Campaign = {
  __typename?: 'Campaign';
  campaignGroupId: Scalars['String'];
  created: Scalars['AWSDateTime'];
  endDate: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  priceRule: PriceRule;
  productVariantRules: ProductVariantRules;
  roundingRules?: Maybe<RoundingRules>;
  startDate: Scalars['AWSDateTime'];
  storeMarkets: Array<CampaignStoreMarket>;
  updated: Scalars['AWSDateTime'];
};

export type CampaignDeletionResult = {
  __typename?: 'CampaignDeletionResult';
  result: Scalars['String'];
};

export type CampaignGroup = {
  __typename?: 'CampaignGroup';
  created: Scalars['AWSDateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updated: Scalars['AWSDateTime'];
};

export type CampaignGroupList = {
  __typename?: 'CampaignGroupList';
  campaignGroup: CampaignGroup;
  campaigns: Array<Campaign>;
};

export type CampaignGroupSearchHit = {
  __typename?: 'CampaignGroupSearchHit';
  created: Scalars['AWSDateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updated: Scalars['AWSDateTime'];
};

export type CampaignGroupSearchHits = {
  __typename?: 'CampaignGroupSearchHits';
  hits: Array<CampaignGroupSearchHit>;
  total: Scalars['Int'];
};

export type CampaignIdentity = {
  __typename?: 'CampaignIdentity';
  campaignGroupId: Scalars['ID'];
  campaignId: Scalars['ID'];
  name: Scalars['String'];
};

export type CampaignMoney = {
  __typename?: 'CampaignMoney';
  key: Scalars['String'];
  value: Scalars['Int'];
};

export type CampaignSearchHit = {
  __typename?: 'CampaignSearchHit';
  campaignGroupId: Scalars['String'];
  created: Scalars['AWSDateTime'];
  endDate: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  startDate: Scalars['AWSDateTime'];
  updated: Scalars['AWSDateTime'];
};

export type CampaignSearchHits = {
  __typename?: 'CampaignSearchHits';
  hits: Array<CampaignSearchHit>;
  total: Scalars['Int'];
};

export type CampaignStoreMarket = {
  __typename?: 'CampaignStoreMarket';
  key: Scalars['String'];
  value: Array<Scalars['String']>;
};

export type CancellationBonus = {
  __typename?: 'CancellationBonus';
  reservationId: Scalars['String'];
  status: Scalars['String'];
};

export type CancellationGiftCard = {
  __typename?: 'CancellationGiftCard';
  giftCardId: Scalars['ID'];
  reservationId: Scalars['String'];
  status: Scalars['String'];
  transactionId?: Maybe<Scalars['String']>;
};

export type CancellationGiftCardProduct = {
  __typename?: 'CancellationGiftCardProduct';
  cancelTransactionId?: Maybe<Scalars['String']>;
  giftCardProductId: Scalars['ID'];
  status: Scalars['String'];
};

export type CancelledPayment = {
  __typename?: 'CancelledPayment';
  reference: Scalars['String'];
};

export type Capabilities = {
  __typename?: 'Capabilities';
  giftCardProviders?: Maybe<Array<Provider>>;
  paymentProviders?: Maybe<Array<Provider>>;
  shippingProviders?: Maybe<Array<Provider>>;
};

export type CapabilityProvider = {
  __typename?: 'CapabilityProvider';
  id: Scalars['ID'];
};

export type CartCapabilities = {
  __typename?: 'CartCapabilities';
  bonus?: Maybe<CapabilityProvider>;
  retain24?: Maybe<CapabilityProvider>;
  voyado?: Maybe<CapabilityProvider>;
};

export type CartRuleOutcome = {
  __typename?: 'CartRuleOutcome';
  discountAmount?: Maybe<Scalars['Int']>;
  ruleID?: Maybe<Scalars['String']>;
};

export enum ChannelType {
  B2B = 'B2B',
  B2C = 'B2C'
}

export type CodeRuleOutcome = {
  __typename?: 'CodeRuleOutcome';
  code?: Maybe<Scalars['String']>;
  discountAmount?: Maybe<Scalars['Int']>;
  ruleID?: Maybe<Scalars['String']>;
};

export type CodesGenerationSearchHit = {
  __typename?: 'CodesGenerationSearchHit';
  created: Scalars['AWSDateTime'];
  discountCodeRuleId: Scalars['String'];
  id: Scalars['ID'];
  numberOfCodesGenerated: Scalars['Int'];
  numberOfCodesRequested: Scalars['Int'];
  prefix: Scalars['String'];
  status: Scalars['String'];
  updated: Scalars['AWSDateTime'];
  usageLimit: Scalars['Int'];
};

export type CodesGenerationSearchHits = {
  __typename?: 'CodesGenerationSearchHits';
  hits: Array<CodesGenerationSearchHit>;
  total: Scalars['Int'];
};

export type CompanyAttribute = {
  __typename?: 'CompanyAttribute';
  name?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  registrationNumber?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
};

export type CompensationBonus = {
  __typename?: 'CompensationBonus';
  amount: Scalars['Int'];
  currencyCode: Scalars['String'];
  reservationId: Scalars['String'];
  transactionId: Scalars['ID'];
};

export type CompensationGiftCard = {
  __typename?: 'CompensationGiftCard';
  amount: Scalars['Int'];
  currencyCode: Scalars['String'];
  giftCardId: Scalars['ID'];
  status: Scalars['String'];
};

export type CompensationOrderLine = {
  __typename?: 'CompensationOrderLine';
  currencyCode: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orderLineId: Scalars['ID'];
  quantity: Scalars['Int'];
  taxPercentage: Scalars['Int'];
  taxPercentageDecimals: Scalars['Int'];
  totalAmount: Scalars['Int'];
  totalDiscountAmount: Scalars['Int'];
  totalTaxAmount: Scalars['Int'];
};

export type CompensationPayment = {
  __typename?: 'CompensationPayment';
  amount: Scalars['Int'];
  currencyCode: Scalars['String'];
  reference: Scalars['String'];
};

export type CompensationShippingFee = {
  __typename?: 'CompensationShippingFee';
  currencyCode: Scalars['String'];
  name: Scalars['String'];
  shippingFeeId: Scalars['ID'];
  taxPercentage: Scalars['Int'];
  taxPercentageDecimals: Scalars['Int'];
  totalAmount: Scalars['Int'];
  totalDiscountAmount: Scalars['Int'];
  totalTaxAmount: Scalars['Int'];
};

export type Condition = {
  __typename?: 'Condition';
  id: Scalars['ID'];
  identifiers?: Maybe<Array<Scalars['String']>>;
  itemRule?: Maybe<ItemRule>;
  thresholds?: Maybe<Array<Money>>;
  triggerQuantity?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
};

export type CreateGenerateCodesResult = {
  __typename?: 'CreateGenerateCodesResult';
  jobId: Scalars['String'];
};

export type DeletedResult = {
  __typename?: 'DeletedResult';
  status: Scalars['String'];
};

export type DeliveryBonus = {
  __typename?: 'DeliveryBonus';
  amount: Scalars['Int'];
  currencyCode: Scalars['String'];
  reservationId: Scalars['String'];
  transactionId: Scalars['ID'];
};

export type DeliveryCapture = {
  __typename?: 'DeliveryCapture';
  reference: Scalars['String'];
};

export type DeliveryGift = {
  __typename?: 'DeliveryGift';
  giftId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type DeliveryGiftCard = {
  __typename?: 'DeliveryGiftCard';
  amount: Scalars['Int'];
  currencyCode: Scalars['String'];
  giftCardId: Scalars['ID'];
  reservationId: Scalars['String'];
  status: Scalars['String'];
  transactionId: Scalars['String'];
};

export type DeliveryGiftCardProduct = {
  __typename?: 'DeliveryGiftCardProduct';
  createFailedReason?: Maybe<Scalars['String']>;
  createId?: Maybe<Scalars['String']>;
  createTransactionId?: Maybe<Scalars['String']>;
  currencyCode: Scalars['String'];
  giftCardProductId: Scalars['ID'];
  priceAmount: Scalars['Int'];
  status: Scalars['String'];
};

export type DeliveryOrderLine = {
  __typename?: 'DeliveryOrderLine';
  currencyCode: Scalars['String'];
  orderLineId: Scalars['ID'];
  quantity: Scalars['Int'];
  taxPercentage: Scalars['Int'];
  taxPercentageDecimals: Scalars['Int'];
  totalAmount: Scalars['Int'];
  totalDiscountAmount: Scalars['Int'];
  totalTaxAmount: Scalars['Int'];
};

export type DeliveryShippingFee = {
  __typename?: 'DeliveryShippingFee';
  currencyCode: Scalars['String'];
  shippingFeeId: Scalars['ID'];
  totalAmount: Scalars['Int'];
};

export type DeliveryTracking = {
  __typename?: 'DeliveryTracking';
  reference: Scalars['String'];
  shippingCompany: Scalars['String'];
  shippingMethod: Scalars['String'];
  url: Scalars['String'];
};

export type DeliveryVoucher = {
  __typename?: 'DeliveryVoucher';
  amount: Scalars['Int'];
  currencyCode: Scalars['String'];
  voucherId: Scalars['ID'];
};

export type Dimensions = {
  __typename?: 'Dimensions';
  height?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

export type DiscountCode = {
  __typename?: 'DiscountCode';
  code: Scalars['String'];
  created: Scalars['AWSDateTime'];
  discountCodeRuleId: Scalars['String'];
  isUnlimited: Scalars['Boolean'];
  updated: Scalars['AWSDateTime'];
  usageLimit?: Maybe<Scalars['Int']>;
  validDateRange?: Maybe<ValidDateRange>;
};

export type DiscountCodeRule = {
  __typename?: 'DiscountCodeRule';
  applyLast: Scalars['Boolean'];
  conditions: Array<Condition>;
  created: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isExclusive: Scalars['Boolean'];
  isStackable: Scalars['Boolean'];
  logicRules: Array<LogicHolder>;
  name: Scalars['String'];
  outcomes: Array<Outcome>;
  sortOrder: Scalars['Int'];
  updated: Scalars['AWSDateTime'];
  validDateRange?: Maybe<ValidDateRange>;
};

export type DiscountCodeRuleSearchHit = {
  __typename?: 'DiscountCodeRuleSearchHit';
  applyLast: Scalars['Boolean'];
  created: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isExclusive: Scalars['Boolean'];
  isStackable: Scalars['Boolean'];
  name: Scalars['String'];
  sortOrder: Scalars['Int'];
  updated: Scalars['AWSDateTime'];
  validFrom?: Maybe<Scalars['AWSDateTime']>;
  validTo?: Maybe<Scalars['AWSDateTime']>;
};

export type DiscountCodeRuleSearchHits = {
  __typename?: 'DiscountCodeRuleSearchHits';
  hits: Array<DiscountCodeRuleSearchHit>;
  total: Scalars['Int'];
};

export type DiscountCodeSearchHit = {
  __typename?: 'DiscountCodeSearchHit';
  code: Scalars['ID'];
  created: Scalars['AWSDateTime'];
  discountCodeRuleId: Scalars['String'];
  isUnlimited: Scalars['Boolean'];
  updated: Scalars['AWSDateTime'];
  usageLimit?: Maybe<Scalars['Int']>;
  validFrom?: Maybe<Scalars['AWSDateTime']>;
  validTo?: Maybe<Scalars['AWSDateTime']>;
};

export type DiscountCodeSearchHits = {
  __typename?: 'DiscountCodeSearchHits';
  hits: Array<DiscountCodeSearchHit>;
  total: Scalars['Int'];
};

export type DiscountExternalRule = {
  __typename?: 'DiscountExternalRule';
  applyLast: Scalars['Boolean'];
  conditions: Array<Condition>;
  created: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isExclusive: Scalars['Boolean'];
  isStackable: Scalars['Boolean'];
  logicRules: Array<LogicHolder>;
  name: Scalars['String'];
  outcomes: Array<Outcome>;
  sortOrder: Scalars['Int'];
  updated: Scalars['AWSDateTime'];
  validDateRange?: Maybe<ValidDateRange>;
};

export type DiscountExternalRuleSearchHit = {
  __typename?: 'DiscountExternalRuleSearchHit';
  applyLast: Scalars['Boolean'];
  created: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isExclusive: Scalars['Boolean'];
  isStackable: Scalars['Boolean'];
  name: Scalars['String'];
  sortOrder: Scalars['Int'];
  updated: Scalars['AWSDateTime'];
  validFrom?: Maybe<Scalars['AWSDateTime']>;
  validTo?: Maybe<Scalars['AWSDateTime']>;
};

export type DiscountExternalRuleSearchHits = {
  __typename?: 'DiscountExternalRuleSearchHits';
  hits: Array<DiscountExternalRuleSearchHit>;
  total: Scalars['Int'];
};

export type DiscountManagementQuotas = {
  __typename?: 'DiscountManagementQuotas';
  maxGenerationJobsPerDay: Scalars['Int'];
  maxNumberOfCodes: Scalars['Int'];
};

export type DiscountRule = {
  __typename?: 'DiscountRule';
  conditions: Array<Condition>;
  created: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isStackable: Scalars['Boolean'];
  logicRules: Array<LogicHolder>;
  name: Scalars['String'];
  outcomes: Array<Outcome>;
  sortOrder: Scalars['Int'];
  updated: Scalars['AWSDateTime'];
  validDateRange?: Maybe<ValidDateRange>;
};

export type DiscountRuleSearchHit = {
  __typename?: 'DiscountRuleSearchHit';
  created: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isStackable: Scalars['Boolean'];
  name: Scalars['String'];
  sortOrder: Scalars['Int'];
  updated: Scalars['AWSDateTime'];
  validFrom?: Maybe<Scalars['AWSDateTime']>;
  validTo?: Maybe<Scalars['AWSDateTime']>;
};

export type DiscountRuleSearchHits = {
  __typename?: 'DiscountRuleSearchHits';
  hits: Array<DiscountRuleSearchHit>;
  total: Scalars['Int'];
};

export type DiscountValue = {
  __typename?: 'DiscountValue';
  fixedDiscounts?: Maybe<Array<Money>>;
  percentage?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
};

export type ExternalApi = {
  __typename?: 'ExternalApi';
  discountManagementQuotas?: Maybe<DiscountManagementQuotas>;
  usagePlans: Array<UsagePlan>;
};

export type ExternalEventDestinationApIs = {
  __typename?: 'ExternalEventDestinationAPIs';
  apiDestinations: Array<Scalars['String']>;
  apiDestinationsDetails: Array<ExternalEventDestinationsDetails>;
};

export type ExternalEventDestinationEventBuses = {
  __typename?: 'ExternalEventDestinationEventBuses';
  eventBuses: Array<Scalars['String']>;
};

export type ExternalEventDestinationsDetails = {
  __typename?: 'ExternalEventDestinationsDetails';
  active: Scalars['Boolean'];
  created: Scalars['AWSDateTime'];
  endpoint?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updated: Scalars['AWSDateTime'];
};

export type ExternalEventRuleState = {
  __typename?: 'ExternalEventRuleState';
  ruleName: Scalars['String'];
  state: Scalars['String'];
};

export type ExternalEventRules = {
  __typename?: 'ExternalEventRules';
  rules: Array<ExternalEventRuleState>;
};

export type ExternalRuleOutcome = {
  __typename?: 'ExternalRuleOutcome';
  discountAmount?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  ruleID?: Maybe<Scalars['String']>;
};

export type GenerateCodes = {
  __typename?: 'GenerateCodes';
  created: Scalars['AWSDateTime'];
  discountCodeRuleId: Scalars['String'];
  fileUrl?: Maybe<Scalars['String']>;
  jobId: Scalars['String'];
  numberOfCodesGenerated?: Maybe<Scalars['Int']>;
  numberOfCodesRequested: Scalars['Int'];
  prefix: Scalars['String'];
  status: Scalars['String'];
  updated: Scalars['AWSDateTime'];
  usageLimit: Scalars['Int'];
};

export type Gift = {
  __typename?: 'Gift';
  productVariantId: Scalars['String'];
  quantity: Scalars['Int'];
};

export type GiftCardProductProvider = {
  __typename?: 'GiftCardProductProvider';
  providerId: Scalars['String'];
  providerName: GiftCardProductProviderName;
  status?: Maybe<ProviderStatusLog>;
};

export enum GiftCardProductProviderName {
  Retain24 = 'Retain24'
}

export type GiftCardProvider = {
  __typename?: 'GiftCardProvider';
  providerId: Scalars['String'];
  providerName: GiftCardProviderName;
  status?: Maybe<ProviderStatusLog>;
};

export enum GiftCardProviderName {
  BsgGiftCard = 'BsgGiftCard',
  KbsGiftCard = 'KbsGiftCard',
  Retain24 = 'Retain24'
}

export type Group = {
  __typename?: 'Group';
  description?: Maybe<Scalars['String']>;
  name: Scalars['ID'];
};

export type GroupDetails = {
  __typename?: 'GroupDetails';
  description?: Maybe<Scalars['String']>;
  name: Scalars['ID'];
  users: Array<User>;
};

export type GroupsList = {
  __typename?: 'GroupsList';
  groups: Array<Group>;
  nextToken?: Maybe<Scalars['String']>;
};

export type IngridConfig = {
  __typename?: 'IngridConfig';
  baseUrl: Scalars['String'];
  created: Scalars['AWSDateTime'];
  credentials: IngridCredentials;
  id: Scalars['ID'];
  siteId: Scalars['String'];
  updated: Scalars['AWSDateTime'];
};

export type IngridCredentials = {
  __typename?: 'IngridCredentials';
  apiKey: Scalars['String'];
};

export type IngridDeliveries = {
  __typename?: 'IngridDeliveries';
  carrierProductId?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  deliveryAddress?: Maybe<IngridDeliveryAddress>;
  deliveryType?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  locationExternalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  taxAmount?: Maybe<Scalars['Int']>;
  taxGroupId?: Maybe<Scalars['String']>;
  taxPercentage?: Maybe<Scalars['Int']>;
  taxPercentageDecimals?: Maybe<Scalars['Int']>;
  tosId?: Maybe<Scalars['String']>;
};

export type IngridDeliveryAddress = {
  __typename?: 'IngridDeliveryAddress';
  addressLines?: Maybe<Array<Scalars['String']>>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

export type InputActionBonus = {
  actionType: InputActionType;
};

export type InputActionGiftCard = {
  actionType: InputActionType;
  giftCards?: InputMaybe<Array<InputActionGiftCardManual>>;
};

export type InputActionGiftCardManual = {
  giftCardId?: InputMaybe<Scalars['String']>;
  transactionId?: InputMaybe<Scalars['String']>;
};

export type InputActionPaymentCancellation = {
  actionType: InputActionType;
  reference?: InputMaybe<Scalars['String']>;
};

export type InputActionPaymentCompensation = {
  actionType: InputActionType;
  reference?: InputMaybe<Scalars['String']>;
};

export type InputActionPaymentDelivery = {
  actionType: InputActionType;
  captureReference?: InputMaybe<Scalars['String']>;
};

export type InputActionPaymentRefund = {
  actionType: InputActionType;
  refundReference?: InputMaybe<Scalars['String']>;
};

export type InputActionPaymentRelease = {
  actionType: InputActionType;
  reference?: InputMaybe<Scalars['String']>;
};

export type InputActionShipping = {
  actionType: InputActionType;
  shippingCompany?: InputMaybe<Scalars['String']>;
  shippingMethod?: InputMaybe<Scalars['String']>;
  trackingReference?: InputMaybe<Scalars['String']>;
  trackingUrl?: InputMaybe<Scalars['String']>;
};

export enum InputActionType {
  Auto = 'AUTO',
  Manual = 'MANUAL',
  Skip = 'SKIP'
}

export type InputAddExternalEventsDestinationApi = {
  apiDestinationEndpoint: Scalars['String'];
  apiKeyAuth?: InputMaybe<InputExternalEventsApiKeyAuth>;
  basicAuth?: InputMaybe<InputExternalEventsBasicAuth>;
};

export type InputAddUserToGroup = {
  groupname: Scalars['String'];
  username: Scalars['AWSEmail'];
};

export type InputAdyenConfig = {
  apiKey: Scalars['String'];
  clientKey: Scalars['String'];
  environment: AdyenEnvironment;
  hmac?: InputMaybe<Scalars['String']>;
  liveEndpointUrlPrefix?: InputMaybe<Scalars['String']>;
  merchantAccount: Scalars['String'];
};

export type InputAdyenWebhookChange = {
  configId: Scalars['String'];
  created: Scalars['AWSDateTime'];
  description: Scalars['String'];
  externalId: Scalars['String'];
  id: Scalars['ID'];
  sequenceNumber: Scalars['Int'];
  status?: InputMaybe<AdyenWebhookStatus>;
  target: AdyenWebhookTarget;
  tested?: InputMaybe<Scalars['AWSDateTime']>;
  updated: Scalars['AWSDateTime'];
  username: Scalars['String'];
};

export type InputBsgGiftCardConfig = {
  baseUrl: Scalars['String'];
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
  scopes: Array<Scalars['String']>;
  tokenUrl: Scalars['String'];
};

export type InputBonusConfig = {
  authUrl: Scalars['String'];
  baseUrl: Scalars['String'];
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
};

export type InputBundle = {
  discountValue: InputDiscountValue;
  id: Scalars['String'];
  itemRules: Array<InputBundleItemRule>;
};

export type InputBundleItemRule = {
  excludeDiscountedPrices: Scalars['Boolean'];
  excludeFullPrices: Scalars['Boolean'];
  productParents: Array<Scalars['String']>;
  quantity: Scalars['Int'];
  tagConditions: Array<InputTagCondition>;
};

export type InputCampaign = {
  campaignGroupId: Scalars['String'];
  endDate: Scalars['AWSDateTime'];
  name: Scalars['String'];
  priceRule: InputPriceRule;
  productVariantRules: InputProductVariantRules;
  roundingRules?: InputMaybe<InputRoundingRules>;
  startDate: Scalars['AWSDateTime'];
  storeMarkets: Array<InputStoreMarket>;
};

export type InputCampaignGroup = {
  description: Scalars['String'];
  name: Scalars['String'];
};

export type InputCampaignMoney = {
  key: Scalars['String'];
  value: Scalars['Int'];
};

export type InputCampaignPeriod = {
  endDate?: InputMaybe<Scalars['AWSDateTime']>;
  startDate?: InputMaybe<Scalars['AWSDateTime']>;
};

export type InputCapabilities = {
  giftCardProviders?: InputMaybe<Array<InputProvider>>;
  paymentProviders?: InputMaybe<Array<InputProvider>>;
  shippingProviders?: InputMaybe<Array<InputProvider>>;
};

export type InputCapabilityProvider = {
  id: Scalars['ID'];
};

export type InputCartCapabilities = {
  bonus?: InputMaybe<InputCapabilityProvider>;
  retain24?: InputMaybe<InputCapabilityProvider>;
  voyado?: InputMaybe<InputCapabilityProvider>;
};

export type InputCondition = {
  id: Scalars['ID'];
  identifiers?: InputMaybe<Array<Scalars['String']>>;
  itemRule?: InputMaybe<InputItemRule>;
  thresholds?: InputMaybe<Array<InputMoney>>;
  triggerQuantity?: InputMaybe<Scalars['Int']>;
  type: InputConditionType;
};

export enum InputConditionType {
  Cartoverthreshold = 'CARTOVERTHRESHOLD',
  Cartunderthreshold = 'CARTUNDERTHRESHOLD',
  Countrycode = 'COUNTRYCODE',
  Minitems = 'MINITEMS',
  Minitemsvalue = 'MINITEMSVALUE',
  Storegroup = 'STOREGROUP'
}

export type InputCreateApiClient = {
  name: Scalars['String'];
  scopes: Array<Scalars['String']>;
};

export type InputCreateGenerateCodes = {
  discountCodeRuleId: Scalars['String'];
  numberOfCodes: Scalars['Int'];
  prefix: Scalars['String'];
  usageLimit: Scalars['Int'];
};

export type InputCreateOrUpdateBundleGroup = {
  bundles: Array<InputBundle>;
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  storeMarkets: Array<InputStoreMarket>;
  validDateRange?: InputMaybe<InputValidDateRange>;
};

export type InputCreateOrUpdateDiscountCode = {
  discountCodeRuleId: Scalars['String'];
  isUnlimited: Scalars['Boolean'];
  usageLimit?: InputMaybe<Scalars['Int']>;
  validDateRange?: InputMaybe<InputValidDateRange>;
};

export type InputCreateOrUpdateDiscountCodeRule = {
  applyLast: Scalars['Boolean'];
  conditions: Array<InputCondition>;
  isActive: Scalars['Boolean'];
  isExclusive: Scalars['Boolean'];
  isStackable: Scalars['Boolean'];
  logicRules: Array<InputLogicHolder>;
  name: Scalars['String'];
  outcomes: Array<InputOutcome>;
  sortOrder: Scalars['Int'];
  validDateRange?: InputMaybe<InputValidDateRange>;
};

export type InputCreateOrUpdateDiscountExternalRule = {
  applyLast: Scalars['Boolean'];
  conditions: Array<InputCondition>;
  isActive: Scalars['Boolean'];
  isExclusive: Scalars['Boolean'];
  isStackable: Scalars['Boolean'];
  logicRules: Array<InputLogicHolder>;
  name: Scalars['String'];
  outcomes: Array<InputOutcome>;
  sortOrder: Scalars['Int'];
  validDateRange?: InputMaybe<InputValidDateRange>;
};

export type InputCreateOrUpdateDiscountRule = {
  conditions: Array<InputCondition>;
  isActive: Scalars['Boolean'];
  isStackable: Scalars['Boolean'];
  logicRules: Array<InputLogicHolder>;
  name: Scalars['String'];
  outcomes: Array<InputOutcome>;
  sortOrder: Scalars['Int'];
  validDateRange?: InputMaybe<InputValidDateRange>;
};

export type InputCreateStoreGroup = {
  channelType: ChannelType;
  countryOfSale?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  name: Scalars['String'];
};

export type InputCreateStoreMarket = {
  capabilities?: InputMaybe<InputCapabilities>;
  cartCapabilities?: InputMaybe<InputCartCapabilities>;
  currencyCode: Scalars['String'];
  isTaxExemptionEligible?: InputMaybe<Scalars['Boolean']>;
  isTaxIncludedInPrice: Scalars['Boolean'];
};

export type InputCreateUser = {
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  username: Scalars['AWSEmail'];
};

export type InputDiscountValue = {
  fixedDiscounts?: InputMaybe<Array<InputMoney>>;
  percentage?: InputMaybe<Scalars['Int']>;
  type: InputDiscountValueType;
};

export enum InputDiscountValueType {
  Fixed = 'FIXED',
  Relative = 'RELATIVE'
}

export type InputExternalEventsApiKeyAuth = {
  key: Scalars['String'];
  name: Scalars['String'];
};

export type InputExternalEventsBasicAuth = {
  password: Scalars['String'];
  user: Scalars['String'];
};

export type InputGift = {
  productVariantId: Scalars['String'];
  quantity: Scalars['Int'];
};

export type InputGiftCardProductSelection = {
  giftCardProductId: Scalars['ID'];
};

export type InputIngridConfig = {
  apiKey: Scalars['String'];
  baseUrl: Scalars['String'];
  siteId: Scalars['String'];
};

export type InputInventory = {
  address?: InputMaybe<InputInventoryAddress>;
  description: Scalars['String'];
  name: Scalars['String'];
};

export type InputInventoryAddress = {
  city: Scalars['String'];
  country: Scalars['String'];
  postalCode: Scalars['String'];
  region: Scalars['String'];
  streetAddress: Scalars['String'];
};

export type InputInventoryProductVariantStock = {
  quantity: Scalars['Int'];
};

export type InputItemRule = {
  excludeDiscountedPrices: Scalars['Boolean'];
  excludeFullPrices?: InputMaybe<Scalars['Boolean']>;
  tagConditions: Array<InputTagCondition>;
};

export type InputKbsGiftCardConfig = {
  baseUrl: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type InputKlarnaCheckoutConfig = {
  baseUrl: Scalars['String'];
  klarnaShippingAssistantEnabled?: InputMaybe<Scalars['Boolean']>;
  merchantId: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type InputLogicHolder = {
  do: Array<Scalars['String']>;
  if: Scalars['String'];
};

export type InputMarketRoundingRule = {
  key: Scalars['String'];
  value?: InputMaybe<InputRoundingRule>;
};

export type InputMoney = {
  currencyCode: Scalars['String'];
  units: Scalars['Int'];
};

export type InputNShiftConfig = {
  baseUrl: Scalars['String'];
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
  deliveryCheckoutId: Scalars['String'];
};

export type InputOrderCancellation = {
  reason?: InputMaybe<InputReason>;
};

export type InputOrderCompensation = {
  bonus?: InputMaybe<InputOrderCompensationBonus>;
  giftCards?: InputMaybe<Array<InputOrderCompensationGiftCard>>;
  orderLines?: InputMaybe<Array<InputOrderLineCompensation>>;
  payment?: InputMaybe<InputOrderCompensationPayment>;
  reason?: InputMaybe<InputReason>;
  shippingFees?: InputMaybe<Array<InputOrderShippingFeeCompensation>>;
};

export type InputOrderCompensationBonus = {
  amount: Scalars['Int'];
};

export type InputOrderCompensationGiftCard = {
  amount: Scalars['Int'];
  giftCardId: Scalars['ID'];
};

export type InputOrderCompensationPayment = {
  amount: Scalars['Int'];
};

export type InputOrderDelivery = {
  giftCardProducts?: InputMaybe<Array<InputGiftCardProductSelection>>;
  gifts?: InputMaybe<Array<InputOrderGiftSelection>>;
  orderLines?: InputMaybe<Array<InputOrderLineSelection>>;
  shippingFees?: InputMaybe<Array<Scalars['String']>>;
};

export type InputOrderGiftSelection = {
  giftId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type InputOrderLineCompensation = {
  orderLineId: Scalars['ID'];
  quantity: Scalars['Int'];
  taxPercentage: Scalars['Int'];
  taxPercentageDecimals: Scalars['Int'];
  totalAmount: Scalars['Int'];
  totalDiscountAmount: Scalars['Int'];
  totalTaxAmount: Scalars['Int'];
};

export type InputOrderLineSelection = {
  orderLineId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type InputOrderRefund = {
  fee?: InputMaybe<InputOrderRefundFee>;
  gifts?: InputMaybe<Array<InputOrderGiftSelection>>;
  orderLines?: InputMaybe<Array<InputOrderLineSelection>>;
  reason?: InputMaybe<InputReason>;
  shippingFees?: InputMaybe<Array<Scalars['String']>>;
};

export type InputOrderRefundFee = {
  amount: Scalars['Int'];
  name: Scalars['String'];
  taxPercentage: Scalars['Int'];
  taxPercentageDecimals: Scalars['Int'];
};

export type InputOrderRelease = {
  reason?: InputMaybe<InputReason>;
};

export type InputOrderShippingFeeCompensation = {
  ShippingFeeId: Scalars['ID'];
  TaxPercentage: Scalars['Int'];
  TaxPercentageDecimals: Scalars['Int'];
  TotalAmount: Scalars['Int'];
  TotalDiscountAmount: Scalars['Int'];
  TotalTaxAmount: Scalars['Int'];
};

export type InputOrderStartCancellation = {
  bonus?: InputMaybe<InputActionBonus>;
  giftCard?: InputMaybe<InputActionGiftCard>;
  payment?: InputMaybe<InputActionPaymentCancellation>;
};

export type InputOrderStartCompensation = {
  bonus?: InputMaybe<InputActionBonus>;
  giftCard?: InputMaybe<InputActionGiftCard>;
  payment?: InputMaybe<InputActionPaymentCompensation>;
};

export type InputOrderStartDelivery = {
  bonus?: InputMaybe<InputActionBonus>;
  giftCard?: InputMaybe<InputActionGiftCard>;
  payment?: InputMaybe<InputActionPaymentDelivery>;
  shipping?: InputMaybe<InputActionShipping>;
};

export type InputOrderStartRefund = {
  bonus?: InputMaybe<InputActionBonus>;
  giftCard?: InputMaybe<InputActionGiftCard>;
  payment?: InputMaybe<InputActionPaymentRefund>;
};

export type InputOrderStartRelease = {
  bonus?: InputMaybe<InputActionBonus>;
  giftCard?: InputMaybe<InputActionGiftCard>;
  payment?: InputMaybe<InputActionPaymentRelease>;
};

export type InputOutcome = {
  combineItemDiscount?: InputMaybe<Scalars['Boolean']>;
  discountItemRule?: InputMaybe<InputItemRule>;
  discountQuantity?: InputMaybe<Scalars['Int']>;
  discountValue?: InputMaybe<InputDiscountValue>;
  gifts?: InputMaybe<Array<InputGift>>;
  id: Scalars['ID'];
  isRecurrent?: InputMaybe<Scalars['Boolean']>;
  subOutcomes?: InputMaybe<Array<InputSubOutcome>>;
  triggerItemRule?: InputMaybe<InputItemRule>;
  triggerQuantity?: InputMaybe<Scalars['Int']>;
  type: InputOutcomeType;
};

export enum InputOutcomeType {
  Buyget = 'BUYGET',
  Buyselect = 'BUYSELECT',
  Cartdiscount = 'CARTDISCOUNT',
  Freeshipping = 'FREESHIPPING',
  Gift = 'GIFT',
  Itemdiscounts = 'ITEMDISCOUNTS',
  Optimizebuygets = 'OPTIMIZEBUYGETS',
  Selectitems = 'SELECTITEMS'
}

export type InputPriceRule = {
  monies?: InputMaybe<Array<InputCampaignMoney>>;
  percentage?: InputMaybe<Scalars['Int']>;
  percentageDecimals?: InputMaybe<Scalars['Int']>;
  type: InputPriceRuleType;
};

export enum InputPriceRuleType {
  FixedDiscount = 'FIXED_DISCOUNT',
  RelativeDiscount = 'RELATIVE_DISCOUNT',
  SetPrice = 'SET_PRICE'
}

export type InputProductVariantRules = {
  tagLogic: Scalars['String'];
};

export type InputProductVariantStockConfig = {
  validateStock: Scalars['Boolean'];
};

export type InputProvider = {
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type InputQliroOneConfig = {
  baseUrl: Scalars['String'];
  integratedShipping?: InputMaybe<InputQliroOneIntegratedShipping>;
  merchantApiKey: Scalars['String'];
  merchantApiSecret: Scalars['String'];
  merchantOrderManagementStatusPushUrl: Scalars['String'];
  useLegacyIds?: InputMaybe<Scalars['Boolean']>;
};

export type InputQliroOneIntegratedShipping = {
  deliveryCheckoutId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type InputReason = {
  cause?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
};

export type InputRemoveUserFromGroup = {
  groupname: Scalars['String'];
  username: Scalars['AWSEmail'];
};

export type InputRetain24Config = {
  baseUrl: Scalars['String'];
  certificate: Scalars['String'];
  certificatePassword: Scalars['String'];
  redeem?: InputMaybe<Scalars['Boolean']>;
  simulationKey?: InputMaybe<Scalars['String']>;
};

export type InputRoundingRule = {
  roundDown: Scalars['Boolean'];
  roundUp: Scalars['Boolean'];
  targets: Array<Scalars['String']>;
};

export type InputRoundingRules = {
  defaultRule: InputRoundingRule;
  marketRules?: InputMaybe<Array<InputMarketRoundingRule>>;
};

export type InputStoreMarket = {
  key: Scalars['String'];
  value: Array<Scalars['String']>;
};

export type InputSubOutcome = {
  discountQuantity?: InputMaybe<Scalars['Int']>;
  discountValue?: InputMaybe<InputDiscountValue>;
  triggerQuantity?: InputMaybe<Scalars['Int']>;
};

export type InputSveaCheckoutConfig = {
  apiKey: Scalars['String'];
  checkoutBaseUrl: Scalars['String'];
  merchantId: Scalars['String'];
  paymentAdminBaseUrl: Scalars['String'];
};

export type InputTagCondition = {
  key: Scalars['String'];
  mode: InputTagConditionMode;
  values: Array<Scalars['String']>;
};

export enum InputTagConditionMode {
  Exclude = 'EXCLUDE',
  Include = 'INCLUDE'
}

export type InputTaxGroup = {
  description: Scalars['String'];
  name: Scalars['String'];
};

export type InputTaxMarket = {
  taxPercentage: Scalars['Int'];
  taxPercentageDecimals: Scalars['Int'];
};

export type InputUpdateApiClient = {
  name: Scalars['String'];
  scopes: Array<Scalars['String']>;
};

export type InputUpdateStoreGroup = {
  countryOfSale?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type InputUpdateStoreMarket = {
  capabilities?: InputMaybe<InputCapabilities>;
  cartCapabilities?: InputMaybe<InputCartCapabilities>;
  currencyCode?: InputMaybe<Scalars['String']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isTaxExemptionEligible?: InputMaybe<Scalars['Boolean']>;
  isTaxIncludedInPrice?: InputMaybe<Scalars['Boolean']>;
};

export type InputUpdateUser = {
  familyName: Scalars['String'];
  givenName: Scalars['String'];
};

export type InputValidDateRange = {
  from: Scalars['String'];
  to: Scalars['String'];
};

export type InputVoyadoConfig = {
  apiKey: Scalars['String'];
  baseUrl: Scalars['String'];
};

export type InputWalleyCheckoutConfig = {
  baseUrl: Scalars['String'];
  clientId: Scalars['String'];
  deliveryModuleEnabled: Scalars['Boolean'];
  profileName?: InputMaybe<Scalars['String']>;
  scope: Scalars['String'];
  secret: Scalars['String'];
  storeId: Scalars['String'];
};

export type Inventory = {
  __typename?: 'Inventory';
  address?: Maybe<InventoryAddress>;
  created: Scalars['AWSDateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  updated: Scalars['AWSDateTime'];
};

export type InventoryAddress = {
  __typename?: 'InventoryAddress';
  city: Scalars['String'];
  country: Scalars['String'];
  postalCode: Scalars['String'];
  region: Scalars['String'];
  streetAddress: Scalars['String'];
};

export type InventoryProductVariantStock = {
  __typename?: 'InventoryProductVariantStock';
  inventoryId: Scalars['String'];
  productVariantId: Scalars['String'];
  quantity: Scalars['Int'];
};

export type InventoryProductVariantStockConfig = {
  __typename?: 'InventoryProductVariantStockConfig';
  productVariantId: Scalars['ID'];
  validateStock?: Maybe<Scalars['Boolean']>;
};

export type InventoryStoreMarket = {
  __typename?: 'InventoryStoreMarket';
  countryCode: Scalars['String'];
  created: Scalars['AWSDateTime'];
  inventories?: Maybe<Array<Inventory>>;
  storeDescription?: Maybe<Scalars['String']>;
  storeGroupId: Scalars['String'];
  storeName?: Maybe<Scalars['String']>;
  updated: Scalars['AWSDateTime'];
};

export type InventoryStoreMarketInventories = {
  __typename?: 'InventoryStoreMarketInventories';
  countryCode: Scalars['String'];
  inventoryIds?: Maybe<Array<Scalars['String']>>;
  storeGroupId: Scalars['String'];
};

export type ItemRule = {
  __typename?: 'ItemRule';
  excludeDiscountedPrices: Scalars['Boolean'];
  excludeFullPrices?: Maybe<Scalars['Boolean']>;
  tagConditions: Array<TagCondition>;
};

export type KbsGiftCardConfig = {
  __typename?: 'KBSGiftCardConfig';
  baseUrl: Scalars['String'];
  created: Scalars['AWSDateTime'];
  credentials: KbsGiftCardCredentials;
  id: Scalars['ID'];
  updated: Scalars['AWSDateTime'];
};

export type KbsGiftCardCredentials = {
  __typename?: 'KBSGiftCardCredentials';
  password: Scalars['String'];
  username: Scalars['String'];
};

export type KeyValue = {
  __typename?: 'KeyValue';
  key: Scalars['String'];
  value?: Maybe<Array<Scalars['String']>>;
};

export type KlarnaCheckoutConfig = {
  __typename?: 'KlarnaCheckoutConfig';
  baseUrl: Scalars['String'];
  created: Scalars['AWSDateTime'];
  credentials: KlarnaCredentials;
  id: Scalars['ID'];
  klarnaShippingAssistantEnabled?: Maybe<Scalars['Boolean']>;
  merchantId: Scalars['String'];
  updated: Scalars['AWSDateTime'];
};

export type KlarnaCredentials = {
  __typename?: 'KlarnaCredentials';
  password: Scalars['String'];
  username: Scalars['String'];
};

export type KlarnaShippingAssistantAddon = {
  __typename?: 'KlarnaShippingAssistantAddon';
  externalId: Scalars['String'];
  price: Scalars['Int'];
  type: Scalars['String'];
  userInput: Scalars['String'];
};

export type KlarnaShippingAssistantAddress = {
  __typename?: 'KlarnaShippingAssistantAddress';
  city: Scalars['String'];
  country: Scalars['String'];
  postalCode: Scalars['String'];
  region: Scalars['String'];
  streetAddress: Scalars['String'];
  streetAddress2: Scalars['String'];
};

export type KlarnaShippingAssistantCarrierProduct = {
  __typename?: 'KlarnaShippingAssistantCarrierProduct';
  identifier?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type KlarnaShippingAssistantLocation = {
  __typename?: 'KlarnaShippingAssistantLocation';
  address: KlarnaShippingAssistantAddress;
  id: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Int'];
};

export type KlarnaShippingAssistantLocationTimeslot = {
  __typename?: 'KlarnaShippingAssistantLocationTimeslot';
  cutoff: Scalars['String'];
  end: Scalars['String'];
  id: Scalars['String'];
  price: Scalars['Int'];
  start: Scalars['String'];
};

export type KlarnaShippingAssistantSelectedShippingOption = {
  __typename?: 'KlarnaShippingAssistantSelectedShippingOption';
  carrier: Scalars['String'];
  carrierProduct?: Maybe<KlarnaShippingAssistantCarrierProduct>;
  location?: Maybe<KlarnaShippingAssistantLocation>;
  name: Scalars['String'];
  price: Scalars['Int'];
  selectedAddons?: Maybe<Array<KlarnaShippingAssistantAddon>>;
  taxAmount: Scalars['Int'];
  taxRate: Scalars['Int'];
  timeslot?: Maybe<KlarnaShippingAssistantLocationTimeslot>;
  tmsReference: Scalars['String'];
  type: Scalars['String'];
};

export type LogicHolder = {
  __typename?: 'LogicHolder';
  do: Array<Scalars['String']>;
  if: Scalars['String'];
};

export type MarketPrice = {
  __typename?: 'MarketPrice';
  countryCode: Scalars['String'];
  price: Price;
};

export type MarketRoundingRule = {
  __typename?: 'MarketRoundingRule';
  key: Scalars['String'];
  value?: Maybe<RoundingRule>;
};

export type Money = {
  __typename?: 'Money';
  currencyCode: Scalars['String'];
  units: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  Order: MutationOrderManagement;
  addExternalEventsDestinationAPI: AddExternalEventsDestinationApiResult;
  addExternalEventsDestinationEventBus: AddExternalEventsDestinationEventBusResult;
  addExternalEventsRule: AddExternalEventsRuleResult;
  addUserToGroup: OperationResult;
  createApiClient: ApiClientDetails;
  createGenerateCodes?: Maybe<CreateGenerateCodesResult>;
  createOrUpdateAdyenConfig: AdyenConfig;
  createOrUpdateBSGGiftCardConfig: BsgGiftCardConfig;
  createOrUpdateBonusConfig: BonusConfig;
  createOrUpdateBundleGroup?: Maybe<BundleGroup>;
  createOrUpdateCampaign: Campaign;
  createOrUpdateCampaignGroup: CampaignGroup;
  createOrUpdateDiscountCode?: Maybe<DiscountCode>;
  createOrUpdateDiscountCodeRule?: Maybe<DiscountCodeRule>;
  createOrUpdateDiscountExternalRule?: Maybe<DiscountExternalRule>;
  createOrUpdateDiscountRule?: Maybe<DiscountRule>;
  createOrUpdateIngridConfig: IngridConfig;
  createOrUpdateInventory: Inventory;
  createOrUpdateKBSGiftCardConfig: KbsGiftCardConfig;
  createOrUpdateKlarnaCheckoutConfig: KlarnaCheckoutConfig;
  createOrUpdateNShiftConfig: NShiftConfig;
  createOrUpdateQliroOneConfig: QliroOneConfig;
  createOrUpdateRetain24Config: Retain24Config;
  createOrUpdateSveaCheckoutConfig: SveaCheckoutConfig;
  createOrUpdateTaxGroup: TaxGroup;
  createOrUpdateTaxMarket: TaxMarket;
  createOrUpdateVoyadoConfig: VoyadoConfig;
  createOrUpdateWalleyCheckoutConfig: WalleyCheckoutConfig;
  createStoreGroup?: Maybe<StoreGroup>;
  createStoreMarket?: Maybe<StoreMarket>;
  createUser: User;
  deleteAdyenConfig: DeletedResult;
  deleteApiClient: OperationResult;
  deleteBSGGiftCardConfig: DeletedResult;
  deleteBonusConfig: DeletedResult;
  deleteBundleGroup: DeletedResult;
  deleteCampaign: CampaignDeletionResult;
  deleteCampaignGroup: DeletedResult;
  deleteDiscountCode: DeletedResult;
  deleteDiscountCodeRule: DeletedResult;
  deleteDiscountExternalRule: DeletedResult;
  deleteDiscountRule: DeletedResult;
  deleteExternalEventsDestinationAPI: DeletedResult;
  deleteExternalEventsDestinationEventBus: DeletedResult;
  deleteExternalEventsRule: DeletedResult;
  deleteIngridConfig: DeletedResult;
  deleteInventory: DeletedResult;
  deleteInventoryProductVariantStock: DeletedResult;
  deleteKBSGiftCardConfig: DeletedResult;
  deleteKlarnaCheckoutConfig: DeletedResult;
  deleteNShiftConfig: DeletedResult;
  deleteQliroOneConfig: DeletedResult;
  deleteRetain24Config: DeletedResult;
  deleteStoreGroup: DeletedResult;
  deleteStoreMarket: DeletedResult;
  deleteSveaCheckoutConfig: DeletedResult;
  deleteTaxGroup: DeletedResult;
  deleteTaxMarket: DeletedResult;
  deleteVoyadoConfig: DeletedResult;
  deleteWalleyCheckoutConfig: DeletedResult;
  disableUser: OperationResult;
  enableUser: OperationResult;
  /** @deprecated use generateAdyenWebhooks() instead */
  generateAdyenWebhookCredentials: AdyenWebhookCredentials;
  generateAdyenWebhooks: AdyenWebhookGenerationResult;
  privateAdyenWebhookChange: AdyenWebhook;
  removeUserFromGroup: OperationResult;
  /** @deprecated use Order.resendOrderEvent() instead */
  resendOrderEvent: OperationResult;
  resendPassword: OperationResult;
  setShippingTaxGroup: ShippingTaxGroup;
  updateApiClient: ApiClientDetails;
  updateCampaignPeriod: Campaign;
  updateInventoryProductVariantStock: InventoryProductVariantStock;
  updateInventoryStoreMarketInventories: InventoryStoreMarketInventories;
  updateProductVariantStockConfig: InventoryProductVariantStockConfig;
  updateStoreGroup?: Maybe<StoreGroup>;
  updateStoreMarket?: Maybe<StoreMarket>;
  updateUser: OperationResult;
};


export type MutationAddExternalEventsDestinationApiArgs = {
  destName: Scalars['String'];
  options: InputAddExternalEventsDestinationApi;
};


export type MutationAddExternalEventsDestinationEventBusArgs = {
  eventBusArn: Scalars['String'];
};


export type MutationAddExternalEventsRuleArgs = {
  ruleName: Scalars['String'];
};


export type MutationAddUserToGroupArgs = {
  input: InputAddUserToGroup;
};


export type MutationCreateApiClientArgs = {
  input: InputCreateApiClient;
};


export type MutationCreateGenerateCodesArgs = {
  input: InputCreateGenerateCodes;
};


export type MutationCreateOrUpdateAdyenConfigArgs = {
  adyenConfig: InputAdyenConfig;
  id: Scalars['ID'];
};


export type MutationCreateOrUpdateBsgGiftCardConfigArgs = {
  bsgGiftCardConfig: InputBsgGiftCardConfig;
  id: Scalars['ID'];
};


export type MutationCreateOrUpdateBonusConfigArgs = {
  bonusConfig: InputBonusConfig;
  id: Scalars['ID'];
};


export type MutationCreateOrUpdateBundleGroupArgs = {
  bundleGroup: InputCreateOrUpdateBundleGroup;
  id: Scalars['ID'];
};


export type MutationCreateOrUpdateCampaignArgs = {
  campaign: InputCampaign;
  id: Scalars['ID'];
};


export type MutationCreateOrUpdateCampaignGroupArgs = {
  campaignGroup: InputCampaignGroup;
  id: Scalars['ID'];
};


export type MutationCreateOrUpdateDiscountCodeArgs = {
  code: Scalars['String'];
  discountCode: InputCreateOrUpdateDiscountCode;
};


export type MutationCreateOrUpdateDiscountCodeRuleArgs = {
  discountCodeRule: InputCreateOrUpdateDiscountCodeRule;
  id: Scalars['ID'];
};


export type MutationCreateOrUpdateDiscountExternalRuleArgs = {
  discountExternalRule: InputCreateOrUpdateDiscountExternalRule;
  id: Scalars['ID'];
};


export type MutationCreateOrUpdateDiscountRuleArgs = {
  discountRule: InputCreateOrUpdateDiscountRule;
  id: Scalars['ID'];
};


export type MutationCreateOrUpdateIngridConfigArgs = {
  id: Scalars['ID'];
  ingridConfig: InputIngridConfig;
};


export type MutationCreateOrUpdateInventoryArgs = {
  id: Scalars['ID'];
  inventory: InputInventory;
};


export type MutationCreateOrUpdateKbsGiftCardConfigArgs = {
  id: Scalars['ID'];
  kbsGiftCardConfig: InputKbsGiftCardConfig;
};


export type MutationCreateOrUpdateKlarnaCheckoutConfigArgs = {
  id: Scalars['ID'];
  klarnaCheckoutConfig: InputKlarnaCheckoutConfig;
};


export type MutationCreateOrUpdateNShiftConfigArgs = {
  id: Scalars['ID'];
  nShiftConfig: InputNShiftConfig;
};


export type MutationCreateOrUpdateQliroOneConfigArgs = {
  id: Scalars['ID'];
  qliroOneConfig: InputQliroOneConfig;
};


export type MutationCreateOrUpdateRetain24ConfigArgs = {
  id: Scalars['ID'];
  retain24Config: InputRetain24Config;
};


export type MutationCreateOrUpdateSveaCheckoutConfigArgs = {
  id: Scalars['ID'];
  sveaCheckoutConfig: InputSveaCheckoutConfig;
};


export type MutationCreateOrUpdateTaxGroupArgs = {
  id: Scalars['ID'];
  taxGroup: InputTaxGroup;
};


export type MutationCreateOrUpdateTaxMarketArgs = {
  countryCode: Scalars['String'];
  taxGroupId: Scalars['String'];
  taxMarket: InputTaxMarket;
};


export type MutationCreateOrUpdateVoyadoConfigArgs = {
  id: Scalars['ID'];
  voyadoConfig: InputVoyadoConfig;
};


export type MutationCreateOrUpdateWalleyCheckoutConfigArgs = {
  id: Scalars['ID'];
  walleyCheckoutConfig: InputWalleyCheckoutConfig;
};


export type MutationCreateStoreGroupArgs = {
  id: Scalars['ID'];
  storeGroup: InputCreateStoreGroup;
};


export type MutationCreateStoreMarketArgs = {
  countryCode: Scalars['String'];
  storeGroupId: Scalars['String'];
  storeMarket: InputCreateStoreMarket;
};


export type MutationCreateUserArgs = {
  input: InputCreateUser;
};


export type MutationDeleteAdyenConfigArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteApiClientArgs = {
  clientId: Scalars['ID'];
};


export type MutationDeleteBsgGiftCardConfigArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBonusConfigArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBundleGroupArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCampaignArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCampaignGroupArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDiscountCodeArgs = {
  code: Scalars['String'];
};


export type MutationDeleteDiscountCodeRuleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDiscountExternalRuleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDiscountRuleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteExternalEventsDestinationApiArgs = {
  destName: Scalars['String'];
};


export type MutationDeleteExternalEventsDestinationEventBusArgs = {
  eventBusArn: Scalars['String'];
};


export type MutationDeleteExternalEventsRuleArgs = {
  ruleName: Scalars['String'];
};


export type MutationDeleteIngridConfigArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteInventoryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteInventoryProductVariantStockArgs = {
  inventoryId: Scalars['ID'];
  productVariantId: Scalars['ID'];
};


export type MutationDeleteKbsGiftCardConfigArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteKlarnaCheckoutConfigArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNShiftConfigArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteQliroOneConfigArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRetain24ConfigArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteStoreGroupArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteStoreMarketArgs = {
  countryCode: Scalars['String'];
  storeGroupId: Scalars['String'];
};


export type MutationDeleteSveaCheckoutConfigArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTaxGroupArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTaxMarketArgs = {
  countryCode: Scalars['String'];
  taxGroupId: Scalars['String'];
};


export type MutationDeleteVoyadoConfigArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWalleyCheckoutConfigArgs = {
  id: Scalars['ID'];
};


export type MutationDisableUserArgs = {
  username: Scalars['AWSEmail'];
};


export type MutationEnableUserArgs = {
  username: Scalars['AWSEmail'];
};


export type MutationGenerateAdyenWebhookCredentialsArgs = {
  id: Scalars['ID'];
};


export type MutationGenerateAdyenWebhooksArgs = {
  id: Scalars['ID'];
};


export type MutationPrivateAdyenWebhookChangeArgs = {
  input: InputAdyenWebhookChange;
};


export type MutationRemoveUserFromGroupArgs = {
  input: InputRemoveUserFromGroup;
};


export type MutationResendOrderEventArgs = {
  orderId: Scalars['ID'];
};


export type MutationResendPasswordArgs = {
  username: Scalars['AWSEmail'];
};


export type MutationSetShippingTaxGroupArgs = {
  taxGroupId: Scalars['String'];
};


export type MutationUpdateApiClientArgs = {
  clientId: Scalars['ID'];
  input: InputUpdateApiClient;
};


export type MutationUpdateCampaignPeriodArgs = {
  id: Scalars['ID'];
  period: InputCampaignPeriod;
};


export type MutationUpdateInventoryProductVariantStockArgs = {
  inventoryId: Scalars['ID'];
  productVariantId: Scalars['ID'];
  stock: InputInventoryProductVariantStock;
};


export type MutationUpdateInventoryStoreMarketInventoriesArgs = {
  countryCode: Scalars['String'];
  inventoryIds: Array<Scalars['String']>;
  storeGroupId: Scalars['ID'];
};


export type MutationUpdateProductVariantStockConfigArgs = {
  config: InputProductVariantStockConfig;
  productVariantId: Scalars['ID'];
};


export type MutationUpdateStoreGroupArgs = {
  id: Scalars['ID'];
  storeGroup: InputUpdateStoreGroup;
};


export type MutationUpdateStoreMarketArgs = {
  countryCode: Scalars['String'];
  storeGroupId: Scalars['String'];
  storeMarket: InputUpdateStoreMarket;
};


export type MutationUpdateUserArgs = {
  input: InputUpdateUser;
  username: Scalars['AWSEmail'];
};

export type MutationOrderManagement = {
  __typename?: 'MutationOrderManagement';
  createCancellation: OrderCancellation;
  createCompensation: OrderCompensation;
  createDelivery: OrderDelivery;
  createRefund: OrderRefund;
  createRelease: OrderRelease;
  deleteCancellation: OrderOperationResult;
  deleteCompensation: OrderOperationResult;
  deleteDelivery: OrderOperationResult;
  deleteRefund: OrderOperationResult;
  deleteRelease: OrderOperationResult;
  resendOrderEvent: OperationResult;
  restartCancellation: OrderOperationResult;
  restartCompensation: OrderOperationResult;
  restartDelivery: OrderOperationResult;
  restartRefund: OrderOperationResult;
  restartRelease: OrderOperationResult;
  startCancellation: OrderOperationResult;
  startCompensation: OrderOperationResult;
  startDelivery: OrderOperationResult;
  startRefund: OrderOperationResult;
  startRelease: OrderOperationResult;
  updateCompensation: OrderCompensation;
  updateDelivery: OrderDelivery;
  updateRefund: OrderRefund;
};


export type MutationOrderManagementCreateCancellationArgs = {
  input: InputOrderCancellation;
  orderId: Scalars['ID'];
};


export type MutationOrderManagementCreateCompensationArgs = {
  input: InputOrderCompensation;
  orderId: Scalars['ID'];
};


export type MutationOrderManagementCreateDeliveryArgs = {
  input: InputOrderDelivery;
  orderId: Scalars['ID'];
};


export type MutationOrderManagementCreateRefundArgs = {
  input: InputOrderRefund;
  orderId: Scalars['ID'];
};


export type MutationOrderManagementCreateReleaseArgs = {
  input: InputOrderRelease;
  orderId: Scalars['ID'];
};


export type MutationOrderManagementDeleteCancellationArgs = {
  cancellationId: Scalars['ID'];
};


export type MutationOrderManagementDeleteCompensationArgs = {
  compensationId: Scalars['ID'];
};


export type MutationOrderManagementDeleteDeliveryArgs = {
  deliveryId: Scalars['ID'];
};


export type MutationOrderManagementDeleteRefundArgs = {
  refundId: Scalars['ID'];
};


export type MutationOrderManagementDeleteReleaseArgs = {
  releaseId: Scalars['ID'];
};


export type MutationOrderManagementResendOrderEventArgs = {
  orderId: Scalars['ID'];
};


export type MutationOrderManagementRestartCancellationArgs = {
  cancellationId: Scalars['ID'];
  input: InputOrderStartCancellation;
};


export type MutationOrderManagementRestartCompensationArgs = {
  compensationId: Scalars['ID'];
  input: InputOrderStartCompensation;
};


export type MutationOrderManagementRestartDeliveryArgs = {
  deliveryId: Scalars['ID'];
  input: InputOrderStartDelivery;
};


export type MutationOrderManagementRestartRefundArgs = {
  input: InputOrderStartRefund;
  refundId: Scalars['ID'];
};


export type MutationOrderManagementRestartReleaseArgs = {
  input: InputOrderStartRelease;
  releaseId: Scalars['ID'];
};


export type MutationOrderManagementStartCancellationArgs = {
  cancellationId: Scalars['ID'];
  input: InputOrderStartCancellation;
};


export type MutationOrderManagementStartCompensationArgs = {
  compensationId: Scalars['ID'];
  input: InputOrderStartCompensation;
};


export type MutationOrderManagementStartDeliveryArgs = {
  deliveryId: Scalars['ID'];
  input: InputOrderStartDelivery;
};


export type MutationOrderManagementStartRefundArgs = {
  input: InputOrderStartRefund;
  refundId: Scalars['ID'];
};


export type MutationOrderManagementStartReleaseArgs = {
  input: InputOrderStartRelease;
  releaseId: Scalars['ID'];
};


export type MutationOrderManagementUpdateCompensationArgs = {
  compensationId: Scalars['ID'];
  input: InputOrderCompensation;
};


export type MutationOrderManagementUpdateDeliveryArgs = {
  deliveryId: Scalars['ID'];
  input: InputOrderDelivery;
};


export type MutationOrderManagementUpdateRefundArgs = {
  input: InputOrderRefund;
  refundId: Scalars['ID'];
};

export type NShiftConfig = {
  __typename?: 'NShiftConfig';
  baseUrl: Scalars['String'];
  created: Scalars['AWSDateTime'];
  credentials: NShiftCredentials;
  deliveryCheckoutId: Scalars['String'];
  id: Scalars['ID'];
  updated: Scalars['AWSDateTime'];
};

export type NShiftCredentials = {
  __typename?: 'NShiftCredentials';
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
};

export type NshiftAddress = {
  __typename?: 'NshiftAddress';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  quickId?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type NshiftPreparedShipment = {
  __typename?: 'NshiftPreparedShipment';
  agent?: Maybe<NshiftAddress>;
  orderNo: Scalars['String'];
  prepareId: Scalars['String'];
  sender: NshiftAddress;
  service: NshiftService;
};

export type NshiftService = {
  __typename?: 'NshiftService';
  id: Scalars['String'];
  name: Scalars['String'];
  sourceSystem: Scalars['String'];
  title: Scalars['String'];
};

export type OperationResult = {
  __typename?: 'OperationResult';
  status: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  Bonus?: Maybe<OrderBonus>;
  Bundles?: Maybe<Array<OrderBundle>>;
  Cancellations: Array<OrderCancellation>;
  Compensations: Array<OrderCompensation>;
  Deliveries: Array<OrderDelivery>;
  GiftCard: OrderGiftCardBsgGiftCard | OrderGiftCardEmpty | OrderGiftCardKbsGiftCard | OrderGiftCardRetain24;
  GiftCardProduct: OrderGiftCardProductEmpty | OrderGiftCardProductRetain24;
  Payment: OrderPaymentAdyen | OrderPaymentBrinkZeroPayment | OrderPaymentKlarnaCheckout | OrderPaymentQliroOne | OrderPaymentSveaCheckout | OrderPaymentWalleyCheckout;
  Promotion: OrderPromotionEmpty | OrderPromotionVoyado;
  Refunds: Array<OrderRefund>;
  Releases: Array<OrderRelease>;
  Reservation?: Maybe<OrderReservation>;
  Shipping: OrderShippingEmpty | OrderShippingIngrid | OrderShippingKlarnaShippingAssistant | OrderShippingNshift | OrderShippingQliroIntegratedShipping | OrderShippingWalleyDeliveryModule;
  Voucher: OrderVoucherEmpty | OrderVoucherVoyado;
  attributes?: Maybe<OrderAttributes>;
  billingAddress: OrderAddress;
  bonusProvider?: Maybe<BonusProvider>;
  channelType: ChannelType;
  countryCode: Scalars['String'];
  currencyCode: Scalars['String'];
  date: Scalars['AWSDateTime'];
  discountCodeRules?: Maybe<Array<OrderDiscountCodeRule>>;
  discountExternalRules?: Maybe<Array<OrderDiscountExternalRule>>;
  discountOutcome: OrderDiscountOutcome;
  discountRules?: Maybe<Array<OrderDiscountRule>>;
  giftCardProvider?: Maybe<GiftCardProvider>;
  gifts?: Maybe<Array<OrderGift>>;
  id: Scalars['ID'];
  isTaxExempt: Scalars['Boolean'];
  isTaxExemptionEligible: Scalars['Boolean'];
  isTaxIncludedInPrice: Scalars['Boolean'];
  languageCode: Scalars['String'];
  orderLines?: Maybe<Array<OrderLine>>;
  paymentProvider: PaymentProvider;
  promotionProvider?: Maybe<PromotionProvider>;
  reference: Scalars['String'];
  shippingAddress: OrderAddress;
  shippingFees?: Maybe<Array<ShippingFee>>;
  shippingProvider?: Maybe<ShippingProvider>;
  storeGroupId: Scalars['String'];
  taxationCountry: Scalars['String'];
  totals: OrderTotals;
  voucherProvider?: Maybe<VoucherProvider>;
};

export type OrderAddress = {
  __typename?: 'OrderAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  pcc?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  stateOrProvince?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  streetAddress2?: Maybe<Scalars['String']>;
  telephoneNumber?: Maybe<Scalars['String']>;
};

export type OrderAttributes = {
  __typename?: 'OrderAttributes';
  company?: Maybe<CompanyAttribute>;
  merchantReference1?: Maybe<Scalars['String']>;
  merchantReference2?: Maybe<Scalars['String']>;
};

export type OrderBonus = {
  __typename?: 'OrderBonus';
  amount: Scalars['Int'];
  currencyCode: Scalars['String'];
  customerId: Scalars['String'];
  failedReason?: Maybe<Scalars['String']>;
  orderId: Scalars['String'];
  points?: Maybe<Scalars['Int']>;
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  reservationId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type OrderBundle = {
  __typename?: 'OrderBundle';
  bundleGroupId: Scalars['String'];
  currencyCode?: Maybe<Scalars['String']>;
  discountAmount?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
};

export type OrderCancellation = {
  __typename?: 'OrderCancellation';
  bonus?: Maybe<CancellationBonus>;
  bonusProvider?: Maybe<BonusProvider>;
  cancelledPayment?: Maybe<CancelledPayment>;
  completed?: Maybe<Scalars['AWSDateTime']>;
  created: Scalars['AWSDateTime'];
  failed?: Maybe<Scalars['AWSDateTime']>;
  giftCardProductProvider?: Maybe<GiftCardProductProvider>;
  giftCardProducts: Array<CancellationGiftCardProduct>;
  giftCardProvider?: Maybe<GiftCardProvider>;
  giftCards: Array<CancellationGiftCard>;
  id: Scalars['ID'];
  orderId: Scalars['String'];
  orderReference: Scalars['String'];
  paymentProvider: PaymentProvider;
  reason?: Maybe<Reason>;
  restarted?: Maybe<Scalars['AWSDateTime']>;
  restarts?: Maybe<Scalars['Int']>;
  revision: Scalars['Int'];
  shippingProvider?: Maybe<ShippingProvider>;
  started?: Maybe<Scalars['AWSDateTime']>;
  updated: Scalars['AWSDateTime'];
  voucherProvider?: Maybe<VoucherProvider>;
};

export type OrderCompensation = {
  __typename?: 'OrderCompensation';
  bonus?: Maybe<CompensationBonus>;
  bonusProvider?: Maybe<BonusProvider>;
  completed?: Maybe<Scalars['AWSDateTime']>;
  created: Scalars['AWSDateTime'];
  failed?: Maybe<Scalars['AWSDateTime']>;
  giftCardProductProvider?: Maybe<GiftCardProductProvider>;
  giftCardProvider?: Maybe<GiftCardProvider>;
  giftCards?: Maybe<Array<CompensationGiftCard>>;
  id: Scalars['ID'];
  orderId: Scalars['String'];
  orderLines?: Maybe<Array<CompensationOrderLine>>;
  orderReference: Scalars['String'];
  payment?: Maybe<CompensationPayment>;
  paymentProvider?: Maybe<PaymentProvider>;
  promotionProvider?: Maybe<PromotionProvider>;
  reason?: Maybe<Reason>;
  restarted?: Maybe<Scalars['AWSDateTime']>;
  restarts?: Maybe<Scalars['Int']>;
  revision: Scalars['Int'];
  shippingFees?: Maybe<Array<CompensationShippingFee>>;
  shippingProvider?: Maybe<ShippingProvider>;
  started?: Maybe<Scalars['AWSDateTime']>;
  totals: OrderCompensationTotals;
  updated: Scalars['AWSDateTime'];
  voucherProvider?: Maybe<VoucherProvider>;
};

export type OrderCompensationTotals = {
  __typename?: 'OrderCompensationTotals';
  bonusTotal: Scalars['Int'];
  giftCardTotal: Scalars['Int'];
  grandTotal: Scalars['Int'];
  paymentTotal: Scalars['Int'];
};

export type OrderDelivery = {
  __typename?: 'OrderDelivery';
  bonus?: Maybe<DeliveryBonus>;
  bonusProvider?: Maybe<BonusProvider>;
  capturedPayment?: Maybe<DeliveryCapture>;
  completed?: Maybe<Scalars['AWSDateTime']>;
  created: Scalars['AWSDateTime'];
  failed?: Maybe<Scalars['AWSDateTime']>;
  giftCardProductProvider?: Maybe<GiftCardProductProvider>;
  giftCardProducts: Array<DeliveryGiftCardProduct>;
  giftCardProvider?: Maybe<GiftCardProvider>;
  giftCards: Array<DeliveryGiftCard>;
  gifts: Array<DeliveryGift>;
  id: Scalars['ID'];
  orderId: Scalars['String'];
  orderLines: Array<DeliveryOrderLine>;
  orderReference: Scalars['String'];
  paymentProvider: PaymentProvider;
  promotionProvider?: Maybe<PromotionProvider>;
  restarted?: Maybe<Scalars['AWSDateTime']>;
  restarts?: Maybe<Scalars['Int']>;
  revision: Scalars['Int'];
  shippingFees: Array<DeliveryShippingFee>;
  shippingProvider?: Maybe<ShippingProvider>;
  started?: Maybe<Scalars['AWSDateTime']>;
  totals: OrderDeliveryTotals;
  tracking?: Maybe<DeliveryTracking>;
  updated: Scalars['AWSDateTime'];
  voucherProvider?: Maybe<VoucherProvider>;
  vouchers: Array<DeliveryVoucher>;
};

export type OrderDeliveryTotals = {
  __typename?: 'OrderDeliveryTotals';
  bonusTotal: Scalars['Int'];
  giftCardTotal: Scalars['Int'];
  grandTotal: Scalars['Int'];
  shippingTotal: Scalars['Int'];
  subTotal: Scalars['Int'];
  voucherTotal: Scalars['Int'];
};

export type OrderDiscountCodeRule = {
  __typename?: 'OrderDiscountCodeRule';
  code: Scalars['String'];
  currencyCode?: Maybe<Scalars['String']>;
  discountAmount?: Maybe<Scalars['Int']>;
  freeShipping?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type OrderDiscountExternalRule = {
  __typename?: 'OrderDiscountExternalRule';
  currencyCode?: Maybe<Scalars['String']>;
  discountAmount?: Maybe<Scalars['Int']>;
  freeShipping?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  name: Scalars['String'];
  reference: Scalars['String'];
};

export type OrderDiscountOutcome = {
  __typename?: 'OrderDiscountOutcome';
  cartRules?: Maybe<Array<CartRuleOutcome>>;
  codeRules?: Maybe<Array<CodeRuleOutcome>>;
  discountReduction?: Maybe<Scalars['Int']>;
  externalRules?: Maybe<Array<ExternalRuleOutcome>>;
  freeShipping?: Maybe<Scalars['Boolean']>;
  totalDiscountAmount?: Maybe<Scalars['Int']>;
};

export type OrderDiscountRule = {
  __typename?: 'OrderDiscountRule';
  currencyCode?: Maybe<Scalars['String']>;
  discountAmount?: Maybe<Scalars['Int']>;
  freeShipping?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type OrderGift = {
  __typename?: 'OrderGift';
  basePriceAmount: Scalars['Int'];
  currencyCode: Scalars['String'];
  description: Scalars['String'];
  discountAmount: Scalars['Int'];
  discountRuleId: Scalars['String'];
  displayDescription: Scalars['String'];
  displayName: Scalars['String'];
  id: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  productParentId: Scalars['String'];
  productVariantId: Scalars['String'];
  quantity: Scalars['Int'];
  salePriceAmount: Scalars['Int'];
  taxGroupId: Scalars['String'];
  taxPercentage: Scalars['Int'];
  taxPercentageDecimals: Scalars['Int'];
  totalDiscountAmount: Scalars['Int'];
  totalPriceAmount: Scalars['Int'];
  totalTaxAmount: Scalars['Int'];
};

export type OrderGiftCard = {
  orderId: Scalars['String'];
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  status: Scalars['String'];
};

export type OrderGiftCardBsgGiftCard = OrderGiftCard & {
  __typename?: 'OrderGiftCardBSGGiftCard';
  giftCards?: Maybe<Array<OrderGiftCardItemBsgGiftCard>>;
  orderId: Scalars['String'];
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  status: Scalars['String'];
};

export type OrderGiftCardEmpty = OrderGiftCard & {
  __typename?: 'OrderGiftCardEmpty';
  orderId: Scalars['String'];
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  status: Scalars['String'];
};

export type OrderGiftCardItemBsgGiftCard = {
  __typename?: 'OrderGiftCardItemBSGGiftCard';
  amount: Scalars['Int'];
  currencyCode: Scalars['String'];
  failedReason?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  reservationId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type OrderGiftCardItemKbsGiftCard = {
  __typename?: 'OrderGiftCardItemKBSGiftCard';
  amount: Scalars['Int'];
  currencyCode: Scalars['String'];
  failedReason?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  reservationId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type OrderGiftCardItemRetain24 = {
  __typename?: 'OrderGiftCardItemRetain24';
  amount: Scalars['Int'];
  currencyCode: Scalars['String'];
  failedReason?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  redeemId?: Maybe<Scalars['String']>;
  reservationId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type OrderGiftCardKbsGiftCard = OrderGiftCard & {
  __typename?: 'OrderGiftCardKBSGiftCard';
  giftCards?: Maybe<Array<OrderGiftCardItemKbsGiftCard>>;
  orderId: Scalars['String'];
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  status: Scalars['String'];
};

export type OrderGiftCardProduct = {
  orderId: Scalars['String'];
  providerId: Scalars['String'];
  providerName: Scalars['String'];
};

export type OrderGiftCardProductEmpty = OrderGiftCardProduct & {
  __typename?: 'OrderGiftCardProductEmpty';
  orderId: Scalars['String'];
  providerId: Scalars['String'];
  providerName: Scalars['String'];
};

export type OrderGiftCardProductItem = {
  amount: Scalars['Int'];
  currencyCode: Scalars['String'];
  distribution: Scalars['String'];
  id: Scalars['String'];
  merchantReference?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  recipient?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type OrderGiftCardProductItemRetain24 = OrderGiftCardProductItem & {
  __typename?: 'OrderGiftCardProductItemRetain24';
  amount: Scalars['Int'];
  currencyCode: Scalars['String'];
  distribution: Scalars['String'];
  emailText?: Maybe<Scalars['String']>;
  failedReason?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  merchantReference?: Maybe<Scalars['String']>;
  multiCode?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  recipient?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  sendDate?: Maybe<Scalars['AWSDateTime']>;
  smsText?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  templateId: Scalars['Int'];
  transactionId?: Maybe<Scalars['Int']>;
};

export type OrderGiftCardProductRetain24 = OrderGiftCardProduct & {
  __typename?: 'OrderGiftCardProductRetain24';
  giftCardProducts?: Maybe<Array<OrderGiftCardProductItemRetain24>>;
  orderId: Scalars['String'];
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  status: Scalars['String'];
};

export type OrderGiftCardRetain24 = OrderGiftCard & {
  __typename?: 'OrderGiftCardRetain24';
  giftCards?: Maybe<Array<OrderGiftCardItemRetain24>>;
  orderId: Scalars['String'];
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  status: Scalars['String'];
};

export type OrderLine = {
  __typename?: 'OrderLine';
  basePriceAmount: Scalars['Int'];
  currencyCode: Scalars['String'];
  description: Scalars['String'];
  discountAmount: Scalars['Int'];
  discountOutcome: OrderLineDiscountOutcome;
  displayDescription: Scalars['String'];
  displayName: Scalars['String'];
  distributedTotalDiscountAmount: Scalars['Int'];
  distributedTotalPriceAmount: Scalars['Int'];
  distributedTotalTaxAmount: Scalars['Int'];
  id: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  productParentId: Scalars['String'];
  productVariantId: Scalars['String'];
  quantity: Scalars['Int'];
  salePriceAmount: Scalars['Int'];
  taxGroupId: Scalars['String'];
  taxPercentage: Scalars['Int'];
  taxPercentageDecimals: Scalars['Int'];
  totalDiscountAmount: Scalars['Int'];
  totalPriceAmount: Scalars['Int'];
  totalTaxAmount: Scalars['Int'];
};

export type OrderLineDiscountOutcome = {
  __typename?: 'OrderLineDiscountOutcome';
  bundles?: Maybe<Array<BundleOutcome>>;
  cartRules?: Maybe<Array<CartRuleOutcome>>;
  codeRules?: Maybe<Array<CodeRuleOutcome>>;
  externalRules?: Maybe<Array<ExternalRuleOutcome>>;
  totalDiscountAmount?: Maybe<Scalars['Int']>;
};

export type OrderOperationResult = {
  __typename?: 'OrderOperationResult';
  status: Scalars['String'];
};

export type OrderPayment = {
  method?: Maybe<Scalars['String']>;
  orderId: Scalars['String'];
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  reference: Scalars['String'];
};

export type OrderPaymentAdyen = OrderPayment & {
  __typename?: 'OrderPaymentAdyen';
  deepLinkPath?: Maybe<Scalars['String']>;
  failedReason?: Maybe<Scalars['String']>;
  merchantAccountCode: Scalars['String'];
  method: Scalars['String'];
  orderId: Scalars['String'];
  potentialFraud?: Maybe<Scalars['Boolean']>;
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  reference: Scalars['String'];
  status: Scalars['String'];
};

export type OrderPaymentBrinkZeroPayment = OrderPayment & {
  __typename?: 'OrderPaymentBrinkZeroPayment';
  method: Scalars['String'];
  orderId: Scalars['String'];
  originalProvider: OrderPaymentBrinkZeroPaymentOriginalProvider;
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  reference: Scalars['String'];
};

export type OrderPaymentBrinkZeroPaymentOriginalProvider = {
  __typename?: 'OrderPaymentBrinkZeroPaymentOriginalProvider';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type OrderPaymentKlarnaCheckout = OrderPayment & {
  __typename?: 'OrderPaymentKlarnaCheckout';
  deepLinkPath?: Maybe<Scalars['String']>;
  fraudStatus?: Maybe<Scalars['String']>;
  klarnaMerchantId: Scalars['String'];
  klarnaOrderId: Scalars['String'];
  method: Scalars['String'];
  orderId: Scalars['String'];
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  reference: Scalars['String'];
  status: Scalars['String'];
};

export type OrderPaymentQliroOne = OrderPayment & {
  __typename?: 'OrderPaymentQliroOne';
  identityVerified: Scalars['Boolean'];
  merchantProvidedQuestionAnswer?: Maybe<Scalars['Boolean']>;
  method: Scalars['String'];
  orderId: Scalars['String'];
  orderItems?: Maybe<Array<OrderPaymentQliroOneOrderItem>>;
  paymentTypeCode?: Maybe<Scalars['String']>;
  privateVerificationCode: Scalars['String'];
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  qliroOrderId: Scalars['Int'];
  reference: Scalars['String'];
  requireIdentityVerification: Scalars['Boolean'];
  signupForNewsletter?: Maybe<Scalars['Boolean']>;
  status: Scalars['String'];
};

export type OrderPaymentQliroOneOrderItem = {
  __typename?: 'OrderPaymentQliroOneOrderItem';
  merchantReference: Scalars['String'];
  paymentTransactionId: Scalars['Int'];
};

export type OrderPaymentSveaCheckout = OrderPayment & {
  __typename?: 'OrderPaymentSveaCheckout';
  expirationDate?: Maybe<Scalars['String']>;
  isCompany?: Maybe<Scalars['Boolean']>;
  method: Scalars['String'];
  orderId: Scalars['String'];
  peppolId?: Maybe<Scalars['String']>;
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  reference: Scalars['String'];
  status: Scalars['String'];
  sveaMerchantId: Scalars['String'];
  sveaOrderId: Scalars['Int'];
};

export type OrderPaymentWalleyCheckout = OrderPayment & {
  __typename?: 'OrderPaymentWalleyCheckout';
  amountToPay: Scalars['Float'];
  method: Scalars['String'];
  orderId: Scalars['String'];
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  purchaseIdentifier: Scalars['String'];
  purchaseResult: Scalars['String'];
  reference: Scalars['String'];
  status: Scalars['String'];
  totalAmount: Scalars['Float'];
};

export type OrderPromotion = {
  orderId: Scalars['String'];
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  status: Scalars['String'];
};

export type OrderPromotionEmpty = OrderPromotion & {
  __typename?: 'OrderPromotionEmpty';
  orderId: Scalars['String'];
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  status: Scalars['String'];
};

export type OrderPromotionItemVoyado = {
  __typename?: 'OrderPromotionItemVoyado';
  failedReason?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  status: Scalars['String'];
  value: Scalars['String'];
};

export type OrderPromotionVoyado = OrderPromotion & {
  __typename?: 'OrderPromotionVoyado';
  contactId: Scalars['String'];
  orderId: Scalars['String'];
  promotions?: Maybe<Array<OrderPromotionItemVoyado>>;
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  status: Scalars['String'];
};

export type OrderRefund = {
  __typename?: 'OrderRefund';
  bonus?: Maybe<RefundBonus>;
  bonusProvider?: Maybe<BonusProvider>;
  completed?: Maybe<Scalars['AWSDateTime']>;
  created: Scalars['AWSDateTime'];
  failed?: Maybe<Scalars['AWSDateTime']>;
  fee?: Maybe<RefundFee>;
  giftCardProductProvider?: Maybe<GiftCardProductProvider>;
  giftCardProvider?: Maybe<GiftCardProvider>;
  giftCards: Array<RefundGiftCard>;
  gifts: Array<RefundGift>;
  id: Scalars['ID'];
  orderId: Scalars['String'];
  orderLines: Array<RefundOrderLine>;
  orderReference: Scalars['String'];
  paymentProvider: PaymentProvider;
  promotionProvider?: Maybe<PromotionProvider>;
  reason?: Maybe<Reason>;
  refundedPayment?: Maybe<RefundedPayment>;
  restarted?: Maybe<Scalars['AWSDateTime']>;
  restarts?: Maybe<Scalars['Int']>;
  revision: Scalars['Int'];
  shippingFees: Array<RefundShippingFee>;
  shippingProvider?: Maybe<ShippingProvider>;
  started?: Maybe<Scalars['AWSDateTime']>;
  totals: OrderRefundTotals;
  updated: Scalars['AWSDateTime'];
  voucherProvider?: Maybe<VoucherProvider>;
  vouchers: Array<RefundVoucher>;
};

export type OrderRefundTotals = {
  __typename?: 'OrderRefundTotals';
  bonusTotal: Scalars['Int'];
  giftCardTotal: Scalars['Int'];
  grandTotal: Scalars['Int'];
  shippingTotal: Scalars['Int'];
  subTotal: Scalars['Int'];
  voucherTotal: Scalars['Int'];
};

export type OrderRelease = {
  __typename?: 'OrderRelease';
  bonus?: Maybe<ReleaseBonus>;
  bonusProvider?: Maybe<BonusProvider>;
  completed?: Maybe<Scalars['AWSDateTime']>;
  created: Scalars['AWSDateTime'];
  failed?: Maybe<Scalars['AWSDateTime']>;
  giftCardProductProvider?: Maybe<GiftCardProductProvider>;
  giftCardProducts: Array<ReleaseGiftCardProduct>;
  giftCardProvider?: Maybe<GiftCardProvider>;
  giftCards: Array<ReleaseGiftCard>;
  gifts: Array<ReleaseGift>;
  id: Scalars['ID'];
  orderId: Scalars['String'];
  orderLines: Array<ReleaseOrderLine>;
  orderReference: Scalars['String'];
  paymentProvider: PaymentProvider;
  promotionProvider?: Maybe<PromotionProvider>;
  reason?: Maybe<Reason>;
  releasedPayment?: Maybe<ReleasedPayment>;
  restarted?: Maybe<Scalars['AWSDateTime']>;
  restarts?: Maybe<Scalars['Int']>;
  revision: Scalars['Int'];
  shippingFees: Array<ReleaseShippingFee>;
  shippingProvider?: Maybe<ShippingProvider>;
  started?: Maybe<Scalars['AWSDateTime']>;
  updated: Scalars['AWSDateTime'];
  voucherProvider?: Maybe<VoucherProvider>;
};

export type OrderReservation = {
  __typename?: 'OrderReservation';
  created: Scalars['AWSDateTime'];
  orderId: Scalars['String'];
  processedProductVariants: Array<ProductVariantReservation>;
  sessionId: Scalars['String'];
  updated: Scalars['AWSDateTime'];
};

export type OrderSearchHit = {
  __typename?: 'OrderSearchHit';
  billingAddress: OrderAddress;
  channelType?: Maybe<ChannelType>;
  countryCode: Scalars['String'];
  currencyCode: Scalars['String'];
  date: Scalars['AWSDateTime'];
  discountCodes?: Maybe<Array<Scalars['String']>>;
  discountExternalReferences?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  isTaxExempt?: Maybe<Scalars['Boolean']>;
  isTaxExemptionEligible?: Maybe<Scalars['Boolean']>;
  merchantReference1?: Maybe<Scalars['String']>;
  merchantReference2?: Maybe<Scalars['String']>;
  paymentMethod: Scalars['String'];
  paymentProviderName: Scalars['String'];
  paymentReference: Scalars['String'];
  reference: Scalars['String'];
  shippingAddress: OrderAddress;
  storeGroupId: Scalars['String'];
  taxationCountry?: Maybe<Scalars['String']>;
  totals: OrderTotals;
};

export type OrderSearchHits = {
  __typename?: 'OrderSearchHits';
  hits: Array<OrderSearchHit>;
  total: Scalars['Int'];
};

export type OrderShipping = {
  orderId: Scalars['String'];
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  reference: Scalars['String'];
};

export type OrderShippingEmpty = OrderShipping & {
  __typename?: 'OrderShippingEmpty';
  orderId: Scalars['String'];
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  reference: Scalars['String'];
};

export type OrderShippingIngrid = OrderShipping & {
  __typename?: 'OrderShippingIngrid';
  deliveries?: Maybe<Array<IngridDeliveries>>;
  orderId: Scalars['String'];
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  reference: Scalars['String'];
};

export type OrderShippingKlarnaShippingAssistant = OrderShipping & {
  __typename?: 'OrderShippingKlarnaShippingAssistant';
  klarnaMerchantId: Scalars['String'];
  klarnaOrderId: Scalars['String'];
  orderId: Scalars['String'];
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  reference: Scalars['String'];
  selectedShippingOption: KlarnaShippingAssistantSelectedShippingOption;
};

export type OrderShippingNshift = OrderShipping & {
  __typename?: 'OrderShippingNshift';
  orderId: Scalars['String'];
  preparedShipment?: Maybe<NshiftPreparedShipment>;
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  reference: Scalars['String'];
};

export type OrderShippingQliroIntegratedShipping = OrderShipping & {
  __typename?: 'OrderShippingQliroIntegratedShipping';
  orderId: Scalars['String'];
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  reference: Scalars['String'];
  shipments: Array<QliroShipmentOrderItem>;
};

export type OrderShippingWalleyDeliveryModule = OrderShipping & {
  __typename?: 'OrderShippingWalleyDeliveryModule';
  orderId: Scalars['String'];
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  purchaseIdentifier: Scalars['String'];
  reference: Scalars['String'];
  shipping: WalleyDeliveryModuleShipping;
};

export enum OrderState {
  Cancelled = 'CANCELLED',
  Compensated = 'COMPENSATED',
  Delivered = 'DELIVERED',
  PartCompensated = 'PART_COMPENSATED',
  PartDelivered = 'PART_DELIVERED',
  PartRefunded = 'PART_REFUNDED',
  Placed = 'PLACED',
  Refunded = 'REFUNDED',
  Released = 'RELEASED'
}

export type OrderStatus = {
  __typename?: 'OrderStatus';
  history: Array<OrderStatusLogEntry>;
  operations?: Maybe<OrderStatusOperations>;
  states: Array<OrderState>;
};

export type OrderStatusLogEntry = {
  __typename?: 'OrderStatusLogEntry';
  errorMessage?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  operation: Scalars['String'];
  operationId: Scalars['ID'];
  status: Scalars['String'];
  timestamp: Scalars['AWSDateTime'];
  type: Scalars['String'];
};

export type OrderStatusOperations = {
  __typename?: 'OrderStatusOperations';
  compensated: OrderStatusTransactionsCompensated;
  delivered: OrderStatusTransactions;
  refunded: OrderStatusTransactions;
  released: OrderStatusTransactions;
};

export type OrderStatusTransactionBonus = {
  __typename?: 'OrderStatusTransactionBonus';
  amount: Scalars['Int'];
};

export type OrderStatusTransactionGift = {
  __typename?: 'OrderStatusTransactionGift';
  giftId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type OrderStatusTransactionGiftCard = {
  __typename?: 'OrderStatusTransactionGiftCard';
  amount: Scalars['Int'];
  giftCardId: Scalars['String'];
};

export type OrderStatusTransactionGiftCardProduct = {
  __typename?: 'OrderStatusTransactionGiftCardProduct';
  giftCardProductId: Scalars['String'];
};

export type OrderStatusTransactionOrderLine = {
  __typename?: 'OrderStatusTransactionOrderLine';
  orderLineId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type OrderStatusTransactionPayment = {
  __typename?: 'OrderStatusTransactionPayment';
  amount: Scalars['Int'];
};

export type OrderStatusTransactionShippingFee = {
  __typename?: 'OrderStatusTransactionShippingFee';
  shippingFeeId: Scalars['String'];
};

export type OrderStatusTransactionVoucher = {
  __typename?: 'OrderStatusTransactionVoucher';
  amount: Scalars['Int'];
  voucherId: Scalars['String'];
};

export type OrderStatusTransactions = {
  __typename?: 'OrderStatusTransactions';
  bonus?: Maybe<OrderStatusTransactionBonus>;
  giftCardProducts: Array<OrderStatusTransactionGiftCardProduct>;
  giftCards: Array<OrderStatusTransactionGiftCard>;
  gifts: Array<OrderStatusTransactionGift>;
  orderLines: Array<OrderStatusTransactionOrderLine>;
  shippingFees: Array<OrderStatusTransactionShippingFee>;
  vouchers: Array<OrderStatusTransactionVoucher>;
};

export type OrderStatusTransactionsCompensated = {
  __typename?: 'OrderStatusTransactionsCompensated';
  bonus?: Maybe<OrderStatusTransactionBonus>;
  giftCards: Array<OrderStatusTransactionGiftCard>;
  payment?: Maybe<OrderStatusTransactionPayment>;
};

export type OrderTotals = {
  __typename?: 'OrderTotals';
  bonusTotal: Scalars['Int'];
  discountTotal: Scalars['Int'];
  giftCardTotal: Scalars['Int'];
  grandTotal: Scalars['Int'];
  shippingTotal: Scalars['Int'];
  subTotal: Scalars['Int'];
  taxTotal: Scalars['Int'];
  voucherTotal: Scalars['Int'];
};

export type OrderVoucher = {
  orderId: Scalars['String'];
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  status: Scalars['String'];
};

export type OrderVoucherEmpty = OrderVoucher & {
  __typename?: 'OrderVoucherEmpty';
  orderId: Scalars['String'];
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  status: Scalars['String'];
};

export type OrderVoucherItemVoyado = {
  __typename?: 'OrderVoucherItemVoyado';
  amount: Scalars['Int'];
  checkNumber: Scalars['String'];
  currencyCode: Scalars['String'];
  failedReason?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  status: Scalars['String'];
};

export type OrderVoucherVoyado = OrderVoucher & {
  __typename?: 'OrderVoucherVoyado';
  contactId: Scalars['String'];
  orderId: Scalars['String'];
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  status: Scalars['String'];
  vouchers?: Maybe<Array<OrderVoucherItemVoyado>>;
};

export type Outcome = {
  __typename?: 'Outcome';
  combineItemDiscount?: Maybe<Scalars['Boolean']>;
  discountItemRule?: Maybe<ItemRule>;
  discountQuantity?: Maybe<Scalars['Int']>;
  discountValue?: Maybe<DiscountValue>;
  gifts?: Maybe<Array<Gift>>;
  id: Scalars['ID'];
  isRecurrent?: Maybe<Scalars['Boolean']>;
  subOutcomes?: Maybe<Array<SubOutcome>>;
  triggerItemRule?: Maybe<ItemRule>;
  triggerQuantity?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
};

export type PaymentProvider = {
  __typename?: 'PaymentProvider';
  providerId: Scalars['String'];
  providerName: PaymentProviderName;
  status?: Maybe<ProviderStatusLog>;
};

export enum PaymentProviderName {
  Adyen = 'Adyen',
  BrinkZeroPayment = 'BrinkZeroPayment',
  KlarnaCheckout = 'KlarnaCheckout',
  QliroOne = 'QliroOne',
  SveaCheckout = 'SveaCheckout',
  WalleyCheckout = 'WalleyCheckout'
}

export type PlatformConfig = {
  __typename?: 'PlatformConfig';
  externalApi: ExternalApi;
};

export type Price = {
  __typename?: 'Price';
  basePriceAmount: Scalars['Int'];
  campaign?: Maybe<CampaignIdentity>;
  currencyCode: Scalars['String'];
  discountAmount: Scalars['Int'];
  lowestPriceRecord?: Maybe<PriceRecord>;
  priceRecords: Array<PriceRecord>;
  referencePriceAmount?: Maybe<Scalars['Int']>;
  salePriceAmount: Scalars['Int'];
  startDate: Scalars['AWSDateTime'];
};

export type PriceRecord = {
  __typename?: 'PriceRecord';
  basePriceAmount: Scalars['Int'];
  campaign?: Maybe<CampaignIdentity>;
  endDate: Scalars['AWSDateTime'];
  salePriceAmount: Scalars['Int'];
  startDate: Scalars['AWSDateTime'];
};

export type PriceRule = {
  __typename?: 'PriceRule';
  monies?: Maybe<Array<CampaignMoney>>;
  percentage?: Maybe<Scalars['Int']>;
  percentageDecimals?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
};

export type ProductParent = {
  __typename?: 'ProductParent';
  Variants?: Maybe<Array<ProductVariant>>;
  created: Scalars['AWSDateTime'];
  customAttributes?: Maybe<Scalars['AWSJSON']>;
  description: Scalars['String'];
  displayDescriptions: Scalars['AWSJSON'];
  displayNames: Scalars['AWSJSON'];
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  name: Scalars['String'];
  tags?: Maybe<Scalars['AWSJSON']>;
  updated: Scalars['AWSDateTime'];
};

export type ProductParentSearchHit = {
  __typename?: 'ProductParentSearchHit';
  created: Scalars['AWSDateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  name: Scalars['String'];
  tags?: Maybe<Array<KeyValue>>;
  updated: Scalars['AWSDateTime'];
};

export type ProductParentSearchHits = {
  __typename?: 'ProductParentSearchHits';
  hits: Array<ProductParentSearchHit>;
  total: Scalars['Int'];
};

export type ProductVariant = {
  __typename?: 'ProductVariant';
  Stock?: Maybe<Stock>;
  StoreGroupPrices: Array<StoreGroupPrice>;
  TaxGroup: TaxGroup;
  created: Scalars['AWSDateTime'];
  customAttributes?: Maybe<Scalars['AWSJSON']>;
  description: Scalars['String'];
  dimensions?: Maybe<Dimensions>;
  displayDescriptions: Scalars['AWSJSON'];
  displayNames: Scalars['AWSJSON'];
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  name: Scalars['String'];
  productParentId: Scalars['ID'];
  shippingAttributes?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Scalars['AWSJSON']>;
  updated: Scalars['AWSDateTime'];
  weight?: Maybe<Scalars['Int']>;
};

export type ProductVariantReservation = {
  __typename?: 'ProductVariantReservation';
  inventoryId: Scalars['String'];
  productVariantId: Scalars['String'];
  quantity: Scalars['Int'];
};

export type ProductVariantRules = {
  __typename?: 'ProductVariantRules';
  tagLogic: Scalars['String'];
};

export type ProductVariantSearchHit = {
  __typename?: 'ProductVariantSearchHit';
  created: Scalars['AWSDateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  isActive: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  name: Scalars['String'];
  productParentId: Scalars['String'];
  tags?: Maybe<Array<KeyValue>>;
  taxGroupId: Scalars['String'];
  updated: Scalars['AWSDateTime'];
};

export type ProductVariantSearchHits = {
  __typename?: 'ProductVariantSearchHits';
  hits: Array<ProductVariantSearchHit>;
  total: Scalars['Int'];
};

export type PromotionProvider = {
  __typename?: 'PromotionProvider';
  providerId: Scalars['String'];
  providerName: PromotionProviderName;
};

export enum PromotionProviderName {
  Voyado = 'Voyado'
}

export type Provider = {
  __typename?: 'Provider';
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type ProviderStatusLog = {
  __typename?: 'ProviderStatusLog';
  current: Scalars['String'];
  history: Array<ProviderStatusLogEntry>;
};

export type ProviderStatusLogEntry = {
  __typename?: 'ProviderStatusLogEntry';
  message?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  timestamp?: Maybe<Scalars['AWSDateTime']>;
  type: Scalars['String'];
};

export type QliroOneConfig = {
  __typename?: 'QliroOneConfig';
  baseUrl: Scalars['String'];
  created: Scalars['AWSDateTime'];
  credentials: QliroOneCredentials;
  id: Scalars['ID'];
  integratedShipping?: Maybe<QliroOneIntegratedShipping>;
  merchantOrderManagementStatusPushUrl: Scalars['String'];
  updated: Scalars['AWSDateTime'];
  useLegacyIds?: Maybe<Scalars['Boolean']>;
};

export type QliroOneCredentials = {
  __typename?: 'QliroOneCredentials';
  merchantApiKey: Scalars['String'];
  merchantApiSecret: Scalars['String'];
};

export type QliroOneIntegratedShipping = {
  __typename?: 'QliroOneIntegratedShipping';
  deliveryCheckoutId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type QliroShipmentOrderItem = {
  __typename?: 'QliroShipmentOrderItem';
  description: Scalars['String'];
  merchantReference: Scalars['String'];
  metadata?: Maybe<QliroShipmentOrderItemMetadata>;
  paymentTransactionId: Scalars['Int'];
  pricePerItemExVat: Scalars['Float'];
  pricePerItemIncVat: Scalars['Float'];
  quantity: Scalars['Int'];
  type: Scalars['String'];
};

export type QliroShipmentOrderItemMetadata = {
  __typename?: 'QliroShipmentOrderItemMetadata';
  shippingMethodMerchantReference: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  Configuration?: Maybe<QueryConfigurationManagement>;
  Order: QueryOrderManagement;
  PlatformConfig?: Maybe<QueryPlatformConfig>;
  Product: QueryProductManagement;
  apiClient: ApiClientDetails;
  apiClients: ApiClientsList;
  apiResources: Array<ApiResource>;
  getAdyenConfig: AdyenConfig;
  getAdyenWebhooks: Array<AdyenWebhookLegacy>;
  getBSGGiftCardConfig: BsgGiftCardConfig;
  getBonusConfig: BonusConfig;
  getBonusConfigs: Array<CapabilityProvider>;
  getBundleGroup: BundleGroup;
  getCampaign: Campaign;
  getCampaignGroup: CampaignGroupList;
  getDiscountCode: DiscountCode;
  getDiscountCodeRule: DiscountCodeRule;
  getDiscountExternalRule: DiscountExternalRule;
  getDiscountRule: DiscountRule;
  getExternalEventsDestinationAPIs: ExternalEventDestinationApIs;
  getExternalEventsDestinationEventBuses: ExternalEventDestinationEventBuses;
  getExternalEventsRule: ExternalEventRuleState;
  getExternalEventsRules: ExternalEventRules;
  getGenerateCodes: GenerateCodes;
  getGiftCardProviders: Array<Provider>;
  getIngridConfig: IngridConfig;
  getInventories: Array<Inventory>;
  getInventory: Inventory;
  getInventoryStoreMarket: InventoryStoreMarket;
  getKBSGiftCardConfig: KbsGiftCardConfig;
  getKlarnaCheckoutConfig: KlarnaCheckoutConfig;
  getNShiftConfig: NShiftConfig;
  getPaymentProviders: Array<Provider>;
  getQliroOneConfig: QliroOneConfig;
  getRetain24Config: Retain24Config;
  getShippingProviders: Array<Provider>;
  getShippingTaxGroup: ShippingTaxGroup;
  getStoreGroup: StoreGroup;
  getStoreGroups: Array<StoreGroup>;
  getSveaCheckoutConfig: SveaCheckoutConfig;
  getTaxGroup: TaxGroup;
  getTaxMarkets: Array<TaxMarket>;
  getVoyadoConfig: VoyadoConfig;
  getVoyadoConfigs: Array<CapabilityProvider>;
  getWalleyCheckoutConfig: WalleyCheckoutConfig;
  groupUsers: UsersList;
  groups?: Maybe<GroupsList>;
  searchBundleGroups?: Maybe<BundleGroupSearchHits>;
  searchCampaignGroups?: Maybe<CampaignGroupSearchHits>;
  searchCampaigns?: Maybe<CampaignSearchHits>;
  searchCodesGenerations?: Maybe<CodesGenerationSearchHits>;
  searchDiscountCodeRules?: Maybe<DiscountCodeRuleSearchHits>;
  searchDiscountCodes?: Maybe<DiscountCodeSearchHits>;
  searchDiscountExternalRules?: Maybe<DiscountExternalRuleSearchHits>;
  searchDiscountRules?: Maybe<DiscountRuleSearchHits>;
  searchOrders?: Maybe<OrderSearchHits>;
  searchProductParents?: Maybe<ProductParentSearchHits>;
  searchProductVariants?: Maybe<ProductVariantSearchHits>;
  searchStoreGroups?: Maybe<StoreGroupSearchHits>;
  searchStoreMarkets?: Maybe<StoreMarketSearchHits>;
  searchTaxGroups?: Maybe<TaxGroupSearchHits>;
  searchTaxMarkets?: Maybe<TaxMarketSearchHits>;
  tagKeys?: Maybe<TagKeys>;
  tagValues?: Maybe<TagValues>;
  user: UserDetails;
  users: UsersList;
};


export type QueryApiClientArgs = {
  clientId: Scalars['ID'];
};


export type QueryApiClientsArgs = {
  limit: Scalars['Int'];
  token?: InputMaybe<Scalars['String']>;
};


export type QueryGetAdyenConfigArgs = {
  id: Scalars['ID'];
};


export type QueryGetBsgGiftCardConfigArgs = {
  id: Scalars['ID'];
};


export type QueryGetBonusConfigArgs = {
  id: Scalars['ID'];
};


export type QueryGetBundleGroupArgs = {
  id: Scalars['ID'];
};


export type QueryGetCampaignArgs = {
  id: Scalars['ID'];
};


export type QueryGetCampaignGroupArgs = {
  id: Scalars['ID'];
};


export type QueryGetDiscountCodeArgs = {
  code: Scalars['String'];
};


export type QueryGetDiscountCodeRuleArgs = {
  id: Scalars['ID'];
};


export type QueryGetDiscountExternalRuleArgs = {
  id: Scalars['ID'];
};


export type QueryGetDiscountRuleArgs = {
  id: Scalars['ID'];
};


export type QueryGetExternalEventsRuleArgs = {
  ruleName: Scalars['String'];
};


export type QueryGetGenerateCodesArgs = {
  jobId: Scalars['String'];
};


export type QueryGetIngridConfigArgs = {
  id: Scalars['ID'];
};


export type QueryGetInventoryArgs = {
  id: Scalars['ID'];
};


export type QueryGetInventoryStoreMarketArgs = {
  countryCode: Scalars['String'];
  storeGroupId: Scalars['String'];
};


export type QueryGetKbsGiftCardConfigArgs = {
  id: Scalars['ID'];
};


export type QueryGetKlarnaCheckoutConfigArgs = {
  id: Scalars['ID'];
};


export type QueryGetNShiftConfigArgs = {
  id: Scalars['ID'];
};


export type QueryGetQliroOneConfigArgs = {
  id: Scalars['ID'];
};


export type QueryGetRetain24ConfigArgs = {
  id: Scalars['ID'];
};


export type QueryGetStoreGroupArgs = {
  id: Scalars['ID'];
};


export type QueryGetSveaCheckoutConfigArgs = {
  id: Scalars['ID'];
};


export type QueryGetTaxGroupArgs = {
  id: Scalars['ID'];
};


export type QueryGetTaxMarketsArgs = {
  taxGroupId: Scalars['String'];
};


export type QueryGetVoyadoConfigArgs = {
  id: Scalars['ID'];
};


export type QueryGetWalleyCheckoutConfigArgs = {
  id: Scalars['ID'];
};


export type QueryGroupUsersArgs = {
  groupname: Scalars['String'];
  limit: Scalars['Int'];
  token?: InputMaybe<Scalars['String']>;
};


export type QueryGroupsArgs = {
  limit: Scalars['Int'];
  token?: InputMaybe<Scalars['String']>;
};


export type QuerySearchBundleGroupsArgs = {
  from: Scalars['Int'];
  query?: InputMaybe<Scalars['AWSJSON']>;
  size: Scalars['Int'];
  sort?: InputMaybe<Array<SearchSorting>>;
  trackTotalHits?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySearchCampaignGroupsArgs = {
  from: Scalars['Int'];
  query?: InputMaybe<Scalars['AWSJSON']>;
  size: Scalars['Int'];
  sort?: InputMaybe<Array<SearchSorting>>;
  trackTotalHits?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySearchCampaignsArgs = {
  from: Scalars['Int'];
  query?: InputMaybe<Scalars['AWSJSON']>;
  size: Scalars['Int'];
  sort?: InputMaybe<Array<SearchSorting>>;
  trackTotalHits?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySearchCodesGenerationsArgs = {
  from: Scalars['Int'];
  query?: InputMaybe<Scalars['AWSJSON']>;
  size: Scalars['Int'];
  sort?: InputMaybe<Array<SearchSorting>>;
  trackTotalHits?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySearchDiscountCodeRulesArgs = {
  from: Scalars['Int'];
  query?: InputMaybe<Scalars['AWSJSON']>;
  size: Scalars['Int'];
  sort?: InputMaybe<Array<SearchSorting>>;
  trackTotalHits?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySearchDiscountCodesArgs = {
  from: Scalars['Int'];
  query?: InputMaybe<Scalars['AWSJSON']>;
  size: Scalars['Int'];
  sort?: InputMaybe<Array<SearchSorting>>;
  trackTotalHits?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySearchDiscountExternalRulesArgs = {
  from: Scalars['Int'];
  query?: InputMaybe<Scalars['AWSJSON']>;
  size: Scalars['Int'];
  sort?: InputMaybe<Array<SearchSorting>>;
  trackTotalHits?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySearchDiscountRulesArgs = {
  from: Scalars['Int'];
  query?: InputMaybe<Scalars['AWSJSON']>;
  size: Scalars['Int'];
  sort?: InputMaybe<Array<SearchSorting>>;
  trackTotalHits?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySearchOrdersArgs = {
  from: Scalars['Int'];
  query?: InputMaybe<Scalars['AWSJSON']>;
  size: Scalars['Int'];
  sort?: InputMaybe<Array<SearchSorting>>;
  trackTotalHits?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySearchProductParentsArgs = {
  from: Scalars['Int'];
  query?: InputMaybe<Scalars['AWSJSON']>;
  size: Scalars['Int'];
  sort?: InputMaybe<Array<SearchSorting>>;
  trackTotalHits?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySearchProductVariantsArgs = {
  from: Scalars['Int'];
  query?: InputMaybe<Scalars['AWSJSON']>;
  size: Scalars['Int'];
  sort?: InputMaybe<Array<SearchSorting>>;
  trackTotalHits?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySearchStoreGroupsArgs = {
  from: Scalars['Int'];
  query?: InputMaybe<Scalars['AWSJSON']>;
  size: Scalars['Int'];
  sort?: InputMaybe<Array<SearchSorting>>;
  trackTotalHits?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySearchStoreMarketsArgs = {
  from: Scalars['Int'];
  query?: InputMaybe<Scalars['AWSJSON']>;
  size: Scalars['Int'];
  sort?: InputMaybe<Array<SearchSorting>>;
  trackTotalHits?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySearchTaxGroupsArgs = {
  from: Scalars['Int'];
  query?: InputMaybe<Scalars['AWSJSON']>;
  size: Scalars['Int'];
  sort?: InputMaybe<Array<SearchSorting>>;
  trackTotalHits?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySearchTaxMarketsArgs = {
  from: Scalars['Int'];
  query?: InputMaybe<Scalars['AWSJSON']>;
  size: Scalars['Int'];
  sort?: InputMaybe<Array<SearchSorting>>;
  trackTotalHits?: InputMaybe<Scalars['Boolean']>;
};


export type QueryTagValuesArgs = {
  key: Scalars['String'];
  prefix?: InputMaybe<Scalars['String']>;
};


export type QueryUserArgs = {
  username: Scalars['AWSEmail'];
};


export type QueryUsersArgs = {
  limit: Scalars['Int'];
  token?: InputMaybe<Scalars['String']>;
};

export type QueryConfigurationManagement = {
  __typename?: 'QueryConfigurationManagement';
  validate?: Maybe<QueryConfigurationManagementValidate>;
};

export type QueryConfigurationManagementValidate = {
  __typename?: 'QueryConfigurationManagementValidate';
  adyen: ValidateConfigResult;
  bonus: ValidateConfigResult;
  klarnaCheckout: ValidateConfigResult;
  qliroOne: ValidateConfigResult;
  retain24: ValidateConfigResult;
  sveaCheckout: ValidateConfigResult;
  walleyCheckout: ValidateConfigResult;
};


export type QueryConfigurationManagementValidateAdyenArgs = {
  baseUrl: Scalars['String'];
  environment?: InputMaybe<Scalars['String']>;
  merchantAccount: Scalars['String'];
  xApiKey: Scalars['String'];
};


export type QueryConfigurationManagementValidateBonusArgs = {
  input: InputBonusConfig;
};


export type QueryConfigurationManagementValidateKlarnaCheckoutArgs = {
  baseUrl: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};


export type QueryConfigurationManagementValidateQliroOneArgs = {
  input: InputQliroOneConfig;
};


export type QueryConfigurationManagementValidateRetain24Args = {
  input: InputRetain24Config;
};


export type QueryConfigurationManagementValidateSveaCheckoutArgs = {
  baseUrl: Scalars['String'];
  checkoutSecret: Scalars['String'];
  merchantId: Scalars['String'];
};


export type QueryConfigurationManagementValidateWalleyCheckoutArgs = {
  input: InputWalleyCheckoutConfig;
};

export type QueryOrderManagement = {
  __typename?: 'QueryOrderManagement';
  cancellation: OrderCancellation;
  compensation: OrderCompensation;
  delivery: OrderDelivery;
  details: Order;
  refund: OrderRefund;
  release: OrderRelease;
  status: OrderStatus;
};


export type QueryOrderManagementCancellationArgs = {
  cancellationId: Scalars['ID'];
};


export type QueryOrderManagementCompensationArgs = {
  compensationId: Scalars['ID'];
};


export type QueryOrderManagementDeliveryArgs = {
  deliveryId: Scalars['ID'];
};


export type QueryOrderManagementDetailsArgs = {
  id: Scalars['ID'];
};


export type QueryOrderManagementRefundArgs = {
  refundId: Scalars['ID'];
};


export type QueryOrderManagementReleaseArgs = {
  releaseId: Scalars['ID'];
};


export type QueryOrderManagementStatusArgs = {
  id: Scalars['ID'];
};

export type QueryPlatformConfig = {
  __typename?: 'QueryPlatformConfig';
  campaign?: Maybe<PlatformConfig>;
  discount?: Maybe<PlatformConfig>;
  order?: Maybe<PlatformConfig>;
  price?: Maybe<PlatformConfig>;
  product?: Maybe<PlatformConfig>;
  stock?: Maybe<PlatformConfig>;
  store?: Maybe<PlatformConfig>;
  tax?: Maybe<PlatformConfig>;
};

export type QueryProductManagement = {
  __typename?: 'QueryProductManagement';
  parent: ProductParent;
  variant: ProductVariant;
};


export type QueryProductManagementParentArgs = {
  id: Scalars['ID'];
};


export type QueryProductManagementVariantArgs = {
  id: Scalars['ID'];
};

export enum QuotaPeriod {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK'
}

export type Reason = {
  __typename?: 'Reason';
  cause?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type RefundBonus = {
  __typename?: 'RefundBonus';
  amount: Scalars['Int'];
  currencyCode: Scalars['String'];
  reservationId: Scalars['String'];
  transactionId: Scalars['ID'];
};

export type RefundFee = {
  __typename?: 'RefundFee';
  amount: Scalars['Int'];
  currencyCode: Scalars['String'];
  name: Scalars['String'];
  taxAmount: Scalars['Int'];
  taxPercentage: Scalars['Int'];
  taxPercentageDecimals: Scalars['Int'];
};

export type RefundGift = {
  __typename?: 'RefundGift';
  giftId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type RefundGiftCard = {
  __typename?: 'RefundGiftCard';
  amount: Scalars['Int'];
  currencyCode: Scalars['String'];
  giftCardId: Scalars['ID'];
  status: Scalars['String'];
};

export type RefundOrderLine = {
  __typename?: 'RefundOrderLine';
  currencyCode: Scalars['String'];
  orderLineId: Scalars['ID'];
  quantity: Scalars['Int'];
  taxPercentage: Scalars['Int'];
  taxPercentageDecimals: Scalars['Int'];
  totalAmount: Scalars['Int'];
  totalDiscountAmount: Scalars['Int'];
  totalTaxAmount: Scalars['Int'];
};

export type RefundShippingFee = {
  __typename?: 'RefundShippingFee';
  currencyCode: Scalars['String'];
  shippingFeeId: Scalars['ID'];
  taxPercentage: Scalars['Int'];
  taxPercentageDecimals: Scalars['Int'];
  totalAmount: Scalars['Int'];
  totalDiscountAmount: Scalars['Int'];
  totalTaxAmount: Scalars['Int'];
};

export type RefundVoucher = {
  __typename?: 'RefundVoucher';
  amount: Scalars['Int'];
  currencyCode: Scalars['String'];
  voucherId: Scalars['ID'];
};

export type RefundedPayment = {
  __typename?: 'RefundedPayment';
  reference: Scalars['String'];
};

export type ReleaseBonus = {
  __typename?: 'ReleaseBonus';
  amount: Scalars['Int'];
  currencyCode: Scalars['String'];
  reservationId: Scalars['String'];
  transactionId?: Maybe<Scalars['String']>;
};

export type ReleaseGift = {
  __typename?: 'ReleaseGift';
  giftId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type ReleaseGiftCard = {
  __typename?: 'ReleaseGiftCard';
  amount: Scalars['Int'];
  currencyCode: Scalars['String'];
  giftCardId: Scalars['ID'];
  status: Scalars['String'];
  transactionId?: Maybe<Scalars['String']>;
};

export type ReleaseGiftCardProduct = {
  __typename?: 'ReleaseGiftCardProduct';
  cancelErrorMessage?: Maybe<Scalars['String']>;
  cancelErrorStatusCode?: Maybe<Scalars['Int']>;
  cancelTransactionId?: Maybe<Scalars['String']>;
  createFailedReason?: Maybe<Scalars['String']>;
  createId?: Maybe<Scalars['String']>;
  createTransactionId?: Maybe<Scalars['String']>;
  currencyCode: Scalars['String'];
  giftCardProductId: Scalars['ID'];
  priceAmount: Scalars['Int'];
  status: Scalars['String'];
};

export type ReleaseOrderLine = {
  __typename?: 'ReleaseOrderLine';
  currencyCode: Scalars['String'];
  orderLineId: Scalars['ID'];
  quantity: Scalars['Int'];
  taxPercentage: Scalars['Int'];
  taxPercentageDecimals: Scalars['Int'];
  totalAmount: Scalars['Int'];
  totalDiscountAmount: Scalars['Int'];
  totalTaxAmount: Scalars['Int'];
};

export type ReleaseShippingFee = {
  __typename?: 'ReleaseShippingFee';
  currencyCode: Scalars['String'];
  shippingFeeId: Scalars['ID'];
  taxPercentage: Scalars['Int'];
  taxPercentageDecimals: Scalars['Int'];
  totalAmount: Scalars['Int'];
  totalDiscountAmount: Scalars['Int'];
  totalTaxAmount: Scalars['Int'];
};

export type ReleasedPayment = {
  __typename?: 'ReleasedPayment';
  reference: Scalars['String'];
};

export type Retain24Config = {
  __typename?: 'Retain24Config';
  baseUrl: Scalars['String'];
  created: Scalars['AWSDateTime'];
  credentials: Retain24Credentials;
  id: Scalars['ID'];
  redeem: Scalars['Boolean'];
  simulationKey?: Maybe<Scalars['String']>;
  updated: Scalars['AWSDateTime'];
};

export type Retain24Credentials = {
  __typename?: 'Retain24Credentials';
  certificate: Scalars['String'];
  privateKey: Scalars['String'];
};

export type RoundingRule = {
  __typename?: 'RoundingRule';
  roundDown: Scalars['Boolean'];
  roundUp: Scalars['Boolean'];
  targets: Array<Scalars['String']>;
};

export type RoundingRules = {
  __typename?: 'RoundingRules';
  defaultRule: RoundingRule;
  marketRules?: Maybe<Array<MarketRoundingRule>>;
};

export type Rule = {
  __typename?: 'Rule';
  discountAmount: Scalars['Int'];
  discountRuleId: Scalars['String'];
};

export type Schema = {
  __typename?: 'Schema';
  mutation?: Maybe<Mutation>;
  query?: Maybe<Query>;
  subscriptions?: Maybe<Subscription>;
};

export type SearchSorting = {
  field: Scalars['String'];
  order?: InputMaybe<SortOrder>;
};

export type ShippingFee = {
  __typename?: 'ShippingFee';
  basePriceAmount?: Maybe<Scalars['Int']>;
  currencyCode?: Maybe<Scalars['String']>;
  discountAmount?: Maybe<Scalars['Int']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  salePriceAmount?: Maybe<Scalars['Int']>;
  taxAmount?: Maybe<Scalars['Int']>;
  taxGroupId?: Maybe<Scalars['String']>;
  taxPercentage?: Maybe<Scalars['Int']>;
  taxPercentageDecimals?: Maybe<Scalars['Int']>;
};

export type ShippingProvider = {
  __typename?: 'ShippingProvider';
  providerId: Scalars['String'];
  providerName: ShippingProviderName;
  status?: Maybe<ProviderStatusLog>;
};

export enum ShippingProviderName {
  Ingrid = 'Ingrid',
  KlarnaShippingAssistant = 'KlarnaShippingAssistant',
  Nshift = 'Nshift',
  QliroIntegratedShipping = 'QliroIntegratedShipping',
  WalleyDeliveryModule = 'WalleyDeliveryModule'
}

export type ShippingTaxGroup = {
  __typename?: 'ShippingTaxGroup';
  created: Scalars['AWSDateTime'];
  taxGroupId: Scalars['String'];
  updated: Scalars['AWSDateTime'];
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Stock = {
  __typename?: 'Stock';
  id: Scalars['ID'];
  inventories: Array<StockInventory>;
  stockQuantity: Scalars['Int'];
  validateStock: Scalars['Boolean'];
};

export type StockInventory = {
  __typename?: 'StockInventory';
  created: Scalars['AWSDateTime'];
  inventoryId: Scalars['ID'];
  quantity: Scalars['Int'];
  updated: Scalars['AWSDateTime'];
};

export type StoreGroup = {
  __typename?: 'StoreGroup';
  channelType: ChannelType;
  countryOfSale?: Maybe<Scalars['String']>;
  created: Scalars['AWSDateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  storeMarkets?: Maybe<Array<StoreMarket>>;
  updated: Scalars['AWSDateTime'];
};

export type StoreGroupPrice = {
  __typename?: 'StoreGroupPrice';
  marketPrices: Array<MarketPrice>;
  storeGroupId: Scalars['ID'];
};

export type StoreGroupSearchHit = {
  __typename?: 'StoreGroupSearchHit';
  countryOfSale?: Maybe<Scalars['String']>;
  created: Scalars['AWSDateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updated: Scalars['AWSDateTime'];
};

export type StoreGroupSearchHits = {
  __typename?: 'StoreGroupSearchHits';
  hits: Array<StoreGroupSearchHit>;
  total: Scalars['Int'];
};

export type StoreMarket = {
  __typename?: 'StoreMarket';
  capabilities?: Maybe<Capabilities>;
  cartCapabilities?: Maybe<CartCapabilities>;
  channelType: ChannelType;
  countryCode: Scalars['String'];
  countryOfSale?: Maybe<Scalars['String']>;
  created: Scalars['AWSDateTime'];
  currencyCode: Scalars['String'];
  description: Scalars['String'];
  isArchived?: Maybe<Scalars['Boolean']>;
  isTaxExemptionEligible?: Maybe<Scalars['Boolean']>;
  isTaxIncludedInPrice: Scalars['Boolean'];
  name: Scalars['String'];
  storeGroupId: Scalars['String'];
  updated: Scalars['AWSDateTime'];
};

export type StoreMarketSearchHit = {
  __typename?: 'StoreMarketSearchHit';
  cartCapabilities?: Maybe<CartCapabilities>;
  countryCode: Scalars['String'];
  created: Scalars['AWSDateTime'];
  currencyCode: Scalars['String'];
  description: Scalars['String'];
  giftCardProviders?: Maybe<Array<Provider>>;
  isArchived: Scalars['Boolean'];
  isTaxExemptionEligible?: Maybe<Scalars['Boolean']>;
  isTaxIncludedInPrice: Scalars['Boolean'];
  name: Scalars['String'];
  paymentProviders?: Maybe<Array<Provider>>;
  shippingProviders?: Maybe<Array<Provider>>;
  storeGroupId: Scalars['String'];
  taxProviders?: Maybe<Array<Provider>>;
  updated: Scalars['AWSDateTime'];
};

export type StoreMarketSearchHits = {
  __typename?: 'StoreMarketSearchHits';
  hits: Array<StoreMarketSearchHit>;
  total: Scalars['Int'];
};

export type SubOutcome = {
  __typename?: 'SubOutcome';
  discountQuantity?: Maybe<Scalars['Int']>;
  discountValue?: Maybe<DiscountValue>;
  triggerQuantity?: Maybe<Scalars['Int']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  OnAdyenWebhookChanged?: Maybe<AdyenWebhook>;
};


export type SubscriptionOnAdyenWebhookChangedArgs = {
  configId: Scalars['String'];
};

export type SveaCheckoutConfig = {
  __typename?: 'SveaCheckoutConfig';
  checkoutBaseUrl: Scalars['String'];
  created: Scalars['AWSDateTime'];
  credentials: SveaCredentials;
  id: Scalars['ID'];
  merchantId: Scalars['String'];
  paymentAdminBaseUrl: Scalars['String'];
  updated: Scalars['AWSDateTime'];
};

export type SveaCredentials = {
  __typename?: 'SveaCredentials';
  apiKey: Scalars['String'];
};

export type TagCondition = {
  __typename?: 'TagCondition';
  key: Scalars['String'];
  mode: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type TagKeys = {
  __typename?: 'TagKeys';
  keys?: Maybe<Array<Scalars['String']>>;
};

export type TagValues = {
  __typename?: 'TagValues';
  key: Scalars['String'];
  values?: Maybe<Array<Scalars['String']>>;
};

export type TaxGroup = {
  __typename?: 'TaxGroup';
  created: Scalars['AWSDateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updated: Scalars['AWSDateTime'];
};

export type TaxGroupSearchHit = {
  __typename?: 'TaxGroupSearchHit';
  created: Scalars['AWSDateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updated: Scalars['AWSDateTime'];
};

export type TaxGroupSearchHits = {
  __typename?: 'TaxGroupSearchHits';
  hits: Array<TaxGroupSearchHit>;
  total: Scalars['Int'];
};

export type TaxMarket = {
  __typename?: 'TaxMarket';
  countryCode: Scalars['String'];
  created: Scalars['AWSDateTime'];
  taxGroupId: Scalars['String'];
  taxPercentage: Scalars['Int'];
  taxPercentageDecimals: Scalars['Int'];
  updated: Scalars['AWSDateTime'];
};

export type TaxMarketSearchHit = {
  __typename?: 'TaxMarketSearchHit';
  countryCode: Scalars['String'];
  created: Scalars['AWSDateTime'];
  taxGroupId: Scalars['String'];
  taxPercentage: Scalars['Int'];
  taxPercentageDecimals: Scalars['Int'];
  updated: Scalars['AWSDateTime'];
};

export type TaxMarketSearchHits = {
  __typename?: 'TaxMarketSearchHits';
  hits: Array<TaxMarketSearchHit>;
  total: Scalars['Int'];
};

export type UsagePlan = {
  __typename?: 'UsagePlan';
  burstLimit: Scalars['Int'];
  name: Scalars['String'];
  quotaLimit: Scalars['Int'];
  quotaPeriod: QuotaPeriod;
  rateLimit: Scalars['Int'];
};

export type User = {
  __typename?: 'User';
  created: Scalars['AWSDateTime'];
  enabled: Scalars['Boolean'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  status: UserStatus;
  updated: Scalars['AWSDateTime'];
  username: Scalars['ID'];
};

export type UserDetails = {
  __typename?: 'UserDetails';
  created: Scalars['AWSDateTime'];
  enabled: Scalars['Boolean'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  groups: Array<Group>;
  status: UserStatus;
  updated: Scalars['AWSDateTime'];
  username: Scalars['ID'];
};

export enum UserStatus {
  Archived = 'ARCHIVED',
  Confirmed = 'CONFIRMED',
  ExternalProvider = 'EXTERNAL_PROVIDER',
  ForceChangePassword = 'FORCE_CHANGE_PASSWORD',
  ResetRequired = 'RESET_REQUIRED',
  Unconfirmed = 'UNCONFIRMED',
  Unknown = 'UNKNOWN'
}

export type UsersList = {
  __typename?: 'UsersList';
  nextToken?: Maybe<Scalars['String']>;
  users: Array<User>;
};

export type ValidDateRange = {
  __typename?: 'ValidDateRange';
  from: Scalars['String'];
  to: Scalars['String'];
};

export type ValidateConfigResult = {
  __typename?: 'ValidateConfigResult';
  errorMessage?: Maybe<Scalars['String']>;
  valid: Scalars['Boolean'];
};

export type VoucherProvider = {
  __typename?: 'VoucherProvider';
  providerId: Scalars['String'];
  providerName: VoucherProviderName;
};

export enum VoucherProviderName {
  Voyado = 'Voyado'
}

export type VoyadoConfig = {
  __typename?: 'VoyadoConfig';
  baseUrl: Scalars['String'];
  created: Scalars['AWSDateTime'];
  credentials: VoyadoCredentials;
  id: Scalars['ID'];
  updated: Scalars['AWSDateTime'];
};

export type VoyadoCredentials = {
  __typename?: 'VoyadoCredentials';
  apiKey: Scalars['String'];
};

export type WalleyCheckoutConfig = {
  __typename?: 'WalleyCheckoutConfig';
  baseUrl: Scalars['String'];
  created: Scalars['AWSDateTime'];
  credentials: WalleyCredentials;
  deliveryModuleEnabled: Scalars['Boolean'];
  id: Scalars['ID'];
  profileName?: Maybe<Scalars['String']>;
  scope: Scalars['String'];
  storeId: Scalars['String'];
  updated: Scalars['AWSDateTime'];
};

export type WalleyCredentials = {
  __typename?: 'WalleyCredentials';
  clientId: Scalars['String'];
  secret: Scalars['String'];
};

export type WalleyDeliveryModuleDeliveryDate = {
  __typename?: 'WalleyDeliveryModuleDeliveryDate';
  date: Scalars['String'];
  fee: Scalars['Float'];
  timeFrom: Scalars['String'];
  timeTo: Scalars['String'];
};

export type WalleyDeliveryModuleDestination = {
  __typename?: 'WalleyDeliveryModuleDestination';
  deliveryDate?: Maybe<WalleyDeliveryModuleDeliveryDate>;
  fee: Scalars['Float'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type WalleyDeliveryModuleOption = {
  __typename?: 'WalleyDeliveryModuleOption';
  Fee: Scalars['Float'];
  Type: Scalars['String'];
  Value: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['String'];
};

export type WalleyDeliveryModuleShipment = {
  __typename?: 'WalleyDeliveryModuleShipment';
  bookedShipmentId: Scalars['String'];
  feeItemId: Scalars['String'];
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  shippingChoice: WalleyDeliveryModuleShippingChoice;
};

export type WalleyDeliveryModuleShipping = {
  __typename?: 'WalleyDeliveryModuleShipping';
  provider: Scalars['String'];
  shipments: Array<WalleyDeliveryModuleShipment>;
  shippingFee: Scalars['Float'];
};

export type WalleyDeliveryModuleShippingChoice = {
  __typename?: 'WalleyDeliveryModuleShippingChoice';
  destination: WalleyDeliveryModuleDestination;
  fee: Scalars['Float'];
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  options: Array<WalleyDeliveryModuleOption>;
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AWSDateTime: ResolverTypeWrapper<Scalars['AWSDateTime']>;
  AWSEmail: ResolverTypeWrapper<Scalars['AWSEmail']>;
  AWSJSON: ResolverTypeWrapper<Scalars['AWSJSON']>;
  AddExternalEventsDestinationAPIResult: ResolverTypeWrapper<AddExternalEventsDestinationApiResult>;
  AddExternalEventsDestinationEventBusResult: ResolverTypeWrapper<AddExternalEventsDestinationEventBusResult>;
  AddExternalEventsRuleResult: ResolverTypeWrapper<AddExternalEventsRuleResult>;
  AdyenConfig: ResolverTypeWrapper<AdyenConfig>;
  AdyenCredentials: ResolverTypeWrapper<AdyenCredentials>;
  AdyenEnvironment: AdyenEnvironment;
  AdyenWebhook: ResolverTypeWrapper<AdyenWebhook>;
  AdyenWebhookCredentials: ResolverTypeWrapper<AdyenWebhookCredentials>;
  AdyenWebhookGenerationResult: ResolverTypeWrapper<AdyenWebhookGenerationResult>;
  AdyenWebhookLegacy: ResolverTypeWrapper<AdyenWebhookLegacy>;
  AdyenWebhookStatus: AdyenWebhookStatus;
  AdyenWebhookTarget: AdyenWebhookTarget;
  ApiClient: ResolverTypeWrapper<ApiClient>;
  ApiClientDetails: ResolverTypeWrapper<ApiClientDetails>;
  ApiClientsList: ResolverTypeWrapper<ApiClientsList>;
  ApiResource: ResolverTypeWrapper<ApiResource>;
  ApiScope: ResolverTypeWrapper<ApiScope>;
  BSGGiftCardConfig: ResolverTypeWrapper<BsgGiftCardConfig>;
  BSGGiftCardCredentials: ResolverTypeWrapper<BsgGiftCardCredentials>;
  BonusConfig: ResolverTypeWrapper<BonusConfig>;
  BonusCredentials: ResolverTypeWrapper<BonusCredentials>;
  BonusProvider: ResolverTypeWrapper<BonusProvider>;
  BonusProviderName: BonusProviderName;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Bundle: ResolverTypeWrapper<Bundle>;
  BundleGroup: ResolverTypeWrapper<BundleGroup>;
  BundleGroupSearchHit: ResolverTypeWrapper<BundleGroupSearchHit>;
  BundleGroupSearchHits: ResolverTypeWrapper<BundleGroupSearchHits>;
  BundleItemRule: ResolverTypeWrapper<BundleItemRule>;
  BundleOutcome: ResolverTypeWrapper<BundleOutcome>;
  BundleStoreMarket: ResolverTypeWrapper<BundleStoreMarket>;
  Campaign: ResolverTypeWrapper<Campaign>;
  CampaignDeletionResult: ResolverTypeWrapper<CampaignDeletionResult>;
  CampaignGroup: ResolverTypeWrapper<CampaignGroup>;
  CampaignGroupList: ResolverTypeWrapper<CampaignGroupList>;
  CampaignGroupSearchHit: ResolverTypeWrapper<CampaignGroupSearchHit>;
  CampaignGroupSearchHits: ResolverTypeWrapper<CampaignGroupSearchHits>;
  CampaignIdentity: ResolverTypeWrapper<CampaignIdentity>;
  CampaignMoney: ResolverTypeWrapper<CampaignMoney>;
  CampaignSearchHit: ResolverTypeWrapper<CampaignSearchHit>;
  CampaignSearchHits: ResolverTypeWrapper<CampaignSearchHits>;
  CampaignStoreMarket: ResolverTypeWrapper<CampaignStoreMarket>;
  CancellationBonus: ResolverTypeWrapper<CancellationBonus>;
  CancellationGiftCard: ResolverTypeWrapper<CancellationGiftCard>;
  CancellationGiftCardProduct: ResolverTypeWrapper<CancellationGiftCardProduct>;
  CancelledPayment: ResolverTypeWrapper<CancelledPayment>;
  Capabilities: ResolverTypeWrapper<Capabilities>;
  CapabilityProvider: ResolverTypeWrapper<CapabilityProvider>;
  CartCapabilities: ResolverTypeWrapper<CartCapabilities>;
  CartRuleOutcome: ResolverTypeWrapper<CartRuleOutcome>;
  ChannelType: ChannelType;
  CodeRuleOutcome: ResolverTypeWrapper<CodeRuleOutcome>;
  CodesGenerationSearchHit: ResolverTypeWrapper<CodesGenerationSearchHit>;
  CodesGenerationSearchHits: ResolverTypeWrapper<CodesGenerationSearchHits>;
  CompanyAttribute: ResolverTypeWrapper<CompanyAttribute>;
  CompensationBonus: ResolverTypeWrapper<CompensationBonus>;
  CompensationGiftCard: ResolverTypeWrapper<CompensationGiftCard>;
  CompensationOrderLine: ResolverTypeWrapper<CompensationOrderLine>;
  CompensationPayment: ResolverTypeWrapper<CompensationPayment>;
  CompensationShippingFee: ResolverTypeWrapper<CompensationShippingFee>;
  Condition: ResolverTypeWrapper<Condition>;
  CreateGenerateCodesResult: ResolverTypeWrapper<CreateGenerateCodesResult>;
  DeletedResult: ResolverTypeWrapper<DeletedResult>;
  DeliveryBonus: ResolverTypeWrapper<DeliveryBonus>;
  DeliveryCapture: ResolverTypeWrapper<DeliveryCapture>;
  DeliveryGift: ResolverTypeWrapper<DeliveryGift>;
  DeliveryGiftCard: ResolverTypeWrapper<DeliveryGiftCard>;
  DeliveryGiftCardProduct: ResolverTypeWrapper<DeliveryGiftCardProduct>;
  DeliveryOrderLine: ResolverTypeWrapper<DeliveryOrderLine>;
  DeliveryShippingFee: ResolverTypeWrapper<DeliveryShippingFee>;
  DeliveryTracking: ResolverTypeWrapper<DeliveryTracking>;
  DeliveryVoucher: ResolverTypeWrapper<DeliveryVoucher>;
  Dimensions: ResolverTypeWrapper<Dimensions>;
  DiscountCode: ResolverTypeWrapper<DiscountCode>;
  DiscountCodeRule: ResolverTypeWrapper<DiscountCodeRule>;
  DiscountCodeRuleSearchHit: ResolverTypeWrapper<DiscountCodeRuleSearchHit>;
  DiscountCodeRuleSearchHits: ResolverTypeWrapper<DiscountCodeRuleSearchHits>;
  DiscountCodeSearchHit: ResolverTypeWrapper<DiscountCodeSearchHit>;
  DiscountCodeSearchHits: ResolverTypeWrapper<DiscountCodeSearchHits>;
  DiscountExternalRule: ResolverTypeWrapper<DiscountExternalRule>;
  DiscountExternalRuleSearchHit: ResolverTypeWrapper<DiscountExternalRuleSearchHit>;
  DiscountExternalRuleSearchHits: ResolverTypeWrapper<DiscountExternalRuleSearchHits>;
  DiscountManagementQuotas: ResolverTypeWrapper<DiscountManagementQuotas>;
  DiscountRule: ResolverTypeWrapper<DiscountRule>;
  DiscountRuleSearchHit: ResolverTypeWrapper<DiscountRuleSearchHit>;
  DiscountRuleSearchHits: ResolverTypeWrapper<DiscountRuleSearchHits>;
  DiscountValue: ResolverTypeWrapper<DiscountValue>;
  ExternalApi: ResolverTypeWrapper<ExternalApi>;
  ExternalEventDestinationAPIs: ResolverTypeWrapper<ExternalEventDestinationApIs>;
  ExternalEventDestinationEventBuses: ResolverTypeWrapper<ExternalEventDestinationEventBuses>;
  ExternalEventDestinationsDetails: ResolverTypeWrapper<ExternalEventDestinationsDetails>;
  ExternalEventRuleState: ResolverTypeWrapper<ExternalEventRuleState>;
  ExternalEventRules: ResolverTypeWrapper<ExternalEventRules>;
  ExternalRuleOutcome: ResolverTypeWrapper<ExternalRuleOutcome>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  GenerateCodes: ResolverTypeWrapper<GenerateCodes>;
  Gift: ResolverTypeWrapper<Gift>;
  GiftCardProductProvider: ResolverTypeWrapper<GiftCardProductProvider>;
  GiftCardProductProviderName: GiftCardProductProviderName;
  GiftCardProvider: ResolverTypeWrapper<GiftCardProvider>;
  GiftCardProviderName: GiftCardProviderName;
  Group: ResolverTypeWrapper<Group>;
  GroupDetails: ResolverTypeWrapper<GroupDetails>;
  GroupsList: ResolverTypeWrapper<GroupsList>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  IngridConfig: ResolverTypeWrapper<IngridConfig>;
  IngridCredentials: ResolverTypeWrapper<IngridCredentials>;
  IngridDeliveries: ResolverTypeWrapper<IngridDeliveries>;
  IngridDeliveryAddress: ResolverTypeWrapper<IngridDeliveryAddress>;
  InputActionBonus: InputActionBonus;
  InputActionGiftCard: InputActionGiftCard;
  InputActionGiftCardManual: InputActionGiftCardManual;
  InputActionPaymentCancellation: InputActionPaymentCancellation;
  InputActionPaymentCompensation: InputActionPaymentCompensation;
  InputActionPaymentDelivery: InputActionPaymentDelivery;
  InputActionPaymentRefund: InputActionPaymentRefund;
  InputActionPaymentRelease: InputActionPaymentRelease;
  InputActionShipping: InputActionShipping;
  InputActionType: InputActionType;
  InputAddExternalEventsDestinationAPI: InputAddExternalEventsDestinationApi;
  InputAddUserToGroup: InputAddUserToGroup;
  InputAdyenConfig: InputAdyenConfig;
  InputAdyenWebhookChange: InputAdyenWebhookChange;
  InputBSGGiftCardConfig: InputBsgGiftCardConfig;
  InputBonusConfig: InputBonusConfig;
  InputBundle: InputBundle;
  InputBundleItemRule: InputBundleItemRule;
  InputCampaign: InputCampaign;
  InputCampaignGroup: InputCampaignGroup;
  InputCampaignMoney: InputCampaignMoney;
  InputCampaignPeriod: InputCampaignPeriod;
  InputCapabilities: InputCapabilities;
  InputCapabilityProvider: InputCapabilityProvider;
  InputCartCapabilities: InputCartCapabilities;
  InputCondition: InputCondition;
  InputConditionType: InputConditionType;
  InputCreateApiClient: InputCreateApiClient;
  InputCreateGenerateCodes: InputCreateGenerateCodes;
  InputCreateOrUpdateBundleGroup: InputCreateOrUpdateBundleGroup;
  InputCreateOrUpdateDiscountCode: InputCreateOrUpdateDiscountCode;
  InputCreateOrUpdateDiscountCodeRule: InputCreateOrUpdateDiscountCodeRule;
  InputCreateOrUpdateDiscountExternalRule: InputCreateOrUpdateDiscountExternalRule;
  InputCreateOrUpdateDiscountRule: InputCreateOrUpdateDiscountRule;
  InputCreateStoreGroup: InputCreateStoreGroup;
  InputCreateStoreMarket: InputCreateStoreMarket;
  InputCreateUser: InputCreateUser;
  InputDiscountValue: InputDiscountValue;
  InputDiscountValueType: InputDiscountValueType;
  InputExternalEventsApiKeyAuth: InputExternalEventsApiKeyAuth;
  InputExternalEventsBasicAuth: InputExternalEventsBasicAuth;
  InputGift: InputGift;
  InputGiftCardProductSelection: InputGiftCardProductSelection;
  InputIngridConfig: InputIngridConfig;
  InputInventory: InputInventory;
  InputInventoryAddress: InputInventoryAddress;
  InputInventoryProductVariantStock: InputInventoryProductVariantStock;
  InputItemRule: InputItemRule;
  InputKBSGiftCardConfig: InputKbsGiftCardConfig;
  InputKlarnaCheckoutConfig: InputKlarnaCheckoutConfig;
  InputLogicHolder: InputLogicHolder;
  InputMarketRoundingRule: InputMarketRoundingRule;
  InputMoney: InputMoney;
  InputNShiftConfig: InputNShiftConfig;
  InputOrderCancellation: InputOrderCancellation;
  InputOrderCompensation: InputOrderCompensation;
  InputOrderCompensationBonus: InputOrderCompensationBonus;
  InputOrderCompensationGiftCard: InputOrderCompensationGiftCard;
  InputOrderCompensationPayment: InputOrderCompensationPayment;
  InputOrderDelivery: InputOrderDelivery;
  InputOrderGiftSelection: InputOrderGiftSelection;
  InputOrderLineCompensation: InputOrderLineCompensation;
  InputOrderLineSelection: InputOrderLineSelection;
  InputOrderRefund: InputOrderRefund;
  InputOrderRefundFee: InputOrderRefundFee;
  InputOrderRelease: InputOrderRelease;
  InputOrderShippingFeeCompensation: InputOrderShippingFeeCompensation;
  InputOrderStartCancellation: InputOrderStartCancellation;
  InputOrderStartCompensation: InputOrderStartCompensation;
  InputOrderStartDelivery: InputOrderStartDelivery;
  InputOrderStartRefund: InputOrderStartRefund;
  InputOrderStartRelease: InputOrderStartRelease;
  InputOutcome: InputOutcome;
  InputOutcomeType: InputOutcomeType;
  InputPriceRule: InputPriceRule;
  InputPriceRuleType: InputPriceRuleType;
  InputProductVariantRules: InputProductVariantRules;
  InputProductVariantStockConfig: InputProductVariantStockConfig;
  InputProvider: InputProvider;
  InputQliroOneConfig: InputQliroOneConfig;
  InputQliroOneIntegratedShipping: InputQliroOneIntegratedShipping;
  InputReason: InputReason;
  InputRemoveUserFromGroup: InputRemoveUserFromGroup;
  InputRetain24Config: InputRetain24Config;
  InputRoundingRule: InputRoundingRule;
  InputRoundingRules: InputRoundingRules;
  InputStoreMarket: InputStoreMarket;
  InputSubOutcome: InputSubOutcome;
  InputSveaCheckoutConfig: InputSveaCheckoutConfig;
  InputTagCondition: InputTagCondition;
  InputTagConditionMode: InputTagConditionMode;
  InputTaxGroup: InputTaxGroup;
  InputTaxMarket: InputTaxMarket;
  InputUpdateApiClient: InputUpdateApiClient;
  InputUpdateStoreGroup: InputUpdateStoreGroup;
  InputUpdateStoreMarket: InputUpdateStoreMarket;
  InputUpdateUser: InputUpdateUser;
  InputValidDateRange: InputValidDateRange;
  InputVoyadoConfig: InputVoyadoConfig;
  InputWalleyCheckoutConfig: InputWalleyCheckoutConfig;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Inventory: ResolverTypeWrapper<Inventory>;
  InventoryAddress: ResolverTypeWrapper<InventoryAddress>;
  InventoryProductVariantStock: ResolverTypeWrapper<InventoryProductVariantStock>;
  InventoryProductVariantStockConfig: ResolverTypeWrapper<InventoryProductVariantStockConfig>;
  InventoryStoreMarket: ResolverTypeWrapper<InventoryStoreMarket>;
  InventoryStoreMarketInventories: ResolverTypeWrapper<InventoryStoreMarketInventories>;
  ItemRule: ResolverTypeWrapper<ItemRule>;
  KBSGiftCardConfig: ResolverTypeWrapper<KbsGiftCardConfig>;
  KBSGiftCardCredentials: ResolverTypeWrapper<KbsGiftCardCredentials>;
  KeyValue: ResolverTypeWrapper<KeyValue>;
  KlarnaCheckoutConfig: ResolverTypeWrapper<KlarnaCheckoutConfig>;
  KlarnaCredentials: ResolverTypeWrapper<KlarnaCredentials>;
  KlarnaShippingAssistantAddon: ResolverTypeWrapper<KlarnaShippingAssistantAddon>;
  KlarnaShippingAssistantAddress: ResolverTypeWrapper<KlarnaShippingAssistantAddress>;
  KlarnaShippingAssistantCarrierProduct: ResolverTypeWrapper<KlarnaShippingAssistantCarrierProduct>;
  KlarnaShippingAssistantLocation: ResolverTypeWrapper<KlarnaShippingAssistantLocation>;
  KlarnaShippingAssistantLocationTimeslot: ResolverTypeWrapper<KlarnaShippingAssistantLocationTimeslot>;
  KlarnaShippingAssistantSelectedShippingOption: ResolverTypeWrapper<KlarnaShippingAssistantSelectedShippingOption>;
  LogicHolder: ResolverTypeWrapper<LogicHolder>;
  MarketPrice: ResolverTypeWrapper<MarketPrice>;
  MarketRoundingRule: ResolverTypeWrapper<MarketRoundingRule>;
  Money: ResolverTypeWrapper<Money>;
  Mutation: ResolverTypeWrapper<{}>;
  MutationOrderManagement: ResolverTypeWrapper<MutationOrderManagement>;
  NShiftConfig: ResolverTypeWrapper<NShiftConfig>;
  NShiftCredentials: ResolverTypeWrapper<NShiftCredentials>;
  NshiftAddress: ResolverTypeWrapper<NshiftAddress>;
  NshiftPreparedShipment: ResolverTypeWrapper<NshiftPreparedShipment>;
  NshiftService: ResolverTypeWrapper<NshiftService>;
  OperationResult: ResolverTypeWrapper<OperationResult>;
  Order: ResolverTypeWrapper<Order>;
  OrderAddress: ResolverTypeWrapper<OrderAddress>;
  OrderAttributes: ResolverTypeWrapper<OrderAttributes>;
  OrderBonus: ResolverTypeWrapper<OrderBonus>;
  OrderBundle: ResolverTypeWrapper<OrderBundle>;
  OrderCancellation: ResolverTypeWrapper<OrderCancellation>;
  OrderCompensation: ResolverTypeWrapper<OrderCompensation>;
  OrderCompensationTotals: ResolverTypeWrapper<OrderCompensationTotals>;
  OrderDelivery: ResolverTypeWrapper<OrderDelivery>;
  OrderDeliveryTotals: ResolverTypeWrapper<OrderDeliveryTotals>;
  OrderDiscountCodeRule: ResolverTypeWrapper<OrderDiscountCodeRule>;
  OrderDiscountExternalRule: ResolverTypeWrapper<OrderDiscountExternalRule>;
  OrderDiscountOutcome: ResolverTypeWrapper<OrderDiscountOutcome>;
  OrderDiscountRule: ResolverTypeWrapper<OrderDiscountRule>;
  OrderGift: ResolverTypeWrapper<OrderGift>;
  OrderGiftCard: ResolversTypes['OrderGiftCardBSGGiftCard'] | ResolversTypes['OrderGiftCardEmpty'] | ResolversTypes['OrderGiftCardKBSGiftCard'] | ResolversTypes['OrderGiftCardRetain24'];
  OrderGiftCardBSGGiftCard: ResolverTypeWrapper<OrderGiftCardBsgGiftCard>;
  OrderGiftCardEmpty: ResolverTypeWrapper<OrderGiftCardEmpty>;
  OrderGiftCardItemBSGGiftCard: ResolverTypeWrapper<OrderGiftCardItemBsgGiftCard>;
  OrderGiftCardItemKBSGiftCard: ResolverTypeWrapper<OrderGiftCardItemKbsGiftCard>;
  OrderGiftCardItemRetain24: ResolverTypeWrapper<OrderGiftCardItemRetain24>;
  OrderGiftCardKBSGiftCard: ResolverTypeWrapper<OrderGiftCardKbsGiftCard>;
  OrderGiftCardProduct: ResolversTypes['OrderGiftCardProductEmpty'] | ResolversTypes['OrderGiftCardProductRetain24'];
  OrderGiftCardProductEmpty: ResolverTypeWrapper<OrderGiftCardProductEmpty>;
  OrderGiftCardProductItem: ResolversTypes['OrderGiftCardProductItemRetain24'];
  OrderGiftCardProductItemRetain24: ResolverTypeWrapper<OrderGiftCardProductItemRetain24>;
  OrderGiftCardProductRetain24: ResolverTypeWrapper<OrderGiftCardProductRetain24>;
  OrderGiftCardRetain24: ResolverTypeWrapper<OrderGiftCardRetain24>;
  OrderLine: ResolverTypeWrapper<OrderLine>;
  OrderLineDiscountOutcome: ResolverTypeWrapper<OrderLineDiscountOutcome>;
  OrderOperationResult: ResolverTypeWrapper<OrderOperationResult>;
  OrderPayment: ResolversTypes['OrderPaymentAdyen'] | ResolversTypes['OrderPaymentBrinkZeroPayment'] | ResolversTypes['OrderPaymentKlarnaCheckout'] | ResolversTypes['OrderPaymentQliroOne'] | ResolversTypes['OrderPaymentSveaCheckout'] | ResolversTypes['OrderPaymentWalleyCheckout'];
  OrderPaymentAdyen: ResolverTypeWrapper<OrderPaymentAdyen>;
  OrderPaymentBrinkZeroPayment: ResolverTypeWrapper<OrderPaymentBrinkZeroPayment>;
  OrderPaymentBrinkZeroPaymentOriginalProvider: ResolverTypeWrapper<OrderPaymentBrinkZeroPaymentOriginalProvider>;
  OrderPaymentKlarnaCheckout: ResolverTypeWrapper<OrderPaymentKlarnaCheckout>;
  OrderPaymentQliroOne: ResolverTypeWrapper<OrderPaymentQliroOne>;
  OrderPaymentQliroOneOrderItem: ResolverTypeWrapper<OrderPaymentQliroOneOrderItem>;
  OrderPaymentSveaCheckout: ResolverTypeWrapper<OrderPaymentSveaCheckout>;
  OrderPaymentWalleyCheckout: ResolverTypeWrapper<OrderPaymentWalleyCheckout>;
  OrderPromotion: ResolversTypes['OrderPromotionEmpty'] | ResolversTypes['OrderPromotionVoyado'];
  OrderPromotionEmpty: ResolverTypeWrapper<OrderPromotionEmpty>;
  OrderPromotionItemVoyado: ResolverTypeWrapper<OrderPromotionItemVoyado>;
  OrderPromotionVoyado: ResolverTypeWrapper<OrderPromotionVoyado>;
  OrderRefund: ResolverTypeWrapper<OrderRefund>;
  OrderRefundTotals: ResolverTypeWrapper<OrderRefundTotals>;
  OrderRelease: ResolverTypeWrapper<OrderRelease>;
  OrderReservation: ResolverTypeWrapper<OrderReservation>;
  OrderSearchHit: ResolverTypeWrapper<OrderSearchHit>;
  OrderSearchHits: ResolverTypeWrapper<OrderSearchHits>;
  OrderShipping: ResolversTypes['OrderShippingEmpty'] | ResolversTypes['OrderShippingIngrid'] | ResolversTypes['OrderShippingKlarnaShippingAssistant'] | ResolversTypes['OrderShippingNshift'] | ResolversTypes['OrderShippingQliroIntegratedShipping'] | ResolversTypes['OrderShippingWalleyDeliveryModule'];
  OrderShippingEmpty: ResolverTypeWrapper<OrderShippingEmpty>;
  OrderShippingIngrid: ResolverTypeWrapper<OrderShippingIngrid>;
  OrderShippingKlarnaShippingAssistant: ResolverTypeWrapper<OrderShippingKlarnaShippingAssistant>;
  OrderShippingNshift: ResolverTypeWrapper<OrderShippingNshift>;
  OrderShippingQliroIntegratedShipping: ResolverTypeWrapper<OrderShippingQliroIntegratedShipping>;
  OrderShippingWalleyDeliveryModule: ResolverTypeWrapper<OrderShippingWalleyDeliveryModule>;
  OrderState: OrderState;
  OrderStatus: ResolverTypeWrapper<OrderStatus>;
  OrderStatusLogEntry: ResolverTypeWrapper<OrderStatusLogEntry>;
  OrderStatusOperations: ResolverTypeWrapper<OrderStatusOperations>;
  OrderStatusTransactionBonus: ResolverTypeWrapper<OrderStatusTransactionBonus>;
  OrderStatusTransactionGift: ResolverTypeWrapper<OrderStatusTransactionGift>;
  OrderStatusTransactionGiftCard: ResolverTypeWrapper<OrderStatusTransactionGiftCard>;
  OrderStatusTransactionGiftCardProduct: ResolverTypeWrapper<OrderStatusTransactionGiftCardProduct>;
  OrderStatusTransactionOrderLine: ResolverTypeWrapper<OrderStatusTransactionOrderLine>;
  OrderStatusTransactionPayment: ResolverTypeWrapper<OrderStatusTransactionPayment>;
  OrderStatusTransactionShippingFee: ResolverTypeWrapper<OrderStatusTransactionShippingFee>;
  OrderStatusTransactionVoucher: ResolverTypeWrapper<OrderStatusTransactionVoucher>;
  OrderStatusTransactions: ResolverTypeWrapper<OrderStatusTransactions>;
  OrderStatusTransactionsCompensated: ResolverTypeWrapper<OrderStatusTransactionsCompensated>;
  OrderTotals: ResolverTypeWrapper<OrderTotals>;
  OrderVoucher: ResolversTypes['OrderVoucherEmpty'] | ResolversTypes['OrderVoucherVoyado'];
  OrderVoucherEmpty: ResolverTypeWrapper<OrderVoucherEmpty>;
  OrderVoucherItemVoyado: ResolverTypeWrapper<OrderVoucherItemVoyado>;
  OrderVoucherVoyado: ResolverTypeWrapper<OrderVoucherVoyado>;
  Outcome: ResolverTypeWrapper<Outcome>;
  PaymentProvider: ResolverTypeWrapper<PaymentProvider>;
  PaymentProviderName: PaymentProviderName;
  PlatformConfig: ResolverTypeWrapper<PlatformConfig>;
  Price: ResolverTypeWrapper<Price>;
  PriceRecord: ResolverTypeWrapper<PriceRecord>;
  PriceRule: ResolverTypeWrapper<PriceRule>;
  ProductParent: ResolverTypeWrapper<ProductParent>;
  ProductParentSearchHit: ResolverTypeWrapper<ProductParentSearchHit>;
  ProductParentSearchHits: ResolverTypeWrapper<ProductParentSearchHits>;
  ProductVariant: ResolverTypeWrapper<ProductVariant>;
  ProductVariantReservation: ResolverTypeWrapper<ProductVariantReservation>;
  ProductVariantRules: ResolverTypeWrapper<ProductVariantRules>;
  ProductVariantSearchHit: ResolverTypeWrapper<ProductVariantSearchHit>;
  ProductVariantSearchHits: ResolverTypeWrapper<ProductVariantSearchHits>;
  PromotionProvider: ResolverTypeWrapper<PromotionProvider>;
  PromotionProviderName: PromotionProviderName;
  Provider: ResolverTypeWrapper<Provider>;
  ProviderStatusLog: ResolverTypeWrapper<ProviderStatusLog>;
  ProviderStatusLogEntry: ResolverTypeWrapper<ProviderStatusLogEntry>;
  QliroOneConfig: ResolverTypeWrapper<QliroOneConfig>;
  QliroOneCredentials: ResolverTypeWrapper<QliroOneCredentials>;
  QliroOneIntegratedShipping: ResolverTypeWrapper<QliroOneIntegratedShipping>;
  QliroShipmentOrderItem: ResolverTypeWrapper<QliroShipmentOrderItem>;
  QliroShipmentOrderItemMetadata: ResolverTypeWrapper<QliroShipmentOrderItemMetadata>;
  Query: ResolverTypeWrapper<{}>;
  QueryConfigurationManagement: ResolverTypeWrapper<QueryConfigurationManagement>;
  QueryConfigurationManagementValidate: ResolverTypeWrapper<QueryConfigurationManagementValidate>;
  QueryOrderManagement: ResolverTypeWrapper<QueryOrderManagement>;
  QueryPlatformConfig: ResolverTypeWrapper<QueryPlatformConfig>;
  QueryProductManagement: ResolverTypeWrapper<QueryProductManagement>;
  QuotaPeriod: QuotaPeriod;
  Reason: ResolverTypeWrapper<Reason>;
  RefundBonus: ResolverTypeWrapper<RefundBonus>;
  RefundFee: ResolverTypeWrapper<RefundFee>;
  RefundGift: ResolverTypeWrapper<RefundGift>;
  RefundGiftCard: ResolverTypeWrapper<RefundGiftCard>;
  RefundOrderLine: ResolverTypeWrapper<RefundOrderLine>;
  RefundShippingFee: ResolverTypeWrapper<RefundShippingFee>;
  RefundVoucher: ResolverTypeWrapper<RefundVoucher>;
  RefundedPayment: ResolverTypeWrapper<RefundedPayment>;
  ReleaseBonus: ResolverTypeWrapper<ReleaseBonus>;
  ReleaseGift: ResolverTypeWrapper<ReleaseGift>;
  ReleaseGiftCard: ResolverTypeWrapper<ReleaseGiftCard>;
  ReleaseGiftCardProduct: ResolverTypeWrapper<ReleaseGiftCardProduct>;
  ReleaseOrderLine: ResolverTypeWrapper<ReleaseOrderLine>;
  ReleaseShippingFee: ResolverTypeWrapper<ReleaseShippingFee>;
  ReleasedPayment: ResolverTypeWrapper<ReleasedPayment>;
  Retain24Config: ResolverTypeWrapper<Retain24Config>;
  Retain24Credentials: ResolverTypeWrapper<Retain24Credentials>;
  RoundingRule: ResolverTypeWrapper<RoundingRule>;
  RoundingRules: ResolverTypeWrapper<RoundingRules>;
  Rule: ResolverTypeWrapper<Rule>;
  Schema: ResolverTypeWrapper<Schema>;
  SearchSorting: SearchSorting;
  ShippingFee: ResolverTypeWrapper<ShippingFee>;
  ShippingProvider: ResolverTypeWrapper<ShippingProvider>;
  ShippingProviderName: ShippingProviderName;
  ShippingTaxGroup: ResolverTypeWrapper<ShippingTaxGroup>;
  SortOrder: SortOrder;
  Stock: ResolverTypeWrapper<Stock>;
  StockInventory: ResolverTypeWrapper<StockInventory>;
  StoreGroup: ResolverTypeWrapper<StoreGroup>;
  StoreGroupPrice: ResolverTypeWrapper<StoreGroupPrice>;
  StoreGroupSearchHit: ResolverTypeWrapper<StoreGroupSearchHit>;
  StoreGroupSearchHits: ResolverTypeWrapper<StoreGroupSearchHits>;
  StoreMarket: ResolverTypeWrapper<StoreMarket>;
  StoreMarketSearchHit: ResolverTypeWrapper<StoreMarketSearchHit>;
  StoreMarketSearchHits: ResolverTypeWrapper<StoreMarketSearchHits>;
  String: ResolverTypeWrapper<Scalars['String']>;
  SubOutcome: ResolverTypeWrapper<SubOutcome>;
  Subscription: ResolverTypeWrapper<{}>;
  SveaCheckoutConfig: ResolverTypeWrapper<SveaCheckoutConfig>;
  SveaCredentials: ResolverTypeWrapper<SveaCredentials>;
  TagCondition: ResolverTypeWrapper<TagCondition>;
  TagKeys: ResolverTypeWrapper<TagKeys>;
  TagValues: ResolverTypeWrapper<TagValues>;
  TaxGroup: ResolverTypeWrapper<TaxGroup>;
  TaxGroupSearchHit: ResolverTypeWrapper<TaxGroupSearchHit>;
  TaxGroupSearchHits: ResolverTypeWrapper<TaxGroupSearchHits>;
  TaxMarket: ResolverTypeWrapper<TaxMarket>;
  TaxMarketSearchHit: ResolverTypeWrapper<TaxMarketSearchHit>;
  TaxMarketSearchHits: ResolverTypeWrapper<TaxMarketSearchHits>;
  UsagePlan: ResolverTypeWrapper<UsagePlan>;
  User: ResolverTypeWrapper<User>;
  UserDetails: ResolverTypeWrapper<UserDetails>;
  UserStatus: UserStatus;
  UsersList: ResolverTypeWrapper<UsersList>;
  ValidDateRange: ResolverTypeWrapper<ValidDateRange>;
  ValidateConfigResult: ResolverTypeWrapper<ValidateConfigResult>;
  VoucherProvider: ResolverTypeWrapper<VoucherProvider>;
  VoucherProviderName: VoucherProviderName;
  VoyadoConfig: ResolverTypeWrapper<VoyadoConfig>;
  VoyadoCredentials: ResolverTypeWrapper<VoyadoCredentials>;
  WalleyCheckoutConfig: ResolverTypeWrapper<WalleyCheckoutConfig>;
  WalleyCredentials: ResolverTypeWrapper<WalleyCredentials>;
  WalleyDeliveryModuleDeliveryDate: ResolverTypeWrapper<WalleyDeliveryModuleDeliveryDate>;
  WalleyDeliveryModuleDestination: ResolverTypeWrapper<WalleyDeliveryModuleDestination>;
  WalleyDeliveryModuleOption: ResolverTypeWrapper<WalleyDeliveryModuleOption>;
  WalleyDeliveryModuleShipment: ResolverTypeWrapper<WalleyDeliveryModuleShipment>;
  WalleyDeliveryModuleShipping: ResolverTypeWrapper<WalleyDeliveryModuleShipping>;
  WalleyDeliveryModuleShippingChoice: ResolverTypeWrapper<WalleyDeliveryModuleShippingChoice>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AWSDateTime: Scalars['AWSDateTime'];
  AWSEmail: Scalars['AWSEmail'];
  AWSJSON: Scalars['AWSJSON'];
  AddExternalEventsDestinationAPIResult: AddExternalEventsDestinationApiResult;
  AddExternalEventsDestinationEventBusResult: AddExternalEventsDestinationEventBusResult;
  AddExternalEventsRuleResult: AddExternalEventsRuleResult;
  AdyenConfig: AdyenConfig;
  AdyenCredentials: AdyenCredentials;
  AdyenWebhook: AdyenWebhook;
  AdyenWebhookCredentials: AdyenWebhookCredentials;
  AdyenWebhookGenerationResult: AdyenWebhookGenerationResult;
  AdyenWebhookLegacy: AdyenWebhookLegacy;
  ApiClient: ApiClient;
  ApiClientDetails: ApiClientDetails;
  ApiClientsList: ApiClientsList;
  ApiResource: ApiResource;
  ApiScope: ApiScope;
  BSGGiftCardConfig: BsgGiftCardConfig;
  BSGGiftCardCredentials: BsgGiftCardCredentials;
  BonusConfig: BonusConfig;
  BonusCredentials: BonusCredentials;
  BonusProvider: BonusProvider;
  Boolean: Scalars['Boolean'];
  Bundle: Bundle;
  BundleGroup: BundleGroup;
  BundleGroupSearchHit: BundleGroupSearchHit;
  BundleGroupSearchHits: BundleGroupSearchHits;
  BundleItemRule: BundleItemRule;
  BundleOutcome: BundleOutcome;
  BundleStoreMarket: BundleStoreMarket;
  Campaign: Campaign;
  CampaignDeletionResult: CampaignDeletionResult;
  CampaignGroup: CampaignGroup;
  CampaignGroupList: CampaignGroupList;
  CampaignGroupSearchHit: CampaignGroupSearchHit;
  CampaignGroupSearchHits: CampaignGroupSearchHits;
  CampaignIdentity: CampaignIdentity;
  CampaignMoney: CampaignMoney;
  CampaignSearchHit: CampaignSearchHit;
  CampaignSearchHits: CampaignSearchHits;
  CampaignStoreMarket: CampaignStoreMarket;
  CancellationBonus: CancellationBonus;
  CancellationGiftCard: CancellationGiftCard;
  CancellationGiftCardProduct: CancellationGiftCardProduct;
  CancelledPayment: CancelledPayment;
  Capabilities: Capabilities;
  CapabilityProvider: CapabilityProvider;
  CartCapabilities: CartCapabilities;
  CartRuleOutcome: CartRuleOutcome;
  CodeRuleOutcome: CodeRuleOutcome;
  CodesGenerationSearchHit: CodesGenerationSearchHit;
  CodesGenerationSearchHits: CodesGenerationSearchHits;
  CompanyAttribute: CompanyAttribute;
  CompensationBonus: CompensationBonus;
  CompensationGiftCard: CompensationGiftCard;
  CompensationOrderLine: CompensationOrderLine;
  CompensationPayment: CompensationPayment;
  CompensationShippingFee: CompensationShippingFee;
  Condition: Condition;
  CreateGenerateCodesResult: CreateGenerateCodesResult;
  DeletedResult: DeletedResult;
  DeliveryBonus: DeliveryBonus;
  DeliveryCapture: DeliveryCapture;
  DeliveryGift: DeliveryGift;
  DeliveryGiftCard: DeliveryGiftCard;
  DeliveryGiftCardProduct: DeliveryGiftCardProduct;
  DeliveryOrderLine: DeliveryOrderLine;
  DeliveryShippingFee: DeliveryShippingFee;
  DeliveryTracking: DeliveryTracking;
  DeliveryVoucher: DeliveryVoucher;
  Dimensions: Dimensions;
  DiscountCode: DiscountCode;
  DiscountCodeRule: DiscountCodeRule;
  DiscountCodeRuleSearchHit: DiscountCodeRuleSearchHit;
  DiscountCodeRuleSearchHits: DiscountCodeRuleSearchHits;
  DiscountCodeSearchHit: DiscountCodeSearchHit;
  DiscountCodeSearchHits: DiscountCodeSearchHits;
  DiscountExternalRule: DiscountExternalRule;
  DiscountExternalRuleSearchHit: DiscountExternalRuleSearchHit;
  DiscountExternalRuleSearchHits: DiscountExternalRuleSearchHits;
  DiscountManagementQuotas: DiscountManagementQuotas;
  DiscountRule: DiscountRule;
  DiscountRuleSearchHit: DiscountRuleSearchHit;
  DiscountRuleSearchHits: DiscountRuleSearchHits;
  DiscountValue: DiscountValue;
  ExternalApi: ExternalApi;
  ExternalEventDestinationAPIs: ExternalEventDestinationApIs;
  ExternalEventDestinationEventBuses: ExternalEventDestinationEventBuses;
  ExternalEventDestinationsDetails: ExternalEventDestinationsDetails;
  ExternalEventRuleState: ExternalEventRuleState;
  ExternalEventRules: ExternalEventRules;
  ExternalRuleOutcome: ExternalRuleOutcome;
  Float: Scalars['Float'];
  GenerateCodes: GenerateCodes;
  Gift: Gift;
  GiftCardProductProvider: GiftCardProductProvider;
  GiftCardProvider: GiftCardProvider;
  Group: Group;
  GroupDetails: GroupDetails;
  GroupsList: GroupsList;
  ID: Scalars['ID'];
  IngridConfig: IngridConfig;
  IngridCredentials: IngridCredentials;
  IngridDeliveries: IngridDeliveries;
  IngridDeliveryAddress: IngridDeliveryAddress;
  InputActionBonus: InputActionBonus;
  InputActionGiftCard: InputActionGiftCard;
  InputActionGiftCardManual: InputActionGiftCardManual;
  InputActionPaymentCancellation: InputActionPaymentCancellation;
  InputActionPaymentCompensation: InputActionPaymentCompensation;
  InputActionPaymentDelivery: InputActionPaymentDelivery;
  InputActionPaymentRefund: InputActionPaymentRefund;
  InputActionPaymentRelease: InputActionPaymentRelease;
  InputActionShipping: InputActionShipping;
  InputAddExternalEventsDestinationAPI: InputAddExternalEventsDestinationApi;
  InputAddUserToGroup: InputAddUserToGroup;
  InputAdyenConfig: InputAdyenConfig;
  InputAdyenWebhookChange: InputAdyenWebhookChange;
  InputBSGGiftCardConfig: InputBsgGiftCardConfig;
  InputBonusConfig: InputBonusConfig;
  InputBundle: InputBundle;
  InputBundleItemRule: InputBundleItemRule;
  InputCampaign: InputCampaign;
  InputCampaignGroup: InputCampaignGroup;
  InputCampaignMoney: InputCampaignMoney;
  InputCampaignPeriod: InputCampaignPeriod;
  InputCapabilities: InputCapabilities;
  InputCapabilityProvider: InputCapabilityProvider;
  InputCartCapabilities: InputCartCapabilities;
  InputCondition: InputCondition;
  InputCreateApiClient: InputCreateApiClient;
  InputCreateGenerateCodes: InputCreateGenerateCodes;
  InputCreateOrUpdateBundleGroup: InputCreateOrUpdateBundleGroup;
  InputCreateOrUpdateDiscountCode: InputCreateOrUpdateDiscountCode;
  InputCreateOrUpdateDiscountCodeRule: InputCreateOrUpdateDiscountCodeRule;
  InputCreateOrUpdateDiscountExternalRule: InputCreateOrUpdateDiscountExternalRule;
  InputCreateOrUpdateDiscountRule: InputCreateOrUpdateDiscountRule;
  InputCreateStoreGroup: InputCreateStoreGroup;
  InputCreateStoreMarket: InputCreateStoreMarket;
  InputCreateUser: InputCreateUser;
  InputDiscountValue: InputDiscountValue;
  InputExternalEventsApiKeyAuth: InputExternalEventsApiKeyAuth;
  InputExternalEventsBasicAuth: InputExternalEventsBasicAuth;
  InputGift: InputGift;
  InputGiftCardProductSelection: InputGiftCardProductSelection;
  InputIngridConfig: InputIngridConfig;
  InputInventory: InputInventory;
  InputInventoryAddress: InputInventoryAddress;
  InputInventoryProductVariantStock: InputInventoryProductVariantStock;
  InputItemRule: InputItemRule;
  InputKBSGiftCardConfig: InputKbsGiftCardConfig;
  InputKlarnaCheckoutConfig: InputKlarnaCheckoutConfig;
  InputLogicHolder: InputLogicHolder;
  InputMarketRoundingRule: InputMarketRoundingRule;
  InputMoney: InputMoney;
  InputNShiftConfig: InputNShiftConfig;
  InputOrderCancellation: InputOrderCancellation;
  InputOrderCompensation: InputOrderCompensation;
  InputOrderCompensationBonus: InputOrderCompensationBonus;
  InputOrderCompensationGiftCard: InputOrderCompensationGiftCard;
  InputOrderCompensationPayment: InputOrderCompensationPayment;
  InputOrderDelivery: InputOrderDelivery;
  InputOrderGiftSelection: InputOrderGiftSelection;
  InputOrderLineCompensation: InputOrderLineCompensation;
  InputOrderLineSelection: InputOrderLineSelection;
  InputOrderRefund: InputOrderRefund;
  InputOrderRefundFee: InputOrderRefundFee;
  InputOrderRelease: InputOrderRelease;
  InputOrderShippingFeeCompensation: InputOrderShippingFeeCompensation;
  InputOrderStartCancellation: InputOrderStartCancellation;
  InputOrderStartCompensation: InputOrderStartCompensation;
  InputOrderStartDelivery: InputOrderStartDelivery;
  InputOrderStartRefund: InputOrderStartRefund;
  InputOrderStartRelease: InputOrderStartRelease;
  InputOutcome: InputOutcome;
  InputPriceRule: InputPriceRule;
  InputProductVariantRules: InputProductVariantRules;
  InputProductVariantStockConfig: InputProductVariantStockConfig;
  InputProvider: InputProvider;
  InputQliroOneConfig: InputQliroOneConfig;
  InputQliroOneIntegratedShipping: InputQliroOneIntegratedShipping;
  InputReason: InputReason;
  InputRemoveUserFromGroup: InputRemoveUserFromGroup;
  InputRetain24Config: InputRetain24Config;
  InputRoundingRule: InputRoundingRule;
  InputRoundingRules: InputRoundingRules;
  InputStoreMarket: InputStoreMarket;
  InputSubOutcome: InputSubOutcome;
  InputSveaCheckoutConfig: InputSveaCheckoutConfig;
  InputTagCondition: InputTagCondition;
  InputTaxGroup: InputTaxGroup;
  InputTaxMarket: InputTaxMarket;
  InputUpdateApiClient: InputUpdateApiClient;
  InputUpdateStoreGroup: InputUpdateStoreGroup;
  InputUpdateStoreMarket: InputUpdateStoreMarket;
  InputUpdateUser: InputUpdateUser;
  InputValidDateRange: InputValidDateRange;
  InputVoyadoConfig: InputVoyadoConfig;
  InputWalleyCheckoutConfig: InputWalleyCheckoutConfig;
  Int: Scalars['Int'];
  Inventory: Inventory;
  InventoryAddress: InventoryAddress;
  InventoryProductVariantStock: InventoryProductVariantStock;
  InventoryProductVariantStockConfig: InventoryProductVariantStockConfig;
  InventoryStoreMarket: InventoryStoreMarket;
  InventoryStoreMarketInventories: InventoryStoreMarketInventories;
  ItemRule: ItemRule;
  KBSGiftCardConfig: KbsGiftCardConfig;
  KBSGiftCardCredentials: KbsGiftCardCredentials;
  KeyValue: KeyValue;
  KlarnaCheckoutConfig: KlarnaCheckoutConfig;
  KlarnaCredentials: KlarnaCredentials;
  KlarnaShippingAssistantAddon: KlarnaShippingAssistantAddon;
  KlarnaShippingAssistantAddress: KlarnaShippingAssistantAddress;
  KlarnaShippingAssistantCarrierProduct: KlarnaShippingAssistantCarrierProduct;
  KlarnaShippingAssistantLocation: KlarnaShippingAssistantLocation;
  KlarnaShippingAssistantLocationTimeslot: KlarnaShippingAssistantLocationTimeslot;
  KlarnaShippingAssistantSelectedShippingOption: KlarnaShippingAssistantSelectedShippingOption;
  LogicHolder: LogicHolder;
  MarketPrice: MarketPrice;
  MarketRoundingRule: MarketRoundingRule;
  Money: Money;
  Mutation: {};
  MutationOrderManagement: MutationOrderManagement;
  NShiftConfig: NShiftConfig;
  NShiftCredentials: NShiftCredentials;
  NshiftAddress: NshiftAddress;
  NshiftPreparedShipment: NshiftPreparedShipment;
  NshiftService: NshiftService;
  OperationResult: OperationResult;
  Order: Order;
  OrderAddress: OrderAddress;
  OrderAttributes: OrderAttributes;
  OrderBonus: OrderBonus;
  OrderBundle: OrderBundle;
  OrderCancellation: OrderCancellation;
  OrderCompensation: OrderCompensation;
  OrderCompensationTotals: OrderCompensationTotals;
  OrderDelivery: OrderDelivery;
  OrderDeliveryTotals: OrderDeliveryTotals;
  OrderDiscountCodeRule: OrderDiscountCodeRule;
  OrderDiscountExternalRule: OrderDiscountExternalRule;
  OrderDiscountOutcome: OrderDiscountOutcome;
  OrderDiscountRule: OrderDiscountRule;
  OrderGift: OrderGift;
  OrderGiftCard: ResolversParentTypes['OrderGiftCardBSGGiftCard'] | ResolversParentTypes['OrderGiftCardEmpty'] | ResolversParentTypes['OrderGiftCardKBSGiftCard'] | ResolversParentTypes['OrderGiftCardRetain24'];
  OrderGiftCardBSGGiftCard: OrderGiftCardBsgGiftCard;
  OrderGiftCardEmpty: OrderGiftCardEmpty;
  OrderGiftCardItemBSGGiftCard: OrderGiftCardItemBsgGiftCard;
  OrderGiftCardItemKBSGiftCard: OrderGiftCardItemKbsGiftCard;
  OrderGiftCardItemRetain24: OrderGiftCardItemRetain24;
  OrderGiftCardKBSGiftCard: OrderGiftCardKbsGiftCard;
  OrderGiftCardProduct: ResolversParentTypes['OrderGiftCardProductEmpty'] | ResolversParentTypes['OrderGiftCardProductRetain24'];
  OrderGiftCardProductEmpty: OrderGiftCardProductEmpty;
  OrderGiftCardProductItem: ResolversParentTypes['OrderGiftCardProductItemRetain24'];
  OrderGiftCardProductItemRetain24: OrderGiftCardProductItemRetain24;
  OrderGiftCardProductRetain24: OrderGiftCardProductRetain24;
  OrderGiftCardRetain24: OrderGiftCardRetain24;
  OrderLine: OrderLine;
  OrderLineDiscountOutcome: OrderLineDiscountOutcome;
  OrderOperationResult: OrderOperationResult;
  OrderPayment: ResolversParentTypes['OrderPaymentAdyen'] | ResolversParentTypes['OrderPaymentBrinkZeroPayment'] | ResolversParentTypes['OrderPaymentKlarnaCheckout'] | ResolversParentTypes['OrderPaymentQliroOne'] | ResolversParentTypes['OrderPaymentSveaCheckout'] | ResolversParentTypes['OrderPaymentWalleyCheckout'];
  OrderPaymentAdyen: OrderPaymentAdyen;
  OrderPaymentBrinkZeroPayment: OrderPaymentBrinkZeroPayment;
  OrderPaymentBrinkZeroPaymentOriginalProvider: OrderPaymentBrinkZeroPaymentOriginalProvider;
  OrderPaymentKlarnaCheckout: OrderPaymentKlarnaCheckout;
  OrderPaymentQliroOne: OrderPaymentQliroOne;
  OrderPaymentQliroOneOrderItem: OrderPaymentQliroOneOrderItem;
  OrderPaymentSveaCheckout: OrderPaymentSveaCheckout;
  OrderPaymentWalleyCheckout: OrderPaymentWalleyCheckout;
  OrderPromotion: ResolversParentTypes['OrderPromotionEmpty'] | ResolversParentTypes['OrderPromotionVoyado'];
  OrderPromotionEmpty: OrderPromotionEmpty;
  OrderPromotionItemVoyado: OrderPromotionItemVoyado;
  OrderPromotionVoyado: OrderPromotionVoyado;
  OrderRefund: OrderRefund;
  OrderRefundTotals: OrderRefundTotals;
  OrderRelease: OrderRelease;
  OrderReservation: OrderReservation;
  OrderSearchHit: OrderSearchHit;
  OrderSearchHits: OrderSearchHits;
  OrderShipping: ResolversParentTypes['OrderShippingEmpty'] | ResolversParentTypes['OrderShippingIngrid'] | ResolversParentTypes['OrderShippingKlarnaShippingAssistant'] | ResolversParentTypes['OrderShippingNshift'] | ResolversParentTypes['OrderShippingQliroIntegratedShipping'] | ResolversParentTypes['OrderShippingWalleyDeliveryModule'];
  OrderShippingEmpty: OrderShippingEmpty;
  OrderShippingIngrid: OrderShippingIngrid;
  OrderShippingKlarnaShippingAssistant: OrderShippingKlarnaShippingAssistant;
  OrderShippingNshift: OrderShippingNshift;
  OrderShippingQliroIntegratedShipping: OrderShippingQliroIntegratedShipping;
  OrderShippingWalleyDeliveryModule: OrderShippingWalleyDeliveryModule;
  OrderStatus: OrderStatus;
  OrderStatusLogEntry: OrderStatusLogEntry;
  OrderStatusOperations: OrderStatusOperations;
  OrderStatusTransactionBonus: OrderStatusTransactionBonus;
  OrderStatusTransactionGift: OrderStatusTransactionGift;
  OrderStatusTransactionGiftCard: OrderStatusTransactionGiftCard;
  OrderStatusTransactionGiftCardProduct: OrderStatusTransactionGiftCardProduct;
  OrderStatusTransactionOrderLine: OrderStatusTransactionOrderLine;
  OrderStatusTransactionPayment: OrderStatusTransactionPayment;
  OrderStatusTransactionShippingFee: OrderStatusTransactionShippingFee;
  OrderStatusTransactionVoucher: OrderStatusTransactionVoucher;
  OrderStatusTransactions: OrderStatusTransactions;
  OrderStatusTransactionsCompensated: OrderStatusTransactionsCompensated;
  OrderTotals: OrderTotals;
  OrderVoucher: ResolversParentTypes['OrderVoucherEmpty'] | ResolversParentTypes['OrderVoucherVoyado'];
  OrderVoucherEmpty: OrderVoucherEmpty;
  OrderVoucherItemVoyado: OrderVoucherItemVoyado;
  OrderVoucherVoyado: OrderVoucherVoyado;
  Outcome: Outcome;
  PaymentProvider: PaymentProvider;
  PlatformConfig: PlatformConfig;
  Price: Price;
  PriceRecord: PriceRecord;
  PriceRule: PriceRule;
  ProductParent: ProductParent;
  ProductParentSearchHit: ProductParentSearchHit;
  ProductParentSearchHits: ProductParentSearchHits;
  ProductVariant: ProductVariant;
  ProductVariantReservation: ProductVariantReservation;
  ProductVariantRules: ProductVariantRules;
  ProductVariantSearchHit: ProductVariantSearchHit;
  ProductVariantSearchHits: ProductVariantSearchHits;
  PromotionProvider: PromotionProvider;
  Provider: Provider;
  ProviderStatusLog: ProviderStatusLog;
  ProviderStatusLogEntry: ProviderStatusLogEntry;
  QliroOneConfig: QliroOneConfig;
  QliroOneCredentials: QliroOneCredentials;
  QliroOneIntegratedShipping: QliroOneIntegratedShipping;
  QliroShipmentOrderItem: QliroShipmentOrderItem;
  QliroShipmentOrderItemMetadata: QliroShipmentOrderItemMetadata;
  Query: {};
  QueryConfigurationManagement: QueryConfigurationManagement;
  QueryConfigurationManagementValidate: QueryConfigurationManagementValidate;
  QueryOrderManagement: QueryOrderManagement;
  QueryPlatformConfig: QueryPlatformConfig;
  QueryProductManagement: QueryProductManagement;
  Reason: Reason;
  RefundBonus: RefundBonus;
  RefundFee: RefundFee;
  RefundGift: RefundGift;
  RefundGiftCard: RefundGiftCard;
  RefundOrderLine: RefundOrderLine;
  RefundShippingFee: RefundShippingFee;
  RefundVoucher: RefundVoucher;
  RefundedPayment: RefundedPayment;
  ReleaseBonus: ReleaseBonus;
  ReleaseGift: ReleaseGift;
  ReleaseGiftCard: ReleaseGiftCard;
  ReleaseGiftCardProduct: ReleaseGiftCardProduct;
  ReleaseOrderLine: ReleaseOrderLine;
  ReleaseShippingFee: ReleaseShippingFee;
  ReleasedPayment: ReleasedPayment;
  Retain24Config: Retain24Config;
  Retain24Credentials: Retain24Credentials;
  RoundingRule: RoundingRule;
  RoundingRules: RoundingRules;
  Rule: Rule;
  Schema: Schema;
  SearchSorting: SearchSorting;
  ShippingFee: ShippingFee;
  ShippingProvider: ShippingProvider;
  ShippingTaxGroup: ShippingTaxGroup;
  Stock: Stock;
  StockInventory: StockInventory;
  StoreGroup: StoreGroup;
  StoreGroupPrice: StoreGroupPrice;
  StoreGroupSearchHit: StoreGroupSearchHit;
  StoreGroupSearchHits: StoreGroupSearchHits;
  StoreMarket: StoreMarket;
  StoreMarketSearchHit: StoreMarketSearchHit;
  StoreMarketSearchHits: StoreMarketSearchHits;
  String: Scalars['String'];
  SubOutcome: SubOutcome;
  Subscription: {};
  SveaCheckoutConfig: SveaCheckoutConfig;
  SveaCredentials: SveaCredentials;
  TagCondition: TagCondition;
  TagKeys: TagKeys;
  TagValues: TagValues;
  TaxGroup: TaxGroup;
  TaxGroupSearchHit: TaxGroupSearchHit;
  TaxGroupSearchHits: TaxGroupSearchHits;
  TaxMarket: TaxMarket;
  TaxMarketSearchHit: TaxMarketSearchHit;
  TaxMarketSearchHits: TaxMarketSearchHits;
  UsagePlan: UsagePlan;
  User: User;
  UserDetails: UserDetails;
  UsersList: UsersList;
  ValidDateRange: ValidDateRange;
  ValidateConfigResult: ValidateConfigResult;
  VoucherProvider: VoucherProvider;
  VoyadoConfig: VoyadoConfig;
  VoyadoCredentials: VoyadoCredentials;
  WalleyCheckoutConfig: WalleyCheckoutConfig;
  WalleyCredentials: WalleyCredentials;
  WalleyDeliveryModuleDeliveryDate: WalleyDeliveryModuleDeliveryDate;
  WalleyDeliveryModuleDestination: WalleyDeliveryModuleDestination;
  WalleyDeliveryModuleOption: WalleyDeliveryModuleOption;
  WalleyDeliveryModuleShipment: WalleyDeliveryModuleShipment;
  WalleyDeliveryModuleShipping: WalleyDeliveryModuleShipping;
  WalleyDeliveryModuleShippingChoice: WalleyDeliveryModuleShippingChoice;
};

export type Aws_Api_KeyDirectiveArgs = { };

export type Aws_Api_KeyDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_Api_KeyDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_AuthDirectiveArgs = {
  cognito_groups?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Aws_AuthDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_AuthDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_Cognito_User_PoolsDirectiveArgs = {
  cognito_groups?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Aws_Cognito_User_PoolsDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_Cognito_User_PoolsDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_IamDirectiveArgs = { };

export type Aws_IamDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_IamDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_LambdaDirectiveArgs = { };

export type Aws_LambdaDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_LambdaDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_OidcDirectiveArgs = { };

export type Aws_OidcDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_OidcDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_PublishDirectiveArgs = {
  subscriptions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Aws_PublishDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_PublishDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_SubscribeDirectiveArgs = {
  mutations?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Aws_SubscribeDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_SubscribeDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type DeferDirectiveArgs = { };

export type DeferDirectiveResolver<Result, Parent, ContextType = any, Args = DeferDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export interface AwsDateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSDateTime'], any> {
  name: 'AWSDateTime';
}

export interface AwsEmailScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSEmail'], any> {
  name: 'AWSEmail';
}

export interface AwsjsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSJSON'], any> {
  name: 'AWSJSON';
}

export type AddExternalEventsDestinationApiResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddExternalEventsDestinationAPIResult'] = ResolversParentTypes['AddExternalEventsDestinationAPIResult']> = {
  apiDestinationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddExternalEventsDestinationEventBusResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddExternalEventsDestinationEventBusResult'] = ResolversParentTypes['AddExternalEventsDestinationEventBusResult']> = {
  eventBusArn?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddExternalEventsRuleResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddExternalEventsRuleResult'] = ResolversParentTypes['AddExternalEventsRuleResult']> = {
  ruleName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdyenConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdyenConfig'] = ResolversParentTypes['AdyenConfig']> = {
  clientKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  credentials?: Resolver<ResolversTypes['AdyenCredentials'], ParentType, ContextType>;
  environment?: Resolver<ResolversTypes['AdyenEnvironment'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  liveEndpointUrlPrefix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  merchantAccount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  webhooks?: Resolver<Maybe<Array<ResolversTypes['AdyenWebhook']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdyenCredentialsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdyenCredentials'] = ResolversParentTypes['AdyenCredentials']> = {
  apiKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hmac?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdyenWebhookResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdyenWebhook'] = ResolversParentTypes['AdyenWebhook']> = {
  configId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  sequenceNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['AdyenWebhookStatus']>, ParentType, ContextType>;
  target?: Resolver<Maybe<ResolversTypes['AdyenWebhookTarget']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdyenWebhookCredentialsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdyenWebhookCredentials'] = ResolversParentTypes['AdyenWebhookCredentials']> = {
  hmac?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  password?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdyenWebhookGenerationResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdyenWebhookGenerationResult'] = ResolversParentTypes['AdyenWebhookGenerationResult']> = {
  started?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdyenWebhookLegacyResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdyenWebhookLegacy'] = ResolversParentTypes['AdyenWebhookLegacy']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApiClientResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApiClient'] = ResolversParentTypes['ApiClient']> = {
  clientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApiClientDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApiClientDetails'] = ResolversParentTypes['ApiClientDetails']> = {
  clientId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  clientSecret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  scopes?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApiClientsListResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApiClientsList'] = ResolversParentTypes['ApiClientsList']> = {
  apiClients?: Resolver<Array<ResolversTypes['ApiClient']>, ParentType, ContextType>;
  nextToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApiResourceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApiResource'] = ResolversParentTypes['ApiResource']> = {
  identifier?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  scopes?: Resolver<Maybe<Array<ResolversTypes['ApiScope']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApiScopeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApiScope'] = ResolversParentTypes['ApiScope']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BsgGiftCardConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['BSGGiftCardConfig'] = ResolversParentTypes['BSGGiftCardConfig']> = {
  baseUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  credentials?: Resolver<ResolversTypes['BSGGiftCardCredentials'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  scopes?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  tokenUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BsgGiftCardCredentialsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BSGGiftCardCredentials'] = ResolversParentTypes['BSGGiftCardCredentials']> = {
  clientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clientSecret?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BonusConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['BonusConfig'] = ResolversParentTypes['BonusConfig']> = {
  authUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  baseUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  credentials?: Resolver<ResolversTypes['BonusCredentials'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BonusCredentialsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BonusCredentials'] = ResolversParentTypes['BonusCredentials']> = {
  clientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clientSecret?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BonusProviderResolvers<ContextType = any, ParentType extends ResolversParentTypes['BonusProvider'] = ResolversParentTypes['BonusProvider']> = {
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['BonusProviderName'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ProviderStatusLog']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BundleResolvers<ContextType = any, ParentType extends ResolversParentTypes['Bundle'] = ResolversParentTypes['Bundle']> = {
  discountValue?: Resolver<ResolversTypes['DiscountValue'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  itemRules?: Resolver<Array<ResolversTypes['BundleItemRule']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BundleGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['BundleGroup'] = ResolversParentTypes['BundleGroup']> = {
  bundles?: Resolver<Array<ResolversTypes['Bundle']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  storeMarkets?: Resolver<Array<Maybe<ResolversTypes['BundleStoreMarket']>>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  validDateRange?: Resolver<Maybe<ResolversTypes['ValidDateRange']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BundleGroupSearchHitResolvers<ContextType = any, ParentType extends ResolversParentTypes['BundleGroupSearchHit'] = ResolversParentTypes['BundleGroupSearchHit']> = {
  bundles?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  validFrom?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  validTo?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BundleGroupSearchHitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BundleGroupSearchHits'] = ResolversParentTypes['BundleGroupSearchHits']> = {
  hits?: Resolver<Array<ResolversTypes['BundleGroupSearchHit']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BundleItemRuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['BundleItemRule'] = ResolversParentTypes['BundleItemRule']> = {
  excludeDiscountedPrices?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  excludeFullPrices?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  productParents?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tagConditions?: Resolver<Array<ResolversTypes['TagCondition']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BundleOutcomeResolvers<ContextType = any, ParentType extends ResolversParentTypes['BundleOutcome'] = ResolversParentTypes['BundleOutcome']> = {
  bundleGroupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bundleId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  discountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BundleStoreMarketResolvers<ContextType = any, ParentType extends ResolversParentTypes['BundleStoreMarket'] = ResolversParentTypes['BundleStoreMarket']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignResolvers<ContextType = any, ParentType extends ResolversParentTypes['Campaign'] = ResolversParentTypes['Campaign']> = {
  campaignGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  priceRule?: Resolver<ResolversTypes['PriceRule'], ParentType, ContextType>;
  productVariantRules?: Resolver<ResolversTypes['ProductVariantRules'], ParentType, ContextType>;
  roundingRules?: Resolver<Maybe<ResolversTypes['RoundingRules']>, ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  storeMarkets?: Resolver<Array<ResolversTypes['CampaignStoreMarket']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignDeletionResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignDeletionResult'] = ResolversParentTypes['CampaignDeletionResult']> = {
  result?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignGroup'] = ResolversParentTypes['CampaignGroup']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignGroupListResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignGroupList'] = ResolversParentTypes['CampaignGroupList']> = {
  campaignGroup?: Resolver<ResolversTypes['CampaignGroup'], ParentType, ContextType>;
  campaigns?: Resolver<Array<ResolversTypes['Campaign']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignGroupSearchHitResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignGroupSearchHit'] = ResolversParentTypes['CampaignGroupSearchHit']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignGroupSearchHitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignGroupSearchHits'] = ResolversParentTypes['CampaignGroupSearchHits']> = {
  hits?: Resolver<Array<ResolversTypes['CampaignGroupSearchHit']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignIdentityResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignIdentity'] = ResolversParentTypes['CampaignIdentity']> = {
  campaignGroupId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  campaignId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignMoneyResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignMoney'] = ResolversParentTypes['CampaignMoney']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignSearchHitResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignSearchHit'] = ResolversParentTypes['CampaignSearchHit']> = {
  campaignGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignSearchHitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignSearchHits'] = ResolversParentTypes['CampaignSearchHits']> = {
  hits?: Resolver<Array<ResolversTypes['CampaignSearchHit']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignStoreMarketResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignStoreMarket'] = ResolversParentTypes['CampaignStoreMarket']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CancellationBonusResolvers<ContextType = any, ParentType extends ResolversParentTypes['CancellationBonus'] = ResolversParentTypes['CancellationBonus']> = {
  reservationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CancellationGiftCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['CancellationGiftCard'] = ResolversParentTypes['CancellationGiftCard']> = {
  giftCardId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  reservationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transactionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CancellationGiftCardProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['CancellationGiftCardProduct'] = ResolversParentTypes['CancellationGiftCardProduct']> = {
  cancelTransactionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  giftCardProductId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CancelledPaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['CancelledPayment'] = ResolversParentTypes['CancelledPayment']> = {
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CapabilitiesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Capabilities'] = ResolversParentTypes['Capabilities']> = {
  giftCardProviders?: Resolver<Maybe<Array<ResolversTypes['Provider']>>, ParentType, ContextType>;
  paymentProviders?: Resolver<Maybe<Array<ResolversTypes['Provider']>>, ParentType, ContextType>;
  shippingProviders?: Resolver<Maybe<Array<ResolversTypes['Provider']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CapabilityProviderResolvers<ContextType = any, ParentType extends ResolversParentTypes['CapabilityProvider'] = ResolversParentTypes['CapabilityProvider']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CartCapabilitiesResolvers<ContextType = any, ParentType extends ResolversParentTypes['CartCapabilities'] = ResolversParentTypes['CartCapabilities']> = {
  bonus?: Resolver<Maybe<ResolversTypes['CapabilityProvider']>, ParentType, ContextType>;
  retain24?: Resolver<Maybe<ResolversTypes['CapabilityProvider']>, ParentType, ContextType>;
  voyado?: Resolver<Maybe<ResolversTypes['CapabilityProvider']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CartRuleOutcomeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CartRuleOutcome'] = ResolversParentTypes['CartRuleOutcome']> = {
  discountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ruleID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CodeRuleOutcomeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CodeRuleOutcome'] = ResolversParentTypes['CodeRuleOutcome']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  discountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ruleID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CodesGenerationSearchHitResolvers<ContextType = any, ParentType extends ResolversParentTypes['CodesGenerationSearchHit'] = ResolversParentTypes['CodesGenerationSearchHit']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  discountCodeRuleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  numberOfCodesGenerated?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  numberOfCodesRequested?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  prefix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  usageLimit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CodesGenerationSearchHitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CodesGenerationSearchHits'] = ResolversParentTypes['CodesGenerationSearchHits']> = {
  hits?: Resolver<Array<ResolversTypes['CodesGenerationSearchHit']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyAttributeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyAttribute'] = ResolversParentTypes['CompanyAttribute']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  registrationNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taxId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompensationBonusResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompensationBonus'] = ResolversParentTypes['CompensationBonus']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reservationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transactionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompensationGiftCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompensationGiftCard'] = ResolversParentTypes['CompensationGiftCard']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  giftCardId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompensationOrderLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompensationOrderLine'] = ResolversParentTypes['CompensationOrderLine']> = {
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderLineId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalDiscountAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompensationPaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompensationPayment'] = ResolversParentTypes['CompensationPayment']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompensationShippingFeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompensationShippingFee'] = ResolversParentTypes['CompensationShippingFee']> = {
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shippingFeeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  taxPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalDiscountAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConditionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Condition'] = ResolversParentTypes['Condition']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  identifiers?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  itemRule?: Resolver<Maybe<ResolversTypes['ItemRule']>, ParentType, ContextType>;
  thresholds?: Resolver<Maybe<Array<ResolversTypes['Money']>>, ParentType, ContextType>;
  triggerQuantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateGenerateCodesResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateGenerateCodesResult'] = ResolversParentTypes['CreateGenerateCodesResult']> = {
  jobId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeletedResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeletedResult'] = ResolversParentTypes['DeletedResult']> = {
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeliveryBonusResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliveryBonus'] = ResolversParentTypes['DeliveryBonus']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reservationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transactionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeliveryCaptureResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliveryCapture'] = ResolversParentTypes['DeliveryCapture']> = {
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeliveryGiftResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliveryGift'] = ResolversParentTypes['DeliveryGift']> = {
  giftId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeliveryGiftCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliveryGiftCard'] = ResolversParentTypes['DeliveryGiftCard']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  giftCardId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  reservationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transactionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeliveryGiftCardProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliveryGiftCardProduct'] = ResolversParentTypes['DeliveryGiftCardProduct']> = {
  createFailedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createTransactionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  giftCardProductId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  priceAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeliveryOrderLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliveryOrderLine'] = ResolversParentTypes['DeliveryOrderLine']> = {
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderLineId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalDiscountAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeliveryShippingFeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliveryShippingFee'] = ResolversParentTypes['DeliveryShippingFee']> = {
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shippingFeeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  totalAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeliveryTrackingResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliveryTracking'] = ResolversParentTypes['DeliveryTracking']> = {
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shippingCompany?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shippingMethod?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeliveryVoucherResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliveryVoucher'] = ResolversParentTypes['DeliveryVoucher']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  voucherId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DimensionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Dimensions'] = ResolversParentTypes['Dimensions']> = {
  height?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountCodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountCode'] = ResolversParentTypes['DiscountCode']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  discountCodeRuleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isUnlimited?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  usageLimit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  validDateRange?: Resolver<Maybe<ResolversTypes['ValidDateRange']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountCodeRuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountCodeRule'] = ResolversParentTypes['DiscountCodeRule']> = {
  applyLast?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  conditions?: Resolver<Array<ResolversTypes['Condition']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isExclusive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isStackable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  logicRules?: Resolver<Array<ResolversTypes['LogicHolder']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  outcomes?: Resolver<Array<ResolversTypes['Outcome']>, ParentType, ContextType>;
  sortOrder?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  validDateRange?: Resolver<Maybe<ResolversTypes['ValidDateRange']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountCodeRuleSearchHitResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountCodeRuleSearchHit'] = ResolversParentTypes['DiscountCodeRuleSearchHit']> = {
  applyLast?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isExclusive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isStackable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sortOrder?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  validFrom?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  validTo?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountCodeRuleSearchHitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountCodeRuleSearchHits'] = ResolversParentTypes['DiscountCodeRuleSearchHits']> = {
  hits?: Resolver<Array<ResolversTypes['DiscountCodeRuleSearchHit']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountCodeSearchHitResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountCodeSearchHit'] = ResolversParentTypes['DiscountCodeSearchHit']> = {
  code?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  discountCodeRuleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isUnlimited?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  usageLimit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  validFrom?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  validTo?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountCodeSearchHitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountCodeSearchHits'] = ResolversParentTypes['DiscountCodeSearchHits']> = {
  hits?: Resolver<Array<ResolversTypes['DiscountCodeSearchHit']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountExternalRuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountExternalRule'] = ResolversParentTypes['DiscountExternalRule']> = {
  applyLast?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  conditions?: Resolver<Array<ResolversTypes['Condition']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isExclusive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isStackable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  logicRules?: Resolver<Array<ResolversTypes['LogicHolder']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  outcomes?: Resolver<Array<ResolversTypes['Outcome']>, ParentType, ContextType>;
  sortOrder?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  validDateRange?: Resolver<Maybe<ResolversTypes['ValidDateRange']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountExternalRuleSearchHitResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountExternalRuleSearchHit'] = ResolversParentTypes['DiscountExternalRuleSearchHit']> = {
  applyLast?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isExclusive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isStackable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sortOrder?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  validFrom?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  validTo?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountExternalRuleSearchHitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountExternalRuleSearchHits'] = ResolversParentTypes['DiscountExternalRuleSearchHits']> = {
  hits?: Resolver<Array<ResolversTypes['DiscountExternalRuleSearchHit']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountManagementQuotasResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountManagementQuotas'] = ResolversParentTypes['DiscountManagementQuotas']> = {
  maxGenerationJobsPerDay?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxNumberOfCodes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountRuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountRule'] = ResolversParentTypes['DiscountRule']> = {
  conditions?: Resolver<Array<ResolversTypes['Condition']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isStackable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  logicRules?: Resolver<Array<ResolversTypes['LogicHolder']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  outcomes?: Resolver<Array<ResolversTypes['Outcome']>, ParentType, ContextType>;
  sortOrder?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  validDateRange?: Resolver<Maybe<ResolversTypes['ValidDateRange']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountRuleSearchHitResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountRuleSearchHit'] = ResolversParentTypes['DiscountRuleSearchHit']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isStackable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sortOrder?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  validFrom?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  validTo?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountRuleSearchHitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountRuleSearchHits'] = ResolversParentTypes['DiscountRuleSearchHits']> = {
  hits?: Resolver<Array<ResolversTypes['DiscountRuleSearchHit']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountValue'] = ResolversParentTypes['DiscountValue']> = {
  fixedDiscounts?: Resolver<Maybe<Array<ResolversTypes['Money']>>, ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalApiResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExternalApi'] = ResolversParentTypes['ExternalApi']> = {
  discountManagementQuotas?: Resolver<Maybe<ResolversTypes['DiscountManagementQuotas']>, ParentType, ContextType>;
  usagePlans?: Resolver<Array<ResolversTypes['UsagePlan']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalEventDestinationApIsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExternalEventDestinationAPIs'] = ResolversParentTypes['ExternalEventDestinationAPIs']> = {
  apiDestinations?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  apiDestinationsDetails?: Resolver<Array<ResolversTypes['ExternalEventDestinationsDetails']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalEventDestinationEventBusesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExternalEventDestinationEventBuses'] = ResolversParentTypes['ExternalEventDestinationEventBuses']> = {
  eventBuses?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalEventDestinationsDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExternalEventDestinationsDetails'] = ResolversParentTypes['ExternalEventDestinationsDetails']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  endpoint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalEventRuleStateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExternalEventRuleState'] = ResolversParentTypes['ExternalEventRuleState']> = {
  ruleName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalEventRulesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExternalEventRules'] = ResolversParentTypes['ExternalEventRules']> = {
  rules?: Resolver<Array<ResolversTypes['ExternalEventRuleState']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalRuleOutcomeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExternalRuleOutcome'] = ResolversParentTypes['ExternalRuleOutcome']> = {
  discountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  reference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ruleID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenerateCodesResolvers<ContextType = any, ParentType extends ResolversParentTypes['GenerateCodes'] = ResolversParentTypes['GenerateCodes']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  discountCodeRuleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fileUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  jobId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numberOfCodesGenerated?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  numberOfCodesRequested?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  prefix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  usageLimit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GiftResolvers<ContextType = any, ParentType extends ResolversParentTypes['Gift'] = ResolversParentTypes['Gift']> = {
  productVariantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GiftCardProductProviderResolvers<ContextType = any, ParentType extends ResolversParentTypes['GiftCardProductProvider'] = ResolversParentTypes['GiftCardProductProvider']> = {
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['GiftCardProductProviderName'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ProviderStatusLog']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GiftCardProviderResolvers<ContextType = any, ParentType extends ResolversParentTypes['GiftCardProvider'] = ResolversParentTypes['GiftCardProvider']> = {
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['GiftCardProviderName'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ProviderStatusLog']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['Group'] = ResolversParentTypes['Group']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['GroupDetails'] = ResolversParentTypes['GroupDetails']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupsListResolvers<ContextType = any, ParentType extends ResolversParentTypes['GroupsList'] = ResolversParentTypes['GroupsList']> = {
  groups?: Resolver<Array<ResolversTypes['Group']>, ParentType, ContextType>;
  nextToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IngridConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['IngridConfig'] = ResolversParentTypes['IngridConfig']> = {
  baseUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  credentials?: Resolver<ResolversTypes['IngridCredentials'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  siteId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IngridCredentialsResolvers<ContextType = any, ParentType extends ResolversParentTypes['IngridCredentials'] = ResolversParentTypes['IngridCredentials']> = {
  apiKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IngridDeliveriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['IngridDeliveries'] = ResolversParentTypes['IngridDeliveries']> = {
  carrierProductId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  currencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deliveryAddress?: Resolver<Maybe<ResolversTypes['IngridDeliveryAddress']>, ParentType, ContextType>;
  deliveryType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locationExternalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  taxAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  taxGroupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taxPercentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tosId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IngridDeliveryAddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['IngridDeliveryAddress'] = ResolversParentTypes['IngridDeliveryAddress']> = {
  addressLines?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InventoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Inventory'] = ResolversParentTypes['Inventory']> = {
  address?: Resolver<Maybe<ResolversTypes['InventoryAddress']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InventoryAddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['InventoryAddress'] = ResolversParentTypes['InventoryAddress']> = {
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postalCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  region?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  streetAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InventoryProductVariantStockResolvers<ContextType = any, ParentType extends ResolversParentTypes['InventoryProductVariantStock'] = ResolversParentTypes['InventoryProductVariantStock']> = {
  inventoryId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productVariantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InventoryProductVariantStockConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['InventoryProductVariantStockConfig'] = ResolversParentTypes['InventoryProductVariantStockConfig']> = {
  productVariantId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  validateStock?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InventoryStoreMarketResolvers<ContextType = any, ParentType extends ResolversParentTypes['InventoryStoreMarket'] = ResolversParentTypes['InventoryStoreMarket']> = {
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  inventories?: Resolver<Maybe<Array<ResolversTypes['Inventory']>>, ParentType, ContextType>;
  storeDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  storeGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  storeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InventoryStoreMarketInventoriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['InventoryStoreMarketInventories'] = ResolversParentTypes['InventoryStoreMarketInventories']> = {
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  inventoryIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  storeGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemRuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemRule'] = ResolversParentTypes['ItemRule']> = {
  excludeDiscountedPrices?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  excludeFullPrices?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  tagConditions?: Resolver<Array<ResolversTypes['TagCondition']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KbsGiftCardConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['KBSGiftCardConfig'] = ResolversParentTypes['KBSGiftCardConfig']> = {
  baseUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  credentials?: Resolver<ResolversTypes['KBSGiftCardCredentials'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KbsGiftCardCredentialsResolvers<ContextType = any, ParentType extends ResolversParentTypes['KBSGiftCardCredentials'] = ResolversParentTypes['KBSGiftCardCredentials']> = {
  password?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KeyValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['KeyValue'] = ResolversParentTypes['KeyValue']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KlarnaCheckoutConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['KlarnaCheckoutConfig'] = ResolversParentTypes['KlarnaCheckoutConfig']> = {
  baseUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  credentials?: Resolver<ResolversTypes['KlarnaCredentials'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  klarnaShippingAssistantEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  merchantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KlarnaCredentialsResolvers<ContextType = any, ParentType extends ResolversParentTypes['KlarnaCredentials'] = ResolversParentTypes['KlarnaCredentials']> = {
  password?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KlarnaShippingAssistantAddonResolvers<ContextType = any, ParentType extends ResolversParentTypes['KlarnaShippingAssistantAddon'] = ResolversParentTypes['KlarnaShippingAssistantAddon']> = {
  externalId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userInput?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KlarnaShippingAssistantAddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['KlarnaShippingAssistantAddress'] = ResolversParentTypes['KlarnaShippingAssistantAddress']> = {
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postalCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  region?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  streetAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  streetAddress2?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KlarnaShippingAssistantCarrierProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['KlarnaShippingAssistantCarrierProduct'] = ResolversParentTypes['KlarnaShippingAssistantCarrierProduct']> = {
  identifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KlarnaShippingAssistantLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['KlarnaShippingAssistantLocation'] = ResolversParentTypes['KlarnaShippingAssistantLocation']> = {
  address?: Resolver<ResolversTypes['KlarnaShippingAssistantAddress'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KlarnaShippingAssistantLocationTimeslotResolvers<ContextType = any, ParentType extends ResolversParentTypes['KlarnaShippingAssistantLocationTimeslot'] = ResolversParentTypes['KlarnaShippingAssistantLocationTimeslot']> = {
  cutoff?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  end?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  start?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KlarnaShippingAssistantSelectedShippingOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['KlarnaShippingAssistantSelectedShippingOption'] = ResolversParentTypes['KlarnaShippingAssistantSelectedShippingOption']> = {
  carrier?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  carrierProduct?: Resolver<Maybe<ResolversTypes['KlarnaShippingAssistantCarrierProduct']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['KlarnaShippingAssistantLocation']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  selectedAddons?: Resolver<Maybe<Array<ResolversTypes['KlarnaShippingAssistantAddon']>>, ParentType, ContextType>;
  taxAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxRate?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timeslot?: Resolver<Maybe<ResolversTypes['KlarnaShippingAssistantLocationTimeslot']>, ParentType, ContextType>;
  tmsReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LogicHolderResolvers<ContextType = any, ParentType extends ResolversParentTypes['LogicHolder'] = ResolversParentTypes['LogicHolder']> = {
  do?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  if?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketPriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['MarketPrice'] = ResolversParentTypes['MarketPrice']> = {
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketRoundingRuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['MarketRoundingRule'] = ResolversParentTypes['MarketRoundingRule']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['RoundingRule']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MoneyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Money'] = ResolversParentTypes['Money']> = {
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  units?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  Order?: Resolver<ResolversTypes['MutationOrderManagement'], ParentType, ContextType>;
  addExternalEventsDestinationAPI?: Resolver<ResolversTypes['AddExternalEventsDestinationAPIResult'], ParentType, ContextType, RequireFields<MutationAddExternalEventsDestinationApiArgs, 'destName' | 'options'>>;
  addExternalEventsDestinationEventBus?: Resolver<ResolversTypes['AddExternalEventsDestinationEventBusResult'], ParentType, ContextType, RequireFields<MutationAddExternalEventsDestinationEventBusArgs, 'eventBusArn'>>;
  addExternalEventsRule?: Resolver<ResolversTypes['AddExternalEventsRuleResult'], ParentType, ContextType, RequireFields<MutationAddExternalEventsRuleArgs, 'ruleName'>>;
  addUserToGroup?: Resolver<ResolversTypes['OperationResult'], ParentType, ContextType, RequireFields<MutationAddUserToGroupArgs, 'input'>>;
  createApiClient?: Resolver<ResolversTypes['ApiClientDetails'], ParentType, ContextType, RequireFields<MutationCreateApiClientArgs, 'input'>>;
  createGenerateCodes?: Resolver<Maybe<ResolversTypes['CreateGenerateCodesResult']>, ParentType, ContextType, RequireFields<MutationCreateGenerateCodesArgs, 'input'>>;
  createOrUpdateAdyenConfig?: Resolver<ResolversTypes['AdyenConfig'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateAdyenConfigArgs, 'adyenConfig' | 'id'>>;
  createOrUpdateBSGGiftCardConfig?: Resolver<ResolversTypes['BSGGiftCardConfig'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateBsgGiftCardConfigArgs, 'bsgGiftCardConfig' | 'id'>>;
  createOrUpdateBonusConfig?: Resolver<ResolversTypes['BonusConfig'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateBonusConfigArgs, 'bonusConfig' | 'id'>>;
  createOrUpdateBundleGroup?: Resolver<Maybe<ResolversTypes['BundleGroup']>, ParentType, ContextType, RequireFields<MutationCreateOrUpdateBundleGroupArgs, 'bundleGroup' | 'id'>>;
  createOrUpdateCampaign?: Resolver<ResolversTypes['Campaign'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateCampaignArgs, 'campaign' | 'id'>>;
  createOrUpdateCampaignGroup?: Resolver<ResolversTypes['CampaignGroup'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateCampaignGroupArgs, 'campaignGroup' | 'id'>>;
  createOrUpdateDiscountCode?: Resolver<Maybe<ResolversTypes['DiscountCode']>, ParentType, ContextType, RequireFields<MutationCreateOrUpdateDiscountCodeArgs, 'code' | 'discountCode'>>;
  createOrUpdateDiscountCodeRule?: Resolver<Maybe<ResolversTypes['DiscountCodeRule']>, ParentType, ContextType, RequireFields<MutationCreateOrUpdateDiscountCodeRuleArgs, 'discountCodeRule' | 'id'>>;
  createOrUpdateDiscountExternalRule?: Resolver<Maybe<ResolversTypes['DiscountExternalRule']>, ParentType, ContextType, RequireFields<MutationCreateOrUpdateDiscountExternalRuleArgs, 'discountExternalRule' | 'id'>>;
  createOrUpdateDiscountRule?: Resolver<Maybe<ResolversTypes['DiscountRule']>, ParentType, ContextType, RequireFields<MutationCreateOrUpdateDiscountRuleArgs, 'discountRule' | 'id'>>;
  createOrUpdateIngridConfig?: Resolver<ResolversTypes['IngridConfig'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateIngridConfigArgs, 'id' | 'ingridConfig'>>;
  createOrUpdateInventory?: Resolver<ResolversTypes['Inventory'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateInventoryArgs, 'id' | 'inventory'>>;
  createOrUpdateKBSGiftCardConfig?: Resolver<ResolversTypes['KBSGiftCardConfig'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateKbsGiftCardConfigArgs, 'id' | 'kbsGiftCardConfig'>>;
  createOrUpdateKlarnaCheckoutConfig?: Resolver<ResolversTypes['KlarnaCheckoutConfig'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateKlarnaCheckoutConfigArgs, 'id' | 'klarnaCheckoutConfig'>>;
  createOrUpdateNShiftConfig?: Resolver<ResolversTypes['NShiftConfig'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateNShiftConfigArgs, 'id' | 'nShiftConfig'>>;
  createOrUpdateQliroOneConfig?: Resolver<ResolversTypes['QliroOneConfig'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateQliroOneConfigArgs, 'id' | 'qliroOneConfig'>>;
  createOrUpdateRetain24Config?: Resolver<ResolversTypes['Retain24Config'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateRetain24ConfigArgs, 'id' | 'retain24Config'>>;
  createOrUpdateSveaCheckoutConfig?: Resolver<ResolversTypes['SveaCheckoutConfig'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateSveaCheckoutConfigArgs, 'id' | 'sveaCheckoutConfig'>>;
  createOrUpdateTaxGroup?: Resolver<ResolversTypes['TaxGroup'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateTaxGroupArgs, 'id' | 'taxGroup'>>;
  createOrUpdateTaxMarket?: Resolver<ResolversTypes['TaxMarket'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateTaxMarketArgs, 'countryCode' | 'taxGroupId' | 'taxMarket'>>;
  createOrUpdateVoyadoConfig?: Resolver<ResolversTypes['VoyadoConfig'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateVoyadoConfigArgs, 'id' | 'voyadoConfig'>>;
  createOrUpdateWalleyCheckoutConfig?: Resolver<ResolversTypes['WalleyCheckoutConfig'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateWalleyCheckoutConfigArgs, 'id' | 'walleyCheckoutConfig'>>;
  createStoreGroup?: Resolver<Maybe<ResolversTypes['StoreGroup']>, ParentType, ContextType, RequireFields<MutationCreateStoreGroupArgs, 'id' | 'storeGroup'>>;
  createStoreMarket?: Resolver<Maybe<ResolversTypes['StoreMarket']>, ParentType, ContextType, RequireFields<MutationCreateStoreMarketArgs, 'countryCode' | 'storeGroupId' | 'storeMarket'>>;
  createUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationCreateUserArgs, 'input'>>;
  deleteAdyenConfig?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteAdyenConfigArgs, 'id'>>;
  deleteApiClient?: Resolver<ResolversTypes['OperationResult'], ParentType, ContextType, RequireFields<MutationDeleteApiClientArgs, 'clientId'>>;
  deleteBSGGiftCardConfig?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteBsgGiftCardConfigArgs, 'id'>>;
  deleteBonusConfig?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteBonusConfigArgs, 'id'>>;
  deleteBundleGroup?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteBundleGroupArgs, 'id'>>;
  deleteCampaign?: Resolver<ResolversTypes['CampaignDeletionResult'], ParentType, ContextType, RequireFields<MutationDeleteCampaignArgs, 'id'>>;
  deleteCampaignGroup?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteCampaignGroupArgs, 'id'>>;
  deleteDiscountCode?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteDiscountCodeArgs, 'code'>>;
  deleteDiscountCodeRule?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteDiscountCodeRuleArgs, 'id'>>;
  deleteDiscountExternalRule?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteDiscountExternalRuleArgs, 'id'>>;
  deleteDiscountRule?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteDiscountRuleArgs, 'id'>>;
  deleteExternalEventsDestinationAPI?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteExternalEventsDestinationApiArgs, 'destName'>>;
  deleteExternalEventsDestinationEventBus?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteExternalEventsDestinationEventBusArgs, 'eventBusArn'>>;
  deleteExternalEventsRule?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteExternalEventsRuleArgs, 'ruleName'>>;
  deleteIngridConfig?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteIngridConfigArgs, 'id'>>;
  deleteInventory?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteInventoryArgs, 'id'>>;
  deleteInventoryProductVariantStock?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteInventoryProductVariantStockArgs, 'inventoryId' | 'productVariantId'>>;
  deleteKBSGiftCardConfig?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteKbsGiftCardConfigArgs, 'id'>>;
  deleteKlarnaCheckoutConfig?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteKlarnaCheckoutConfigArgs, 'id'>>;
  deleteNShiftConfig?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteNShiftConfigArgs, 'id'>>;
  deleteQliroOneConfig?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteQliroOneConfigArgs, 'id'>>;
  deleteRetain24Config?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteRetain24ConfigArgs, 'id'>>;
  deleteStoreGroup?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteStoreGroupArgs, 'id'>>;
  deleteStoreMarket?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteStoreMarketArgs, 'countryCode' | 'storeGroupId'>>;
  deleteSveaCheckoutConfig?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteSveaCheckoutConfigArgs, 'id'>>;
  deleteTaxGroup?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteTaxGroupArgs, 'id'>>;
  deleteTaxMarket?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteTaxMarketArgs, 'countryCode' | 'taxGroupId'>>;
  deleteVoyadoConfig?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteVoyadoConfigArgs, 'id'>>;
  deleteWalleyCheckoutConfig?: Resolver<ResolversTypes['DeletedResult'], ParentType, ContextType, RequireFields<MutationDeleteWalleyCheckoutConfigArgs, 'id'>>;
  disableUser?: Resolver<ResolversTypes['OperationResult'], ParentType, ContextType, RequireFields<MutationDisableUserArgs, 'username'>>;
  enableUser?: Resolver<ResolversTypes['OperationResult'], ParentType, ContextType, RequireFields<MutationEnableUserArgs, 'username'>>;
  generateAdyenWebhookCredentials?: Resolver<ResolversTypes['AdyenWebhookCredentials'], ParentType, ContextType, RequireFields<MutationGenerateAdyenWebhookCredentialsArgs, 'id'>>;
  generateAdyenWebhooks?: Resolver<ResolversTypes['AdyenWebhookGenerationResult'], ParentType, ContextType, RequireFields<MutationGenerateAdyenWebhooksArgs, 'id'>>;
  privateAdyenWebhookChange?: Resolver<ResolversTypes['AdyenWebhook'], ParentType, ContextType, RequireFields<MutationPrivateAdyenWebhookChangeArgs, 'input'>>;
  removeUserFromGroup?: Resolver<ResolversTypes['OperationResult'], ParentType, ContextType, RequireFields<MutationRemoveUserFromGroupArgs, 'input'>>;
  resendOrderEvent?: Resolver<ResolversTypes['OperationResult'], ParentType, ContextType, RequireFields<MutationResendOrderEventArgs, 'orderId'>>;
  resendPassword?: Resolver<ResolversTypes['OperationResult'], ParentType, ContextType, RequireFields<MutationResendPasswordArgs, 'username'>>;
  setShippingTaxGroup?: Resolver<ResolversTypes['ShippingTaxGroup'], ParentType, ContextType, RequireFields<MutationSetShippingTaxGroupArgs, 'taxGroupId'>>;
  updateApiClient?: Resolver<ResolversTypes['ApiClientDetails'], ParentType, ContextType, RequireFields<MutationUpdateApiClientArgs, 'clientId' | 'input'>>;
  updateCampaignPeriod?: Resolver<ResolversTypes['Campaign'], ParentType, ContextType, RequireFields<MutationUpdateCampaignPeriodArgs, 'id' | 'period'>>;
  updateInventoryProductVariantStock?: Resolver<ResolversTypes['InventoryProductVariantStock'], ParentType, ContextType, RequireFields<MutationUpdateInventoryProductVariantStockArgs, 'inventoryId' | 'productVariantId' | 'stock'>>;
  updateInventoryStoreMarketInventories?: Resolver<ResolversTypes['InventoryStoreMarketInventories'], ParentType, ContextType, RequireFields<MutationUpdateInventoryStoreMarketInventoriesArgs, 'countryCode' | 'inventoryIds' | 'storeGroupId'>>;
  updateProductVariantStockConfig?: Resolver<ResolversTypes['InventoryProductVariantStockConfig'], ParentType, ContextType, RequireFields<MutationUpdateProductVariantStockConfigArgs, 'config' | 'productVariantId'>>;
  updateStoreGroup?: Resolver<Maybe<ResolversTypes['StoreGroup']>, ParentType, ContextType, RequireFields<MutationUpdateStoreGroupArgs, 'id' | 'storeGroup'>>;
  updateStoreMarket?: Resolver<Maybe<ResolversTypes['StoreMarket']>, ParentType, ContextType, RequireFields<MutationUpdateStoreMarketArgs, 'countryCode' | 'storeGroupId' | 'storeMarket'>>;
  updateUser?: Resolver<ResolversTypes['OperationResult'], ParentType, ContextType, RequireFields<MutationUpdateUserArgs, 'input' | 'username'>>;
};

export type MutationOrderManagementResolvers<ContextType = any, ParentType extends ResolversParentTypes['MutationOrderManagement'] = ResolversParentTypes['MutationOrderManagement']> = {
  createCancellation?: Resolver<ResolversTypes['OrderCancellation'], ParentType, ContextType, RequireFields<MutationOrderManagementCreateCancellationArgs, 'input' | 'orderId'>>;
  createCompensation?: Resolver<ResolversTypes['OrderCompensation'], ParentType, ContextType, RequireFields<MutationOrderManagementCreateCompensationArgs, 'input' | 'orderId'>>;
  createDelivery?: Resolver<ResolversTypes['OrderDelivery'], ParentType, ContextType, RequireFields<MutationOrderManagementCreateDeliveryArgs, 'input' | 'orderId'>>;
  createRefund?: Resolver<ResolversTypes['OrderRefund'], ParentType, ContextType, RequireFields<MutationOrderManagementCreateRefundArgs, 'input' | 'orderId'>>;
  createRelease?: Resolver<ResolversTypes['OrderRelease'], ParentType, ContextType, RequireFields<MutationOrderManagementCreateReleaseArgs, 'input' | 'orderId'>>;
  deleteCancellation?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementDeleteCancellationArgs, 'cancellationId'>>;
  deleteCompensation?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementDeleteCompensationArgs, 'compensationId'>>;
  deleteDelivery?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementDeleteDeliveryArgs, 'deliveryId'>>;
  deleteRefund?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementDeleteRefundArgs, 'refundId'>>;
  deleteRelease?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementDeleteReleaseArgs, 'releaseId'>>;
  resendOrderEvent?: Resolver<ResolversTypes['OperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementResendOrderEventArgs, 'orderId'>>;
  restartCancellation?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementRestartCancellationArgs, 'cancellationId' | 'input'>>;
  restartCompensation?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementRestartCompensationArgs, 'compensationId' | 'input'>>;
  restartDelivery?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementRestartDeliveryArgs, 'deliveryId' | 'input'>>;
  restartRefund?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementRestartRefundArgs, 'input' | 'refundId'>>;
  restartRelease?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementRestartReleaseArgs, 'input' | 'releaseId'>>;
  startCancellation?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementStartCancellationArgs, 'cancellationId' | 'input'>>;
  startCompensation?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementStartCompensationArgs, 'compensationId' | 'input'>>;
  startDelivery?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementStartDeliveryArgs, 'deliveryId' | 'input'>>;
  startRefund?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementStartRefundArgs, 'input' | 'refundId'>>;
  startRelease?: Resolver<ResolversTypes['OrderOperationResult'], ParentType, ContextType, RequireFields<MutationOrderManagementStartReleaseArgs, 'input' | 'releaseId'>>;
  updateCompensation?: Resolver<ResolversTypes['OrderCompensation'], ParentType, ContextType, RequireFields<MutationOrderManagementUpdateCompensationArgs, 'compensationId' | 'input'>>;
  updateDelivery?: Resolver<ResolversTypes['OrderDelivery'], ParentType, ContextType, RequireFields<MutationOrderManagementUpdateDeliveryArgs, 'deliveryId' | 'input'>>;
  updateRefund?: Resolver<ResolversTypes['OrderRefund'], ParentType, ContextType, RequireFields<MutationOrderManagementUpdateRefundArgs, 'input' | 'refundId'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NShiftConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['NShiftConfig'] = ResolversParentTypes['NShiftConfig']> = {
  baseUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  credentials?: Resolver<ResolversTypes['NShiftCredentials'], ParentType, ContextType>;
  deliveryCheckoutId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NShiftCredentialsResolvers<ContextType = any, ParentType extends ResolversParentTypes['NShiftCredentials'] = ResolversParentTypes['NShiftCredentials']> = {
  clientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clientSecret?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NshiftAddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['NshiftAddress'] = ResolversParentTypes['NshiftAddress']> = {
  address1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quickId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zipCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NshiftPreparedShipmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['NshiftPreparedShipment'] = ResolversParentTypes['NshiftPreparedShipment']> = {
  agent?: Resolver<Maybe<ResolversTypes['NshiftAddress']>, ParentType, ContextType>;
  orderNo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  prepareId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sender?: Resolver<ResolversTypes['NshiftAddress'], ParentType, ContextType>;
  service?: Resolver<ResolversTypes['NshiftService'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NshiftServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['NshiftService'] = ResolversParentTypes['NshiftService']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sourceSystem?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OperationResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['OperationResult'] = ResolversParentTypes['OperationResult']> = {
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderResolvers<ContextType = any, ParentType extends ResolversParentTypes['Order'] = ResolversParentTypes['Order']> = {
  Bonus?: Resolver<Maybe<ResolversTypes['OrderBonus']>, ParentType, ContextType>;
  Bundles?: Resolver<Maybe<Array<ResolversTypes['OrderBundle']>>, ParentType, ContextType>;
  Cancellations?: Resolver<Array<ResolversTypes['OrderCancellation']>, ParentType, ContextType>;
  Compensations?: Resolver<Array<ResolversTypes['OrderCompensation']>, ParentType, ContextType>;
  Deliveries?: Resolver<Array<ResolversTypes['OrderDelivery']>, ParentType, ContextType>;
  GiftCard?: Resolver<ResolversTypes['OrderGiftCard'], ParentType, ContextType>;
  GiftCardProduct?: Resolver<ResolversTypes['OrderGiftCardProduct'], ParentType, ContextType>;
  Payment?: Resolver<ResolversTypes['OrderPayment'], ParentType, ContextType>;
  Promotion?: Resolver<ResolversTypes['OrderPromotion'], ParentType, ContextType>;
  Refunds?: Resolver<Array<ResolversTypes['OrderRefund']>, ParentType, ContextType>;
  Releases?: Resolver<Array<ResolversTypes['OrderRelease']>, ParentType, ContextType>;
  Reservation?: Resolver<Maybe<ResolversTypes['OrderReservation']>, ParentType, ContextType>;
  Shipping?: Resolver<ResolversTypes['OrderShipping'], ParentType, ContextType>;
  Voucher?: Resolver<ResolversTypes['OrderVoucher'], ParentType, ContextType>;
  attributes?: Resolver<Maybe<ResolversTypes['OrderAttributes']>, ParentType, ContextType>;
  billingAddress?: Resolver<ResolversTypes['OrderAddress'], ParentType, ContextType>;
  bonusProvider?: Resolver<Maybe<ResolversTypes['BonusProvider']>, ParentType, ContextType>;
  channelType?: Resolver<ResolversTypes['ChannelType'], ParentType, ContextType>;
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  discountCodeRules?: Resolver<Maybe<Array<ResolversTypes['OrderDiscountCodeRule']>>, ParentType, ContextType>;
  discountExternalRules?: Resolver<Maybe<Array<ResolversTypes['OrderDiscountExternalRule']>>, ParentType, ContextType>;
  discountOutcome?: Resolver<ResolversTypes['OrderDiscountOutcome'], ParentType, ContextType>;
  discountRules?: Resolver<Maybe<Array<ResolversTypes['OrderDiscountRule']>>, ParentType, ContextType>;
  giftCardProvider?: Resolver<Maybe<ResolversTypes['GiftCardProvider']>, ParentType, ContextType>;
  gifts?: Resolver<Maybe<Array<ResolversTypes['OrderGift']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isTaxExempt?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isTaxExemptionEligible?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isTaxIncludedInPrice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  languageCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderLines?: Resolver<Maybe<Array<ResolversTypes['OrderLine']>>, ParentType, ContextType>;
  paymentProvider?: Resolver<ResolversTypes['PaymentProvider'], ParentType, ContextType>;
  promotionProvider?: Resolver<Maybe<ResolversTypes['PromotionProvider']>, ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shippingAddress?: Resolver<ResolversTypes['OrderAddress'], ParentType, ContextType>;
  shippingFees?: Resolver<Maybe<Array<ResolversTypes['ShippingFee']>>, ParentType, ContextType>;
  shippingProvider?: Resolver<Maybe<ResolversTypes['ShippingProvider']>, ParentType, ContextType>;
  storeGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxationCountry?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totals?: Resolver<ResolversTypes['OrderTotals'], ParentType, ContextType>;
  voucherProvider?: Resolver<Maybe<ResolversTypes['VoucherProvider']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderAddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderAddress'] = ResolversParentTypes['OrderAddress']> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  familyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  givenName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pcc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stateOrProvince?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  streetAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  streetAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  telephoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderAttributesResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderAttributes'] = ResolversParentTypes['OrderAttributes']> = {
  company?: Resolver<Maybe<ResolversTypes['CompanyAttribute']>, ParentType, ContextType>;
  merchantReference1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  merchantReference2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderBonusResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderBonus'] = ResolversParentTypes['OrderBonus']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  customerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  failedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reservationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderBundleResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderBundle'] = ResolversParentTypes['OrderBundle']> = {
  bundleGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  discountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderCancellationResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderCancellation'] = ResolversParentTypes['OrderCancellation']> = {
  bonus?: Resolver<Maybe<ResolversTypes['CancellationBonus']>, ParentType, ContextType>;
  bonusProvider?: Resolver<Maybe<ResolversTypes['BonusProvider']>, ParentType, ContextType>;
  cancelledPayment?: Resolver<Maybe<ResolversTypes['CancelledPayment']>, ParentType, ContextType>;
  completed?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  failed?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  giftCardProductProvider?: Resolver<Maybe<ResolversTypes['GiftCardProductProvider']>, ParentType, ContextType>;
  giftCardProducts?: Resolver<Array<ResolversTypes['CancellationGiftCardProduct']>, ParentType, ContextType>;
  giftCardProvider?: Resolver<Maybe<ResolversTypes['GiftCardProvider']>, ParentType, ContextType>;
  giftCards?: Resolver<Array<ResolversTypes['CancellationGiftCard']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentProvider?: Resolver<ResolversTypes['PaymentProvider'], ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['Reason']>, ParentType, ContextType>;
  restarted?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  restarts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shippingProvider?: Resolver<Maybe<ResolversTypes['ShippingProvider']>, ParentType, ContextType>;
  started?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  voucherProvider?: Resolver<Maybe<ResolversTypes['VoucherProvider']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderCompensationResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderCompensation'] = ResolversParentTypes['OrderCompensation']> = {
  bonus?: Resolver<Maybe<ResolversTypes['CompensationBonus']>, ParentType, ContextType>;
  bonusProvider?: Resolver<Maybe<ResolversTypes['BonusProvider']>, ParentType, ContextType>;
  completed?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  failed?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  giftCardProductProvider?: Resolver<Maybe<ResolversTypes['GiftCardProductProvider']>, ParentType, ContextType>;
  giftCardProvider?: Resolver<Maybe<ResolversTypes['GiftCardProvider']>, ParentType, ContextType>;
  giftCards?: Resolver<Maybe<Array<ResolversTypes['CompensationGiftCard']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderLines?: Resolver<Maybe<Array<ResolversTypes['CompensationOrderLine']>>, ParentType, ContextType>;
  orderReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  payment?: Resolver<Maybe<ResolversTypes['CompensationPayment']>, ParentType, ContextType>;
  paymentProvider?: Resolver<Maybe<ResolversTypes['PaymentProvider']>, ParentType, ContextType>;
  promotionProvider?: Resolver<Maybe<ResolversTypes['PromotionProvider']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['Reason']>, ParentType, ContextType>;
  restarted?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  restarts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shippingFees?: Resolver<Maybe<Array<ResolversTypes['CompensationShippingFee']>>, ParentType, ContextType>;
  shippingProvider?: Resolver<Maybe<ResolversTypes['ShippingProvider']>, ParentType, ContextType>;
  started?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  totals?: Resolver<ResolversTypes['OrderCompensationTotals'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  voucherProvider?: Resolver<Maybe<ResolversTypes['VoucherProvider']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderCompensationTotalsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderCompensationTotals'] = ResolversParentTypes['OrderCompensationTotals']> = {
  bonusTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  giftCardTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  grandTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  paymentTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderDeliveryResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderDelivery'] = ResolversParentTypes['OrderDelivery']> = {
  bonus?: Resolver<Maybe<ResolversTypes['DeliveryBonus']>, ParentType, ContextType>;
  bonusProvider?: Resolver<Maybe<ResolversTypes['BonusProvider']>, ParentType, ContextType>;
  capturedPayment?: Resolver<Maybe<ResolversTypes['DeliveryCapture']>, ParentType, ContextType>;
  completed?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  failed?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  giftCardProductProvider?: Resolver<Maybe<ResolversTypes['GiftCardProductProvider']>, ParentType, ContextType>;
  giftCardProducts?: Resolver<Array<ResolversTypes['DeliveryGiftCardProduct']>, ParentType, ContextType>;
  giftCardProvider?: Resolver<Maybe<ResolversTypes['GiftCardProvider']>, ParentType, ContextType>;
  giftCards?: Resolver<Array<ResolversTypes['DeliveryGiftCard']>, ParentType, ContextType>;
  gifts?: Resolver<Array<ResolversTypes['DeliveryGift']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderLines?: Resolver<Array<ResolversTypes['DeliveryOrderLine']>, ParentType, ContextType>;
  orderReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentProvider?: Resolver<ResolversTypes['PaymentProvider'], ParentType, ContextType>;
  promotionProvider?: Resolver<Maybe<ResolversTypes['PromotionProvider']>, ParentType, ContextType>;
  restarted?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  restarts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shippingFees?: Resolver<Array<ResolversTypes['DeliveryShippingFee']>, ParentType, ContextType>;
  shippingProvider?: Resolver<Maybe<ResolversTypes['ShippingProvider']>, ParentType, ContextType>;
  started?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  totals?: Resolver<ResolversTypes['OrderDeliveryTotals'], ParentType, ContextType>;
  tracking?: Resolver<Maybe<ResolversTypes['DeliveryTracking']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  voucherProvider?: Resolver<Maybe<ResolversTypes['VoucherProvider']>, ParentType, ContextType>;
  vouchers?: Resolver<Array<ResolversTypes['DeliveryVoucher']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderDeliveryTotalsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderDeliveryTotals'] = ResolversParentTypes['OrderDeliveryTotals']> = {
  bonusTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  giftCardTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  grandTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shippingTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  subTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  voucherTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderDiscountCodeRuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderDiscountCodeRule'] = ResolversParentTypes['OrderDiscountCodeRule']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  discountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  freeShipping?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderDiscountExternalRuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderDiscountExternalRule'] = ResolversParentTypes['OrderDiscountExternalRule']> = {
  currencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  discountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  freeShipping?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderDiscountOutcomeResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderDiscountOutcome'] = ResolversParentTypes['OrderDiscountOutcome']> = {
  cartRules?: Resolver<Maybe<Array<ResolversTypes['CartRuleOutcome']>>, ParentType, ContextType>;
  codeRules?: Resolver<Maybe<Array<ResolversTypes['CodeRuleOutcome']>>, ParentType, ContextType>;
  discountReduction?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  externalRules?: Resolver<Maybe<Array<ResolversTypes['ExternalRuleOutcome']>>, ParentType, ContextType>;
  freeShipping?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  totalDiscountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderDiscountRuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderDiscountRule'] = ResolversParentTypes['OrderDiscountRule']> = {
  currencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  discountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  freeShipping?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderGiftResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderGift'] = ResolversParentTypes['OrderGift']> = {
  basePriceAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  discountAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  discountRuleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productParentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productVariantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  salePriceAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalDiscountAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalPriceAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderGiftCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderGiftCard'] = ResolversParentTypes['OrderGiftCard']> = {
  __resolveType: TypeResolveFn<'OrderGiftCardBSGGiftCard' | 'OrderGiftCardEmpty' | 'OrderGiftCardKBSGiftCard' | 'OrderGiftCardRetain24', ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type OrderGiftCardBsgGiftCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderGiftCardBSGGiftCard'] = ResolversParentTypes['OrderGiftCardBSGGiftCard']> = {
  giftCards?: Resolver<Maybe<Array<ResolversTypes['OrderGiftCardItemBSGGiftCard']>>, ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderGiftCardEmptyResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderGiftCardEmpty'] = ResolversParentTypes['OrderGiftCardEmpty']> = {
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderGiftCardItemBsgGiftCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderGiftCardItemBSGGiftCard'] = ResolversParentTypes['OrderGiftCardItemBSGGiftCard']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  failedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reservationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderGiftCardItemKbsGiftCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderGiftCardItemKBSGiftCard'] = ResolversParentTypes['OrderGiftCardItemKBSGiftCard']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  failedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reservationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderGiftCardItemRetain24Resolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderGiftCardItemRetain24'] = ResolversParentTypes['OrderGiftCardItemRetain24']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  failedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  redeemId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reservationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderGiftCardKbsGiftCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderGiftCardKBSGiftCard'] = ResolversParentTypes['OrderGiftCardKBSGiftCard']> = {
  giftCards?: Resolver<Maybe<Array<ResolversTypes['OrderGiftCardItemKBSGiftCard']>>, ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderGiftCardProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderGiftCardProduct'] = ResolversParentTypes['OrderGiftCardProduct']> = {
  __resolveType: TypeResolveFn<'OrderGiftCardProductEmpty' | 'OrderGiftCardProductRetain24', ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type OrderGiftCardProductEmptyResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderGiftCardProductEmpty'] = ResolversParentTypes['OrderGiftCardProductEmpty']> = {
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderGiftCardProductItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderGiftCardProductItem'] = ResolversParentTypes['OrderGiftCardProductItem']> = {
  __resolveType: TypeResolveFn<'OrderGiftCardProductItemRetain24', ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  distribution?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  merchantReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recipient?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type OrderGiftCardProductItemRetain24Resolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderGiftCardProductItemRetain24'] = ResolversParentTypes['OrderGiftCardProductItemRetain24']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  distribution?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  emailText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  failedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  merchantReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  multiCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recipient?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  referenceNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sendDate?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  smsText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  templateId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  transactionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderGiftCardProductRetain24Resolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderGiftCardProductRetain24'] = ResolversParentTypes['OrderGiftCardProductRetain24']> = {
  giftCardProducts?: Resolver<Maybe<Array<ResolversTypes['OrderGiftCardProductItemRetain24']>>, ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderGiftCardRetain24Resolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderGiftCardRetain24'] = ResolversParentTypes['OrderGiftCardRetain24']> = {
  giftCards?: Resolver<Maybe<Array<ResolversTypes['OrderGiftCardItemRetain24']>>, ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderLine'] = ResolversParentTypes['OrderLine']> = {
  basePriceAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  discountAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  discountOutcome?: Resolver<ResolversTypes['OrderLineDiscountOutcome'], ParentType, ContextType>;
  displayDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  distributedTotalDiscountAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  distributedTotalPriceAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  distributedTotalTaxAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productParentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productVariantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  salePriceAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalDiscountAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalPriceAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderLineDiscountOutcomeResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderLineDiscountOutcome'] = ResolversParentTypes['OrderLineDiscountOutcome']> = {
  bundles?: Resolver<Maybe<Array<ResolversTypes['BundleOutcome']>>, ParentType, ContextType>;
  cartRules?: Resolver<Maybe<Array<ResolversTypes['CartRuleOutcome']>>, ParentType, ContextType>;
  codeRules?: Resolver<Maybe<Array<ResolversTypes['CodeRuleOutcome']>>, ParentType, ContextType>;
  externalRules?: Resolver<Maybe<Array<ResolversTypes['ExternalRuleOutcome']>>, ParentType, ContextType>;
  totalDiscountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderOperationResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderOperationResult'] = ResolversParentTypes['OrderOperationResult']> = {
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderPaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPayment'] = ResolversParentTypes['OrderPayment']> = {
  __resolveType: TypeResolveFn<'OrderPaymentAdyen' | 'OrderPaymentBrinkZeroPayment' | 'OrderPaymentKlarnaCheckout' | 'OrderPaymentQliroOne' | 'OrderPaymentSveaCheckout' | 'OrderPaymentWalleyCheckout', ParentType, ContextType>;
  method?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type OrderPaymentAdyenResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPaymentAdyen'] = ResolversParentTypes['OrderPaymentAdyen']> = {
  deepLinkPath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  failedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  merchantAccountCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  method?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  potentialFraud?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderPaymentBrinkZeroPaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPaymentBrinkZeroPayment'] = ResolversParentTypes['OrderPaymentBrinkZeroPayment']> = {
  method?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  originalProvider?: Resolver<ResolversTypes['OrderPaymentBrinkZeroPaymentOriginalProvider'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderPaymentBrinkZeroPaymentOriginalProviderResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPaymentBrinkZeroPaymentOriginalProvider'] = ResolversParentTypes['OrderPaymentBrinkZeroPaymentOriginalProvider']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderPaymentKlarnaCheckoutResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPaymentKlarnaCheckout'] = ResolversParentTypes['OrderPaymentKlarnaCheckout']> = {
  deepLinkPath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fraudStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  klarnaMerchantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  klarnaOrderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  method?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderPaymentQliroOneResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPaymentQliroOne'] = ResolversParentTypes['OrderPaymentQliroOne']> = {
  identityVerified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  merchantProvidedQuestionAnswer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  method?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderItems?: Resolver<Maybe<Array<ResolversTypes['OrderPaymentQliroOneOrderItem']>>, ParentType, ContextType>;
  paymentTypeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  privateVerificationCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  qliroOrderId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  requireIdentityVerification?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  signupForNewsletter?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderPaymentQliroOneOrderItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPaymentQliroOneOrderItem'] = ResolversParentTypes['OrderPaymentQliroOneOrderItem']> = {
  merchantReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentTransactionId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderPaymentSveaCheckoutResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPaymentSveaCheckout'] = ResolversParentTypes['OrderPaymentSveaCheckout']> = {
  expirationDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isCompany?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  method?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  peppolId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sveaMerchantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sveaOrderId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderPaymentWalleyCheckoutResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPaymentWalleyCheckout'] = ResolversParentTypes['OrderPaymentWalleyCheckout']> = {
  amountToPay?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  method?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  purchaseIdentifier?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  purchaseResult?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderPromotionResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPromotion'] = ResolversParentTypes['OrderPromotion']> = {
  __resolveType: TypeResolveFn<'OrderPromotionEmpty' | 'OrderPromotionVoyado', ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type OrderPromotionEmptyResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPromotionEmpty'] = ResolversParentTypes['OrderPromotionEmpty']> = {
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderPromotionItemVoyadoResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPromotionItemVoyado'] = ResolversParentTypes['OrderPromotionItemVoyado']> = {
  failedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderPromotionVoyadoResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPromotionVoyado'] = ResolversParentTypes['OrderPromotionVoyado']> = {
  contactId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  promotions?: Resolver<Maybe<Array<ResolversTypes['OrderPromotionItemVoyado']>>, ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderRefundResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderRefund'] = ResolversParentTypes['OrderRefund']> = {
  bonus?: Resolver<Maybe<ResolversTypes['RefundBonus']>, ParentType, ContextType>;
  bonusProvider?: Resolver<Maybe<ResolversTypes['BonusProvider']>, ParentType, ContextType>;
  completed?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  failed?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  fee?: Resolver<Maybe<ResolversTypes['RefundFee']>, ParentType, ContextType>;
  giftCardProductProvider?: Resolver<Maybe<ResolversTypes['GiftCardProductProvider']>, ParentType, ContextType>;
  giftCardProvider?: Resolver<Maybe<ResolversTypes['GiftCardProvider']>, ParentType, ContextType>;
  giftCards?: Resolver<Array<ResolversTypes['RefundGiftCard']>, ParentType, ContextType>;
  gifts?: Resolver<Array<ResolversTypes['RefundGift']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderLines?: Resolver<Array<ResolversTypes['RefundOrderLine']>, ParentType, ContextType>;
  orderReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentProvider?: Resolver<ResolversTypes['PaymentProvider'], ParentType, ContextType>;
  promotionProvider?: Resolver<Maybe<ResolversTypes['PromotionProvider']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['Reason']>, ParentType, ContextType>;
  refundedPayment?: Resolver<Maybe<ResolversTypes['RefundedPayment']>, ParentType, ContextType>;
  restarted?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  restarts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shippingFees?: Resolver<Array<ResolversTypes['RefundShippingFee']>, ParentType, ContextType>;
  shippingProvider?: Resolver<Maybe<ResolversTypes['ShippingProvider']>, ParentType, ContextType>;
  started?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  totals?: Resolver<ResolversTypes['OrderRefundTotals'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  voucherProvider?: Resolver<Maybe<ResolversTypes['VoucherProvider']>, ParentType, ContextType>;
  vouchers?: Resolver<Array<ResolversTypes['RefundVoucher']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderRefundTotalsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderRefundTotals'] = ResolversParentTypes['OrderRefundTotals']> = {
  bonusTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  giftCardTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  grandTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shippingTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  subTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  voucherTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderReleaseResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderRelease'] = ResolversParentTypes['OrderRelease']> = {
  bonus?: Resolver<Maybe<ResolversTypes['ReleaseBonus']>, ParentType, ContextType>;
  bonusProvider?: Resolver<Maybe<ResolversTypes['BonusProvider']>, ParentType, ContextType>;
  completed?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  failed?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  giftCardProductProvider?: Resolver<Maybe<ResolversTypes['GiftCardProductProvider']>, ParentType, ContextType>;
  giftCardProducts?: Resolver<Array<ResolversTypes['ReleaseGiftCardProduct']>, ParentType, ContextType>;
  giftCardProvider?: Resolver<Maybe<ResolversTypes['GiftCardProvider']>, ParentType, ContextType>;
  giftCards?: Resolver<Array<ResolversTypes['ReleaseGiftCard']>, ParentType, ContextType>;
  gifts?: Resolver<Array<ResolversTypes['ReleaseGift']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderLines?: Resolver<Array<ResolversTypes['ReleaseOrderLine']>, ParentType, ContextType>;
  orderReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentProvider?: Resolver<ResolversTypes['PaymentProvider'], ParentType, ContextType>;
  promotionProvider?: Resolver<Maybe<ResolversTypes['PromotionProvider']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['Reason']>, ParentType, ContextType>;
  releasedPayment?: Resolver<Maybe<ResolversTypes['ReleasedPayment']>, ParentType, ContextType>;
  restarted?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  restarts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shippingFees?: Resolver<Array<ResolversTypes['ReleaseShippingFee']>, ParentType, ContextType>;
  shippingProvider?: Resolver<Maybe<ResolversTypes['ShippingProvider']>, ParentType, ContextType>;
  started?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  voucherProvider?: Resolver<Maybe<ResolversTypes['VoucherProvider']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderReservationResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderReservation'] = ResolversParentTypes['OrderReservation']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  processedProductVariants?: Resolver<Array<ResolversTypes['ProductVariantReservation']>, ParentType, ContextType>;
  sessionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderSearchHitResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderSearchHit'] = ResolversParentTypes['OrderSearchHit']> = {
  billingAddress?: Resolver<ResolversTypes['OrderAddress'], ParentType, ContextType>;
  channelType?: Resolver<Maybe<ResolversTypes['ChannelType']>, ParentType, ContextType>;
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  discountCodes?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  discountExternalReferences?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isTaxExempt?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isTaxExemptionEligible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  merchantReference1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  merchantReference2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paymentMethod?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentProviderName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shippingAddress?: Resolver<ResolversTypes['OrderAddress'], ParentType, ContextType>;
  storeGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxationCountry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totals?: Resolver<ResolversTypes['OrderTotals'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderSearchHitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderSearchHits'] = ResolversParentTypes['OrderSearchHits']> = {
  hits?: Resolver<Array<ResolversTypes['OrderSearchHit']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderShippingResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderShipping'] = ResolversParentTypes['OrderShipping']> = {
  __resolveType: TypeResolveFn<'OrderShippingEmpty' | 'OrderShippingIngrid' | 'OrderShippingKlarnaShippingAssistant' | 'OrderShippingNshift' | 'OrderShippingQliroIntegratedShipping' | 'OrderShippingWalleyDeliveryModule', ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type OrderShippingEmptyResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderShippingEmpty'] = ResolversParentTypes['OrderShippingEmpty']> = {
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderShippingIngridResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderShippingIngrid'] = ResolversParentTypes['OrderShippingIngrid']> = {
  deliveries?: Resolver<Maybe<Array<ResolversTypes['IngridDeliveries']>>, ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderShippingKlarnaShippingAssistantResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderShippingKlarnaShippingAssistant'] = ResolversParentTypes['OrderShippingKlarnaShippingAssistant']> = {
  klarnaMerchantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  klarnaOrderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  selectedShippingOption?: Resolver<ResolversTypes['KlarnaShippingAssistantSelectedShippingOption'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderShippingNshiftResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderShippingNshift'] = ResolversParentTypes['OrderShippingNshift']> = {
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  preparedShipment?: Resolver<Maybe<ResolversTypes['NshiftPreparedShipment']>, ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderShippingQliroIntegratedShippingResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderShippingQliroIntegratedShipping'] = ResolversParentTypes['OrderShippingQliroIntegratedShipping']> = {
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shipments?: Resolver<Array<ResolversTypes['QliroShipmentOrderItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderShippingWalleyDeliveryModuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderShippingWalleyDeliveryModule'] = ResolversParentTypes['OrderShippingWalleyDeliveryModule']> = {
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  purchaseIdentifier?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shipping?: Resolver<ResolversTypes['WalleyDeliveryModuleShipping'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderStatus'] = ResolversParentTypes['OrderStatus']> = {
  history?: Resolver<Array<ResolversTypes['OrderStatusLogEntry']>, ParentType, ContextType>;
  operations?: Resolver<Maybe<ResolversTypes['OrderStatusOperations']>, ParentType, ContextType>;
  states?: Resolver<Array<ResolversTypes['OrderState']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderStatusLogEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderStatusLogEntry'] = ResolversParentTypes['OrderStatusLogEntry']> = {
  errorMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  operation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  operationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderStatusOperationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderStatusOperations'] = ResolversParentTypes['OrderStatusOperations']> = {
  compensated?: Resolver<ResolversTypes['OrderStatusTransactionsCompensated'], ParentType, ContextType>;
  delivered?: Resolver<ResolversTypes['OrderStatusTransactions'], ParentType, ContextType>;
  refunded?: Resolver<ResolversTypes['OrderStatusTransactions'], ParentType, ContextType>;
  released?: Resolver<ResolversTypes['OrderStatusTransactions'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderStatusTransactionBonusResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderStatusTransactionBonus'] = ResolversParentTypes['OrderStatusTransactionBonus']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderStatusTransactionGiftResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderStatusTransactionGift'] = ResolversParentTypes['OrderStatusTransactionGift']> = {
  giftId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderStatusTransactionGiftCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderStatusTransactionGiftCard'] = ResolversParentTypes['OrderStatusTransactionGiftCard']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  giftCardId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderStatusTransactionGiftCardProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderStatusTransactionGiftCardProduct'] = ResolversParentTypes['OrderStatusTransactionGiftCardProduct']> = {
  giftCardProductId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderStatusTransactionOrderLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderStatusTransactionOrderLine'] = ResolversParentTypes['OrderStatusTransactionOrderLine']> = {
  orderLineId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderStatusTransactionPaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderStatusTransactionPayment'] = ResolversParentTypes['OrderStatusTransactionPayment']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderStatusTransactionShippingFeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderStatusTransactionShippingFee'] = ResolversParentTypes['OrderStatusTransactionShippingFee']> = {
  shippingFeeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderStatusTransactionVoucherResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderStatusTransactionVoucher'] = ResolversParentTypes['OrderStatusTransactionVoucher']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  voucherId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderStatusTransactionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderStatusTransactions'] = ResolversParentTypes['OrderStatusTransactions']> = {
  bonus?: Resolver<Maybe<ResolversTypes['OrderStatusTransactionBonus']>, ParentType, ContextType>;
  giftCardProducts?: Resolver<Array<ResolversTypes['OrderStatusTransactionGiftCardProduct']>, ParentType, ContextType>;
  giftCards?: Resolver<Array<ResolversTypes['OrderStatusTransactionGiftCard']>, ParentType, ContextType>;
  gifts?: Resolver<Array<ResolversTypes['OrderStatusTransactionGift']>, ParentType, ContextType>;
  orderLines?: Resolver<Array<ResolversTypes['OrderStatusTransactionOrderLine']>, ParentType, ContextType>;
  shippingFees?: Resolver<Array<ResolversTypes['OrderStatusTransactionShippingFee']>, ParentType, ContextType>;
  vouchers?: Resolver<Array<ResolversTypes['OrderStatusTransactionVoucher']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderStatusTransactionsCompensatedResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderStatusTransactionsCompensated'] = ResolversParentTypes['OrderStatusTransactionsCompensated']> = {
  bonus?: Resolver<Maybe<ResolversTypes['OrderStatusTransactionBonus']>, ParentType, ContextType>;
  giftCards?: Resolver<Array<ResolversTypes['OrderStatusTransactionGiftCard']>, ParentType, ContextType>;
  payment?: Resolver<Maybe<ResolversTypes['OrderStatusTransactionPayment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderTotalsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderTotals'] = ResolversParentTypes['OrderTotals']> = {
  bonusTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  discountTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  giftCardTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  grandTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shippingTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  subTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  voucherTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderVoucherResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderVoucher'] = ResolversParentTypes['OrderVoucher']> = {
  __resolveType: TypeResolveFn<'OrderVoucherEmpty' | 'OrderVoucherVoyado', ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type OrderVoucherEmptyResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderVoucherEmpty'] = ResolversParentTypes['OrderVoucherEmpty']> = {
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderVoucherItemVoyadoResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderVoucherItemVoyado'] = ResolversParentTypes['OrderVoucherItemVoyado']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  checkNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  failedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderVoucherVoyadoResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderVoucherVoyado'] = ResolversParentTypes['OrderVoucherVoyado']> = {
  contactId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vouchers?: Resolver<Maybe<Array<ResolversTypes['OrderVoucherItemVoyado']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OutcomeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Outcome'] = ResolversParentTypes['Outcome']> = {
  combineItemDiscount?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  discountItemRule?: Resolver<Maybe<ResolversTypes['ItemRule']>, ParentType, ContextType>;
  discountQuantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  discountValue?: Resolver<Maybe<ResolversTypes['DiscountValue']>, ParentType, ContextType>;
  gifts?: Resolver<Maybe<Array<ResolversTypes['Gift']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isRecurrent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  subOutcomes?: Resolver<Maybe<Array<ResolversTypes['SubOutcome']>>, ParentType, ContextType>;
  triggerItemRule?: Resolver<Maybe<ResolversTypes['ItemRule']>, ParentType, ContextType>;
  triggerQuantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentProviderResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentProvider'] = ResolversParentTypes['PaymentProvider']> = {
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['PaymentProviderName'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ProviderStatusLog']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlatformConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlatformConfig'] = ResolversParentTypes['PlatformConfig']> = {
  externalApi?: Resolver<ResolversTypes['ExternalApi'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Price'] = ResolversParentTypes['Price']> = {
  basePriceAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  campaign?: Resolver<Maybe<ResolversTypes['CampaignIdentity']>, ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  discountAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  lowestPriceRecord?: Resolver<Maybe<ResolversTypes['PriceRecord']>, ParentType, ContextType>;
  priceRecords?: Resolver<Array<ResolversTypes['PriceRecord']>, ParentType, ContextType>;
  referencePriceAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  salePriceAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceRecordResolvers<ContextType = any, ParentType extends ResolversParentTypes['PriceRecord'] = ResolversParentTypes['PriceRecord']> = {
  basePriceAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  campaign?: Resolver<Maybe<ResolversTypes['CampaignIdentity']>, ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  salePriceAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceRuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['PriceRule'] = ResolversParentTypes['PriceRule']> = {
  monies?: Resolver<Maybe<Array<ResolversTypes['CampaignMoney']>>, ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  percentageDecimals?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductParentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductParent'] = ResolversParentTypes['ProductParent']> = {
  Variants?: Resolver<Maybe<Array<ResolversTypes['ProductVariant']>>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  customAttributes?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayDescriptions?: Resolver<ResolversTypes['AWSJSON'], ParentType, ContextType>;
  displayNames?: Resolver<ResolversTypes['AWSJSON'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isArchived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tags?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductParentSearchHitResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductParentSearchHit'] = ResolversParentTypes['ProductParentSearchHit']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isArchived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<ResolversTypes['KeyValue']>>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductParentSearchHitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductParentSearchHits'] = ResolversParentTypes['ProductParentSearchHits']> = {
  hits?: Resolver<Array<ResolversTypes['ProductParentSearchHit']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductVariantResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductVariant'] = ResolversParentTypes['ProductVariant']> = {
  Stock?: Resolver<Maybe<ResolversTypes['Stock']>, ParentType, ContextType>;
  StoreGroupPrices?: Resolver<Array<ResolversTypes['StoreGroupPrice']>, ParentType, ContextType>;
  TaxGroup?: Resolver<ResolversTypes['TaxGroup'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  customAttributes?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dimensions?: Resolver<Maybe<ResolversTypes['Dimensions']>, ParentType, ContextType>;
  displayDescriptions?: Resolver<ResolversTypes['AWSJSON'], ParentType, ContextType>;
  displayNames?: Resolver<ResolversTypes['AWSJSON'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isArchived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productParentId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  shippingAttributes?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  tags?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductVariantReservationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductVariantReservation'] = ResolversParentTypes['ProductVariantReservation']> = {
  inventoryId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productVariantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductVariantRulesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductVariantRules'] = ResolversParentTypes['ProductVariantRules']> = {
  tagLogic?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductVariantSearchHitResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductVariantSearchHit'] = ResolversParentTypes['ProductVariantSearchHit']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  imageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isArchived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productParentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<ResolversTypes['KeyValue']>>, ParentType, ContextType>;
  taxGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductVariantSearchHitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductVariantSearchHits'] = ResolversParentTypes['ProductVariantSearchHits']> = {
  hits?: Resolver<Array<ResolversTypes['ProductVariantSearchHit']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PromotionProviderResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromotionProvider'] = ResolversParentTypes['PromotionProvider']> = {
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['PromotionProviderName'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProviderResolvers<ContextType = any, ParentType extends ResolversParentTypes['Provider'] = ResolversParentTypes['Provider']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProviderStatusLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProviderStatusLog'] = ResolversParentTypes['ProviderStatusLog']> = {
  current?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  history?: Resolver<Array<ResolversTypes['ProviderStatusLogEntry']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProviderStatusLogEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProviderStatusLogEntry'] = ResolversParentTypes['ProviderStatusLogEntry']> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QliroOneConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['QliroOneConfig'] = ResolversParentTypes['QliroOneConfig']> = {
  baseUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  credentials?: Resolver<ResolversTypes['QliroOneCredentials'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  integratedShipping?: Resolver<Maybe<ResolversTypes['QliroOneIntegratedShipping']>, ParentType, ContextType>;
  merchantOrderManagementStatusPushUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  useLegacyIds?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QliroOneCredentialsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QliroOneCredentials'] = ResolversParentTypes['QliroOneCredentials']> = {
  merchantApiKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  merchantApiSecret?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QliroOneIntegratedShippingResolvers<ContextType = any, ParentType extends ResolversParentTypes['QliroOneIntegratedShipping'] = ResolversParentTypes['QliroOneIntegratedShipping']> = {
  deliveryCheckoutId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QliroShipmentOrderItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['QliroShipmentOrderItem'] = ResolversParentTypes['QliroShipmentOrderItem']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  merchantReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['QliroShipmentOrderItemMetadata']>, ParentType, ContextType>;
  paymentTransactionId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pricePerItemExVat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  pricePerItemIncVat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QliroShipmentOrderItemMetadataResolvers<ContextType = any, ParentType extends ResolversParentTypes['QliroShipmentOrderItemMetadata'] = ResolversParentTypes['QliroShipmentOrderItemMetadata']> = {
  shippingMethodMerchantReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  Configuration?: Resolver<Maybe<ResolversTypes['QueryConfigurationManagement']>, ParentType, ContextType>;
  Order?: Resolver<ResolversTypes['QueryOrderManagement'], ParentType, ContextType>;
  PlatformConfig?: Resolver<Maybe<ResolversTypes['QueryPlatformConfig']>, ParentType, ContextType>;
  Product?: Resolver<ResolversTypes['QueryProductManagement'], ParentType, ContextType>;
  apiClient?: Resolver<ResolversTypes['ApiClientDetails'], ParentType, ContextType, RequireFields<QueryApiClientArgs, 'clientId'>>;
  apiClients?: Resolver<ResolversTypes['ApiClientsList'], ParentType, ContextType, RequireFields<QueryApiClientsArgs, 'limit'>>;
  apiResources?: Resolver<Array<ResolversTypes['ApiResource']>, ParentType, ContextType>;
  getAdyenConfig?: Resolver<ResolversTypes['AdyenConfig'], ParentType, ContextType, RequireFields<QueryGetAdyenConfigArgs, 'id'>>;
  getAdyenWebhooks?: Resolver<Array<ResolversTypes['AdyenWebhookLegacy']>, ParentType, ContextType>;
  getBSGGiftCardConfig?: Resolver<ResolversTypes['BSGGiftCardConfig'], ParentType, ContextType, RequireFields<QueryGetBsgGiftCardConfigArgs, 'id'>>;
  getBonusConfig?: Resolver<ResolversTypes['BonusConfig'], ParentType, ContextType, RequireFields<QueryGetBonusConfigArgs, 'id'>>;
  getBonusConfigs?: Resolver<Array<ResolversTypes['CapabilityProvider']>, ParentType, ContextType>;
  getBundleGroup?: Resolver<ResolversTypes['BundleGroup'], ParentType, ContextType, RequireFields<QueryGetBundleGroupArgs, 'id'>>;
  getCampaign?: Resolver<ResolversTypes['Campaign'], ParentType, ContextType, RequireFields<QueryGetCampaignArgs, 'id'>>;
  getCampaignGroup?: Resolver<ResolversTypes['CampaignGroupList'], ParentType, ContextType, RequireFields<QueryGetCampaignGroupArgs, 'id'>>;
  getDiscountCode?: Resolver<ResolversTypes['DiscountCode'], ParentType, ContextType, RequireFields<QueryGetDiscountCodeArgs, 'code'>>;
  getDiscountCodeRule?: Resolver<ResolversTypes['DiscountCodeRule'], ParentType, ContextType, RequireFields<QueryGetDiscountCodeRuleArgs, 'id'>>;
  getDiscountExternalRule?: Resolver<ResolversTypes['DiscountExternalRule'], ParentType, ContextType, RequireFields<QueryGetDiscountExternalRuleArgs, 'id'>>;
  getDiscountRule?: Resolver<ResolversTypes['DiscountRule'], ParentType, ContextType, RequireFields<QueryGetDiscountRuleArgs, 'id'>>;
  getExternalEventsDestinationAPIs?: Resolver<ResolversTypes['ExternalEventDestinationAPIs'], ParentType, ContextType>;
  getExternalEventsDestinationEventBuses?: Resolver<ResolversTypes['ExternalEventDestinationEventBuses'], ParentType, ContextType>;
  getExternalEventsRule?: Resolver<ResolversTypes['ExternalEventRuleState'], ParentType, ContextType, RequireFields<QueryGetExternalEventsRuleArgs, 'ruleName'>>;
  getExternalEventsRules?: Resolver<ResolversTypes['ExternalEventRules'], ParentType, ContextType>;
  getGenerateCodes?: Resolver<ResolversTypes['GenerateCodes'], ParentType, ContextType, RequireFields<QueryGetGenerateCodesArgs, 'jobId'>>;
  getGiftCardProviders?: Resolver<Array<ResolversTypes['Provider']>, ParentType, ContextType>;
  getIngridConfig?: Resolver<ResolversTypes['IngridConfig'], ParentType, ContextType, RequireFields<QueryGetIngridConfigArgs, 'id'>>;
  getInventories?: Resolver<Array<ResolversTypes['Inventory']>, ParentType, ContextType>;
  getInventory?: Resolver<ResolversTypes['Inventory'], ParentType, ContextType, RequireFields<QueryGetInventoryArgs, 'id'>>;
  getInventoryStoreMarket?: Resolver<ResolversTypes['InventoryStoreMarket'], ParentType, ContextType, RequireFields<QueryGetInventoryStoreMarketArgs, 'countryCode' | 'storeGroupId'>>;
  getKBSGiftCardConfig?: Resolver<ResolversTypes['KBSGiftCardConfig'], ParentType, ContextType, RequireFields<QueryGetKbsGiftCardConfigArgs, 'id'>>;
  getKlarnaCheckoutConfig?: Resolver<ResolversTypes['KlarnaCheckoutConfig'], ParentType, ContextType, RequireFields<QueryGetKlarnaCheckoutConfigArgs, 'id'>>;
  getNShiftConfig?: Resolver<ResolversTypes['NShiftConfig'], ParentType, ContextType, RequireFields<QueryGetNShiftConfigArgs, 'id'>>;
  getPaymentProviders?: Resolver<Array<ResolversTypes['Provider']>, ParentType, ContextType>;
  getQliroOneConfig?: Resolver<ResolversTypes['QliroOneConfig'], ParentType, ContextType, RequireFields<QueryGetQliroOneConfigArgs, 'id'>>;
  getRetain24Config?: Resolver<ResolversTypes['Retain24Config'], ParentType, ContextType, RequireFields<QueryGetRetain24ConfigArgs, 'id'>>;
  getShippingProviders?: Resolver<Array<ResolversTypes['Provider']>, ParentType, ContextType>;
  getShippingTaxGroup?: Resolver<ResolversTypes['ShippingTaxGroup'], ParentType, ContextType>;
  getStoreGroup?: Resolver<ResolversTypes['StoreGroup'], ParentType, ContextType, RequireFields<QueryGetStoreGroupArgs, 'id'>>;
  getStoreGroups?: Resolver<Array<ResolversTypes['StoreGroup']>, ParentType, ContextType>;
  getSveaCheckoutConfig?: Resolver<ResolversTypes['SveaCheckoutConfig'], ParentType, ContextType, RequireFields<QueryGetSveaCheckoutConfigArgs, 'id'>>;
  getTaxGroup?: Resolver<ResolversTypes['TaxGroup'], ParentType, ContextType, RequireFields<QueryGetTaxGroupArgs, 'id'>>;
  getTaxMarkets?: Resolver<Array<ResolversTypes['TaxMarket']>, ParentType, ContextType, RequireFields<QueryGetTaxMarketsArgs, 'taxGroupId'>>;
  getVoyadoConfig?: Resolver<ResolversTypes['VoyadoConfig'], ParentType, ContextType, RequireFields<QueryGetVoyadoConfigArgs, 'id'>>;
  getVoyadoConfigs?: Resolver<Array<ResolversTypes['CapabilityProvider']>, ParentType, ContextType>;
  getWalleyCheckoutConfig?: Resolver<ResolversTypes['WalleyCheckoutConfig'], ParentType, ContextType, RequireFields<QueryGetWalleyCheckoutConfigArgs, 'id'>>;
  groupUsers?: Resolver<ResolversTypes['UsersList'], ParentType, ContextType, RequireFields<QueryGroupUsersArgs, 'groupname' | 'limit'>>;
  groups?: Resolver<Maybe<ResolversTypes['GroupsList']>, ParentType, ContextType, RequireFields<QueryGroupsArgs, 'limit'>>;
  searchBundleGroups?: Resolver<Maybe<ResolversTypes['BundleGroupSearchHits']>, ParentType, ContextType, RequireFields<QuerySearchBundleGroupsArgs, 'from' | 'size'>>;
  searchCampaignGroups?: Resolver<Maybe<ResolversTypes['CampaignGroupSearchHits']>, ParentType, ContextType, RequireFields<QuerySearchCampaignGroupsArgs, 'from' | 'size'>>;
  searchCampaigns?: Resolver<Maybe<ResolversTypes['CampaignSearchHits']>, ParentType, ContextType, RequireFields<QuerySearchCampaignsArgs, 'from' | 'size'>>;
  searchCodesGenerations?: Resolver<Maybe<ResolversTypes['CodesGenerationSearchHits']>, ParentType, ContextType, RequireFields<QuerySearchCodesGenerationsArgs, 'from' | 'size'>>;
  searchDiscountCodeRules?: Resolver<Maybe<ResolversTypes['DiscountCodeRuleSearchHits']>, ParentType, ContextType, RequireFields<QuerySearchDiscountCodeRulesArgs, 'from' | 'size'>>;
  searchDiscountCodes?: Resolver<Maybe<ResolversTypes['DiscountCodeSearchHits']>, ParentType, ContextType, RequireFields<QuerySearchDiscountCodesArgs, 'from' | 'size'>>;
  searchDiscountExternalRules?: Resolver<Maybe<ResolversTypes['DiscountExternalRuleSearchHits']>, ParentType, ContextType, RequireFields<QuerySearchDiscountExternalRulesArgs, 'from' | 'size'>>;
  searchDiscountRules?: Resolver<Maybe<ResolversTypes['DiscountRuleSearchHits']>, ParentType, ContextType, RequireFields<QuerySearchDiscountRulesArgs, 'from' | 'size'>>;
  searchOrders?: Resolver<Maybe<ResolversTypes['OrderSearchHits']>, ParentType, ContextType, RequireFields<QuerySearchOrdersArgs, 'from' | 'size'>>;
  searchProductParents?: Resolver<Maybe<ResolversTypes['ProductParentSearchHits']>, ParentType, ContextType, RequireFields<QuerySearchProductParentsArgs, 'from' | 'size'>>;
  searchProductVariants?: Resolver<Maybe<ResolversTypes['ProductVariantSearchHits']>, ParentType, ContextType, RequireFields<QuerySearchProductVariantsArgs, 'from' | 'size'>>;
  searchStoreGroups?: Resolver<Maybe<ResolversTypes['StoreGroupSearchHits']>, ParentType, ContextType, RequireFields<QuerySearchStoreGroupsArgs, 'from' | 'size'>>;
  searchStoreMarkets?: Resolver<Maybe<ResolversTypes['StoreMarketSearchHits']>, ParentType, ContextType, RequireFields<QuerySearchStoreMarketsArgs, 'from' | 'size'>>;
  searchTaxGroups?: Resolver<Maybe<ResolversTypes['TaxGroupSearchHits']>, ParentType, ContextType, RequireFields<QuerySearchTaxGroupsArgs, 'from' | 'size'>>;
  searchTaxMarkets?: Resolver<Maybe<ResolversTypes['TaxMarketSearchHits']>, ParentType, ContextType, RequireFields<QuerySearchTaxMarketsArgs, 'from' | 'size'>>;
  tagKeys?: Resolver<Maybe<ResolversTypes['TagKeys']>, ParentType, ContextType>;
  tagValues?: Resolver<Maybe<ResolversTypes['TagValues']>, ParentType, ContextType, RequireFields<QueryTagValuesArgs, 'key'>>;
  user?: Resolver<ResolversTypes['UserDetails'], ParentType, ContextType, RequireFields<QueryUserArgs, 'username'>>;
  users?: Resolver<ResolversTypes['UsersList'], ParentType, ContextType, RequireFields<QueryUsersArgs, 'limit'>>;
};

export type QueryConfigurationManagementResolvers<ContextType = any, ParentType extends ResolversParentTypes['QueryConfigurationManagement'] = ResolversParentTypes['QueryConfigurationManagement']> = {
  validate?: Resolver<Maybe<ResolversTypes['QueryConfigurationManagementValidate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryConfigurationManagementValidateResolvers<ContextType = any, ParentType extends ResolversParentTypes['QueryConfigurationManagementValidate'] = ResolversParentTypes['QueryConfigurationManagementValidate']> = {
  adyen?: Resolver<ResolversTypes['ValidateConfigResult'], ParentType, ContextType, RequireFields<QueryConfigurationManagementValidateAdyenArgs, 'baseUrl' | 'merchantAccount' | 'xApiKey'>>;
  bonus?: Resolver<ResolversTypes['ValidateConfigResult'], ParentType, ContextType, RequireFields<QueryConfigurationManagementValidateBonusArgs, 'input'>>;
  klarnaCheckout?: Resolver<ResolversTypes['ValidateConfigResult'], ParentType, ContextType, RequireFields<QueryConfigurationManagementValidateKlarnaCheckoutArgs, 'baseUrl' | 'password' | 'username'>>;
  qliroOne?: Resolver<ResolversTypes['ValidateConfigResult'], ParentType, ContextType, RequireFields<QueryConfigurationManagementValidateQliroOneArgs, 'input'>>;
  retain24?: Resolver<ResolversTypes['ValidateConfigResult'], ParentType, ContextType, RequireFields<QueryConfigurationManagementValidateRetain24Args, 'input'>>;
  sveaCheckout?: Resolver<ResolversTypes['ValidateConfigResult'], ParentType, ContextType, RequireFields<QueryConfigurationManagementValidateSveaCheckoutArgs, 'baseUrl' | 'checkoutSecret' | 'merchantId'>>;
  walleyCheckout?: Resolver<ResolversTypes['ValidateConfigResult'], ParentType, ContextType, RequireFields<QueryConfigurationManagementValidateWalleyCheckoutArgs, 'input'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryOrderManagementResolvers<ContextType = any, ParentType extends ResolversParentTypes['QueryOrderManagement'] = ResolversParentTypes['QueryOrderManagement']> = {
  cancellation?: Resolver<ResolversTypes['OrderCancellation'], ParentType, ContextType, RequireFields<QueryOrderManagementCancellationArgs, 'cancellationId'>>;
  compensation?: Resolver<ResolversTypes['OrderCompensation'], ParentType, ContextType, RequireFields<QueryOrderManagementCompensationArgs, 'compensationId'>>;
  delivery?: Resolver<ResolversTypes['OrderDelivery'], ParentType, ContextType, RequireFields<QueryOrderManagementDeliveryArgs, 'deliveryId'>>;
  details?: Resolver<ResolversTypes['Order'], ParentType, ContextType, RequireFields<QueryOrderManagementDetailsArgs, 'id'>>;
  refund?: Resolver<ResolversTypes['OrderRefund'], ParentType, ContextType, RequireFields<QueryOrderManagementRefundArgs, 'refundId'>>;
  release?: Resolver<ResolversTypes['OrderRelease'], ParentType, ContextType, RequireFields<QueryOrderManagementReleaseArgs, 'releaseId'>>;
  status?: Resolver<ResolversTypes['OrderStatus'], ParentType, ContextType, RequireFields<QueryOrderManagementStatusArgs, 'id'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryPlatformConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['QueryPlatformConfig'] = ResolversParentTypes['QueryPlatformConfig']> = {
  campaign?: Resolver<Maybe<ResolversTypes['PlatformConfig']>, ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['PlatformConfig']>, ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['PlatformConfig']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['PlatformConfig']>, ParentType, ContextType>;
  product?: Resolver<Maybe<ResolversTypes['PlatformConfig']>, ParentType, ContextType>;
  stock?: Resolver<Maybe<ResolversTypes['PlatformConfig']>, ParentType, ContextType>;
  store?: Resolver<Maybe<ResolversTypes['PlatformConfig']>, ParentType, ContextType>;
  tax?: Resolver<Maybe<ResolversTypes['PlatformConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryProductManagementResolvers<ContextType = any, ParentType extends ResolversParentTypes['QueryProductManagement'] = ResolversParentTypes['QueryProductManagement']> = {
  parent?: Resolver<ResolversTypes['ProductParent'], ParentType, ContextType, RequireFields<QueryProductManagementParentArgs, 'id'>>;
  variant?: Resolver<ResolversTypes['ProductVariant'], ParentType, ContextType, RequireFields<QueryProductManagementVariantArgs, 'id'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReasonResolvers<ContextType = any, ParentType extends ResolversParentTypes['Reason'] = ResolversParentTypes['Reason']> = {
  cause?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RefundBonusResolvers<ContextType = any, ParentType extends ResolversParentTypes['RefundBonus'] = ResolversParentTypes['RefundBonus']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reservationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transactionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RefundFeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RefundFee'] = ResolversParentTypes['RefundFee']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RefundGiftResolvers<ContextType = any, ParentType extends ResolversParentTypes['RefundGift'] = ResolversParentTypes['RefundGift']> = {
  giftId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RefundGiftCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['RefundGiftCard'] = ResolversParentTypes['RefundGiftCard']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  giftCardId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RefundOrderLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['RefundOrderLine'] = ResolversParentTypes['RefundOrderLine']> = {
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderLineId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalDiscountAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RefundShippingFeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RefundShippingFee'] = ResolversParentTypes['RefundShippingFee']> = {
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shippingFeeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  taxPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalDiscountAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RefundVoucherResolvers<ContextType = any, ParentType extends ResolversParentTypes['RefundVoucher'] = ResolversParentTypes['RefundVoucher']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  voucherId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RefundedPaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['RefundedPayment'] = ResolversParentTypes['RefundedPayment']> = {
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReleaseBonusResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReleaseBonus'] = ResolversParentTypes['ReleaseBonus']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reservationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transactionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReleaseGiftResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReleaseGift'] = ResolversParentTypes['ReleaseGift']> = {
  giftId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReleaseGiftCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReleaseGiftCard'] = ResolversParentTypes['ReleaseGiftCard']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  giftCardId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transactionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReleaseGiftCardProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReleaseGiftCardProduct'] = ResolversParentTypes['ReleaseGiftCardProduct']> = {
  cancelErrorMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cancelErrorStatusCode?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cancelTransactionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createFailedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createTransactionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  giftCardProductId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  priceAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReleaseOrderLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReleaseOrderLine'] = ResolversParentTypes['ReleaseOrderLine']> = {
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderLineId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalDiscountAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReleaseShippingFeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReleaseShippingFee'] = ResolversParentTypes['ReleaseShippingFee']> = {
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shippingFeeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  taxPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalDiscountAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReleasedPaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReleasedPayment'] = ResolversParentTypes['ReleasedPayment']> = {
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Retain24ConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['Retain24Config'] = ResolversParentTypes['Retain24Config']> = {
  baseUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  credentials?: Resolver<ResolversTypes['Retain24Credentials'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  redeem?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  simulationKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Retain24CredentialsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Retain24Credentials'] = ResolversParentTypes['Retain24Credentials']> = {
  certificate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  privateKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoundingRuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['RoundingRule'] = ResolversParentTypes['RoundingRule']> = {
  roundDown?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  roundUp?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  targets?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoundingRulesResolvers<ContextType = any, ParentType extends ResolversParentTypes['RoundingRules'] = ResolversParentTypes['RoundingRules']> = {
  defaultRule?: Resolver<ResolversTypes['RoundingRule'], ParentType, ContextType>;
  marketRules?: Resolver<Maybe<Array<ResolversTypes['MarketRoundingRule']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['Rule'] = ResolversParentTypes['Rule']> = {
  discountAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  discountRuleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SchemaResolvers<ContextType = any, ParentType extends ResolversParentTypes['Schema'] = ResolversParentTypes['Schema']> = {
  mutation?: Resolver<Maybe<ResolversTypes['Mutation']>, ParentType, ContextType>;
  query?: Resolver<Maybe<ResolversTypes['Query']>, ParentType, ContextType>;
  subscriptions?: Resolver<Maybe<ResolversTypes['Subscription']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShippingFeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShippingFee'] = ResolversParentTypes['ShippingFee']> = {
  basePriceAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  currencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  discountAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  salePriceAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  taxAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  taxGroupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taxPercentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShippingProviderResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShippingProvider'] = ResolversParentTypes['ShippingProvider']> = {
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['ShippingProviderName'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ProviderStatusLog']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShippingTaxGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShippingTaxGroup'] = ResolversParentTypes['ShippingTaxGroup']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  taxGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StockResolvers<ContextType = any, ParentType extends ResolversParentTypes['Stock'] = ResolversParentTypes['Stock']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inventories?: Resolver<Array<ResolversTypes['StockInventory']>, ParentType, ContextType>;
  stockQuantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  validateStock?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StockInventoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['StockInventory'] = ResolversParentTypes['StockInventory']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  inventoryId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StoreGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['StoreGroup'] = ResolversParentTypes['StoreGroup']> = {
  channelType?: Resolver<ResolversTypes['ChannelType'], ParentType, ContextType>;
  countryOfSale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  storeMarkets?: Resolver<Maybe<Array<ResolversTypes['StoreMarket']>>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StoreGroupPriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['StoreGroupPrice'] = ResolversParentTypes['StoreGroupPrice']> = {
  marketPrices?: Resolver<Array<ResolversTypes['MarketPrice']>, ParentType, ContextType>;
  storeGroupId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StoreGroupSearchHitResolvers<ContextType = any, ParentType extends ResolversParentTypes['StoreGroupSearchHit'] = ResolversParentTypes['StoreGroupSearchHit']> = {
  countryOfSale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StoreGroupSearchHitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['StoreGroupSearchHits'] = ResolversParentTypes['StoreGroupSearchHits']> = {
  hits?: Resolver<Array<ResolversTypes['StoreGroupSearchHit']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StoreMarketResolvers<ContextType = any, ParentType extends ResolversParentTypes['StoreMarket'] = ResolversParentTypes['StoreMarket']> = {
  capabilities?: Resolver<Maybe<ResolversTypes['Capabilities']>, ParentType, ContextType>;
  cartCapabilities?: Resolver<Maybe<ResolversTypes['CartCapabilities']>, ParentType, ContextType>;
  channelType?: Resolver<ResolversTypes['ChannelType'], ParentType, ContextType>;
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  countryOfSale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isArchived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isTaxExemptionEligible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isTaxIncludedInPrice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  storeGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StoreMarketSearchHitResolvers<ContextType = any, ParentType extends ResolversParentTypes['StoreMarketSearchHit'] = ResolversParentTypes['StoreMarketSearchHit']> = {
  cartCapabilities?: Resolver<Maybe<ResolversTypes['CartCapabilities']>, ParentType, ContextType>;
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  giftCardProviders?: Resolver<Maybe<Array<ResolversTypes['Provider']>>, ParentType, ContextType>;
  isArchived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isTaxExemptionEligible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isTaxIncludedInPrice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentProviders?: Resolver<Maybe<Array<ResolversTypes['Provider']>>, ParentType, ContextType>;
  shippingProviders?: Resolver<Maybe<Array<ResolversTypes['Provider']>>, ParentType, ContextType>;
  storeGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxProviders?: Resolver<Maybe<Array<ResolversTypes['Provider']>>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StoreMarketSearchHitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['StoreMarketSearchHits'] = ResolversParentTypes['StoreMarketSearchHits']> = {
  hits?: Resolver<Array<ResolversTypes['StoreMarketSearchHit']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubOutcomeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubOutcome'] = ResolversParentTypes['SubOutcome']> = {
  discountQuantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  discountValue?: Resolver<Maybe<ResolversTypes['DiscountValue']>, ParentType, ContextType>;
  triggerQuantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = {
  OnAdyenWebhookChanged?: SubscriptionResolver<Maybe<ResolversTypes['AdyenWebhook']>, "OnAdyenWebhookChanged", ParentType, ContextType, RequireFields<SubscriptionOnAdyenWebhookChangedArgs, 'configId'>>;
};

export type SveaCheckoutConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['SveaCheckoutConfig'] = ResolversParentTypes['SveaCheckoutConfig']> = {
  checkoutBaseUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  credentials?: Resolver<ResolversTypes['SveaCredentials'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  merchantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentAdminBaseUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SveaCredentialsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SveaCredentials'] = ResolversParentTypes['SveaCredentials']> = {
  apiKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagConditionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TagCondition'] = ResolversParentTypes['TagCondition']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  values?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagKeysResolvers<ContextType = any, ParentType extends ResolversParentTypes['TagKeys'] = ResolversParentTypes['TagKeys']> = {
  keys?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagValuesResolvers<ContextType = any, ParentType extends ResolversParentTypes['TagValues'] = ResolversParentTypes['TagValues']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  values?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaxGroup'] = ResolversParentTypes['TaxGroup']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxGroupSearchHitResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaxGroupSearchHit'] = ResolversParentTypes['TaxGroupSearchHit']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxGroupSearchHitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaxGroupSearchHits'] = ResolversParentTypes['TaxGroupSearchHits']> = {
  hits?: Resolver<Array<ResolversTypes['TaxGroupSearchHit']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxMarketResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaxMarket'] = ResolversParentTypes['TaxMarket']> = {
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  taxGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxMarketSearchHitResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaxMarketSearchHit'] = ResolversParentTypes['TaxMarketSearchHit']> = {
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  taxGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxPercentageDecimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxMarketSearchHitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaxMarketSearchHits'] = ResolversParentTypes['TaxMarketSearchHits']> = {
  hits?: Resolver<Array<ResolversTypes['TaxMarketSearchHit']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsagePlanResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsagePlan'] = ResolversParentTypes['UsagePlan']> = {
  burstLimit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quotaLimit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  quotaPeriod?: Resolver<ResolversTypes['QuotaPeriod'], ParentType, ContextType>;
  rateLimit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  familyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  givenName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['UserStatus'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserDetails'] = ResolversParentTypes['UserDetails']> = {
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  familyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  givenName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  groups?: Resolver<Array<ResolversTypes['Group']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['UserStatus'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersListResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersList'] = ResolversParentTypes['UsersList']> = {
  nextToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ValidDateRangeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ValidDateRange'] = ResolversParentTypes['ValidDateRange']> = {
  from?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  to?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ValidateConfigResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ValidateConfigResult'] = ResolversParentTypes['ValidateConfigResult']> = {
  errorMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  valid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VoucherProviderResolvers<ContextType = any, ParentType extends ResolversParentTypes['VoucherProvider'] = ResolversParentTypes['VoucherProvider']> = {
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerName?: Resolver<ResolversTypes['VoucherProviderName'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VoyadoConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['VoyadoConfig'] = ResolversParentTypes['VoyadoConfig']> = {
  baseUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  credentials?: Resolver<ResolversTypes['VoyadoCredentials'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VoyadoCredentialsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VoyadoCredentials'] = ResolversParentTypes['VoyadoCredentials']> = {
  apiKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WalleyCheckoutConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['WalleyCheckoutConfig'] = ResolversParentTypes['WalleyCheckoutConfig']> = {
  baseUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  credentials?: Resolver<ResolversTypes['WalleyCredentials'], ParentType, ContextType>;
  deliveryModuleEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  profileName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scope?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  storeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WalleyCredentialsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WalleyCredentials'] = ResolversParentTypes['WalleyCredentials']> = {
  clientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  secret?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WalleyDeliveryModuleDeliveryDateResolvers<ContextType = any, ParentType extends ResolversParentTypes['WalleyDeliveryModuleDeliveryDate'] = ResolversParentTypes['WalleyDeliveryModuleDeliveryDate']> = {
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  timeFrom?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timeTo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WalleyDeliveryModuleDestinationResolvers<ContextType = any, ParentType extends ResolversParentTypes['WalleyDeliveryModuleDestination'] = ResolversParentTypes['WalleyDeliveryModuleDestination']> = {
  deliveryDate?: Resolver<Maybe<ResolversTypes['WalleyDeliveryModuleDeliveryDate']>, ParentType, ContextType>;
  fee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WalleyDeliveryModuleOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['WalleyDeliveryModuleOption'] = ResolversParentTypes['WalleyDeliveryModuleOption']> = {
  Fee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  Type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WalleyDeliveryModuleShipmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['WalleyDeliveryModuleShipment'] = ResolversParentTypes['WalleyDeliveryModuleShipment']> = {
  bookedShipmentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  feeItemId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shippingChoice?: Resolver<ResolversTypes['WalleyDeliveryModuleShippingChoice'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WalleyDeliveryModuleShippingResolvers<ContextType = any, ParentType extends ResolversParentTypes['WalleyDeliveryModuleShipping'] = ResolversParentTypes['WalleyDeliveryModuleShipping']> = {
  provider?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shipments?: Resolver<Array<ResolversTypes['WalleyDeliveryModuleShipment']>, ParentType, ContextType>;
  shippingFee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WalleyDeliveryModuleShippingChoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['WalleyDeliveryModuleShippingChoice'] = ResolversParentTypes['WalleyDeliveryModuleShippingChoice']> = {
  destination?: Resolver<ResolversTypes['WalleyDeliveryModuleDestination'], ParentType, ContextType>;
  fee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  options?: Resolver<Array<ResolversTypes['WalleyDeliveryModuleOption']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AWSDateTime?: GraphQLScalarType;
  AWSEmail?: GraphQLScalarType;
  AWSJSON?: GraphQLScalarType;
  AddExternalEventsDestinationAPIResult?: AddExternalEventsDestinationApiResultResolvers<ContextType>;
  AddExternalEventsDestinationEventBusResult?: AddExternalEventsDestinationEventBusResultResolvers<ContextType>;
  AddExternalEventsRuleResult?: AddExternalEventsRuleResultResolvers<ContextType>;
  AdyenConfig?: AdyenConfigResolvers<ContextType>;
  AdyenCredentials?: AdyenCredentialsResolvers<ContextType>;
  AdyenWebhook?: AdyenWebhookResolvers<ContextType>;
  AdyenWebhookCredentials?: AdyenWebhookCredentialsResolvers<ContextType>;
  AdyenWebhookGenerationResult?: AdyenWebhookGenerationResultResolvers<ContextType>;
  AdyenWebhookLegacy?: AdyenWebhookLegacyResolvers<ContextType>;
  ApiClient?: ApiClientResolvers<ContextType>;
  ApiClientDetails?: ApiClientDetailsResolvers<ContextType>;
  ApiClientsList?: ApiClientsListResolvers<ContextType>;
  ApiResource?: ApiResourceResolvers<ContextType>;
  ApiScope?: ApiScopeResolvers<ContextType>;
  BSGGiftCardConfig?: BsgGiftCardConfigResolvers<ContextType>;
  BSGGiftCardCredentials?: BsgGiftCardCredentialsResolvers<ContextType>;
  BonusConfig?: BonusConfigResolvers<ContextType>;
  BonusCredentials?: BonusCredentialsResolvers<ContextType>;
  BonusProvider?: BonusProviderResolvers<ContextType>;
  Bundle?: BundleResolvers<ContextType>;
  BundleGroup?: BundleGroupResolvers<ContextType>;
  BundleGroupSearchHit?: BundleGroupSearchHitResolvers<ContextType>;
  BundleGroupSearchHits?: BundleGroupSearchHitsResolvers<ContextType>;
  BundleItemRule?: BundleItemRuleResolvers<ContextType>;
  BundleOutcome?: BundleOutcomeResolvers<ContextType>;
  BundleStoreMarket?: BundleStoreMarketResolvers<ContextType>;
  Campaign?: CampaignResolvers<ContextType>;
  CampaignDeletionResult?: CampaignDeletionResultResolvers<ContextType>;
  CampaignGroup?: CampaignGroupResolvers<ContextType>;
  CampaignGroupList?: CampaignGroupListResolvers<ContextType>;
  CampaignGroupSearchHit?: CampaignGroupSearchHitResolvers<ContextType>;
  CampaignGroupSearchHits?: CampaignGroupSearchHitsResolvers<ContextType>;
  CampaignIdentity?: CampaignIdentityResolvers<ContextType>;
  CampaignMoney?: CampaignMoneyResolvers<ContextType>;
  CampaignSearchHit?: CampaignSearchHitResolvers<ContextType>;
  CampaignSearchHits?: CampaignSearchHitsResolvers<ContextType>;
  CampaignStoreMarket?: CampaignStoreMarketResolvers<ContextType>;
  CancellationBonus?: CancellationBonusResolvers<ContextType>;
  CancellationGiftCard?: CancellationGiftCardResolvers<ContextType>;
  CancellationGiftCardProduct?: CancellationGiftCardProductResolvers<ContextType>;
  CancelledPayment?: CancelledPaymentResolvers<ContextType>;
  Capabilities?: CapabilitiesResolvers<ContextType>;
  CapabilityProvider?: CapabilityProviderResolvers<ContextType>;
  CartCapabilities?: CartCapabilitiesResolvers<ContextType>;
  CartRuleOutcome?: CartRuleOutcomeResolvers<ContextType>;
  CodeRuleOutcome?: CodeRuleOutcomeResolvers<ContextType>;
  CodesGenerationSearchHit?: CodesGenerationSearchHitResolvers<ContextType>;
  CodesGenerationSearchHits?: CodesGenerationSearchHitsResolvers<ContextType>;
  CompanyAttribute?: CompanyAttributeResolvers<ContextType>;
  CompensationBonus?: CompensationBonusResolvers<ContextType>;
  CompensationGiftCard?: CompensationGiftCardResolvers<ContextType>;
  CompensationOrderLine?: CompensationOrderLineResolvers<ContextType>;
  CompensationPayment?: CompensationPaymentResolvers<ContextType>;
  CompensationShippingFee?: CompensationShippingFeeResolvers<ContextType>;
  Condition?: ConditionResolvers<ContextType>;
  CreateGenerateCodesResult?: CreateGenerateCodesResultResolvers<ContextType>;
  DeletedResult?: DeletedResultResolvers<ContextType>;
  DeliveryBonus?: DeliveryBonusResolvers<ContextType>;
  DeliveryCapture?: DeliveryCaptureResolvers<ContextType>;
  DeliveryGift?: DeliveryGiftResolvers<ContextType>;
  DeliveryGiftCard?: DeliveryGiftCardResolvers<ContextType>;
  DeliveryGiftCardProduct?: DeliveryGiftCardProductResolvers<ContextType>;
  DeliveryOrderLine?: DeliveryOrderLineResolvers<ContextType>;
  DeliveryShippingFee?: DeliveryShippingFeeResolvers<ContextType>;
  DeliveryTracking?: DeliveryTrackingResolvers<ContextType>;
  DeliveryVoucher?: DeliveryVoucherResolvers<ContextType>;
  Dimensions?: DimensionsResolvers<ContextType>;
  DiscountCode?: DiscountCodeResolvers<ContextType>;
  DiscountCodeRule?: DiscountCodeRuleResolvers<ContextType>;
  DiscountCodeRuleSearchHit?: DiscountCodeRuleSearchHitResolvers<ContextType>;
  DiscountCodeRuleSearchHits?: DiscountCodeRuleSearchHitsResolvers<ContextType>;
  DiscountCodeSearchHit?: DiscountCodeSearchHitResolvers<ContextType>;
  DiscountCodeSearchHits?: DiscountCodeSearchHitsResolvers<ContextType>;
  DiscountExternalRule?: DiscountExternalRuleResolvers<ContextType>;
  DiscountExternalRuleSearchHit?: DiscountExternalRuleSearchHitResolvers<ContextType>;
  DiscountExternalRuleSearchHits?: DiscountExternalRuleSearchHitsResolvers<ContextType>;
  DiscountManagementQuotas?: DiscountManagementQuotasResolvers<ContextType>;
  DiscountRule?: DiscountRuleResolvers<ContextType>;
  DiscountRuleSearchHit?: DiscountRuleSearchHitResolvers<ContextType>;
  DiscountRuleSearchHits?: DiscountRuleSearchHitsResolvers<ContextType>;
  DiscountValue?: DiscountValueResolvers<ContextType>;
  ExternalApi?: ExternalApiResolvers<ContextType>;
  ExternalEventDestinationAPIs?: ExternalEventDestinationApIsResolvers<ContextType>;
  ExternalEventDestinationEventBuses?: ExternalEventDestinationEventBusesResolvers<ContextType>;
  ExternalEventDestinationsDetails?: ExternalEventDestinationsDetailsResolvers<ContextType>;
  ExternalEventRuleState?: ExternalEventRuleStateResolvers<ContextType>;
  ExternalEventRules?: ExternalEventRulesResolvers<ContextType>;
  ExternalRuleOutcome?: ExternalRuleOutcomeResolvers<ContextType>;
  GenerateCodes?: GenerateCodesResolvers<ContextType>;
  Gift?: GiftResolvers<ContextType>;
  GiftCardProductProvider?: GiftCardProductProviderResolvers<ContextType>;
  GiftCardProvider?: GiftCardProviderResolvers<ContextType>;
  Group?: GroupResolvers<ContextType>;
  GroupDetails?: GroupDetailsResolvers<ContextType>;
  GroupsList?: GroupsListResolvers<ContextType>;
  IngridConfig?: IngridConfigResolvers<ContextType>;
  IngridCredentials?: IngridCredentialsResolvers<ContextType>;
  IngridDeliveries?: IngridDeliveriesResolvers<ContextType>;
  IngridDeliveryAddress?: IngridDeliveryAddressResolvers<ContextType>;
  Inventory?: InventoryResolvers<ContextType>;
  InventoryAddress?: InventoryAddressResolvers<ContextType>;
  InventoryProductVariantStock?: InventoryProductVariantStockResolvers<ContextType>;
  InventoryProductVariantStockConfig?: InventoryProductVariantStockConfigResolvers<ContextType>;
  InventoryStoreMarket?: InventoryStoreMarketResolvers<ContextType>;
  InventoryStoreMarketInventories?: InventoryStoreMarketInventoriesResolvers<ContextType>;
  ItemRule?: ItemRuleResolvers<ContextType>;
  KBSGiftCardConfig?: KbsGiftCardConfigResolvers<ContextType>;
  KBSGiftCardCredentials?: KbsGiftCardCredentialsResolvers<ContextType>;
  KeyValue?: KeyValueResolvers<ContextType>;
  KlarnaCheckoutConfig?: KlarnaCheckoutConfigResolvers<ContextType>;
  KlarnaCredentials?: KlarnaCredentialsResolvers<ContextType>;
  KlarnaShippingAssistantAddon?: KlarnaShippingAssistantAddonResolvers<ContextType>;
  KlarnaShippingAssistantAddress?: KlarnaShippingAssistantAddressResolvers<ContextType>;
  KlarnaShippingAssistantCarrierProduct?: KlarnaShippingAssistantCarrierProductResolvers<ContextType>;
  KlarnaShippingAssistantLocation?: KlarnaShippingAssistantLocationResolvers<ContextType>;
  KlarnaShippingAssistantLocationTimeslot?: KlarnaShippingAssistantLocationTimeslotResolvers<ContextType>;
  KlarnaShippingAssistantSelectedShippingOption?: KlarnaShippingAssistantSelectedShippingOptionResolvers<ContextType>;
  LogicHolder?: LogicHolderResolvers<ContextType>;
  MarketPrice?: MarketPriceResolvers<ContextType>;
  MarketRoundingRule?: MarketRoundingRuleResolvers<ContextType>;
  Money?: MoneyResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  MutationOrderManagement?: MutationOrderManagementResolvers<ContextType>;
  NShiftConfig?: NShiftConfigResolvers<ContextType>;
  NShiftCredentials?: NShiftCredentialsResolvers<ContextType>;
  NshiftAddress?: NshiftAddressResolvers<ContextType>;
  NshiftPreparedShipment?: NshiftPreparedShipmentResolvers<ContextType>;
  NshiftService?: NshiftServiceResolvers<ContextType>;
  OperationResult?: OperationResultResolvers<ContextType>;
  Order?: OrderResolvers<ContextType>;
  OrderAddress?: OrderAddressResolvers<ContextType>;
  OrderAttributes?: OrderAttributesResolvers<ContextType>;
  OrderBonus?: OrderBonusResolvers<ContextType>;
  OrderBundle?: OrderBundleResolvers<ContextType>;
  OrderCancellation?: OrderCancellationResolvers<ContextType>;
  OrderCompensation?: OrderCompensationResolvers<ContextType>;
  OrderCompensationTotals?: OrderCompensationTotalsResolvers<ContextType>;
  OrderDelivery?: OrderDeliveryResolvers<ContextType>;
  OrderDeliveryTotals?: OrderDeliveryTotalsResolvers<ContextType>;
  OrderDiscountCodeRule?: OrderDiscountCodeRuleResolvers<ContextType>;
  OrderDiscountExternalRule?: OrderDiscountExternalRuleResolvers<ContextType>;
  OrderDiscountOutcome?: OrderDiscountOutcomeResolvers<ContextType>;
  OrderDiscountRule?: OrderDiscountRuleResolvers<ContextType>;
  OrderGift?: OrderGiftResolvers<ContextType>;
  OrderGiftCard?: OrderGiftCardResolvers<ContextType>;
  OrderGiftCardBSGGiftCard?: OrderGiftCardBsgGiftCardResolvers<ContextType>;
  OrderGiftCardEmpty?: OrderGiftCardEmptyResolvers<ContextType>;
  OrderGiftCardItemBSGGiftCard?: OrderGiftCardItemBsgGiftCardResolvers<ContextType>;
  OrderGiftCardItemKBSGiftCard?: OrderGiftCardItemKbsGiftCardResolvers<ContextType>;
  OrderGiftCardItemRetain24?: OrderGiftCardItemRetain24Resolvers<ContextType>;
  OrderGiftCardKBSGiftCard?: OrderGiftCardKbsGiftCardResolvers<ContextType>;
  OrderGiftCardProduct?: OrderGiftCardProductResolvers<ContextType>;
  OrderGiftCardProductEmpty?: OrderGiftCardProductEmptyResolvers<ContextType>;
  OrderGiftCardProductItem?: OrderGiftCardProductItemResolvers<ContextType>;
  OrderGiftCardProductItemRetain24?: OrderGiftCardProductItemRetain24Resolvers<ContextType>;
  OrderGiftCardProductRetain24?: OrderGiftCardProductRetain24Resolvers<ContextType>;
  OrderGiftCardRetain24?: OrderGiftCardRetain24Resolvers<ContextType>;
  OrderLine?: OrderLineResolvers<ContextType>;
  OrderLineDiscountOutcome?: OrderLineDiscountOutcomeResolvers<ContextType>;
  OrderOperationResult?: OrderOperationResultResolvers<ContextType>;
  OrderPayment?: OrderPaymentResolvers<ContextType>;
  OrderPaymentAdyen?: OrderPaymentAdyenResolvers<ContextType>;
  OrderPaymentBrinkZeroPayment?: OrderPaymentBrinkZeroPaymentResolvers<ContextType>;
  OrderPaymentBrinkZeroPaymentOriginalProvider?: OrderPaymentBrinkZeroPaymentOriginalProviderResolvers<ContextType>;
  OrderPaymentKlarnaCheckout?: OrderPaymentKlarnaCheckoutResolvers<ContextType>;
  OrderPaymentQliroOne?: OrderPaymentQliroOneResolvers<ContextType>;
  OrderPaymentQliroOneOrderItem?: OrderPaymentQliroOneOrderItemResolvers<ContextType>;
  OrderPaymentSveaCheckout?: OrderPaymentSveaCheckoutResolvers<ContextType>;
  OrderPaymentWalleyCheckout?: OrderPaymentWalleyCheckoutResolvers<ContextType>;
  OrderPromotion?: OrderPromotionResolvers<ContextType>;
  OrderPromotionEmpty?: OrderPromotionEmptyResolvers<ContextType>;
  OrderPromotionItemVoyado?: OrderPromotionItemVoyadoResolvers<ContextType>;
  OrderPromotionVoyado?: OrderPromotionVoyadoResolvers<ContextType>;
  OrderRefund?: OrderRefundResolvers<ContextType>;
  OrderRefundTotals?: OrderRefundTotalsResolvers<ContextType>;
  OrderRelease?: OrderReleaseResolvers<ContextType>;
  OrderReservation?: OrderReservationResolvers<ContextType>;
  OrderSearchHit?: OrderSearchHitResolvers<ContextType>;
  OrderSearchHits?: OrderSearchHitsResolvers<ContextType>;
  OrderShipping?: OrderShippingResolvers<ContextType>;
  OrderShippingEmpty?: OrderShippingEmptyResolvers<ContextType>;
  OrderShippingIngrid?: OrderShippingIngridResolvers<ContextType>;
  OrderShippingKlarnaShippingAssistant?: OrderShippingKlarnaShippingAssistantResolvers<ContextType>;
  OrderShippingNshift?: OrderShippingNshiftResolvers<ContextType>;
  OrderShippingQliroIntegratedShipping?: OrderShippingQliroIntegratedShippingResolvers<ContextType>;
  OrderShippingWalleyDeliveryModule?: OrderShippingWalleyDeliveryModuleResolvers<ContextType>;
  OrderStatus?: OrderStatusResolvers<ContextType>;
  OrderStatusLogEntry?: OrderStatusLogEntryResolvers<ContextType>;
  OrderStatusOperations?: OrderStatusOperationsResolvers<ContextType>;
  OrderStatusTransactionBonus?: OrderStatusTransactionBonusResolvers<ContextType>;
  OrderStatusTransactionGift?: OrderStatusTransactionGiftResolvers<ContextType>;
  OrderStatusTransactionGiftCard?: OrderStatusTransactionGiftCardResolvers<ContextType>;
  OrderStatusTransactionGiftCardProduct?: OrderStatusTransactionGiftCardProductResolvers<ContextType>;
  OrderStatusTransactionOrderLine?: OrderStatusTransactionOrderLineResolvers<ContextType>;
  OrderStatusTransactionPayment?: OrderStatusTransactionPaymentResolvers<ContextType>;
  OrderStatusTransactionShippingFee?: OrderStatusTransactionShippingFeeResolvers<ContextType>;
  OrderStatusTransactionVoucher?: OrderStatusTransactionVoucherResolvers<ContextType>;
  OrderStatusTransactions?: OrderStatusTransactionsResolvers<ContextType>;
  OrderStatusTransactionsCompensated?: OrderStatusTransactionsCompensatedResolvers<ContextType>;
  OrderTotals?: OrderTotalsResolvers<ContextType>;
  OrderVoucher?: OrderVoucherResolvers<ContextType>;
  OrderVoucherEmpty?: OrderVoucherEmptyResolvers<ContextType>;
  OrderVoucherItemVoyado?: OrderVoucherItemVoyadoResolvers<ContextType>;
  OrderVoucherVoyado?: OrderVoucherVoyadoResolvers<ContextType>;
  Outcome?: OutcomeResolvers<ContextType>;
  PaymentProvider?: PaymentProviderResolvers<ContextType>;
  PlatformConfig?: PlatformConfigResolvers<ContextType>;
  Price?: PriceResolvers<ContextType>;
  PriceRecord?: PriceRecordResolvers<ContextType>;
  PriceRule?: PriceRuleResolvers<ContextType>;
  ProductParent?: ProductParentResolvers<ContextType>;
  ProductParentSearchHit?: ProductParentSearchHitResolvers<ContextType>;
  ProductParentSearchHits?: ProductParentSearchHitsResolvers<ContextType>;
  ProductVariant?: ProductVariantResolvers<ContextType>;
  ProductVariantReservation?: ProductVariantReservationResolvers<ContextType>;
  ProductVariantRules?: ProductVariantRulesResolvers<ContextType>;
  ProductVariantSearchHit?: ProductVariantSearchHitResolvers<ContextType>;
  ProductVariantSearchHits?: ProductVariantSearchHitsResolvers<ContextType>;
  PromotionProvider?: PromotionProviderResolvers<ContextType>;
  Provider?: ProviderResolvers<ContextType>;
  ProviderStatusLog?: ProviderStatusLogResolvers<ContextType>;
  ProviderStatusLogEntry?: ProviderStatusLogEntryResolvers<ContextType>;
  QliroOneConfig?: QliroOneConfigResolvers<ContextType>;
  QliroOneCredentials?: QliroOneCredentialsResolvers<ContextType>;
  QliroOneIntegratedShipping?: QliroOneIntegratedShippingResolvers<ContextType>;
  QliroShipmentOrderItem?: QliroShipmentOrderItemResolvers<ContextType>;
  QliroShipmentOrderItemMetadata?: QliroShipmentOrderItemMetadataResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  QueryConfigurationManagement?: QueryConfigurationManagementResolvers<ContextType>;
  QueryConfigurationManagementValidate?: QueryConfigurationManagementValidateResolvers<ContextType>;
  QueryOrderManagement?: QueryOrderManagementResolvers<ContextType>;
  QueryPlatformConfig?: QueryPlatformConfigResolvers<ContextType>;
  QueryProductManagement?: QueryProductManagementResolvers<ContextType>;
  Reason?: ReasonResolvers<ContextType>;
  RefundBonus?: RefundBonusResolvers<ContextType>;
  RefundFee?: RefundFeeResolvers<ContextType>;
  RefundGift?: RefundGiftResolvers<ContextType>;
  RefundGiftCard?: RefundGiftCardResolvers<ContextType>;
  RefundOrderLine?: RefundOrderLineResolvers<ContextType>;
  RefundShippingFee?: RefundShippingFeeResolvers<ContextType>;
  RefundVoucher?: RefundVoucherResolvers<ContextType>;
  RefundedPayment?: RefundedPaymentResolvers<ContextType>;
  ReleaseBonus?: ReleaseBonusResolvers<ContextType>;
  ReleaseGift?: ReleaseGiftResolvers<ContextType>;
  ReleaseGiftCard?: ReleaseGiftCardResolvers<ContextType>;
  ReleaseGiftCardProduct?: ReleaseGiftCardProductResolvers<ContextType>;
  ReleaseOrderLine?: ReleaseOrderLineResolvers<ContextType>;
  ReleaseShippingFee?: ReleaseShippingFeeResolvers<ContextType>;
  ReleasedPayment?: ReleasedPaymentResolvers<ContextType>;
  Retain24Config?: Retain24ConfigResolvers<ContextType>;
  Retain24Credentials?: Retain24CredentialsResolvers<ContextType>;
  RoundingRule?: RoundingRuleResolvers<ContextType>;
  RoundingRules?: RoundingRulesResolvers<ContextType>;
  Rule?: RuleResolvers<ContextType>;
  Schema?: SchemaResolvers<ContextType>;
  ShippingFee?: ShippingFeeResolvers<ContextType>;
  ShippingProvider?: ShippingProviderResolvers<ContextType>;
  ShippingTaxGroup?: ShippingTaxGroupResolvers<ContextType>;
  Stock?: StockResolvers<ContextType>;
  StockInventory?: StockInventoryResolvers<ContextType>;
  StoreGroup?: StoreGroupResolvers<ContextType>;
  StoreGroupPrice?: StoreGroupPriceResolvers<ContextType>;
  StoreGroupSearchHit?: StoreGroupSearchHitResolvers<ContextType>;
  StoreGroupSearchHits?: StoreGroupSearchHitsResolvers<ContextType>;
  StoreMarket?: StoreMarketResolvers<ContextType>;
  StoreMarketSearchHit?: StoreMarketSearchHitResolvers<ContextType>;
  StoreMarketSearchHits?: StoreMarketSearchHitsResolvers<ContextType>;
  SubOutcome?: SubOutcomeResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  SveaCheckoutConfig?: SveaCheckoutConfigResolvers<ContextType>;
  SveaCredentials?: SveaCredentialsResolvers<ContextType>;
  TagCondition?: TagConditionResolvers<ContextType>;
  TagKeys?: TagKeysResolvers<ContextType>;
  TagValues?: TagValuesResolvers<ContextType>;
  TaxGroup?: TaxGroupResolvers<ContextType>;
  TaxGroupSearchHit?: TaxGroupSearchHitResolvers<ContextType>;
  TaxGroupSearchHits?: TaxGroupSearchHitsResolvers<ContextType>;
  TaxMarket?: TaxMarketResolvers<ContextType>;
  TaxMarketSearchHit?: TaxMarketSearchHitResolvers<ContextType>;
  TaxMarketSearchHits?: TaxMarketSearchHitsResolvers<ContextType>;
  UsagePlan?: UsagePlanResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserDetails?: UserDetailsResolvers<ContextType>;
  UsersList?: UsersListResolvers<ContextType>;
  ValidDateRange?: ValidDateRangeResolvers<ContextType>;
  ValidateConfigResult?: ValidateConfigResultResolvers<ContextType>;
  VoucherProvider?: VoucherProviderResolvers<ContextType>;
  VoyadoConfig?: VoyadoConfigResolvers<ContextType>;
  VoyadoCredentials?: VoyadoCredentialsResolvers<ContextType>;
  WalleyCheckoutConfig?: WalleyCheckoutConfigResolvers<ContextType>;
  WalleyCredentials?: WalleyCredentialsResolvers<ContextType>;
  WalleyDeliveryModuleDeliveryDate?: WalleyDeliveryModuleDeliveryDateResolvers<ContextType>;
  WalleyDeliveryModuleDestination?: WalleyDeliveryModuleDestinationResolvers<ContextType>;
  WalleyDeliveryModuleOption?: WalleyDeliveryModuleOptionResolvers<ContextType>;
  WalleyDeliveryModuleShipment?: WalleyDeliveryModuleShipmentResolvers<ContextType>;
  WalleyDeliveryModuleShipping?: WalleyDeliveryModuleShippingResolvers<ContextType>;
  WalleyDeliveryModuleShippingChoice?: WalleyDeliveryModuleShippingChoiceResolvers<ContextType>;
};

export type DirectiveResolvers<ContextType = any> = {
  aws_api_key?: Aws_Api_KeyDirectiveResolver<any, any, ContextType>;
  aws_auth?: Aws_AuthDirectiveResolver<any, any, ContextType>;
  aws_cognito_user_pools?: Aws_Cognito_User_PoolsDirectiveResolver<any, any, ContextType>;
  aws_iam?: Aws_IamDirectiveResolver<any, any, ContextType>;
  aws_lambda?: Aws_LambdaDirectiveResolver<any, any, ContextType>;
  aws_oidc?: Aws_OidcDirectiveResolver<any, any, ContextType>;
  aws_publish?: Aws_PublishDirectiveResolver<any, any, ContextType>;
  aws_subscribe?: Aws_SubscribeDirectiveResolver<any, any, ContextType>;
  defer?: DeferDirectiveResolver<any, any, ContextType>;
};
