import React from "react"
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import { Query } from "@lib/types/common"
import { useQuery } from "@apollo/client"
import esb from "elastic-builder"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"

import SEARCH_ORDERS_TOTAL_HITS from "graphql/queries/order/SearchOrdersTotalHits"

import { Wrapper, Totals, Label, PeriodSelection } from "./LastFiveDays.styled"

//import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
//import SecondaryButton from "components/Ui/Buttons/SecondaryButton"

export const LastFiveDays = () => {
  //const [range, setRange] = useState<string>("24h")

  dayjs.extend(utc)

  /*
  const getHourQuery = (hour: number) => {
    return esb
      .requestBodySearch()
      .query(
        esb
          .boolQuery()
          .must([
            esb
              .rangeQuery("date")
              .gt(dayjs().subtract(hour, "hour").startOf("hour").utc().format())
              .lte(dayjs().subtract(hour, "hour").endOf("hour").utc().format())
          ])
      )
  }
  */

  const getQuery = (days: number) => {
    return esb.requestBodySearch().query(
      esb.boolQuery().must([
        esb
          .rangeQuery("date")
          .gt(
            dayjs()
              .subtract(days + 1, "days")
              .startOf("day")
              .format("YYYY-MM-DD")
          )
          .lte(dayjs().subtract(days, "days").startOf("day").format("YYYY-MM-DD"))
          .format("yyyy-MM-dd")
      ])
    )
  }

  const getResults = () => {
    const queryOne = getQuery(1).toJSON() as Query
    const queryTwo = getQuery(2).toJSON() as Query
    const queryThree = getQuery(3).toJSON() as Query
    const queryFour = getQuery(4).toJSON() as Query
    const queryFive = getQuery(5).toJSON() as Query

    const { data: dataOne } = useQuery(SEARCH_ORDERS_TOTAL_HITS, {
      variables: { from: 0, size: 1000, query: JSON.stringify(queryOne.query) }
    })
    const { data: dataTwo } = useQuery(SEARCH_ORDERS_TOTAL_HITS, {
      variables: { from: 0, size: 1000, query: JSON.stringify(queryTwo.query) }
    })
    const { data: dataThree } = useQuery(SEARCH_ORDERS_TOTAL_HITS, {
      variables: { from: 0, size: 1000, query: JSON.stringify(queryThree.query) }
    })
    const { data: dataFour } = useQuery(SEARCH_ORDERS_TOTAL_HITS, {
      variables: { from: 0, size: 1000, query: JSON.stringify(queryFour.query) }
    })
    const { data: dataFive } = useQuery(SEARCH_ORDERS_TOTAL_HITS, {
      variables: { from: 0, size: 1000, query: JSON.stringify(queryFive.query) }
    })
    if (
      dataOne?.searchOrders &&
      dataTwo?.searchOrders &&
      dataThree?.searchOrders &&
      dataFour?.searchOrders &&
      dataFive?.searchOrders
    ) {
      return [
        {
          Orders: dataOne?.searchOrders.total,
          label: dayjs().subtract(1, "days").format("YYYY-MM-DD")
        },
        {
          Orders: dataTwo?.searchOrders.total,
          label: dayjs().subtract(2, "days").format("YYYY-MM-DD")
        },
        {
          Orders: dataThree?.searchOrders.total,
          label: dayjs().subtract(3, "days").format("YYYY-MM-DD")
        },
        {
          Orders: dataFour?.searchOrders.total,
          label: dayjs().subtract(4, "days").format("YYYY-MM-DD")
        },
        {
          Orders: dataFive?.searchOrders.total,
          label: dayjs().subtract(5, "days").format("YYYY-MM-DD")
        }
      ].reverse()
    }
  }

  /*
  const getOrdersByHour = () => {
    const yesterday = [...Array(24)].map((_, h) => h).slice(dayjs().hour() - 1)
    const today = [...Array(24)].map((_, h) => h).slice(0, dayjs().hour() + 1)
    const arr = yesterday.concat(today)

    const result = arr.map((i) => {
      const queryOne = getHourQuery(i).toJSON() as Query
      const { data } = useQuery(SEARCH_ORDERS_TOTAL_HITS, {
        variables: { from: 0, size: 1000, query: JSON.stringify(queryOne.query) }
      })

      return { Orders: data?.searchOrders.total, label: i < 9 ? `0${i}` : `${i}` }
    })

    return result
  }
  */

  //const ordersByHour = getOrdersByHour()
  const ordersByDays = getResults()

  return (
    <>
      <Wrapper>
        <Label>
          <div>Order count last 5 days</div>
          <div>
            <PeriodSelection>
              <Totals>
                Total orders:{" "}
                {ordersByDays?.reduce(
                  (acc: number, order: { Orders: number; label: string }) => acc + order.Orders,
                  0
                )}
              </Totals>
              {/* 
              {range === "24h" ? (
                <SecondaryButton handleClick={() => setRange("5days")}>Last 5 days</SecondaryButton>
              ) : (
                <PrimaryButton handleClick={() => setRange("5days")}>Last 5 days</PrimaryButton>
              )}
              {range === "5days" ? (
                <SecondaryButton handleClick={() => setRange("24h")}> Last 24hs</SecondaryButton>
              ) : (
                <PrimaryButton handleClick={() => setRange("24h")}>Last 24hs</PrimaryButton>
              )}
              */}
            </PeriodSelection>
          </div>
        </Label>

        {/*ordersByHour && range === "24h" && (
          <>
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart data={ordersByHour} margin={{ left: 0, right: 30, top: 60, bottom: 30 }} height={500}>
                <YAxis />
                <XAxis dataKey="label" className="xAxis" tickMargin={15} />
                <Area
                  animationDuration={500}
                  type="natural"
                  dataKey="Orders"
                  stroke="#91ecda"
                  fill="#f4fdfb"
                  strokeWidth={5}
                  dot={{ stroke: "#91ecda", strokeWidth: 5 }}
                  activeDot={{ stroke: "#91ecda", strokeWidth: 7 }}
                />
                <Tooltip cursor={false} itemStyle={{ color: "black", fontWeight: 700 }} />
              </AreaChart>
            </ResponsiveContainer>
          </>
        )*/}

        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={ordersByDays}
            margin={{ left: 0, right: 30, top: 60, bottom: 30 }}
            height={500}
          >
            <YAxis />
            <XAxis dataKey="label" className="xAxis" tickMargin={15} />
            <Area
              animationDuration={500}
              type="natural"
              dataKey="Orders"
              stroke="#91ecda"
              fill="#f4fdfb"
              strokeWidth={5}
              dot={{ stroke: "#91ecda", strokeWidth: 5 }}
              activeDot={{ stroke: "#91ecda", strokeWidth: 7 }}
            />
            <Tooltip cursor={false} itemStyle={{ color: "black", fontWeight: 700 }} />
          </AreaChart>
        </ResponsiveContainer>
      </Wrapper>
    </>
  )
}

export default LastFiveDays
